import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  > strong {
    color: #f5f5f5;
    font-size: 2.25rem;
    margin-bottom: 24px;
    text-align: center;
  }

  > span {
    color: #58c78f;
    text-align: center;
    font-weight: bold;
    margin-bottom: 32px;
  }

  > input {
    min-width: 250px;
    max-width: 250px;
    margin-bottom: 16px;
  }

  > div {
    min-width: 250px;
    max-width: 250px;
    margin-bottom: 16px;
  }

  > button {
    min-width: 250px;
  }
`;
