import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Content = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RoundBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  max-width: 350px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 135px;
    height: 135px;
    padding: 8px;

    border-radius: 50%;
    border: 8px solid #0a9a56;

    + div {
      margin-top: 24px;
    }

    > strong {
      font-size: 1.5rem;
      color: #f5f5f5;
      font-weight: normal;
    }

    > span {
      font-size: 1rem;
      color: #f5f5f5;
      text-align: center;
    }
  }

  @media (max-width: 1080px) {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 48px;
    max-width: unset;
    flex-wrap: wrap;

    > div {
      margin: 16px;

      + div {
        margin-top: 0;
      }
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 0 80px;

  > p {
    font-size: 1.1rem;
    color: #f5f5f5;
    line-height: 1.75;
    text-align: center;

    + p {
      margin-top: 24px;
    }
  }

  > button {
    margin: 24px auto;
  }
`;

export const Bold = styled.strong`
  color: #f5f5f5;
`;

export const GreenBold = styled(Bold)`
  color: #0a9a56;
`;

export const MrStoomContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  max-width: 350px;

  > img {
    width: 100%;
    height: auto;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  animation: ${fadeIn} 0.25s ease forwards 1;

  > section {
    align-items: flex-start;
    justify-content: center;
    margin: 50px 100px;

    @media (max-width: 1600px) {
      margin: 50px 0;

      > div:nth-of-type(2) {
        padding: 0 32px;
      }
      > div:nth-of-type(1),
      > div:nth-of-type(3) {
        max-width: 300px;
      }
    }

    @media (max-width: 1300px) {
      > div:nth-of-type(1),
      > div:nth-of-type(3) {
        max-width: 250px;
      }
    }
    @media (max-width: 1080px) {
      align-items: center;
      > div:nth-of-type(1) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;
