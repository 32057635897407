export const currencyBRL = (
  value: string | number,
  withPrefix = true, // Coloca o prefixo R$
  divided = true, // Divide o valor por 100 (3150 -> 31.50)
  discount = false, // 10% de desconto
): string => {
  let parseVal = parseFloat(`${value}`);
  // if (discount) parseVal = parseVal * 0.93;
  // eslint-disable-next-line
  if (discount) parseVal = parseVal * 0.9;
  return new Intl.NumberFormat('pt-BR', {
    style: withPrefix ? 'currency' : undefined,
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(!divided ? parseVal : parseVal / 100);
};

export const currencySpread = (
  value: string | number,
  divided = true,
  discount = false,
): { integer: number | string; cents: number | string } => {
  let parseVal = parseFloat(`${value}`);
  // if (discount) parseVal *= 0.93;
  if (discount) parseVal *= 0.9;
  const formatValue = new Intl.NumberFormat('pt-BR', {
    style: undefined,
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(!divided ? parseVal : parseVal / 100);
  const [integer, cents] = formatValue.split(',');

  return { integer, cents };
};

export const parseInputMask = (date: string): string => {
  const dateNumber = date.replace(/[^\d]+/g, '');

  const dateDD = `${dateNumber.substr(0, 2)}`;
  if (date.length < 3) {
    if (parseInt(dateDD, 10) > 31) {
      return `${dateDD.substr(0, 1)}`;
    }
    return dateDD;
  }

  const dateMM = `${dateNumber.substr(2, 2)}`;
  if (date.length > 2 && date.length < 5) {
    if (parseInt(dateMM, 10) > 12) {
      return `${dateDD}/${dateMM.substr(0, 1)}`;
    }
    return `${dateDD}/${dateMM}`;
  }

  return `${dateDD}/${dateMM}/${dateNumber.substr(4, 4)}`;
};

export const formatCEP = (cep: string): string => {
  return `${cep.substr(0, 5)}-${cep.substr(5, 3)}`;
};

export const formatContact = (contact: string): string => {
  return `(${contact.substr(0, 2)}) ${contact.substr(2, 5)}-${contact.substr(
    7,
    11,
  )}`;
};

export const formatCpfCnpj = (udoc?: string): string => {
  let result = '';
  if (udoc) {
    const regexVal = `${udoc}`.replace(/[^\d]+/g, '');
    if (regexVal.length === 11) {
      result = `${regexVal.substr(0, 3).padEnd(3, '_')}.${regexVal
        .substr(3, 3)
        .padEnd(3, '_')}.${regexVal
        .substr(6, 3)
        .padEnd(3, '_')}-${regexVal.substr(9, 2).padEnd(2, '_')}`;
    } else {
      result = `${regexVal.substr(0, 2).padEnd(2, '_')}.${regexVal
        .substr(2, 3)
        .padEnd(3, '_')}.${regexVal
        .substr(5, 3)
        .padEnd(3, '_')}/${regexVal
        .substr(8, 4)
        .padEnd(4, '_')}-${regexVal.substr(12, 2).padEnd(2, '_')}`;
    }
  }
  return result;
};
export const formatDate = (date?: string): string => {
  let result = '';
  if (date) {
    try {
      result = new Date(`${date} 12:00`).toLocaleDateString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
      });
    } catch (err) {
      // console.log('DateParseErr');
      result = '';
    }
  }
  return result;
};

export const parseQueryStringToArrayNumber = (
  query?: string,
): number[] | undefined => {
  if (!query) return undefined;
  const parsedJson = JSON.parse(query);

  if (Array.isArray(parsedJson)) {
    const fixedQuery = parsedJson
      .map((item) => {
        // eslint-disable-next-line
        if (Number(item) !== NaN || item === '0') return item;
        return undefined;
      })
      .filter((item) => item !== undefined);
    return fixedQuery as number[];
  }
  return undefined;
};
