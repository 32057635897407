import React from 'react';

import { ReactComponent as TitleStarsSVG } from 'assets/draws/estrelas.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/icons/arrowRight.svg';

import RatingStars from 'components/RatingStars';
import Carousel from 'components/Carousel';
import SectionTitle from 'components/SectionTitle';

import { Container, Main, RateContainer } from './styles';

interface Rate {
  id: number;
  title: string;
  text: string;
  name: string;
  rate: number;
  total: number;
}

export interface RatingProps {
  rates: Rate[];
  stars: number;
  total: number;
}

// const total = 135;
// const stars = 4;
// const data: Rate[] = [
//   {
//     id: 1,
//     title: 'Melhor all day',
//     text:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.',
//     name: 'Isaias Tavares',
//     stars: 4,
//     total: 120,
//   },
//   {
//     id: 2,
//     title: 'Tinha que ter de litro',
//     text:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Integer nec odio. Praesent libero',
//     name: 'Luan Nomi',
//     stars: 4,
//     total: 100,
//   },
//   {
//     id: 3,
//     title: 'Maravilhoso!',
//     text:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Integer nec odio. Praesent libero',
//     name: 'Mirella Fuchs',
//     stars: 4,
//     total: 125,
//   },
//   {
//     id: 4,
//     title: 'Melhor all day',
//     text:
//       'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero.',
//     name: 'Nicolas Noah Bernardes',
//     stars: 4,
//     total: 120,
//   },
// ];

export const Ratings: React.FC<RatingProps> = ({ rates, stars, total }) => {
  return (
    <Container>
      <SectionTitle title="Avaliações" icon={<TitleStarsSVG />} />

      <Main>
        <div>
          <strong>Avaliação geral</strong>
          <RatingStars
            total={total}
            rate={total !== 0 ? stars : 0}
            direction="column"
            calc
            showTotal
          />
        </div>
        {rates.length !== 0 && (
          <Carousel
            totalSlides={rates.length}
            slidesPerPage={3}
            onWidth1366={2}
            onWidth1000={1}
            useMediaQuery
            fullWidth
            minHeight="215px"
            btnInside={false}
          >
            {rates.map((item) => (
              <RateContainer key={item.id}>
                <RatingStars rate={item.rate} total={item.total} />

                <strong>{item.title}</strong>

                <p>{item.text}</p>

                <div>
                  <strong>{item.name}</strong>
                  <span>({item.total} avaliações feitas)</span>
                </div>
              </RateContainer>
            ))}
          </Carousel>
        )}
      </Main>
    </Container>
  );
};

export default Ratings;
