import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { v4 } from 'uuid';

import { processProducts, checkPromo, ApplyDiscount } from 'utils/promo';

import { useNotification } from './notification';

interface ItemDef {
  id: string;
  idProduct: number;
  quantity: number;
  price: number;
  priceDiscount?: number;
  name: string;
  img: string;
}

export type CartItemJuice = {
  type: 'juice';
  bottle: number;
  nic: number;
  nicType?: 1 | 2;
  isOfficial?: boolean;
  proportion: number;
  mint?: number;
  promoQtd?: number;
} & ItemDef;

type CartItemVape = {
  type: 'vape';
  color?: number;
} & ItemDef;

type CartItemAccessory = {
  type: 'accessory';
  color?: number;
} & ItemDef;

export type NewCartItem =
  | Omit<CartItemJuice, 'id'>
  | Omit<CartItemAccessory, 'id'>
  | Omit<CartItemVape, 'id'>;

export type CartItem = CartItemJuice | CartItemAccessory | CartItemVape;

interface CartProductsWithPromo {
  nicSalt: ApplyDiscount;
  nicFree: ApplyDiscount;
  discountNicSalt: number;
  discountNicFree: number;
  discountTotal: number;
}

interface PropsContext {
  importOrder: (newCart: CartItem[]) => void;
  addItem: (newItem: NewCartItem) => void;
  removeItem: (id: string) => void;
  clearCart: () => void;
  changeQuantity: (id: string, action: '+' | '-' | number) => void;
  cartProducts: CartItem[];
  cartProductsPromo: CartProductsWithPromo;
  total: number;
}

const CartContext = createContext<PropsContext>({} as PropsContext);

const CartProvider: React.FC = ({ children }) => {
  const { addNotification } = useNotification();

  const [cart, setCart] = useState<CartItem[]>(() => {
    const storage = localStorage.getItem('@Constellation:Cart');
    if (!storage) return [];

    const parsedStorage = JSON.parse(storage);
    if (parsedStorage) {
      localStorage.setItem(
        '@Constellation:Cart',
        JSON.stringify(parsedStorage),
      );
      return parsedStorage;
    }

    return [];
  });

  const findEqualByType = (
    type: 'juice' | 'vape' | 'accessory',
    itemLoop: CartItem,
    newItem: Omit<CartItem, 'id'>,
  ): boolean => {
    if (type === 'juice') {
      const itemL = itemLoop as CartItemJuice;
      const itemN = newItem as Omit<CartItemJuice, 'id'>;
      if (itemL.isOfficial !== itemN.isOfficial) return false;
      if (
        itemL.nic === itemN.nic &&
        itemL.proportion === itemN.proportion &&
        itemL.bottle === itemN.bottle &&
        itemL.mint === itemN.mint
      ) {
        return true;
      }
      return false;
    }
    if (type === 'vape') {
      const itemL = itemLoop as CartItemVape;
      const itemN = newItem as Omit<CartItemVape, 'id'>;
      if (itemL.color === itemN.color) return true;
      return false;
    }

    const itemL = itemLoop as CartItemAccessory;
    const itemN = newItem as Omit<CartItemAccessory, 'id'>;
    if (itemL.color === itemN.color) return true;
    return false;
  };

  const addItem = useCallback(
    (newItem: NewCartItem): void => {
      let newCartData = [...cart];
      const thisItem = { ...newItem, id: v4() } as any;

      if (cart.length > 0) {
        const filteredItems = cart.filter((cartI) => {
          if (cartI.idProduct !== newItem.idProduct) return false;
          if (cartI.type !== newItem.type) return false;
          return findEqualByType(newItem.type, cartI, newItem);
        });
        // console.log('AfterFilter', filteredItems.length, filteredItems);

        if (filteredItems.length > 0) {
          const newCartItem: any = { ...thisItem };

          filteredItems.forEach((item) => {
            newCartItem.quantity += item.quantity;
          });
          // console.log('NewFetch', newCartItem);
          newCartData = [
            ...newCartData.filter((item) => {
              const thisItemOnFiltered = filteredItems.filter(
                (filteredI) => filteredI.id === item.id,
              );
              if (thisItemOnFiltered.length > 0) return false;

              return true;
            }),
            newCartItem,
          ];
        } else {
          newCartData = [...newCartData, thisItem];
        }
      } else {
        newCartData = [thisItem];
      }

      setCart(newCartData);
      // console.log('CartHookNewCart', newCartData);
      const itemProps: any = {};
      if (thisItem?.type === 'juice' && thisItem.isOficial) {
        itemProps.nic = thisItem.nic?.id || undefined;
        itemProps.proportion = thisItem.proportion?.id || undefined;
        itemProps.bottle = thisItem.bottle?.id || undefined;
        itemProps.mint = thisItem?.mint?.id || undefined;
      }

      addNotification({
        id: thisItem.id,
        img: thisItem.img,
        type: 'product',
        name: thisItem.name,
        quantity: thisItem.quantity,
        ...itemProps,
        color: thisItem?.color || undefined,
      });
    },
    [cart],
  );

  const removeItem = useCallback(
    (id: string): void => {
      setCart(cart.filter((cartI) => cartI.id !== id));
    },
    [cart],
  );

  const changeQuantity = useCallback(
    (id: string, action: '+' | '-' | number) => {
      setCart(
        cart.map((cartI) => {
          if (cartI.id === id) {
            let newQuantity = cartI.quantity;
            if (action === '+') {
              newQuantity += 1;
            }
            if (action === '-') {
              newQuantity -= 1;
            }
            if (typeof action === 'number') {
              newQuantity = action;
            }
            return { ...cartI, quantity: newQuantity };
          }
          return { ...cartI };
        }),
      );
    },
    [cart],
  );

  const clearCart = useCallback(() => {
    setCart([]);
    localStorage.setItem('@Constellation:Cart', JSON.stringify([]));
  }, []);

  const importOrder = useCallback((newCart: CartItem[]) => {
    setCart(newCart);
    localStorage.setItem('@Constellation:Cart', JSON.stringify(newCart));
  }, []);

  interface JuiceSameProp {
    [key: number]: CartItemJuice[];
  }

  const totalCart = useMemo(() => {
    let result = 0;

    cart.forEach((item) => {
      if (item.priceDiscount) {
        result += item.priceDiscount * item.quantity;
        return;
      }
      result += item.price * item.quantity;
    });

    return result;
  }, [cart]);

  const processCartPromo = useMemo((): CartProductsWithPromo => {
    const productsNicFree: CartItemJuice[] = [];
    const productsNicSalt: CartItemJuice[] = [];

    cart.forEach((item) => {
      if (item.type === 'juice' && item.isOfficial) {
        if (item?.nicType === 1) {
          productsNicFree.push(item);
        } else if (item?.nicType === 2) {
          productsNicSalt.push(item);
        }
      }
    });

    const productNicFree: JuiceSameProp = processProducts(productsNicFree);
    const productNicSalt: JuiceSameProp = processProducts(productsNicSalt, 2);

    const nicFreePromo = checkPromo(productNicFree);
    const nicSaltPromo = checkPromo(productNicSalt);

    // setCartPromo(result);

    // console.log('Discounts', {
    //   nicFree: nicFreePromo.products,
    //   nicSalt: nicSaltPromo.products,
    //   discountNicFree: nicFreePromo.discount,
    //   discountNicSalt: nicSaltPromo.discount,
    //   discountTotal: nicFreePromo.discount + nicSaltPromo.discount,
    // });
    return {
      nicFree: nicFreePromo.products,
      nicSalt: nicSaltPromo.products,
      discountNicFree: nicFreePromo.discount,
      discountNicSalt: nicSaltPromo.discount,
      discountTotal: nicFreePromo.discount + nicSaltPromo.discount,
    };
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('@Constellation:Cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider
      value={{
        changeQuantity,
        importOrder,
        addItem,
        removeItem,
        clearCart,
        cartProducts: cart,
        total: totalCart,
        cartProductsPromo: processCartPromo,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCart(): PropsContext {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within an CartProvider');
  }

  return context;
}

export { CartProvider, useCart };
