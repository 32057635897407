import styled, { css } from 'styled-components';

interface ContainerProps {
  direction: 'row' | 'column';
  styleInput?: boolean;
  flex?: string;
  maxWidth?: string;
  hidden?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;

  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}

  > span {
    color: #ffffff;
    font-size: 1.1rem;
  }

  ${({ direction }) =>
    direction === 'row'
      ? css`
          flex-direction: row;
          > span {
            margin-right: 8px;
          }
        `
      : css`
          flex-direction: column;
          > span {
            margin-bottom: 8px;
            width: 100%;
            text-align: left;
          }
        `}

  ${({ styleInput }) =>
    styleInput &&
    css`
      input {
        height: 40px;
        background-color: #fff;
        border-radius: 5px;
        color: #261231;
        font-weight: bold !important;
        padding: 10px 8px;
        font-size: 0.9rem;
        width: 100%;

        &::placeholder {
          color: #261231;
          font-weight: bold;
        }
      }
    `}


    ${({ hidden }) =>
    hidden &&
    css`
      display: none;
      opacity: 0;
    `}
`;
