import React, { createContext, useContext, useState } from 'react';

import { swalFire } from 'services/swal';
import { api, handleRespReturn, handleErrors } from 'services/api';

interface FavoritesContextData {
  favorites: number[];
  updateFav: (id: number, token?: string) => void;
}

const FavoritesContext = createContext<FavoritesContextData>(
  {} as FavoritesContextData,
);

const FavoritesProvider: React.FC = ({ children }) => {
  const [favorites, setFavorites] = useState<number[]>(() => {
    const favStorage = localStorage.getItem('@Constellation:Favorites');
    if (!favStorage) return [];

    const parsedFav = JSON.parse(favStorage);
    if (!parsedFav || typeof parsedFav !== 'object') return [];
    if (Number.isNaN(parsedFav[0])) return [];

    return parsedFav;
  });

  const updateFav = async (id: number, token?: string): Promise<void> => {
    let newFav = [];
    let action = 0;
    if (!favorites.includes(id)) action = 1;

    if (token) {
      try {
        const resp = await api().request({
          url: `/products/favorite`,
          method: 'POST',
          data: { idProduct: id },
        });

        const isErrored = handleRespReturn(resp);
        if (isErrored || !resp.data.content) return;
      } catch (err) {
        handleErrors(err, true);
        return;
      }
    }

    if (action === 0) {
      newFav = favorites.filter((item) => item !== id);
      setFavorites(newFav);
      swalFire({
        text: 'Produto removido dos favoritos.',
        icon: 'success',
        toast: true,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-right',
      });
    } else {
      swalFire({
        text: 'Produto adicionado aos favoritos.',
        icon: 'success',
        toast: true,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-right',
      });
      newFav = [...favorites, id];
      setFavorites(newFav);
    }

    localStorage.setItem('@Constellation:Favorites', JSON.stringify(newFav));
  };

  return (
    <FavoritesContext.Provider value={{ favorites, updateFav }}>
      {children}
    </FavoritesContext.Provider>
  );
};

function useFavorites(): FavoritesContextData {
  const context = useContext(FavoritesContext);

  if (!context) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }

  return context;
}

export { useFavorites, FavoritesProvider };
