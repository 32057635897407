import styled, { css } from 'styled-components';

export const Container = styled.a<{ comingSoon?: boolean }>`
  position: relative;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  max-height: 175px;
  /* min-width: 225px; */
  min-width: calc(100% / 4);
  max-width: calc(100% / 4);

  /* margin: 1rem; */

  transition: filter 0.25s ease, transform 0.25s ease;

  &:hover {
    transform: scale(1.15);
    filter: brightness(125%);
  }

  > svg {
    height: 100%;
    width: auto;
    max-width: 400px;
  }

  ${({ comingSoon }) =>
    comingSoon &&
    css`
      &::after {
        content: 'Em breve';
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(-50%);

        color: #00c763;
        font-weight: bold !important;
        background-color: #ffffff60;

        border-radius: 8px;
        padding: 8px;
      }
    `}

  @media (max-width: 1300px) {
    > svg {
      padding: 16px;
    }
  }

  @media (max-width: 1150px) {
    > svg {
      padding: 16px;
    }

    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
  }

  @media (max-width: 800px) {
    > svg {
      padding: 16px;
    }

    min-width: calc(100% / 2);
    max-width: calc(100% / 2);
  }

  @media (max-width: 600px) {
    > svg {
      padding: 16px;
    }

    min-width: 100%;
    max-width: 100%;
  }
`;
