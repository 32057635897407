import React from 'react';

import Skeleton from 'react-loading-skeleton';

import { Container } from './styles';

interface SkeletonContainerProps {
  total: number;
  maxHeight?: string;
  totalAbove600?: number;
  totalAbove800?: number;
}

const SkeletonContainer: React.FC<SkeletonContainerProps> = ({
  total,
  totalAbove600 = 1,
  totalAbove800 = 1,
  maxHeight,
}) => {
  return (
    <Container
      total={total}
      maxHeight={maxHeight}
      totalAbove600={totalAbove600}
      totalAbove800={totalAbove800}
    >
      <Skeleton count={total} />
    </Container>
  );
};

export default SkeletonContainer;
