import React, { useState } from 'react';

import { swalFire } from 'services/swal';

import GreenButton from 'components/Button/GreenButton';
import InputGeneric from 'components/Input/Generic';

import { api, handleErrors, handleRespReturn } from 'services/api';

import CpfCnpj from 'components/Input/MaskedInputs/CpfCnpj';
import InputContainer from 'components/Input/Container';

import GenericModal from '../Generic';
import { Content } from './styles';

export interface ForgotMailProps {
  isShown: boolean;
  close(): void;
}

interface Form {
  email?: string;
  udoc?: string;
}

const ForgotPassword: React.FC<ForgotMailProps> = ({ isShown, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<Form>({});

  const handleSubmit = async (bool?: boolean): Promise<void> => {
    if (isLoading) return;
    if (!bool) {
      close();
      return;
    }

    if (!form?.email) {
      swalFire({
        text: 'Preencha o e-mail cadastrado.',
        icon: 'warning',
      });
      return;
    }

    if (!form?.udoc) {
      swalFire({
        text: 'Preencha o CPF/CNPJ cadastrado.',
        icon: 'warning',
      });
      return;
    }

    setIsLoading(true);
    try {
      const resp = await api().post('/user/forgotPassword', {
        email: form.email,
        udoc: form.udoc,
      });
      setIsLoading(false);

      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      swalFire({
        text: 'Verifique seu e-mail cadastrado.',
        icon: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
      return;
    }
    close();
  };

  return (
    <GenericModal
      isShown={isShown}
      returnTrigger={(bool) => handleSubmit(bool)}
      disableMinSize
    >
      <Content>
        <strong>Esqueci minha senha</strong>

        <span>
          Enviaremos em seu e-mail um link para recadastrar a sua senha e um
          código de segurança.
        </span>

        <InputGeneric
          placeholder="E-mail cadastrado"
          onChangeCustom={({ target }) => {
            setForm({ ...form, email: target.value });
          }}
          value={form?.email || ''}
        />

        <InputContainer title="" direction="column" styleInput>
          <CpfCnpj
            onChangeInput={(value) => {
              setForm({ ...form, udoc: value });
            }}
            currentValue={form.udoc}
            placeholder="CPF/CNPJ"
          />
        </InputContainer>

        <GreenButton
          text={isLoading ? 'Enviando...' : 'Enviar'}
          onClick={() => handleSubmit(true)}
        />
      </Content>
    </GenericModal>
  );
};

export default ForgotPassword;
