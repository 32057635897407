import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.article<{ current?: number }>`
  position: sticky;
  top: 0;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > div {
    position: relative;
    z-index: 120;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: 0 auto;
    width: 100%;
    background-color: rgba(24, 11, 31, 0.9);

    > button {
      position: relative;
      background-color: transparent;
      border: 0;
      font-size: 1.2rem;
      color: #f5f5f5;

      text-transform: uppercase;
      height: 60px;
      width: 350px;
      text-align: center;

      transition: filter 0.2s ease;

      &:hover {
        filter: brightness(75%);
      }

      ${({ current }) =>
        current !== undefined &&
        css`
          &:nth-of-type(${current}) {
            &::after {
              position: absolute;
              content: '';
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);

              border-width: 15px;
              border-style: solid;
              border-color: transparent transparent #4f1e66 transparent;
            }
          }
        `}
    }
  }

  > section {
    position: absolute;
    top: 60px;
    z-index: 110;
    width: 100%;

    > article {
      width: 100%;
      animation: ${fadeIn} 0.25s ease forwards;
    }
  }

  @media (max-width: 1300px) {
    > div {
      flex-direction: column;
      height: unset;

      > button {
        height: unset;
        padding: 8px;
        width: 100%;
        max-width: 600px;

        &::after {
          display: none;
        }
      }
    }

    > section {
      top: calc((1.2rem + 20px) * 3);
    }
  }

  @media (max-width: 600px) {
    > div {
      flex-direction: column;
      height: unset;

      > button {
        height: unset;
        padding: 8px;
        width: 100%;
        max-width: 600px;

        &::after {
          display: none;
        }
      }
    }

    > section {
      top: calc((1.2rem + 19px) * 3);
      max-height: calc(100vh - ((1.2rem + 19px) * 3));
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;
