import React, { HtmlHTMLAttributes, useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { Label, Container, Text } from './styles';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckChange(state: boolean): void;
}

const RadioButton: React.FC<Props> = ({
  defaultChecked = false,
  checked = false,
  children,
  onCheckChange,
  ...rest
}) => {
  const checkId = v4();
  const [check, setCheck] = useState(checked || defaultChecked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <Container {...rest}>
      <Label htmlFor={checkId}>
        <input
          type="checkbox"
          id={checkId}
          onChange={(event) => onCheckChange(event.target.checked)}
          checked={check}
        />
        <span className="circle" />
      </Label>
      <Text className="text" onClick={() => onCheckChange(!check)}>
        {children}
      </Text>
    </Container>
  );
};

export default RadioButton;
