import React from 'react';
import SectionTitle from 'components/SectionTitle';
import ProductThumbSmall from 'components/Product/ThumbSmall';
import { DefaultData } from 'components/Product/ProductDTO';
import ProductCarousel from 'components/Carousel/ProductCarousel';

import { ReactComponent as MeteoroSVG } from 'assets/backgrounds/meteoro.svg';
import { ReactComponent as AlienSVG } from 'assets/draws/alienOhm.svg';

import { Container } from './styles';

export interface BoughtTogetherProps {
  products: DefaultData[];
}

// const tempProducts: DefaultData[] = [
//   {
//     id: 1,
//     name: 'Aludra',
//     data: { price: 3150 },
//     type: 'juice',
//   },
//   {
//     id: 2,
//     name: 'Aludra',
//     data: { price: 3150 },
//     type: 'juice',
//   },
//   {
//     id: 3,
//     name: 'Aludra',
//     data: { price: 3150 },
//     type: 'juice',
//   },
//   {
//     id: 4,
//     name: 'Aludra',
//     data: { price: 3150 },
//     type: 'juice',
//   },
//   {
//     id: 5,
//     name: 'Aludra',
//     data: { price: 3150 },
//     type: 'juice',
//   },
// ];
const BoughtTogether: React.FC<BoughtTogetherProps> = ({ products }) => {
  const [productList, _] = React.useState<DefaultData[]>(products);
  return (
    <Container>
      <MeteoroSVG />

      <SectionTitle icon={<AlienSVG />} title="Aproveite e compre junto" />

      {productList.length > 0 && (
        <ProductCarousel totalItems={productList.length} productSizeType={2}>
          {productList.map((item) => (
            <ProductThumbSmall
              key={item.id}
              {...item}
              // id={item.id}
              // name={item.name}
              // type={item.type}
              // data={item.data}
              // img={item.img}
            />
          ))}
        </ProductCarousel>
      )}
    </Container>
  );
};

export default BoughtTogether;
