import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 50px 100px;

  animation: ${fadeIn} 0.25s ease forwards 1;

  > strong {
    color: #58c78f;
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1200px) {
    margin: 48px 24px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: calc((315px + 2rem) * 3);
  margin: 40px auto;

  @media (max-width: 1200px) {
    max-width: calc((315px + 2rem) * 2);
  }

  @media (max-width: 750px) {
    max-width: calc((315px + 2rem));
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 16px 40px;
    border-radius: 25px;
    background-color: #68577180;
    backdrop-filter: blur(16px);

    margin: 1rem;

    font-size: 1.2rem;
    color: #58c78f;
    height: 70px;
    min-width: 300px;
    max-width: 315px;
    width: 100%;

    transition: filter 0.25s ease;
    &:hover {
      filter: brightness(75%);
    }

    > svg {
      margin-right: 12px;
      width: 24px;
      height: 24px;
      fill: #58c78f;
    }
  }
`;
