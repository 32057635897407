import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

interface ContainerProps {
  isShowing: boolean;
  removeItem?: boolean;
}

export const Wrap = styled.article`
  position: fixed;
  top: 0;
  width: 100vw;
  animation: ${fadeIn} 0.25s forwards ease 1;
  z-index: 500;
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 0;
  width: 100%;
  transition: height 0.25s ease;

  ${({ isShowing }) =>
    isShowing &&
    css`
      margin-top: 12px;
      margin-bottom: 32px;
      height: 110px;
    `}

  > div {
    animation: ${fadeIn} 0.25s forwards ease 1;
    ${({ removeItem }) =>
      removeItem === true && ` animation: ${fadeOut} .25s forwards 1;`}
  }
`;
