import React, { useState } from 'react';

import axios, { AxiosResponse } from 'axios';
import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';
import { DeliveryOptions } from 'components/DeliveryOptions';
import { CartItem } from 'hooks/Cart';

// eslint-disable-next-line
import { default as NumberFormat } from 'react-number-format';

import GreenButton from '../Button/GreenButton';

import { Container } from './styles';

export interface CEPResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  unidade: string;
  ibge: string;
  gia: string;
  erro?: string;
}

export const getAddress = async (
  cep: string,
): Promise<CEPResponse | undefined> => {
  const cepRegex = cep.replace(/[^\d]+/g, '');
  if (cepRegex.length === 8) {
    try {
      const response: AxiosResponse<CEPResponse> = await axios.get(
        `https://viacep.com.br/ws/${cepRegex}/json/ `,
      );

      if (response.data.erro) {
        return undefined;
      }

      return response.data;
    } catch (err) {
      return undefined;
    }
  }

  return undefined;
};

export const formatAddress = (address?: CEPResponse): string => {
  if (!address) return '';
  let street = '';
  let neighborhood = '';
  let cityState = '';
  if (address.uf && address.localidade) {
    cityState = `${address.localidade}/${address.uf}`;
  }

  if (address.bairro) {
    neighborhood = `${address.localidade} - `;
  }

  if (address.logradouro) {
    street = `${address.logradouro} - `;
  }
  return `${street}${neighborhood}${cityState}`;
};

export const getDelivery = async (
  // PRODUTO ÚNICO
  idProduct?: number,
  idBottle?: number,
  qtd?: number,
  // PRODUTO ÚNICO
  // CARRINHO
  cart?: CartItem[],
  cupomCode?: string,
  // CARRINHO
  cep?: string,
): Promise<false | DeliveryOptions[]> => {
  let cartNotJson;

  if (cart) {
    cartNotJson = cart.map((item) => {
      return {
        ...item,
        id: undefined,
        img: undefined,
        name: undefined,
      };
    });
  }

  try {
    const resp = await api().get(
      `/cart/delivery?idProduct=${idProduct}&idBottle=${idBottle}&qtd=${qtd}&cep=${cep}&cart=${
        cartNotJson ? JSON.stringify(cartNotJson) : ''
      }&cupomCode=${cupomCode || ''}`,
    );
    const isErrored = handleRespReturn(resp);
    if (isErrored) return false;

    return resp.data.content;
  } catch (err) {
    handleErrors(err);
  }

  return false;
};

interface Props {
  onChangeCep(value: string): void;
  onSearchAddress(cepResponse: CEPResponse | undefined): void;
  onCalcDelivery(deliveryOptions: DeliveryOptions[]): void;
  currentValue?: string | number;
  qtd?: number;
  idProduct?: number;
  idBottle?: number;
  cart?: CartItem[];
  cupomCode?: string;
}

const SearchCEP: React.FC<Props> = ({
  onChangeCep,
  onSearchAddress,
  onCalcDelivery,
  currentValue,
  idProduct,
  idBottle,
  qtd,
  cart,
  cupomCode,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  return (
    <Container>
      <NumberFormat
        inputMode="numeric"
        displayType="input"
        format="#####-###"
        mask="_"
        value={currentValue}
        onValueChange={async (values) => {
          onChangeCep(values.value);
          // console.log(values);
          if (values.value.length === 8) {
            const cepResponse = await getAddress(values.value);
            if (cepResponse !== undefined && !cepResponse.erro) {
              onSearchAddress(cepResponse);
              return;
            }

            swalFire({
              title: 'CEP não encontrado.',
              icon: 'error',
              toast: true,
              position: 'top-right',
              timer: 3000,
              showCloseButton: false,
            });
            return;
          }
          onSearchAddress(undefined);
        }}
      />
      <GreenButton
        text="Calcular frete"
        onClick={async () => {
          // console.log('Calcular frete', currentValue, qtd, idProduct, cart);
          if (!currentValue) return;
          if (`${currentValue}`.replace(/[^\d]+/g, '').length < 8) return;
          if ((cart && cart.length > 0) || (qtd && idProduct)) {
            setIsSearching(true);
            const result = await getDelivery(
              idProduct,
              idBottle,
              qtd,
              cart,
              cupomCode,
              `${currentValue}`.replace(/[^\d]+/g, ''),
            );
            setIsSearching(false);
            if (result) {
              onCalcDelivery(result);
              return;
            }
            swalFire({
              text: 'Endereço não encontrado.',
              icon: 'warning',
            });
          }
        }}
        isLoading={isSearching}
        loadingLabel="Calculando frete"
        sideRound
      />

      <span>Prazo de postagem de 2 dias úteis</span>
    </Container>
  );
};

export default SearchCEP;
