import styled, { css } from 'styled-components';
import Menta from '../../../assets/flavours/folha_menta.svg';

interface Props {
  mintVal: number;
}

export const Container = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;
  /* width: 100%; */

  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    background: transparent; /* Otherwise white in Chrome */

    cursor: pointer;
    transition: all 0.25s ease;
    width: 100%;
    max-width: 250px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('${Menta}');
    background-color: transparent;
    border: 0;

    height: 40px;
    width: 32px;
    margin-top: -16px;

    ${(props) =>
      props.mintVal === 0 &&
      css`
        margin-left: -8px;
      `}
    ${(props) =>
      props.mintVal === 3 &&
      css`
        margin-left: 16px;
      `}
    /* transition: all 0.25s ease; */
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('${Menta}');
    height: 40px;
    width: 32px;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    ${(props) =>
      props.mintVal === 0 &&
      css`
        margin-left: -8px;
      `}
    ${(props) =>
      props.mintVal === 3 &&
      css`
        margin-left: 16px;
      `}
  }

  /* All the same stuff for IE */
  input[type='range']::-ms-thumb {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('${Menta}');
    height: 40px;
    width: 32px;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    ${(props) =>
      props.mintVal === 0 &&
      css`
        margin-left: -8px;
      `}
    ${(props) =>
      props.mintVal === 3 &&
      css`
        margin-left: 16px;
      `}
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    /* background: transparent;
    border-color: transparent; */
    color: transparent;
    background: url('${Menta}');
    height: 32px;
    width: 32px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    /* background: linear-gradient(
      90deg,
      rgba(0, 199, 99, 0.4) 0%,
      rgba(0, 199, 99, 0.4) 33%,
      rgba(0, 199, 99, 0.6) 33%,
      rgba(0, 199, 99, 0.6) 66%,
      rgba(0, 199, 99, 1) 66%,
      rgba(0, 199, 99, 1) 100%
    ); */
    background: linear-gradient(
      90deg,
      #99e9c1 0%,
      #99e9c1 33%,
      #66dda1 33%,
      #66dda1 66%,
      #33d282 66%,
      #33d282 100%
    );
    border-radius: 5px;
    border: 0;
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 15px;
    background: linear-gradient(
      90deg,
      #99e9c1 0%,
      #99e9c1 33%,
      #66dda1 33%,
      #66dda1 66%,
      #33d282 66%,
      #33d282 100%
    );
    /* background: linear-gradient(
      90deg,
      rgba(0, 199, 99, 0.4) 0%,
      rgba(0, 199, 99, 0.4) 33%,
      rgba(0, 199, 99, 0.6) 33%,
      rgba(0, 199, 99, 0.6) 66%,
      rgba(0, 199, 99, 1) 66%,
      rgba(0, 199, 99, 1) 100%
    ); */
    border-radius: 5px;
    border: 0;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    height: 15px;
    background: linear-gradient(
      90deg,
      #99e9c1 0%,
      #99e9c1 33%,
      #66dda1 33%,
      #66dda1 66%,
      #33d282 66%,
      #33d282 100%
    );
    /* background: linear-gradient(
      90deg,
      rgba(0, 199, 99, 0.4) 0%,
      rgba(0, 199, 99, 0.4) 33%,
      rgba(0, 199, 99, 0.6) 33%,
      rgba(0, 199, 99, 0.6) 66%,
      rgba(0, 199, 99, 1) 66%,
      rgba(0, 199, 99, 1) 100%
    ); */
    border-radius: 5px;
    border: 0;
  }

  input[type='range']::-ms-fill-upper {
    height: 15px;
    background: linear-gradient(
      90deg,
      #99e9c1 0%,
      #99e9c1 33%,
      #66dda1 33%,
      #66dda1 66%,
      #33d282 66%,
      #33d282 100%
    );
    /* background: linear-gradient(
      90deg,
      rgba(0, 199, 99, 0.4) 0%,
      rgba(0, 199, 99, 0.4) 33%,
      rgba(0, 199, 99, 0.6) 33%,
      rgba(0, 199, 99, 0.6) 66%,
      rgba(0, 199, 99, 1) 66%,
      rgba(0, 199, 99, 1) 100%
    ); */
    border-radius: 5px;
    border: 0;
  }

  span {
    position: absolute;

    left: calc(${(props) => props.mintVal * 33}% - 24px);
    bottom: -40px;

    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;

    color: #009245;
    transition: all 0.25s ease;
    /* font-family: 'Ubuntu', sans-serif; */
    text-align: center;
  }
`;
