import React, { useState, useEffect } from 'react';

import { currencyBRL } from 'utils/format';
import RadioButton from 'components/Button/RadioButton';
import { Container } from './styles';

export interface DeliveryOptions {
  id: number;
  label: string;
  deliveryDays: number;
  deliveryTime: string;
  price: number;
  isFree?: boolean;
}

interface Props {
  onChange(option?: DeliveryOptions): void;
  options?: DeliveryOptions[];
}

const DeliveryOptions: React.FC<Props> = ({ options, onChange }) => {
  const [selected, setSelected] = useState<number | undefined>();

  useEffect(() => {
    setSelected(undefined);
    onChange(undefined);
  }, [options]);

  return (
    <Container>
      {options &&
        options.length > 0 &&
        options.map((item) => (
          <div key={item.id}>
            <RadioButton
              checked={selected === item.id}
              onCheckChange={() => {
                setSelected(item.id);
                onChange(item);
              }}
            >
              {item.label}
            </RadioButton>

            <span>{item.deliveryTime}</span>

            <span>
              {item.isFree ? 'Frete grátis' : currencyBRL(item.price)}
            </span>
          </div>
        ))}
    </Container>
  );
};

export default DeliveryOptions;
