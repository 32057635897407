import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

interface FireProps extends SweetAlertOptions {
  title?: string;
  icon: SweetAlertIcon;
  text?: string;
}

export const swalFire = ({ icon, title, text, ...rest }: FireProps): void => {
  let defTitle;
  if (!title && icon) {
    if (icon === 'error') {
      defTitle = 'Oops...';
    }
    if (icon === 'success') {
      defTitle = 'Sucesso';
    }
    if (icon === 'warning') {
      defTitle = 'Aviso!';
    }
    if (icon === 'info') {
      defTitle = 'Aviso!';
    }
  }

  Swal.fire({
    ...rest,
    title: title || defTitle,
    text,
    icon,
    background: '#4f1e66',
    confirmButtonColor: '#00c763',
    customClass: {
      title: 'txt-fff',
      content: 'txt-fff',
    },
  });
};

export const swalToast = ({
  icon,
  title,
  text,
  timer = 3000,
  ...rest
}: FireProps): void => {
  let defTitle;
  if (!title && icon) {
    if (icon === 'error') {
      defTitle = 'Oops...';
    }
    if (icon === 'success') {
      defTitle = 'Sucesso';
    }
    if (icon === 'warning') {
      defTitle = 'Aviso!';
    }
    if (icon === 'info') {
      defTitle = 'Aviso!';
    }
  }

  Swal.fire({
    ...rest,
    title: title || defTitle,
    toast: true,
    timer,
    position: 'top-right',
    text,
    icon,
    background: '#4f1e66',
    confirmButtonColor: '#00c763',
    customClass: {
      title: 'txt-fff',
      content: 'txt-fff',
    },
  });
};

export const swalMixin = ({
  icon,
  title,
  text,
  ...rest
}: FireProps): typeof Swal => {
  return Swal.mixin({
    ...rest,
    title,
    text,
    icon,
    background: '#4f1e66',
    confirmButtonColor: '#00c763',
    customClass: {
      title: 'txt-fff',
      content: 'txt-fff',
    },
  });
};

export const swalPromise = ({
  icon,
  title = 'Tem certeza desta ação?',
  html,
  cancelButtonText = 'Não, quero cancelar.',
  confirmButtonText = 'Sim, quero prosseguir.',
  ...rest
}: FireProps) => {
  return Swal.fire({
    ...rest,
    title,
    html,
    icon,
    background: '#4f1e66',
    customClass: {
      cancelButton: 'cancel-btn',
      confirmButton: 'confirm-btn',
      title: 'txt-fff',
      content: 'txt-fff',
    },
    cancelButtonText,
    confirmButtonText,
    confirmButtonColor: '#00c763',
    showCancelButton: true,
    reverseButtons: true,
  });
};
