import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;

  background-color: #4f1e66;
  width: 100%;
  height: 275px;
  padding: 32px;

  @media (max-width: 1300px) {
    height: unset;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: 650px) {
    height: unset;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
`;
