import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';
import { FiPlus } from 'react-icons/fi';
import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';

import ModalAddress, {
  AddressProps,
  RespAddressModal,
} from 'components/Modal/Address';
import { formatCEP, formatContact } from 'utils/format';
import { ReactComponent as EntregaSVG } from 'assets/icons/Entrega.svg';
import RadioButton from 'components/Button/RadioButton';
import { Container, AddAddress, CardAddress } from './styles';

export interface Addresses {
  id: number;
  receiver: string;
  name: string;
  street: string;
  number: string;
  info?: string;
  district: string;
  cep: string;
  state: string;
  city: string;
  contact: string;
  favorite?: boolean;
}

interface Props {
  enableReq?: boolean;
}

const Address: React.FC<Props> = ({ enableReq }) => {
  const [modalProps, setModalProps] = useState<AddressProps>({
    isShown: false,
  });

  const [addresses, setAddresses] = useState<Addresses[] | undefined>(
    undefined,
  );

  const handleNewOrEditAddress = (thisAddress?: Addresses): void => {
    const response: RespAddressModal = async (
      isConfirmed,
      newAddress,
      idAddress,
    ) => {
      if (isConfirmed && newAddress) {
        if (thisAddress && !idAddress) {
          swalFire({
            text: 'Ocorreu um erro, recarregue e tente novamente.',
            icon: 'error',
          });

          return;
        }
        try {
          const resp = await api().request({
            url: '/user/profile/address',
            method: 'POST',
            data: { idAddress, address: newAddress },
          });

          const isErrored = handleRespReturn(resp);
          if (isErrored) return;
          let newAddresses = addresses ? [...addresses] : [];
          if (!idAddress) {
            newAddresses.push({ id: resp.data.content, ...newAddress });
          } else {
            // console.log(idAddress, newAddress);
            newAddresses = newAddresses.map((adI) => {
              if (adI.id === idAddress) {
                // console.log(adI.id, idAddress, newAddress);
                return { id: idAddress, ...newAddress };
              }
              return { ...adI };
            });
          }
          setAddresses([...newAddresses]);
        } catch (err) {
          handleErrors(err, true);
          return;
        }
      }
      setModalProps({ isShown: false });
    };

    setModalProps({
      isShown: true,
      handleResponse: response,
      editAddress: thisAddress,
      idAddress: thisAddress?.id,
    });
  };

  const handleFavorite = async (item: Addresses): Promise<void> => {
    if (!addresses) return;

    if (item.favorite) {
      swalFire({
        text: 'Esse endereço já é o endereço principal.',
        icon: 'info',
      });
      return;
    }

    try {
      const resp = await api().request({
        url: `/user/profile/address/favorite?idAddress=${item.id}`,
        method: 'POST',
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      setAddresses([
        ...addresses.map((adI) => {
          return { ...adI, favorite: adI.id === item.id };
        }),
      ]);

      swalFire({
        text: 'Endereço favoritado.',
        icon: 'success',
      });
    } catch (err) {
      handleErrors(err, true);
    }
  };

  const handleDeleteAddress = async (item: Addresses): Promise<void> => {
    if (!addresses) return;
    if (item.favorite === true) {
      swalFire({
        text: 'Endereços principais não podem ser deletados.',
        icon: 'warning',
      });
      return;
    }

    try {
      const resp = await api().request({
        url: `/user/profile/address?idAddress=${item.id}`,
        method: 'DELETE',
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      setAddresses([...addresses.filter((adI) => adI.id !== item.id)]);
    } catch (err) {
      handleErrors(err, true);
    }
  };

  useEffect(() => {
    const getAddress = async (): Promise<void> => {
      try {
        const resp = await api().request({
          url: `/user/profile/address`,
          method: 'GET',
        });
        const isErrored = handleRespReturn(resp);
        if (isErrored) return;
        // console.log(resp.data);
        setAddresses([...resp.data.content]);
      } catch (err) {
        handleErrors(err, true);
      }
    };

    // console.log('FixAdd', addresses, addresses?.length, enableReq);
    if ((!addresses || addresses.length === 0) && enableReq) {
      // console.log('getAdd');
      getAddress();
    }
  }, [enableReq]);

  return (
    <Container>
      <ModalAddress {...modalProps} />

      <AddAddress onClick={() => handleNewOrEditAddress()}>
        <article>
          <FiPlus />
        </article>
        <strong>Novo endereço</strong>
      </AddAddress>

      {addresses === undefined && <Skeleton count={3} />}
      {addresses !== undefined &&
        addresses.length > 0 &&
        addresses.map((item) => (
          <CardAddress key={item.id}>
            <header>
              <EntregaSVG />
              <strong>{item.name}</strong>

              <div>
                <button
                  type="button"
                  onClick={() => handleNewOrEditAddress(item)}
                >
                  Editar
                </button>
                {!item.favorite && (
                  <button
                    type="button"
                    onClick={() => handleDeleteAddress(item)}
                  >
                    Excluir
                  </button>
                )}
              </div>
            </header>

            <main>
              <div>
                <strong>{item.receiver}</strong>

                <span>
                  {item.street},&nbsp;
                  {item.number}
                </span>

                {item.info ? <span>{item.info}</span> : 'Sem complemento'}

                <span>{item.district}</span>

                <span>
                  {formatCEP(item.cep)}&nbsp;|&nbsp;
                  {item.city}&nbsp;-&nbsp;
                  {item.state}
                </span>

                <span>
                  {item.contact ? formatContact(item.contact) : 'Sem contato'}
                </span>
              </div>

              <div>
                <RadioButton
                  checked={item.favorite}
                  onCheckChange={() => handleFavorite(item)}
                >
                  Selecionar como endereço principal
                </RadioButton>
              </div>
            </main>
          </CardAddress>
        ))}
    </Container>
  );
};

export default Address;
