import styled, { css } from 'styled-components';

interface ContainerProps {
  total: number;
  maxHeight?: string;
  totalAbove800: number;
  totalAbove600: number;
}

export const Container = styled.article<ContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;

  /* padding: 1rem; */

  ${({ maxHeight }) =>
    maxHeight &&
    css`
      min-height: ${maxHeight};
      max-height: ${maxHeight};
    `}

  > span {
    display: flex;
    justify-content: space-between;

    flex: 1;
    width: 100%;
    height: 100%;

    > span {
      flex: 1;
      width: 100%;
      height: 100%;
      ${({ total }) => `max-width: calc(100% / ${total} - 1rem);`}
    }
  }

  + div {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    > span {
      > span {
        margin: 0 8px;
        ${({ totalAbove800 }) =>
          `max-width: calc(100% / ${totalAbove800} - 1rem);`}
      }

      > span:nth-of-type(${({ totalAbove800 }) => totalAbove800}) ~ span {
        display: none;
      }
    }
  }

  @media (max-width: 600px) {
    > span {
      > span:nth-of-type(${({ totalAbove600 }) => totalAbove600}) {
        ${({ totalAbove600 }) =>
          `max-width: calc(100% / ${totalAbove600} - 1rem);`}
        margin: 0 8px;
      }

      > span:nth-of-type(${({ totalAbove600 }) => totalAbove600}) ~ span {
        display: none;
      }
    }
  }
`;
