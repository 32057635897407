import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: calc(1.25rem + 6px + 6px);
  margin-top: 6px;
  width: 100%;

  > input {
    color: #261231;
    font-size: 1.5rem;

    padding: 6px 12px;
    width: 100%;
    height: 35px;
    text-align: center;
    font-weight: bold;

    border: 1px solid #fff;
  }
`;

export const Error = styled.span<{ isShown: boolean }>`
  position: absolute;
  bottom: -6px;
  transform: translateY(100%);

  font-size: 1rem;
  font-weight: bold;
  color: #e15b60;

  opacity: 0;
  visibility: hidden;
  user-select: none;

  transition: opacity 0.25s ease;

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;
