import React, { HTMLAttributes } from 'react';

import { InputField } from './styles';

interface Props extends HTMLAttributes<HTMLInputElement> {
  onChangeInput: (value?: string) => void;
  currentValue?: string;
  readOnly?: boolean;
}

const InputCC: React.FC<Props> = ({
  onChangeInput,
  currentValue,
  readOnly,
  ...rest
}) => {
  return (
    <InputField
      {...rest}
      readOnly={readOnly}
      disabled={readOnly}
      type="text"
      value={currentValue || ''}
      onChange={({ target }) => {
        const regexVal = target.value.replace(/[^\d]+/g, '');
        const valSize = regexVal.length;
        if (valSize <= 4) {
          onChangeInput(regexVal);
          return;
        }
        if (valSize > 4 && valSize <= 8) {
          onChangeInput(`${regexVal.substr(0, 4)} ${regexVal.substr(4, 4)}`);
          return;
        }
        if (valSize > 8 && valSize <= 12) {
          onChangeInput(
            `${regexVal.substr(0, 4)} ${regexVal.substr(
              4,
              4,
            )} ${regexVal.substr(8, 4)}`,
          );
          return;
        }
        onChangeInput(
          `${regexVal.substr(0, 4)} ${regexVal.substr(4, 4)} ${regexVal.substr(
            8,
            4,
          )} ${regexVal.substr(12, 4)}`,
        );
      }}
    />
  );
};

export default InputCC;
