import React, { useEffect, useState, useMemo } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';

import SkeletonContainer from 'components/Skeleton';
import ProductThumbSmall from 'components/Product/ThumbSmall';
import InputContainer from 'components/Input/Container';
import InputGeneric from 'components/Input/Generic';
import { Input } from 'components/Input/Generic/styles';
import { DefaultData as Products } from 'components/Product/ProductDTO';

import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import GreenButton from 'components/Button/GreenButton';
import PriceSlider from './PriceSlider';

import FilterContainer from './FilterContainer';

import {
  Container,
  ButtonContainer,
  Content,
  Row,
  ColumnFilter,
  FilterSection,
  ProductSection,
  ConfigContainer,
  CategoryContainer,
} from './styles';

type QueryParam = string | undefined;
export type TypeFilter = 'atty' | 'mod' | 'kit' | 'pod' | 'accessory' | 'juice';

export interface FilterConfig {
  subType?: string;
  brand?: string;
  category?: TypeFilter;
}

interface Filters {
  price: [number | undefined, number | undefined];
}

const Filtros: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  // const [urlQuery, setUrlQuery] = useState(
  //   new URLSearchParams(location.search || ''),
  // );

  const [filterConfig, setFilterConfig] = useState<FilterConfig | undefined>(
    undefined,
  );

  const [currentOrder, setCurrentOrder] = useState<number | undefined>(5);

  const [filterName, setFilterName] = useState<string | undefined>();
  const [products, setProducts] = useState<Products[] | undefined>();

  useEffect(() => {
    // console.log('EffectLocation', location.search);
    const urlQuery = new URLSearchParams(location.search || '');
    const category: TypeFilter | undefined =
      (urlQuery.get('category') as TypeFilter) || undefined;
    if (category) {
      const brand: QueryParam = urlQuery.get('brand') || undefined;
      const subType: QueryParam = urlQuery.get('subType') || undefined;

      setFilterConfig({
        category,
        brand,
        subType,
      });
      setProducts(undefined);
    }

    // eslint-disable-next-line
  }, [location]);

  // useEffect(() => {
  //   document
  //     .querySelector('#main-content')
  //     ?.scrollTo({ top: 0, behavior: 'smooth' });
  //   // console.log('FilterUrl', filterConfig);
  // }, [filterConfig]);

  const handleOrderChange = (target: 1 | 2 | 3 | 4 | 5 | 6): void => {
    if (!products) return;
    if (target === currentOrder) return;
    setCurrentOrder(target);

    let orderedProducts = products;

    if (target === 1 || target === 2) {
      const returnTrue = target === 1 ? -1 : 1;
      const returnFalse = target === 1 ? 1 : -1;

      orderedProducts = products.sort((a: Products, b: Products) => {
        return a.data.price < b.data.price ? returnTrue : returnFalse;
      });
    }

    if (target === 3) {
      orderedProducts = products.sort((a: Products, b: Products) => {
        if (!a.rankPoints || !b.rankPoints) return 0;

        return a.rankPoints < b.rankPoints ? -1 : 1;
      });
    }

    if (target === 4) {
      orderedProducts = products.sort((a: Products, b: Products) => {
        const aDate = new Date(a.dtAdd).getTime();
        const bDate = new Date(b.dtAdd).getTime();
        return aDate < bDate ? 1 : -1;
      });
    }

    if (target === 5) {
      orderedProducts = products.sort((a: Products, b: Products) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    }

    if (target === 6) {
      orderedProducts = products.sort((a: Products, b: Products) => {
        return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
      });
    }

    setProducts(orderedProducts);
  };

  const handleFilter = async (newFilter: any): Promise<void> => {
    // console.log('HandleFilter', newFilter);
    if (!filterConfig?.category) return;

    const filterBody = {
      ...newFilter,
      type: filterConfig?.category,
    };

    // console.log('HandleFilterReq', filterBody);

    let query = `?type=${filterConfig.category}`;
    if (newFilter?.priceMin) query += `&priceMin=${newFilter?.priceMin}`;
    if (newFilter?.priceMax) query += `&priceMax=${newFilter?.priceMax}`;

    if (newFilter?.brand?.length) {
      query += `&brand=${JSON.stringify(newFilter.brand)}`;
    }

    if (newFilter?.profile?.length) {
      query += `&profile=${JSON.stringify(newFilter.profile)}`;
    }

    if (newFilter?.flavour?.length) {
      query += `&flavour=${JSON.stringify(newFilter.flavour)}`;
    }

    if (newFilter?.subType?.length) {
      query += `&subType=${JSON.stringify(newFilter.subType)}`;
    }

    if (newFilter?.nicType) query += `&nicType=${newFilter.nicType}`;

    try {
      const resp = await api().request({
        url: `/products/filter${query}`,
        method: 'GET',
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored || !resp.data.content) return;
      // console.log(resp.data.content);
      setProducts(
        resp.data.content.sort((a: Products, b: Products) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        }),
      );
    } catch (err) {
      handleErrors(err, true);
    }
  };

  const labelParse = useMemo((): string | undefined => {
    if (filterConfig?.category === 'accessory') return 'acessórios';
    // if (filterConfig?.category === 'mod') return 'mods';
    // if (filterConfig?.category === 'kit') return 'kits';
    // if (filterConfig?.category === 'atty') return 'atomizadores';
    if (filterConfig?.category === 'pod') return 'pods';
    if (filterConfig?.category === 'juice') return 'juices';

    return undefined;
  }, [filterConfig?.category]);

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <h1>
        {labelParse ? (
          <>
            Você está buscando pela categoria <b>{labelParse}</b>
          </>
        ) : (
          ''
        )}
      </h1>

      <strong>
        {labelParse ? (
          <>
            Exibindo resultados para <b>{labelParse}</b>
          </>
        ) : (
          ''
        )}
      </strong>

      <Content>
        <ConfigContainer>
          <Input
            placeholder="Filtre por nome"
            className="invert"
            onChange={({ target }) => {
              setFilterName(target.value);
            }}
            value={filterName || ''}
          />

          <ButtonContainer mlAuto>
            <GreenButton
              icon={<IoMdArrowDropdown />}
              text="R$"
              onClick={() => handleOrderChange(1)}
              outline={currentOrder !== 1}
            />
            <GreenButton
              icon={<IoMdArrowDropup />}
              text="R$"
              onClick={() => handleOrderChange(2)}
              outline={currentOrder !== 2}
            />
            <GreenButton
              outline={currentOrder !== 3}
              text="Destaques"
              onClick={() => handleOrderChange(3)}
            />
            <GreenButton
              outline={currentOrder !== 4}
              text="Recentes"
              onClick={() => handleOrderChange(4)}
            />
            <GreenButton
              outline={currentOrder !== 5}
              icon={<IoMdArrowDropdown />}
              text="Alfabética"
              onClick={() => handleOrderChange(5)}
            />
            <GreenButton
              outline={currentOrder !== 6}
              icon={<IoMdArrowDropup />}
              text="Alfabética"
              onClick={() => handleOrderChange(6)}
            />
          </ButtonContainer>
        </ConfigContainer>

        <Row>
          <FilterSection>
            {(!filterConfig || !filterConfig.category) && (
              <ColumnFilter>
                <strong>Selecione uma categoria</strong>

                <CategoryContainer>
                  {/* <GreenButton
                    text="Mod"
                    onClick={() => {
                      history.push('/filter?category=mods');
                      setFilterConfig({ ...filterConfig, category: 'mod' });
                    }}
                    outline
                  />

                  <GreenButton
                    text="Atomizador"
                    onClick={() => {
                      history.push('/filter?category=atty');
                      setFilterConfig({ ...filterConfig, category: 'atty' });
                    }}
                    outline
                  /> */}

                  <GreenButton
                    text="Pod"
                    onClick={() => {
                      history.push('/filter?category=pod');
                      setFilterConfig({ ...filterConfig, category: 'pod' });
                    }}
                    outline
                  />

                  {/* <GreenButton
                    text="Kit"
                    onClick={() => {
                      history.push('/filter?category=kit');
                      setFilterConfig({ ...filterConfig, category: 'kit' });
                    }}
                    outline
                  /> */}

                  <GreenButton
                    text="Juices"
                    onClick={() => {
                      history.push('/filter?category=juice');
                      setFilterConfig({ ...filterConfig, category: 'juice' });
                    }}
                    outline
                  />

                  <GreenButton
                    text="Acessório"
                    onClick={() => {
                      history.push('/filter?category=accessory');
                      setFilterConfig({
                        ...filterConfig,
                        category: 'accessory',
                      });
                    }}
                    outline
                  />
                </CategoryContainer>
              </ColumnFilter>
            )}

            <FilterContainer
              filter={filterConfig}
              handleFilter={handleFilter}
            />
          </FilterSection>

          <ProductSection>
            {products === undefined && (
              <SkeletonContainer total={4} maxHeight="315px" />
            )}

            {products !== undefined && products.length === 0 && (
              <strong>Nenhum produto encontrado</strong>
            )}

            {products !== undefined && products.length > 0 && (
              <div>
                {products
                  .filter((item) => {
                    if (filterName) {
                      return item.name
                        .toLowerCase()
                        .includes(filterName.toLowerCase());
                    }
                    return true;
                  })
                  .map((item) => (
                    <ProductThumbSmall
                      key={item.id}
                      {...item}
                      // id={item.id}
                      // name={item.name}
                      // type={item.type}
                      // data={item.data}
                      // img={item.img}
                    />
                  ))}
              </div>
            )}
          </ProductSection>
        </Row>
      </Content>
    </Container>
  );
};

export default Filtros;

/* <strong>Filtros</strong>

<div>
   <strong>Preço</strong>
   <PriceSlider max={100} />
</div> */
