import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  > section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 50px 100px;
  }

  @media (max-width: 1400px) {
    > section {
      margin: 50px 40px;
    }
  }

  @media (max-width: 1200px) {
    > section {
      flex-direction: column;
      margin: 16px 16px;
    }
  }
`;

export const NavButton = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: transparent;

  padding: 12px 24px;
  color: #f5f5f5;
  text-align: left;
  font-weight: bold !important;

  transition: background-color 0.25s ease;

  &:hover {
    background-color: rgba(0, 199, 99, 0.5);
  }

  &:not(:last-child) {
    border-bottom: #00c763 1px solid;
  }

  @media (max-width: 1200px) {
    border: 1px solid #00c763 !important;
    text-align: center;
  }
`;

export const Nav = styled.nav<{ current: number }>`
  display: flex;
  flex-direction: column;
  border: #00c763 1px solid;
  border-left: 0;
  margin-right: 50px;
  min-width: 300px;

  > button:nth-of-type(${({ current }) => current}) {
    background-color: #00c763;

    transition: filter 0.25s ease;

    &:hover {
      filter: brightness(75%);
    }
  }

  @media (max-width: 1200px) {
    margin-right: 0;
    margin-bottom: 50px;
    width: 100%;
    min-width: unset;
    margin-left: -8px;
  }
`;

export const Content = styled.main<{ current: number }>`
  display: flex;
  flex: 1;

  > section {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s ease;
    animation: ${fadeOut} 0.25s ease;

    ${({ current }) => css`
      &:nth-of-type(${current}) {
        opacity: 1;
        position: unset;
        visibility: visible;

        animation: ${fadeIn} 0.25s ease;
      }
    `}
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;
