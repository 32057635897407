import React, { useEffect, useState, useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import { useHistory, useParams } from 'react-router-dom';

import { CupomState } from 'components/DiscountCupom';

import { ReactComponent as ResumoSVG } from 'assets/icons/Resumo.svg';
import { ReactComponent as PagamentoSVG } from 'assets/icons/Pagamento.svg';
import { ReactComponent as EntregaSVG } from 'assets/icons/Entrega.svg';
import {
  currencyBRL,
  formatCEP,
  formatContact,
  currencySpread,
} from 'utils/format';
import { checkPromo, processProducts } from 'utils/promo';

import { api, handleErrors, handleRespReturn } from 'services/api';

import { useAuth } from 'hooks/auth';
import { CartItem } from 'hooks/Cart';
import CartProduct from 'components/Cart/CartItem';
import { swalFire } from 'services/swal';

import Payment, { paymentTypes } from 'pages/Profile/Order/OrderInfo/Payment';

import WppHelpButton from 'components/WppHelpButton';

import {
  Container,
  OrderContainer,
  Card,
  MainDelivery,
  ProductContainer,
  MainPayment,
  OrderObs,
} from './styles';

interface Shipment {
  label: string;
  deliveryTime: number;
  price: number;
  isFree?: boolean;
}

interface Address {
  name: string;
  street: string;
  number: string;
  info?: string;
  district: string;
  cep: string;
  state: string;
  city: string;
  contact?: string;
}

interface Delivery {
  address: Address;
  shipment: Shipment;
}

interface PaymentProps {
  promoNicFree?: { amount: number; price: number };
  promoNicSalt?: { amount: number; price: number };
  delivery: number;
  cupom?: CupomState;
  method: any;
  discount10: number;
  subTotal: number;
  total: number;
}

export interface Order {
  id: number;
  ticket: string;
  products: CartItem[];
  delivery: Delivery;
  payment: PaymentProps;
  obs?: string;
}

interface Params {
  hashOrder?: string;
}

const CheckoutDone: React.FC = () => {
  const { hashOrder } = useParams<Params>();
  const { token } = useAuth();
  const history = useHistory();

  const [order, setOrder] = useState<Order | undefined>();

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    const redirectOut = () => history.push('/');
    const getCart = async (hash: string): Promise<void> => {
      try {
        const resp = await api().request({
          url: `/checkout?hash=${hash}&resume=true`,
          method: 'GET',
        });
        const isErrored = handleRespReturn(resp);
        if (isErrored) {
          // redirectOut();
          return;
        }
        console.log('Resp', resp.data.content);
        const { content } = resp.data;
        const thisOrder = { ...content };

        let thisCupom = thisOrder?.payment?.cupom;

        if (
          thisCupom &&
          thisCupom?.code &&
          thisCupom?.discount &&
          thisCupom?.type
        ) {
          const { discount, type: discountType } = thisCupom;
          let discountVal = 0;

          if (discountType === 'fixed') {
            discountVal = discount;
          } else {
            let subTotal = thisOrder.payment.subTotal;

            if (content.payment?.promoNicFree?.price)
              subTotal -= content.payment.promoNicFree.price;

            if (content.payment?.promoNicSalt?.price)
              subTotal -= content.payment.promoNicSalt.price;

            discountVal = subTotal * discount;
          }

          thisCupom = { ...thisCupom, discountVal };
        }

        // const paymentType = content.payment.type;
        // const payment = { type: paymentType };

        // if (paymentType === 'CC') {
        //   payment = {
        //     type: 'CC',
        //     cardNumber: content.payment.cardNumber,
        //     count:  content.payment.count
        //     subTotal: content.payment
        //   }
        // }

        // let total = content.payment.subTotal;
        // const total10 = total * 0.1;
        // if (payment?.type !== 'CC') {
        //   total -= total10;
        // }

        // if (thisCupom && thisCupom?.discountVal) {
        //   total -= thisCupom.discountVal;
        // }

        // if (!content.delivery.shipment.isFree) {
        //   total += content.payment.delivery;
        // }

        // thisOrder.payment.total = total;

        thisOrder.payment.cupom = thisCupom;
        // thisOrder.payment.type = payment;
        // console.log('Fixx', thisOrder);
        setOrder(thisOrder);
      } catch (err) {
        console.log('Fixx', err);
        // redirectOut();
        handleErrors(err, true);
      }
    };

    if (!token) {
      swalFire({ text: 'Acesso recusado.', icon: 'error' });
      // redirectOut();
    }

    if (hashOrder && token && !order) {
      getCart(hashOrder);
    } else {
      // redirectOut();
    }
    // eslint-disable-next-line
  }, [hashOrder, token]);

  const processCartPromo = useMemo((): any => {
    if (!order?.products) return undefined;
    const productsNicFree: any[] = [];
    const productsNicSalt: any[] = [];

    order?.products.forEach((item: any) => {
      if (item.type === 'juice' && item.isOfficial && item.nicType) {
        if (item.nicType === 1) {
          productsNicFree.push(item);
        } else if (item.nicType === 2) {
          productsNicSalt.push(item);
        }
      }
    });
    const productNicFree: any = processProducts(productsNicFree);
    const productNicSalt: any = processProducts(productsNicSalt, 2);

    const niceFreePromo = checkPromo(productNicFree);
    const niceSaltPromo = checkPromo(productNicSalt);

    // setCartPromo(result);

    return {
      nicFree: niceFreePromo.products,
      nicSalt: niceSaltPromo.products,
      discountNicFree: niceFreePromo.discount,
      discountNicSalt: niceSaltPromo.discount,
      discountTotal: niceFreePromo.discount + niceSaltPromo.discount,
    };
  }, [order]);

  return (
    <Container>
      <h1>Pedido realizado!</h1>

      <strong>
        Obrigado por escolher a Constellation.
        <br /> Agora é só esperar a confirmação do pagamento no seu e-mail!
      </strong>

      <div>
        <button type="button" onClick={() => history.push('/profile')}>
          Acompanhar pedido
        </button>

        <strong>Código do pedido&nbsp;&nbsp;&nbsp;{order?.ticket}</strong>
      </div>

      {!order && (
        <section>
          <Skeleton count={3} />
        </section>
      )}

      {order && (
        <OrderContainer>
          <Card padding="0">
            <header>
              <ResumoSVG />
              <strong>Produtos</strong>
            </header>
            <main>
              {order.products.map((product) => {
                let productPromoQtt = 0;

                if (
                  product.type === 'juice' &&
                  processCartPromo &&
                  product.isOfficial
                ) {
                  if (product.nicType === 1) {
                    productPromoQtt = processCartPromo.nicFree[product.id] || 0;
                  } else if (product.nicType === 2) {
                    productPromoQtt = processCartPromo.nicSalt[product.id] || 0;
                  }
                }

                return (
                  <ProductContainer key={product.id}>
                    <CartProduct
                      product={product}
                      small
                      showQtt
                      productPromoQtt={productPromoQtt}
                    />

                    <div>
                      <strong>
                        {currencyBRL(
                          product.price * product.quantity,
                          true,
                          true,
                        )}
                      </strong>
                    </div>
                  </ProductContainer>
                );
              })}
            </main>
          </Card>

          <Card>
            <header>
              <EntregaSVG />
              <strong>Entrega</strong>
            </header>

            <MainDelivery>
              {order.obs ? (
                <OrderObs>
                  <strong>Observação do pedido:</strong>
                  <p>{order.obs}</p>
                </OrderObs>
              ) : (
                ''
              )}

              <div>
                <strong>{order.delivery.address.name}</strong>

                <span>
                  {order.delivery.address.street},&nbsp;
                  {order.delivery.address.number}
                </span>

                <span>{order.delivery.address.info || 'Sem complemento'}</span>

                <span>{order.delivery.address.district}</span>

                <span>
                  {formatCEP(order.delivery.address.cep)}&nbsp;|&nbsp;
                  {order.delivery.address.city}&nbsp;-&nbsp;
                  {order.delivery.address.state}
                </span>

                <span>
                  {order.delivery.address.contact
                    ? formatContact(order.delivery.address.contact)
                    : 'Sem contato'}
                </span>
              </div>

              <div>
                <span>Prazo para postagem: 2 dias úteis</span>

                <div>
                  {/* <span>{order.delivery.shipment.label}&nbsp;</span> */}
                  <span>{order.delivery.shipment.deliveryTime}</span>
                  <span>
                    {order.delivery.shipment.isFree
                      ? 'Frete grátis'
                      : currencyBRL(order.delivery.shipment.price, true, true)}
                  </span>
                </div>
              </div>
            </MainDelivery>
          </Card>

          <Card>
            <header>
              <PagamentoSVG />
              <strong>Valores e pagamento</strong>
            </header>

            <MainPayment>
              <div>
                <div>
                  <span>Produtos</span>
                  <span>{currencyBRL(order.payment.subTotal)}</span>
                </div>

                <div>
                  <span>Frete</span>
                  <span>
                    {order.delivery.shipment.isFree
                      ? 'Frete grátis'
                      : currencyBRL(order.payment.delivery)}
                  </span>
                </div>

                {order.payment.promoNicFree &&
                  order.payment.promoNicFree.price > 0 && (
                    <div>
                      <span>Desconto Freebase grátis</span>
                      <span className="crossLine">
                        {currencyBRL(order.payment.promoNicFree.price)}
                      </span>
                    </div>
                  )}

                {order.payment.promoNicSalt &&
                  order?.payment?.promoNicSalt?.price > 0 && (
                    <div>
                      <span>Desconto Nic Salt grátis</span>
                      <span className="crossLine">
                        {currencyBRL(order.payment.promoNicSalt.price)}
                      </span>
                    </div>
                  )}

                {order &&
                order?.payment?.cupom?.code &&
                order?.payment?.cupom?.discountVal ? (
                  <div>
                    <span>Desconto {order?.payment?.cupom?.code}</span>
                    <span className="crossLine">
                      {currencyBRL(order?.payment?.cupom?.discountVal)}
                    </span>
                  </div>
                ) : (
                  ''
                )}

                {!paymentTypes.CC.includes(order.payment.method.type) ? (
                  <div>
                    <span>Desconto {order.payment.method.type}</span>
                    <span className="crossLine">
                      {currencyBRL(order.payment.discount10)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <Payment
                showPaymentInfo
                total={order.payment.total}
                payment={order.payment.method}
              />
            </MainPayment>
          </Card>
        </OrderContainer>
      )}

      {/* <WppHelpButton /> */}
    </Container>
  );
};

export default CheckoutDone;
