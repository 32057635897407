import styled, { css } from 'styled-components';

export const Container = styled.article<{
  direction: 'row' | 'column';
  total?: number;
}>`
  display: flex;
  ${({ direction }) =>
    direction === 'row' &&
    css`
      align-items: center;
      justify-content: flex-start;

      font-size: 0.8rem;

      > svg {
        height: 20px;
      }
    `}
  ${({ direction }) =>
    direction === 'column' &&
    css`
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      font-size: 1rem;

      > svg {
        height: 40px;
      }
    `}

  > svg {
    width: 110px;
    min-width: 110px;
    height: 30px;
    min-height: 30px;
    margin-right: 4px;
    ${({ total }) => total === 0 && 'opacity: .64;'}
  }

  > span {
    /* margin-top: 8px; */
    text-align: center;
    font-size: 1rem;
  }
`;
