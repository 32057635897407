import styled, { css } from 'styled-components';

interface ContainerProps {
  minHeight: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: ${({ minHeight }) => minHeight};
`;

interface SliderContainerProps {
  totalSlides: number;
  slidesPerPage: number;
  currentSlide: number;
  fullWidth: boolean;
  top?: string;
}

export const SliderContainer = styled.section<SliderContainerProps>`
  position: relative;

  display: flex;
  align-items: flex-start;
  overflow: hidden;
  width: 100%;
  /*
  NÃO USAR NUNCA JAMAIS EM HIPOTESE ALGUMA!!!!!!!!!!!!!!!!!!
  height: 100%;
  NÃO USAR NUNCA JAMAIS EM HIPOTESE ALGUMA!!!!!!!!!!!!!!!!!!
  */

  > * {
    position: absolute !important;

    ${({ top }) =>
      top
        ? `top: ${top};`
        : css`
            top: 50%;
            transform: translateY(-50%);
          `}

    left: -100vw;

    height: 100%;
    width: 100%;

    ${({ slidesPerPage, fullWidth }) => {
      let calc = `calc(100% / ${slidesPerPage} - 2rem)`;
      if (fullWidth === true && slidesPerPage === 1) {
        calc = `calc(100% / ${slidesPerPage})`;
      }
      return `max-width: ${calc}!important;`;
    }}

    user-select: none;
    opacity: 0;

    transition: all 0.25s ease;

    &:nth-child(${({ currentSlide, slidesPerPage }) =>
          currentSlide * slidesPerPage})
      ~ * {
      left: 100vw;
    }

    ${({ currentSlide, slidesPerPage, fullWidth }) => {
      if (slidesPerPage === 1 && fullWidth === true) {
        return `&:nth-child(${currentSlide}) {
          user-select: unset;
          opacity: 1;

          left: 0;
          right: 0;
        }`;
      }
      // const fixCalc = currentSlide - slidesPerPage;

      let min = (currentSlide - 1) * slidesPerPage + 1;

      let max = min + slidesPerPage;

      if (currentSlide === 1) {
        min = 1;
        max = slidesPerPage;
      }

      let secondIdx = 0;
      const loopSliders = [];
      for (let index = min; index <= max; index += 1) {
        const percentageCalc = 100 / slidesPerPage;

        let calc = `calc(${percentageCalc}% * ${secondIdx} + 1rem)`;

        if (index === currentSlide) {
          calc = '1rem';
        }
        // if (index === currentSlide * slidesPerPage) calc = '100%';

        loopSliders.push(`&:nth-child(${index}) {
          user-select: unset;
          opacity: 1;

          left: ${calc};
        }`);
        secondIdx += 1;
      }
      return loopSliders.map((item) => item);
    }}
  }
`;

interface Props {
  inside?: boolean;
  fullWidth?: boolean;
}

const Button = styled.button<Props>`
  cursor: pointer;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 0;
  z-index: 100;

  > svg {
    height: 32px;
    width: 32px;
  }
`;

export const ButtonBack = styled(Button)`
  left: -16px;
  ${({ inside, fullWidth }) =>
    inside &&
    css`
      left: ${fullWidth ? '0' : '16px'};
    `}
`;

export const ButtonNext = styled(Button)`
  right: -16px;
  ${({ inside, fullWidth }) =>
    inside &&
    css`
      right: ${fullWidth ? '0' : '16px'};
    `}
`;

interface SliderImgProps {
  img: string;
}

export const SliderBackgroundImg = styled.div<SliderImgProps>`
  width: 100vw;

  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
