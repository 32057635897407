import styled, { css } from 'styled-components';

interface ButtonProps {
  customColor: number;
  minWidth?: string;
  maxWidth?: string;
}

const colors = ['#4F1E66', '#00C763'];
export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  background-color: ${({ customColor }) => colors[customColor - 1]};
  border-radius: 8px;

  height: 50px;
  min-width: 225px;
  width: 100%;

  > strong {
    color: #ffffff;
  }

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `}
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  transition: filter 0.25s ease;

  &:hover {
    filter: brightness(75%);
  }
`;
