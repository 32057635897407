import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import { Container, Label } from './styles';

interface InputCheckboxProps {
  defaultChecked?: boolean;
  checked?: boolean;
  onCheckChange(state: boolean): void;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  onCheckChange,
  checked,
  children,
  defaultChecked,
}) => {
  const checkId = v4();
  const [check, setCheck] = useState(checked || defaultChecked);

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  return (
    <Container>
      <Label htmlFor={checkId}>
        <input
          id={checkId}
          type="checkbox"
          onChange={(event) => onCheckChange(event.target.checked)}
          checked={check || false}
        />
        {children}
      </Label>
    </Container>
  );
};

export default InputCheckbox;
