import styled from 'styled-components';
import { Container as InputContainer } from 'components/Input/Container/styles';

export const Row = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 8px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-end;

    max-width: 500px;

    > button {
      margin: 0 auto;
      min-width: 200px;
    }

    > span {
      color: #58c78f;
      text-align: left;
      line-height: 1.5;
      margin: 16px 0;
      max-width: 265px;
      transform: translateX(-25%);

      > a {
        text-decoration: underline;
        transition: filter 0.25s ease;

        &:hover {
          filter: brightness(75%);
        }
      }
    }

    > strong {
      color: #58c78f;
      font-size: 1.1rem;
      margin-top: 24px;
      margin-bottom: 16px;
      width: 100%;
      text-align: center;
    }

    > div {
      width: 100%;
      justify-content: flex-end;
      transform: translateX(-20%);
      input {
        min-width: 250px;
        max-width: 250px;
      }
      + div {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 600px) {
    ${InputContainer} {
      flex-direction: column !important;

      > span {
        margin-bottom: 8px;
        margin-right: 0;
        width: 100%;
      }

      > div,
      > input,
      > div > input {
        max-width: unset;
        width: 100%;
      }
    }

    > div:nth-of-type(2) {
      > div {
        transform: translateX(0);

        input {
          min-width: unset;
          max-width: unset;
        }
      }

      > span {
        transform: translateX(0);
      }
    }
  }
`;
