import React, { useState } from 'react';

import { Container, CircleButton, CircleButtonColor } from './styles';

interface Options {
  id: number;
  name?: string;
  amount?: number;
}

interface OptionsColor extends Options {
  code: string;
}

interface ButtonsProps {
  options: Options[];
  onChange(item: Options): void;
}

export const CircleButtons: React.FC<ButtonsProps> = ({
  options,
  onChange,
}) => {
  const [currentOption, setCurrentOption] = useState<Options | undefined>(
    options[0],
  );
  const handleClick = (item: Options): void => {
    setCurrentOption(item);
    onChange(item);
  };

  return (
    <>
      {options.map((item) => (
        <CircleButton
          key={item.id}
          onClick={() => handleClick(item)}
          isActive={currentOption?.id === item.id}
        >
          {item.amount}
          {item.name}
        </CircleButton>
      ))}
    </>
  );
};

interface ButtonsColorProps {
  options: OptionsColor[];
  onChange(item: Options): void;
}

const CircleColorButtons: React.FC<ButtonsColorProps> = ({
  options,
  onChange,
}) => {
  const [currentOption, setCurrentOption] = useState<Options | undefined>();
  const handleClick = (item: Options): void => {
    setCurrentOption(item);
    onChange(item);
  };

  return (
    <>
      {options.map((item) => (
        <CircleButtonColor
          key={item.id}
          onClick={() => handleClick(item)}
          isActive={currentOption?.id === item.id}
          hex={item.code}
          name={item.name}
        >
          <span>{item.name}</span>
        </CircleButtonColor>
      ))}
    </>
  );
};

interface Props {
  isColor?: boolean;
  options?: Options[];
  onChange(item: Options): void;
}

const CircleSelect: React.FC<Props> = ({ options, onChange, isColor }) => {
  return (
    <Container>
      {options && isColor && (
        <CircleColorButtons
          options={options as OptionsColor[]}
          onChange={onChange}
        />
      )}

      {options && !isColor && (
        <CircleButtons options={options} onChange={onChange} />
      )}
    </Container>
  );
};

export default CircleSelect;
