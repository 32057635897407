import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  background-color: #4f1e66;
  width: 100%;
  min-height: 275px;
  /* padding: 24px; */
`;
