import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ReactComponent as CartSVG } from 'assets/icons/cesta.svg';
import { ReactComponent as DiscoSVG } from 'assets/backgrounds/discoVoador.svg';
import { DefaultData as DefaultProductThumb } from 'components/Product/ProductDTO';

import CurrentPath from 'components/CurrentPath';
import { currencyBRL } from 'utils/format';
import randomNumber from 'utils/randomNumber';
import { useProductProps, Color, PropsData } from 'hooks/productProps';

import { useCart } from 'hooks/Cart';
import { swalFire, swalMixin, swalToast } from 'services/swal';
import DeliveryOptions, {
  DeliveryOptions as DeliveryDTO,
} from 'components/DeliveryOptions';

import CircleSelect from '../CircleSelect';
import MintSelector from '../MintSelector';
import IconFlavour from '../Flavours/Sabores';
import Icon from '../Flavours/Essencias';

import AboutProduct, { AboutProductProps } from '../AboutProduct';
import BoughtTogether from '../BoughtTogether';
import Ratings, { RatingProps } from '../Ratings';
import Questions, { Question } from '../Questions';
import IntegerCustom from '../../Input/IntegerCustom';
import GreenButton from '../../Button/GreenButton';
import CEPDelivery, { formatAddress, CEPResponse } from '../../CEPDelivery';

import {
  SkeletonContainer,
  Container,
  JuiceImageContainer,
  Flavours,
  JuiceInfoContainer,
  VapeImageContainer,
  VapeInfoContainer,
} from './styles';

const SkeletonLoader: React.FC = () => {
  return (
    <SkeletonContainer>
      <div>
        <section>
          <div>
            <Skeleton />
          </div>

          <div>
            <Skeleton count={2} />
            <Skeleton />
            <Skeleton />

            <div>
              <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>

              <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            </div>
          </div>
        </section>
      </div>
    </SkeletonContainer>
  );
};

export interface ProductDefault {
  id: number;
  name: string;
  description: string;
  image: string;
  type: 'vape' | 'juice' | 'accessory';
  questions: Question[];
  ratings: RatingProps;
  boughtTogether: DefaultProductThumb[];
  stock?: number;
}

interface Ejuice extends ProductDefault {
  essences: number[];
  flavours: number[];
  bottle: number[];
  nic: number[];
  nicType?: 1 | 2;
  proportion: number[];
  mint: number[];
  price: number;
  linha: number;
  isOfficial?: boolean;
  juiceOutOfStock?: boolean;
  useCustomPrice?: boolean;
  priceList?: { [key: string]: string };
}

interface JuiceProps {
  ejuice: Ejuice;
}

// interface PriceProp {
//   size?: number;
//   nic?: number;
//   proportion?: number;
// }

interface ProductForm {
  quantity: number;
  cep?: string;
  location?: CEPResponse;
  delivery?: DeliveryDTO;
  deliveryList?: DeliveryDTO[];
  currentPrice: number;
  price?: number;
}

type JuiceFormKetType = { id: number; priceAdd: number };

interface JuiceForm {
  bottle?: JuiceFormKetType;
  proportion?: JuiceFormKetType;
  nic?: JuiceFormKetType;
  mint?: JuiceFormKetType;
}

interface JuiceImageMemoProps {
  image?: string;
  essences?: number[];
}

const JuiceImageMemo = React.memo(
  (props: JuiceImageMemoProps) => {
    return (
      <JuiceImageContainer>
        <figure>
          <img src={props.image} alt="Produto Juice" />
          <figcaption>Produto Juice</figcaption>
        </figure>

        <div>
          {props.essences &&
            props.essences.map((item) => (
              <Icon key={item} idx={item} position="right" />
            ))}

          {/* {!props.essences && (
            <>
              <Icon idx={randomNumber(0, 27, 0)} position="right" />
              <Icon idx={randomNumber(0, 27, 0)} position="right" />
              <Icon idx={randomNumber(0, 27, 0)} position="right" />
            </>
          )} */}
        </div>
      </JuiceImageContainer>
    );
  },
  (prevProps, nextProps) => {
    // console.log('memoImage re-render', prevProps, nextProps);
    if (prevProps.essences?.length !== nextProps.essences?.length) return false;
    if (prevProps.image !== nextProps.image) return false;
    return true;
  },
);

const JuiceProduct: React.FC<JuiceProps> = ({ ejuice }) => {
  const { addItem } = useCart();
  const { props } = useProductProps();

  // const [proportionOptions, ___] = useState<Proportion[]>(
  //   getProps('proportion', ejuice.proportion) as any[],
  // );

  // const [bottleOptions, __] = useState<BottleSize[]>(
  //   getProps('bottles', ejuice.bottle) as any[],
  // );

  // const [nicOptions, _] = useState<Nic[]>(getProps('nic', ejuice.nic) as any[]);

  const [productForm, setProductForm] = useState<ProductForm>({
    quantity: 1,
    currentPrice: ejuice.price,
  });

  const [form, setForm] = useState<JuiceForm>({});
  const [cep, setCep] = useState<string | undefined>();

  const [productOptions, setProductOptions] = useState<PropsData | undefined>();

  useEffect(() => {
    if (!ejuice.useCustomPrice) return;
    if (
      !productOptions?.bottles &&
      !productOptions?.nic &&
      !productOptions?.proportion &&
      !productOptions?.mint &&
      props?.mint &&
      props?.bottles &&
      props?.nic &&
      props?.proportion
    ) {
      setProductOptions({
        bottles: props.bottles.filter((item) =>
          ejuice.bottle.includes(item.id),
        ),
        nic: props.nic.filter((item) => ejuice.nic.includes(item.id)),
        proportion: props.proportion.filter((item) =>
          ejuice.proportion.includes(item.id),
        ),
        mint: props.mint.filter((item) => ejuice.mint.includes(item.id)),
      });
    }
  }, [props, ejuice]);

  useEffect(() => {
    if (
      productOptions?.bottles &&
      productOptions?.proportion &&
      productOptions?.mint &&
      productOptions?.nic
    ) {
      if (
        form?.bottle === undefined &&
        form?.nic === undefined &&
        form.proportion === undefined
      ) {
        setForm({
          bottle: productOptions.bottles[0],
          nic: productOptions.nic[0],
          proportion: productOptions.proportion[0],
          mint: undefined,
        });
      }
    }
  }, [productOptions, form]);

  // const productOptions = React.useMemo(() => {
  //   console.log('Memo', props);
  //   if (!props?.bottles || !props?.nic || !props?.proportion) return undefined;
  //   const { bottle, nic, proportion } = ejuice;
  //   return {
  //     bottles: props.bottles.filter((item) => bottle.includes(item.id)),
  //     nic: props.nic.filter((item) => nic.includes(item.id)),
  //     proportion: props.proportion.filter((item) =>
  //       proportion.includes(item.id),
  //     ),
  //   };
  // }, [props, ejuice]);

  // useEffect(() => {
  //   if (productOptions) {
  //     setForm({
  //       bottle: productOptions.bottles[0],
  //       nic: productOptions.nic[0],
  //       proportion: productOptions.proportion[0],
  //       mint: 0,
  //     });
  //   }
  // }, [productOptions]);

  const customPrice = React.useMemo((): number => {
    if (ejuice.isOfficial && ejuice.useCustomPrice && ejuice.priceList) {
      const priceKey = `${ejuice.linha}-${form?.bottle?.id || 1}-${
        form?.nic?.id || 0
      }-${form?.mint?.id || 0}`;
      // console.log('priceKey', form);
      // console.log('priceKey', priceKey);
      return parseInt(ejuice.priceList[priceKey], 10);
    }

    return ejuice?.price || 0;
    // const basePrice = ejuice.price;
    // let resultPrice = basePrice;

    // if (form.nic) resultPrice += form.nic.priceAdd;
    // if (form.bottle) resultPrice += form.bottle.priceAdd;
    // if (form.proportion) resultPrice += form.proportion.priceAdd;
    // if (form?.mint?.id) resultPrice += form.mint.priceAdd;

    // return resultPrice;
  }, [ejuice, form]);

  const changeFormState = (key: keyof JuiceForm, newVal: any): void => {
    setForm({ ...form, [key]: newVal });
  };

  // const img = React.useMemo(() => JuicePNG, []);

  const productImage = React.useMemo((): string | undefined => {
    // console.log('useMemoImage re-render');
    return ejuice.image;
  }, [ejuice.image]);

  // console.log('JuiceProduct re-render');

  const handleSubmit = (): void => {
    if (productForm.quantity === 0) {
      swalFire({ text: 'Selecione uma quantidade.', icon: 'warning' });
      return;
    }

    if (ejuice.isOfficial) {
      if (ejuice.juiceOutOfStock) {
        swalFire({ text: 'Produto sem estoque.', icon: 'warning' });
        return;
      }

      if (ejuice.proportion && !form.proportion) {
        swalFire({ text: 'Selecione uma proporção.', icon: 'warning' });
        return;
      }

      if (ejuice.nic && !form.nic) {
        swalFire({
          text: 'Selecione uma quantidade de nicotina.',
          icon: 'warning',
        });
        return;
      }

      if (ejuice.bottle && !form.bottle) {
        swalFire({ text: 'Selecione uma quantidade de ml.', icon: 'warning' });
        return;
      }
    } else if (
      (ejuice.stock && productForm.quantity > ejuice.stock) ||
      !ejuice.stock
    ) {
      swalFire({ text: 'Estoque indisponível.', icon: 'warning' });
      return;
    }

    const itemProps: any = {};
    if (ejuice.isOfficial) {
      itemProps.nic = form.nic?.id || 1;
      itemProps.proportion = form.proportion?.id || 1;
      itemProps.bottle = form.bottle?.id || 1;
      itemProps.mint = form?.mint?.id || 0;
    }
    addItem({
      idProduct: ejuice.id,
      img: ejuice.image,
      name: ejuice.name,
      type: 'juice',
      quantity: productForm.quantity,
      price: customPrice,
      ...itemProps,
      nicType: ejuice.nicType,
      isOfficial: ejuice.isOfficial,
    });
  };

  return (
    <Container>
      <CurrentPath
        pathList={[{ title: 'Página inicial', to: '/' }, { title: 'Produto' }]}
      />

      <section>
        <JuiceImageMemo image={productImage} essences={ejuice.essences} />
        {/* <JuiceImageContainer img={productImage}>
          <div />
          <div>
            {ejuice.essences &&
              ejuice.essences.map((item) => <Icon key={item} idx={item} />)}
            {!ejuice.essences && (
              <>
                <Icon idx={randomNumber(0, 27, 0)} />
                <Icon idx={randomNumber(0, 27, 0)} />
                <Icon idx={randomNumber(0, 27, 0)} />
              </>
            )}
          </div>
        </JuiceImageContainer> */}

        <JuiceInfoContainer>
          <section>
            <h1>{ejuice.name}</h1>
            <strong>a partir de {currencyBRL(ejuice.price)}</strong>
            <p>{ejuice.description}</p>

            {ejuice.isOfficial && ejuice?.bottle && ejuice.bottle.length > 0 && (
              <div>
                <span>Tamanho (ml)</span>
                <CircleSelect
                  options={productOptions?.bottles}
                  onChange={(option: any) => changeFormState('bottle', option)}
                />
              </div>
            )}

            {ejuice.isOfficial && ejuice?.nic && ejuice.nic.length > 0 && (
              <div>
                <span>Nicotina (mg/ml)</span>
                <CircleSelect
                  options={productOptions?.nic}
                  onChange={(option: any) => changeFormState('nic', option)}
                />
              </div>
            )}

            {ejuice.isOfficial &&
              ejuice?.proportion &&
              ejuice.proportion.length > 0 && (
                <div>
                  <span>Proporção VG/PG</span>
                  <CircleSelect
                    options={productOptions?.proportion}
                    onChange={(option: any) => {
                      changeFormState('proportion', option);
                    }}
                  />
                </div>
              )}

            {ejuice.isOfficial && ejuice?.mint && ejuice.mint.length > 0 && (
              <div>
                <span>Menta adicional</span>
                <MintSelector
                  options={productOptions?.mint}
                  onChange={(value) => {
                    changeFormState('mint', value);
                  }}
                />
              </div>
            )}
          </section>

          <section>
            <Flavours>
              {ejuice.flavours.map((item) => (
                <IconFlavour key={item} idx={item} />
              ))}
            </Flavours>

            <article>
              <div>
                <DiscoSVG />

                <strong>R$</strong>
                <strong>{currencyBRL(customPrice, false)}</strong>
              </div>

              <strong>ou {currencyBRL(customPrice * 0.9)} no depósito</strong>

              <strong>
                Valor total&nbsp;
                {productForm.quantity > 0 &&
                  currencyBRL(customPrice * productForm.quantity)}
              </strong>

              {!ejuice.isOfficial && (
                <>
                  {!ejuice.stock ? <article>Sem estoque</article> : ''}
                  {ejuice.stock && ejuice.stock > 0 && ejuice.stock < 10 ? (
                    <article className="warn">
                      Apenas {ejuice.stock} em estoque
                    </article>
                  ) : (
                    ''
                  )}
                </>
              )}

              {ejuice.isOfficial && ejuice.juiceOutOfStock && (
                <article>Sem estoque</article>
              )}

              <div>
                <IntegerCustom
                  max={99}
                  min={1}
                  onChange={(value: number) => {
                    let newVal = value;
                    if (newVal > 99) newVal = 99;
                    if (newVal <= 0) newVal = 1;
                    let newValue = value;
                    if (newValue > 99) newValue = productForm.quantity;
                    if (
                      !ejuice.isOfficial &&
                      ejuice.stock &&
                      newValue > ejuice.stock
                    ) {
                      newValue = ejuice.stock;
                    }
                    setProductForm({ ...productForm, quantity: newValue });

                    // setProductForm({
                    //   ...productForm,
                    //   quantity: newVal,
                    // });
                  }}
                  currentValue={productForm.quantity}
                />

                <GreenButton
                  text="Adicionar ao carrinho"
                  onClick={handleSubmit}
                  icon={<CartSVG />}
                  size="big"
                />
              </div>

              <CEPDelivery
                idProduct={ejuice.id}
                idBottle={form?.bottle?.id}
                qtd={productForm.quantity}
                onCalcDelivery={(deliveryOptions) => {
                  setProductForm({
                    ...productForm,
                    deliveryList: deliveryOptions,
                  });
                  // console.log('CalcDelivery', deliveryOptions);
                  // setDelivery(deliveryOptions);
                }}
                onChangeCep={(value: string) => {
                  setCep(value);
                  setProductForm({
                    ...productForm,
                    deliveryList: undefined,
                  });
                }}
                onSearchAddress={(address: CEPResponse | undefined) => {
                  setProductForm({
                    ...productForm,
                    location: address ? { ...address } : undefined,
                  });
                }}
                currentValue={cep}
              />

              <div>
                {productForm.location && (
                  <span>{formatAddress(productForm.location)}</span>
                )}
              </div>

              <DeliveryOptions
                onChange={(option) => {
                  setProductForm({ ...productForm, delivery: option });
                }}
                options={productForm?.deliveryList}
              />
            </article>
          </section>
        </JuiceInfoContainer>
      </section>

      <Ratings {...ejuice.ratings} />

      <Questions questions={ejuice.questions} idProduct={ejuice.id} />

      <BoughtTogether products={ejuice.boughtTogether} />
    </Container>
  );
};

interface About {
  description: string;
  specs: { title: string; data: string[] }[];
}

interface Vape extends ProductDefault {
  about: AboutProductProps;
  color?: number[];
  price: number;
  priceDiscount: number;
  images: string[];
}

interface VapeForm {
  color?: number;
}

interface VapeProps {
  vape: Vape;
}

interface Form {
  location?: CEPResponse;
  deliveryList?: DeliveryDTO[];
  delivery?: DeliveryDTO;
}

const VapeProduct: React.FC<VapeProps> = ({ vape }) => {
  const { addItem } = useCart();
  const { props } = useProductProps();

  const [colorOptions, setColorOptions] = useState<Color[] | undefined>();

  useEffect(() => {
    if (!vape?.color || vape.color.length === 0) return;
    if (!colorOptions && props?.colors && vape?.color) {
      const colorList = vape.color as number[];
      setColorOptions(
        props.colors.filter((item) => colorList.includes(item.id)),
      );
    }
  }, [props, vape]);

  const [quantity, setQuantity] = useState<number>(1);
  const [form, setForm] = useState<Form>({});
  const [cep, setCep] = useState<string | undefined>();
  const [vapeForm, setVapeForm] = useState<VapeForm>({});

  // const [currentPrice, setCurrentPrice] = useState(vape.price);

  // const changeFormState = (key: keyof JuiceForm, newVal: any): void => {
  //   setForm({ ...form, [key]: newVal });
  // };

  const handleSubmit = (): void => {
    if (!vape.stock) {
      swalToast({ text: 'Não há estoque para esse produto.', icon: 'warning' });
      return;
    }

    if (vape.color && !vapeForm.color) {
      swalMixin;
      swalFire({ text: 'Selecione uma cor.', icon: 'warning' });
      return;
    }

    addItem({
      idProduct: vape.id,
      img: vape.images[0],
      name: vape.name,
      price: vape.price,
      priceDiscount: vape.priceDiscount,
      type: 'vape',
      quantity,
      color: vapeForm?.color,
    });
  };

  return (
    <Container>
      <CurrentPath
        pathList={[{ title: 'Página inicial', to: '/' }, { title: 'Produto' }]}
      />

      <section>
        <VapeImageContainer>
          <Carousel>
            {vape.images.map((item, index) => (
              // eslint-disable-next-line
              <div key={index}>
                <img src={item} alt="product imgs" />
              </div>
            ))}
          </Carousel>
        </VapeImageContainer>

        <VapeInfoContainer>
          <section>
            <h1>{vape.name}</h1>

            <p>{vape.description}</p>
            {colorOptions && colorOptions.length > 0 && (
              <div>
                <span>Cores</span>
                <CircleSelect
                  isColor
                  options={colorOptions}
                  onChange={(option: any) => {
                    setVapeForm({ ...vapeForm, color: option?.id });
                  }}
                />
              </div>
            )}
          </section>

          <section>
            <div>
              <DiscoSVG />

              <span>
                {vape.priceDiscount ? currencyBRL(vape.price, false) : ''}
              </span>

              <div>
                <strong>R$</strong>
                <strong>
                  {currencyBRL(
                    vape.priceDiscount ? vape.priceDiscount : vape.price,
                    false,
                  )}
                </strong>
              </div>
            </div>

            <strong>
              ou&nbsp;
              {currencyBRL(
                vape.priceDiscount
                  ? vape.priceDiscount * 0.9
                  : vape.price * 0.9,
              )}
              &nbsp;no depósito
            </strong>

            <strong className={quantity === 0 ? 'hidden' : ''}>
              Valor total&nbsp;
              {quantity > 0 &&
                currencyBRL((vape.priceDiscount || vape.price) * quantity)}
            </strong>

            {!vape.stock ? <article>Sem estoque</article> : ''}
            {vape.stock && vape.stock > 0 && vape.stock < 10 ? (
              <article className="warn">Apenas {vape.stock} em estoque</article>
            ) : (
              ''
            )}

            <div>
              <IntegerCustom
                max={99}
                min={1}
                onChange={(value: number) => {
                  let newValue = value;
                  if (newValue > 99) newValue = quantity;
                  if (vape.stock && newValue > vape.stock)
                    newValue = vape.stock;
                  if (newValue <= 0) newValue = 1;
                  setQuantity(newValue);
                }}
                currentValue={quantity}
              />

              <GreenButton
                text="Adicionar ao carrinho"
                onClick={handleSubmit}
                icon={<CartSVG />}
                size="big"
              />
            </div>

            <CEPDelivery
              idProduct={vape.id}
              qtd={quantity}
              onCalcDelivery={(deliveryOptions) => {
                setForm({
                  ...form,
                  deliveryList: deliveryOptions,
                });
              }}
              onChangeCep={(value: string) => {
                setCep(value);
                setForm({ ...form, deliveryList: undefined });
              }}
              onSearchAddress={(address: CEPResponse | undefined) => {
                setForm({
                  ...form,
                  location: address ? { ...address } : undefined,
                });
              }}
              currentValue={cep}
            />

            <div>
              {form.location && <span>{formatAddress(form.location)}</span>}
            </div>

            <DeliveryOptions
              onChange={(option) => {
                setForm({ ...form, delivery: option });
              }}
              options={form?.deliveryList}
            />
          </section>
        </VapeInfoContainer>
      </section>

      {vape.about && <AboutProduct {...vape.about} />}

      <Ratings {...vape.ratings} />

      <Questions questions={vape.questions} idProduct={vape.id} />

      <BoughtTogether products={vape.boughtTogether} />
    </Container>
  );
};

export type Products = Ejuice | Vape;

interface ProductProps {
  product?: Products;
  renderSkeleton?: boolean;
}

const ProductPage: React.FC<ProductProps> = ({
  product,
  renderSkeleton = false,
}) => {
  // console.log('ProductPage re-render', product);
  // console.log('ProductPage re-render', props);

  const renderResultElement = React.useMemo((): JSX.Element => {
    if (renderSkeleton) {
      return <SkeletonLoader />;
    }

    if (product !== undefined) {
      if (product.type === 'juice')
        return <JuiceProduct ejuice={product as Ejuice} />;

      if (product.type === 'vape' || product.type === 'accessory')
        return <VapeProduct vape={product as Vape} />;

      // if (product.type === '')
      //   return <VapeProduct vape={product as Vape} />;

      return <Container />;
    }

    return <SkeletonLoader />;
  }, [product, renderSkeleton]);

  return renderResultElement;
};

export default ProductPage;
