import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  animation: ${fadeIn} 0.25s ease forwards 1;
`;

export const Content = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 50px 100px;
  animation: ${fadeIn} 0.25s ease forwards 1;

  @media (max-width: 1200px) {
    margin: 48px 24px;
  }
`;

export const Question = styled.div<{ isActive?: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;

  max-height: 65px;
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  transition: max-height 0.25s ease;
  animation: ${fadeIn} 0.25s ease forwards 1;

  + div {
    margin-top: 24px;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      max-height: unset;
    `}
`;

export const QuestionBtn = styled.button<{ isActive?: boolean }>`
  position: relative;
  z-index: 50;
  width: 100%;
  height: 65px;
  padding: 12px 24px;

  border: 0;
  background: #68577180;
  border-radius: 25px;
  backdrop-filter: blur(16px);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  > strong {
    color: #58c78f;
    font-size: 1.25rem;
  }

  > svg {
    width: 32px;
    height: 32px;

    margin-right: 16px;

    fill: #58c78f;
    transform: rotate(0);
    transition: transform 0.25s ease;
  }

  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(75%);
  }

  ${({ isActive }) =>
    isActive &&
    css`
      > svg {
        transform: rotate(90deg);
      }
    `}
`;

export const QuestionContainer = styled.div<{ isShown?: boolean }>`
  position: absolute;

  opacity: 0;
  top: 0;
  transform: translateY(-100%);
  transition: transform 0.25s ease, top 0.25s ease;

  z-index: 20;

  background: #4f1e66;
  border-radius: 25px 25px;
  backdrop-filter: blur(16px);
  padding: calc(65px + 24px) 50px 24px 50px;
  margin: -65px auto 0 auto;
  max-width: calc(100% - 24px);

  width: 100%;

  > p {
    color: #ffffff;
    white-space: pre-wrap;

    font-size: 1rem;
    line-height: 1.75;
    font-weight: bold !important;
    text-align: left;
  }

  ${({ isShown }) =>
    isShown &&
    css`
      position: unset;

      opacity: 1;
      transform: translateY(0);
    `}
`;
