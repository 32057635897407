import React, { useState } from 'react';

import { swalFire } from 'services/swal';

import GreenButton from 'components/Button/GreenButton';
import CpfCnpj from 'components/Input/MaskedInputs/CpfCnpj';
import ContainerInput from 'components/Input/Container';
import { api, handleErrors, handleRespReturn } from 'services/api';

import GenericModal from '../Generic';
import { Content } from './styles';

export interface ForgotMailProps {
  isShown: boolean;
  close(): void;
}

const ForgotEmail: React.FC<ForgotMailProps> = ({ isShown, close }) => {
  const [udoc, setUdoc] = useState<string | undefined>();

  const handleSubmit = async (bool?: boolean): Promise<void> => {
    if (!bool) {
      close();
      return;
    }
    if (!udoc || (udoc.length !== 11 && udoc.length !== 14)) {
      swalFire({
        text: 'Preencha o campo com um CPF ou CNPJ.',
        icon: 'warning',
      });
      return;
    }
    try {
      const resp = await api().post('/user/forgotEmail', {
        udoc,
      });
      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      swalFire({
        text: 'Verifique um de seus e-mails.',
        icon: 'success',
      });
    } catch (err) {
      handleErrors(err, true);
      return;
    }
    close();
  };

  return (
    <GenericModal
      isShown={isShown}
      returnTrigger={(bool) => handleSubmit(bool)}
      disableMinSize
    >
      <Content>
        <strong>Esqueci meu e-mail</strong>

        <span>Digite abaixo o seu CPF ou CNPJ cadastrado</span>

        <CpfCnpj
          placeholder="CPF ou CNPJ"
          onChangeInput={(value) => {
            setUdoc(value);
          }}
          currentValue={udoc}
        />

        <GreenButton text="Ver e-mail" onClick={() => handleSubmit(true)} />
      </Content>
    </GenericModal>
  );
};

export default ForgotEmail;
