import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.section`
  position: relative;
  z-index: 50;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Main = styled.div`
  position: relative;
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const AskList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  > h1 {
    color: #f5f5f5;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;

    padding-left: 16px;
    animation: ${fadeIn} 0.25s ease;

    > strong {
      width: 100%;
      text-align: left;
      color: #c7e22b;
      font-size: 1.1rem;
      font-weight: bold;
    }

    > span {
      text-align: left;
      font-size: 1.1rem;
      color: #ffffff;
      margin-left: 12px;
      margin-top: 10px;

      > svg {
        color: #00c763;
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }
    }

    + div {
      margin-top: 24px;
    }
  }

  @media (max-width: 1000px) {
    margin-left: 0;
  }
`;

export const DoAskContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 350px;

  > h3 {
    text-align: left;
    color: #f5f5f5;
    font-size: 1.5rem;
  }

  > textarea {
    color: #f5f5f5;
    background-color: #4f1e66;
    border: 1px solid #4f1e66;
    border-radius: 12px;

    padding: 16px 22px;
    margin-top: 16px;

    resize: none;

    height: 110px;
    width: 100%;

    /* &::placeholder {
      color: #e4e4e4;
    } */
  }

  @media (max-width: 1000px) {
    min-width: unset;
  }
`;

interface SendButtonProps {
  send?: boolean;
}

export const SendButton = styled.button<SendButtonProps>`
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #00c763;
  color: #ffffff;

  font-size: 0.9rem;
  border: 0;
  margin-left: auto;
  margin-top: 12px;
  padding: 8px 12px;
  border-radius: 8px;

  transition: filter 0.25s ease, background-color 0.25s ease;

  > span {
    grid-column: 1;
    grid-row: 1;
    color: inherit;
    font-size: inherit;
    opacity: 0;

    transition: opacity 0.25s ease;
  }

  > svg {
    grid-column: 1;
    grid-row: 1;

    opacity: 0;

    width: 24px;
    height: 24px;
    margin: 0 auto;
    color: #ffffff;
    fill: #ffffff;
    transition: all 0.25s ease;
  }

  &:hover {
    filter: brightness(85%);
  }

  ${({ send }) =>
    send === true &&
    css`
      background-color: #58c78f;
      > span:nth-of-type(2) {
        opacity: 1;
      }
    `}

  ${({ send }) =>
    send === undefined &&
    css`
      > span:nth-of-type(1) {
        opacity: 1;
      }
    `}

  ${({ send }) =>
    send === false &&
    css`
      > svg {
        opacity: 1;
      }
    `}
`;

export const ShowMore = styled.button<{ hidden?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
  color: #c7e22b;
  font-size: 1.25rem;

  ${({ hidden }) =>
    hidden && `opacity: 0; pointer-events: none; user-select: none;`}

  @media (max-width: 1000px) {
    top: unset;
    bottom: -16px;
    transform: translateY(100%);
  }
`;
