import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { api, handleErrors } from 'services/api';
import ProductComponent, { Products } from '../../components/Product/Page';
import {
  Container,
  SkeletonContainer,
  ProductContainer,
  ProductSection,
} from './styles';

export const ProductPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [isLoading, setIsLoading] = useState(true);

  const [product, setProduct] = useState<Products | undefined>();

  useEffect(() => {
    let mounted = true;

    const getProduct = async (): Promise<void> => {
      document
        .querySelector('#main-content')
        ?.scrollTo({ top: 0, behavior: 'smooth' });

      try {
        const resp = await api().get(`/products?idProduct=${id}`);
        // console.log('RespApiProduct', resp.data);
        if (mounted) {
          setProduct({ ...resp.data.content });
          setIsLoading(false);
        }
      } catch (err) {
        if (mounted) {
          handleErrors(err);
        }
      }
    };

    // console.log('HOCProduct Effect');
    if (id && mounted) {
      // console.log('Effect', id, product);
      if ((product && `${product.id}` !== `${id}`) || !product) {
        // console.log('EffectGet');
        setIsLoading(true);
        setProduct(undefined);
        getProduct();
      }
    } else {
      // console.log('NoData');
      // setTimeout(() => {
      //   console.log('Do Something');
      //   setProduct({
      //     name: 'Aludra',
      //     type: 'juice',
      //     description:
      //       'Um juice formado por 8 ingredientes, dentro deles hortelã, hortelã-pimenta e marrolho, fazendo assim um blend com refrescancia única, suave, um pouco doce e perfeito para all-day em dias quentes!',
      //     price: 3135,
      //     nic: [1, 2, 3],
      //     proportion: [1, 2, 3],
      //     size: [1, 2, 3],
      //   });
      //   setIsLoading(false);
      // }, 1500);
    }

    return function () {
      mounted = false;
    };
  }, [id]);

  return <ProductComponent product={product} renderSkeleton={isLoading} />;
};

export default ProductPage;
