import React, { HTMLAttributes } from 'react';

import { InputField } from './styles';

interface Props extends HTMLAttributes<HTMLInputElement> {
  onChangeInput: (value?: string) => void;
  currentValue?: string;
  readOnly?: boolean;
}

const InputExpirationCC: React.FC<Props> = ({
  onChangeInput,
  currentValue,
  readOnly,
  ...rest
}) => {
  return (
    <InputField
      {...rest}
      readOnly={readOnly}
      disabled={readOnly}
      type="text"
      value={currentValue || ''}
      onChange={({ target }) => {
        const regexVal = target.value.replace(/[^\d]+/g, '');
        const valSize = regexVal.length;
        if (valSize === 0) {
          onChangeInput(undefined);
          return;
        }
        onChangeInput(`${regexVal.substr(0, 3)}`);
      }}
    />
  );
};

export default InputExpirationCC;
