import React from 'react';

import { ReactComponent as RatingSVG } from 'assets/icons/ratingStars.svg';

import { Container } from './styles';

interface Props {
  rate: number;
  total?: number;
  calc?: boolean;
  direction?: 'row' | 'column';
  showTotal?: boolean;
}
const RatingStars: React.FC<Props> = ({
  rate,
  total,
  calc,
  direction = 'row',
  showTotal,
}) => {
  const rateClass = React.useMemo(() => {
    const rateStr = `${rate}`.replace('.', '');
    if (!total) {
      return `rate${rateStr}`;
    }

    if (calc && total) {
      const [int, double] = rate.toFixed(2).split('.');

      let doubleInt = parseInt(double, 10);
      let fixedInt = parseInt(int, 10);

      if (doubleInt <= 30) doubleInt = 0;
      if (doubleInt > 30 && doubleInt <= 90) doubleInt = 5;
      if (doubleInt > 90) {
        doubleInt = 0;
        fixedInt += 1;
      }

      return `rate${fixedInt}${doubleInt || ''}`;
    }

    return `rate${rateStr}`;
  }, [rate, total]);

  return (
    <Container direction={direction} total={total}>
      <RatingSVG className={rateClass} />
      {showTotal && <span>{total} avaliações</span>}
      {/* {total !== 0 && direction === 'row' && <span>({total} avaliações)</span>} */}
      {/* {total !== 0 && direction !== 'row' && <span>{total} avaliações</span>} */}
    </Container>
  );
};

export default RatingStars;
