import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ReactGA from 'react-ga';
import AppProvider from 'hooks/index';
import GlobalStyle, { RemoveMobile } from './styles/globalStyle';
import Routes from './routes';
import WppButton from './components/WppButton';
import Socket from './components/Socket';

const trackingId = 'UA-181229251-1';
ReactGA.initialize(trackingId);

export const ReactGAFn = ReactGA;

const App: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        {/* <RemoveMobile>
          <strong>Em breve ...</strong>
          <strong>Versão mobile</strong>
        </RemoveMobile> */}
        <GlobalStyle />
        <WppButton />

        <Routes />
        {/* {window.innerWidth > 1000 && <Routes />} */}
      </AppProvider>
    </Router>
  );
};

export default App;
