import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  > * + * {
    margin-top: 24px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > strong {
    color: #f5f5f5;
    font-size: 1.1rem;
    margin-bottom: 16px;
  }
`;

export const PriceFilter = styled(Column)`
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > input {
      background-color: #4f1e66;
      color: #fff;

      &::placeholder {
        color: #fff;
        font-weight: bold;
      }
    }

    > input + input {
      margin-left: 16px;
    }
  }
`;
