import React from 'react';

import { ReactComponent as AtabacadoSVG } from 'assets/flavours/Atabacado.svg';
import { ReactComponent as CremosoSVG } from 'assets/flavours/Cremoso.svg';
import { ReactComponent as DoceSVG } from 'assets/flavours/Doce.svg';
import { ReactComponent as FrutadoSVG } from 'assets/flavours/Frutado.svg';
import { ReactComponent as GeladoSVG } from 'assets/flavours/Gelado.svg';
import { ReactComponent as MentoladoSVG } from 'assets/flavours/Mentolado.svg';

import { IconContainer, TooltipPos } from './styles';

interface Props {
  idx: number;
  size?: string;
  outline?: boolean;
  position?: keyof TooltipPos;
  onClick?: (idx: number) => void;
}

const defaultSizeIcon = '32px';

const arrIcons = [
  { icon: <AtabacadoSVG />, text: 'Atabacado' },
  { icon: <CremosoSVG />, text: 'Cremoso' },
  { icon: <DoceSVG />, text: 'Doce' },
  { icon: <FrutadoSVG />, text: 'Frutado' },
  { icon: <GeladoSVG />, text: 'Gelado' },
  { icon: <MentoladoSVG />, text: 'Mentolado' },
];

const Icon: React.FC<Props> = ({
  idx,
  size = defaultSizeIcon,
  position = 'bottom',
  outline = false,
  onClick,
}) => {
  return (
    <IconContainer
      size={size}
      outline={outline}
      position={position}
      onClick={() => {
        if (onClick) onClick(idx - 1);
      }}
    >
      {arrIcons[idx - 1].icon}
      <span>{arrIcons[idx - 1].text}</span>
    </IconContainer>
  );
};

export default Icon;
