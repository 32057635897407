import React from 'react';

import { Container } from './styles';

interface Props {
  title: string;
  direction?: 'row' | 'column';
  styleInput?: boolean;
  flex?: string;
  maxWidth?: string;
  hidden?: boolean;
}

const InputContainer: React.FC<Props> = ({
  title,
  children,
  direction = 'column',
  styleInput = false,
  flex,
  maxWidth,
  hidden,
}) => {
  return (
    <Container
      direction={direction}
      styleInput={styleInput}
      flex={flex}
      maxWidth={maxWidth}
      hidden={hidden}
    >
      <span>{title}</span>
      {children}
    </Container>
  );
};

export default InputContainer;
