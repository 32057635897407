import React, { useMemo } from 'react';

import { parseQueryStringToArrayNumber } from 'utils/format';

import { FilterConfig } from '../index';
import FilterJuice from './FilterJuice';
// import FilterMod from './FilterMod';
import FilterPod from './FilterPod';
// import FilterAtty from './FilterAtty';
// import FilterKit from './FilterKit';
import FilterAccessory from './FilterAccessory';
import { FilterTitle } from './styles';

interface FilterContainerProps {
  filter?: FilterConfig;
  handleFilter: (filters: any) => void;
}

const FilterContainer: React.FC<FilterContainerProps> = ({
  filter,
  handleFilter,
}) => {
  const component = useMemo(() => {
    // console.log('HOC', filter);
    if (!filter?.category) return <></>;

    if (filter) {
      if (filter.category === 'juice') {
        return (
          <FilterJuice
            handleOnChange={handleFilter}
            brand={parseQueryStringToArrayNumber(filter.brand)}
          />
        );
      }

      if (filter.category === 'accessory') {
        return (
          <FilterAccessory
            handleOnChange={handleFilter}
            subType={parseQueryStringToArrayNumber(filter.subType)}
          />
        );
      }

      // if (filter.category === 'kit') {
      //   return (
      //     <FilterKit
      //       handleOnChange={handleFilter}
      //       subType={parseQueryStringToArrayNumber(filter.subType)}
      //     />
      //   );
      // }

      // if (filter.category === 'atty') {
      //   return (
      //     <FilterAtty
      //       handleOnChange={handleFilter}
      //       subType={parseQueryStringToArrayNumber(filter.subType)}
      //     />
      //   );
      // }

      // if (filter.category === 'mod') {
      //   return (
      //     <FilterMod
      //       handleOnChange={handleFilter}
      //       subType={parseQueryStringToArrayNumber(filter.subType)}
      //       brand={parseQueryStringToArrayNumber(filter.brand)}
      //     />
      //   );
      // }

      if (filter.category === 'pod') {
        return (
          <FilterPod
            handleOnChange={handleFilter}
            subType={parseQueryStringToArrayNumber(filter.subType)}
          />
        );
      }
    }

    return <></>;
  }, [filter]);

  return (
    <>
      {filter?.category && <FilterTitle>Filtros</FilterTitle>}
      {component}
    </>
  );
};

export default FilterContainer;
