import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 24px 100px;

  > button {
    background-color: transparent;
    color: #58c78f;
    text-decoration: underline;
  }

  > div {
    display: flex;
    align-items: center;

    > button + button {
      margin-left: 12px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
    margin: 24px 50px;

    > button {
      margin-top: 24px;
    }

    > div {
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px 0;

      > button {
        margin-left: 0;
        margin: 1rem;
      }
    }
  }
`;

export const SectionList = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  > strong {
    color: #58c78f;
    font-size: 1.75rem;
    margin: 16px auto;
  }

  > article + article {
    margin-top: 24px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(250px));
    row-gap: 16px;
    column-gap: 16px;
    justify-content: center;
    grid-auto-flow: row;

    z-index: 50;
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
  }
`;
