import { swalToast } from 'services/swal';

export const copyToClipboard = async (copyContent: string | undefined) => {
  if (!copyContent) return;
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea');
    textArea.value = copyContent;
    textArea.style.position = 'fixed';
    textArea.style.top = '-100vh';
    textArea.style.left = '-100vw';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      console.log('Copied to clipboard');
      swalToast({
        icon: 'success',
        title: 'Copiado com sucesso.',
        timer: 2000,
      });
    } catch (err) {
      console.log('Copy clipboard Err:', err);
    }
    return;
  }

  navigator.clipboard.writeText(copyContent).then(
    function () {
      console.log('Copied to clipboard');
      swalToast({
        icon: 'success',
        title: 'Copiado com sucesso.',
        timer: 2000,
      });
    },
    function (err) {
      console.log('Copy clipboard Err:', err);
    },
  );
};
