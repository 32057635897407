import React, { useEffect, useState } from 'react';

import Skeleton from 'react-loading-skeleton';

import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';

import { Addresses } from 'pages/Profile/Address';
import { ReactComponent as TelescopioSVG } from 'assets/draws/telescopio.svg';

import CurrentPath from 'components/CurrentPath';
import SectionTitle from 'components/SectionTitle';

import { formatContact } from 'utils/format';

import {
  Container,
  AddContainer,
  Content,
  LocationContainer,
  Button,
} from './styles';

interface AddressDealer extends Omit<Addresses, 'city' | 'state'> {
  site: string;
  map: string;
}

interface Dealers {
  city: string;
  state: string;
  addresses: AddressDealer[];
}

const Dealer: React.FC = () => {
  const [locations, setLocations] = useState<Dealers[] | undefined>();

  const handleLinkClick = (link: string) => window.open(link, '_blank');

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    let mounted = true;
    const getLocations = async (): Promise<void> => {
      try {
        const resp = await api().request({
          url: '/generic/revendedores',
          method: 'GET',
        });

        const isErrored = handleRespReturn(resp);
        if (isErrored || !mounted) return;
        // console.log(resp.data.content);
        setLocations(resp.data.content);
      } catch (err) {
        if (!mounted) return;
        handleErrors(err, true);
      }
    };

    if (!locations && mounted) {
      getLocations();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <CurrentPath
        pathList={[
          { title: 'Página inicial', to: '/' },
          { title: 'Revendedores' },
        ]}
      />

      <SectionTitle
        title="Encontre o revendedor mais próximo de você!"
        icon={<TelescopioSVG />}
        margin="0"
      />

      <Content>
        {!locations && (
          <span>
            <Skeleton />
            <Skeleton count={4} />
          </span>
        )}

        {locations &&
          locations.length > 0 &&
          locations.map((item) => (
            <LocationContainer key={item.city}>
              <h1>
                {item.state} - {item.city}
              </h1>

              <div>
                {item.addresses.map((addI) => (
                  <AddContainer key={addI.name}>
                    <header>
                      <strong>{addI.name}</strong>
                      <button
                        type="button"
                        onClick={() => handleLinkClick(addI.map)}
                      >
                        ver no mapa
                      </button>
                    </header>

                    <main>
                      {addI?.street && addI?.number ? (
                        <strong>
                          {addI.street}, {addI.number}
                        </strong>
                      ) : (
                        ''
                      )}

                      {addI?.district ? <span>{addI.district}</span> : ''}

                      {addI?.info ? <span>{addI.info}</span> : ''}

                      {addI?.contact && (
                        <span>{formatContact(addI.contact)}</span>
                      )}

                      {addI.site ? (
                        <Button
                          onClick={() => {
                            if (addI.site.includes('@')) {
                              window.open(
                                `https://instagram.com/${addI.site.replace(
                                  '@',
                                  '',
                                )}`,
                                '_blank',
                              );
                            }
                            window.open(addI.site, '_blank');
                          }}
                        >
                          {addI.site}
                        </Button>
                      ) : (
                        ''
                      )}
                    </main>
                  </AddContainer>
                ))}
              </div>
            </LocationContainer>
          ))}
      </Content>
    </Container>
  );
};

export default Dealer;
