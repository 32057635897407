import React from 'react';

import { NavLink } from 'react-router-dom';
import { Container } from './styles';

interface Path {
  title: string;
  to?: string;
}

interface Props {
  pathList: Path[];
}

const CurrentPath: React.FC<Props> = ({ pathList }) => {
  return (
    <Container>
      {pathList.map((item, index) => (
        <>
          {/* eslint-disable-next-line */}
          <NavLink key={`${index}${item.title}`} to={item?.to || '/'}>
            {item.title}
          </NavLink>
          {index + 1 !== pathList.length ? <span>{'>'}</span> : ''}
        </>
      ))}
    </Container>
  );
};

export default CurrentPath;
