import React, {
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react';

import { ReactComponent as ArrowLeftSVG } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/icons/arrowRight.svg';

import {
  Container,
  ButtonBack,
  ButtonNext,
  SliderContainer,
  SliderBackgroundImg,
} from './styles';

type ButtonProps = JSX.Element | string;

interface ProductsCarousel {
  totalItems: number;
  minHeight?: string;
  btnInside?: boolean;
  btnNext?: ButtonProps;
  btnBack?: ButtonProps;
  timer?: number;
  productSizeType?: number;
}

export interface SizeProps {
  size: number;
  minHeight: number;
  itemSize: number;
  marginSize: number;
  above600: {
    size: number;
    minHeight: number;
    itemSize: number;
    marginSize: number;
  };
}

interface ProductSizeProps {
  [key: number]: SizeProps;
}

const productSizes: ProductSizeProps = {
  1: {
    size: 400 + 32,
    minHeight: 535,
    itemSize: 400,
    marginSize: 32,
    above600: {
      size: 275 + 16,
      minHeight: 425,
      itemSize: 275,
      marginSize: 16,
    },
  },
  2: {
    size: 250 + 28,
    minHeight: 350,
    itemSize: 250,
    marginSize: 28,
    above600: {
      size: 250 + 28,
      minHeight: 350,
      itemSize: 250,
      marginSize: 28,
    },
  },
};

const ProductCarousel: React.FC<ProductsCarousel> = ({
  btnBack = <ArrowLeftSVG />,
  btnNext = <ArrowRightSVG />,
  btnInside = true,
  totalItems,
  timer = undefined,
  productSizeType = 1,
  children,
}) => {
  const renderNumberOfSlides = useCallback((width?: number): number => {
    let size = productSizes[productSizeType].size;
    // let size = productSizeType === 1 ? 400 + 32 : 250 + 28;
    const screenSize = width || window.innerWidth;

    if (screenSize <= 600) {
      size = productSizes[productSizeType].above600.size;
    }

    const calc = Math.floor(screenSize / size);
    return calc;
  }, []);

  const [screenSize, setScreenSize] = useState<number>(window.innerWidth);

  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const [timerEffect, setTimerEffect] = useState<NodeJS.Timeout | undefined>();
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    renderNumberOfSlides(),
  );

  const handleNextSlide = useCallback(() => {
    if (itemsPerPage === totalItems) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }

    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    const lastSlide = Math.ceil(totalItems / itemsPerPage);
    if (currentSlide === lastSlide) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }, [currentSlide, timerEffect]);

  const handlePreviousSlide = useCallback(() => {
    if (itemsPerPage === totalItems) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }
    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    if (currentSlide === 1) {
      setCurrentSlide(Math.ceil(totalItems / itemsPerPage));
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }, [currentSlide, timerEffect]);

  useEffect(() => {
    if (timer) {
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }
  }, []);

  useLayoutEffect(() => {
    let id: NodeJS.Timeout | undefined;
    const updateItemsByScreenSize = (): void => {
      const numberOfSlides = renderNumberOfSlides(window.innerWidth);
      setItemsPerPage(numberOfSlides);
      setScreenSize(window.innerWidth);
    };

    const eventHandler = (): void => {
      if (id) clearTimeout(id);
      id = setTimeout(updateItemsByScreenSize, 500);
    };

    window.addEventListener('resize', eventHandler);

    return () => {
      if (id) clearTimeout(id);
      window.removeEventListener('resize', eventHandler);
    };
  }, []);

  return (
    <Container>
      <SliderContainer
        sizeProps={productSizes[productSizeType]}
        minHeight={productSizes[productSizeType].minHeight}
        itemSize={productSizes[productSizeType].itemSize}
        marginSize={productSizes[productSizeType].marginSize}
        // minHeight={productSizeType === 1 ? 535 : 350}
        // itemSize={productSizeType === 1 ? 400 : 250}
        // marginSize={productSizeType === 1 ? 32 : 28}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentSlide={currentSlide}
        screenSize={screenSize}
      >
        {children}
      </SliderContainer>

      <ButtonBack inside={btnInside} onClick={() => handlePreviousSlide()}>
        {btnBack}
      </ButtonBack>

      <ButtonNext inside={btnInside} onClick={() => handleNextSlide()}>
        {btnNext}
      </ButtonNext>
    </Container>
  );
};

export default ProductCarousel;
