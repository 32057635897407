import styled, { css } from 'styled-components';

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > svg {
    position: absolute;
    z-index: 30;
    /* top: -140px;
    left: 0;
    width: 500px;
    height: 500px;
    transform: rotateZ(-90deg) translateY(-25%); */
    &:nth-of-type(1) {
      top: 0;
      left: 0;
    }

    &:nth-of-type(2) {
      top: 50%;
      right: 0;
      transform: translateX(-25%);
    }

    &:nth-of-type(3) {
      top: 100%;
      left: 0;
      transform: translateX(25%) translateY(-90%);
    }
  }

  > article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    background-color: #ffffff30;
    border-radius: 25px;
    backdrop-filter: blur(16px);

    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: 24px 40px;
    z-index: 50;
  }

  @media (max-width: 1000px) {
    > svg {
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        display: none;
      }
    }

    > article {
      max-width: calc(100% - 16px);
      padding: 16px;
    }
  }
`;

export const Nav = styled.div<{ current: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    cursor: pointer;

    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #4f1e66;

    color: #58c78f;
    font-weight: bold !important;
    font-size: 1.5rem;
    text-align: center;

    padding: 12px 0;

    min-width: 200px;

    transition: filter 0.25s ease;

    &:hover {
      filter: brightness(75%);
    }
    ${({ current }) => css`
      &:nth-of-type(${current}) {
        border-bottom: 5px solid #4f1e66;
      }
    `}
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const NavContent = styled.div<{ current: number }>`
  display: grid;
  width: 100%;

  > div {
    grid-column: 1;
    grid-row: 1;
    flex-direction: column;

    margin-top: 24px;
    width: 100%;
    opacity: 0;

    user-select: none;
    pointer-events: none;

    transition: opacity 0.25s ease;

    ${({ current }) => css`
      &:nth-of-type(${current}) {
        opacity: 1;
        pointer-events: unset;
      }
    `}
  }
`;

export const DescContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 24px;
  }

  > p {
    line-height: 1.5;
    font-size: 1.25rem;
    color: #ffffff;
    font-weight: bold !important;
    text-align: left;
    white-space: pre-line;
  }

  > figure {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;

    > img {
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      display: block; /*to make it behave as div and avoir whitespace issue*/
    }
  }
  > iframe {
    width: 100%;
  }
`;

export const SpecContainer = styled.div`
  > div {
    display: flex;
    align-items: flex-start;

    width: 100%;
    padding: 12px 4px;

    border-bottom: 1px solid #f5f5f5;

    > strong,
    > div > span {
      color: #f5f5f5;
      font-size: 1rem;
      text-align: left;
    }

    > strong {
      font-weight: bold !important;
      max-width: 20%;
      width: 100%;
      text-transform: uppercase;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;

      > span + span {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 1200px) {
    > div {
      flex-direction: column;

      > div {
        margin-top: 16px;
        margin-left: 24px;
      }
    }
  }
`;
