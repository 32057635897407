import React from 'react';

import { Input } from 'components/Input/Generic/styles';

interface InputProps {
  onChange(value?: number): void;
  value?: string | number;
  disableCents?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const MaskDoc: React.FC<InputProps> = ({
  value = '',
  onChange,
  disabled = false,
  // eslint-disable-next-line
  disableCents = true,
  placeholder = '',
}: InputProps) => {
  const handleValue = (thisVal?: string | number): string => {
    if (!thisVal) return '';
    const regexVal = `${thisVal}`.replace(/[^\d]+/g, '');

    return `R$ ${regexVal}`;
  };

  return (
    <Input
      type="text"
      placeholder={placeholder}
      onChange={({ target }) => {
        const regexVal = target.value.replace(/[^\d]+/g, '');
        // eslint-disable-next-line
        if (Number(regexVal)) {
          onChange(parseInt(regexVal, 10));
          return;
        }
        onChange(undefined);
      }}
      value={handleValue(value)}
      readOnly={disabled}
      disabled={disabled}
    />
  );
};
export default MaskDoc;
