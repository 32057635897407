import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > strong {
      color: #00c763;

      + strong {
        margin-left: 16px;
      }
    }
  }

  > div:nth-of-type(2) {
    display: flex;
  }
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-of-type(2) {
      justify-content: flex-start;
      margin-top: 4px;
    }

    > span {
      color: #00c763;

      + span {
        margin-left: 16px;
      }
    }
  }
`;

export const ContainerPIX = styled.article`
  margin-top: 32px;

  display: flex;
  flex-direction: column;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: #fff;
    border: 0;
    background-color: transparent;

    transition: filter 0.25s ease;

    &:hover {
      filter: brightness(75%);
    }

    > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  > div {
    margin-top: 24px;
    display: flex;
    flex-direction: column;

    > img {
      max-width: 250px;
      margin: 0 auto;
      min-width: 250px;
    }

    > button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      margin-top: 6px;
      font-weight: bold !important;
      font-size: 1.1rem;

      color: #fff;
      border: 0;
      background-color: transparent;

      transition: filter 0.25s ease;

      &:hover {
        filter: brightness(75%);
      }

      > svg {
        margin-left: 4px;
      }
    }

    > p {
      width: 100%;
      margin: 16px auto;
      max-width: 250px;

      line-height: 1.5;
      text-align: center;

      font-size: 0.9rem;
    }
  }
`;

export const ContainerTED = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    color: #fff;
    margin-bottom: 16px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    > svg {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;

      > span {
        color: #fff;
        + span {
          margin-top: 4px;
        }
      }
    }
  }
`;

// export const QRCode
