import styled from 'styled-components';
import { Container as InputContainer } from 'components/Input/Container/styles';

export const ClientContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  > section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  @media (max-width: 950px) {
    > section {
      flex-direction: column;
      align-items: stretch;
      max-width: 600px;
      margin: 0 auto;
      margin-top: 24px;
    }
  }

  @media (max-width: 450px) {
    > section {
      margin-top: 24px;
      flex-direction: column;
      align-items: stretch;
      max-width: calc(100% - (16px * 2));
      margin: 0 16px;
    }
  }
`;

const DefContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 40px 10%; */

  > div {
    display: flex;
    flex-direction: column;

    > strong {
      color: #58c78f;
      font-size: 1.33rem;
      text-align: center;

      &:first-child {
        margin-bottom: 24px;
      }

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    > span {
      text-align: center;
      color: #58c78f;

      &:nth-of-type(1) {
        line-height: 1.25;
        max-width: 325px;
        font-size: 1.1rem;
      }

      &:nth-of-type(2) {
        margin-top: 32px;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 450px) {
    ${InputContainer} {
      flex-direction: column !important;

      > span {
        margin-bottom: 8px;
        margin-right: 0;
        width: 100%;
      }

      > div,
      > input {
        max-width: unset !important;
        width: 100%;
      }
    }
  }
`;

export const RegisterContainer = styled(DefContainer)`
  align-items: flex-end;

  > div {
    align-items: center;
    margin-right: 80px;

    > button {
      margin-top: 12px;
    }
  }

  @media (max-width: 950px) {
    align-items: center;

    > div {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
`;

export const LoginContainer = styled(DefContainer)`
  border-left: 1px solid #00c763;
  align-items: flex-start;

  > div {
    align-items: center;
    margin-left: 80px;

    > button,
    > div {
      margin-top: 16px;
    }

    > button,
    > div {
      min-width: 275px;
      max-width: 275px;
    }

    > div > input,
    > div > div {
      max-width: 200px;
    }
  }

  @media (max-width: 950px) {
    align-items: center;
    border-top: 1px solid #00c763;
    border-left: 0;

    > div {
      margin-left: 0;
      margin-top: 40px;
      align-items: center;
    }
  }
`;

export const ForgotLink = styled.button`
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;

  color: #58c78f;
  text-decoration: underline;
`;
