import React, { useEffect, useState } from 'react';

import { swalFire } from 'services/swal';

import GreenButton from 'components/Button/GreenButton';
import CepInput from 'components/Input/MaskedInputs/Cep';
import WppInput from 'components/Input/MaskedInputs/Wpp';
import ContainerInput from 'components/Input/Container';
import GenericInput from 'components/Input/Generic';

import GenericModal from '../Generic';
import { Content, Form } from './styles';

interface AddressDone {
  receiver: string;
  name: string;
  street: string;
  number: string;
  info?: string;
  district: string;
  cep: string;
  state: string;
  city: string;
  contact: string;
}

interface Address {
  receiver?: string;
  name?: string;
  street?: string;
  number?: string;
  info?: string;
  district?: string;
  cep?: string;
  state?: string;
  city?: string;
  contact?: string;
}

export type RespAddressModal = (
  isConfirmed?: boolean,
  addressDone?: AddressDone,
  idAddress?: number,
) => void | Promise<void>;

export interface AddressProps {
  isShown: boolean;
  idAddress?: number;
  editAddress?: AddressDone;
  handleResponse?: RespAddressModal;
}

const ModalAddress: React.FC<AddressProps> = ({
  isShown,
  idAddress,
  editAddress,
  handleResponse,
}) => {
  const [form, setForm] = useState<Address>({});

  const handleSubmit = async (bool?: boolean): Promise<void> => {
    if (!handleResponse) return;
    if (!bool) {
      handleResponse(false);
      return;
    }
    const { cep, city, district, name, number, receiver, state, street } = form;
    if (
      !cep ||
      !city ||
      !district ||
      !name ||
      !number ||
      !receiver ||
      !state ||
      !street
    ) {
      swalFire({
        title: 'Aviso!',
        text: 'Preencha todos os campos relacionados ao endereço.',
        icon: 'warning',
      });
      return;
    }
    handleResponse(true, form as AddressDone, idAddress);
  };

  useEffect(() => {
    if (isShown) {
      if (editAddress) {
        setForm({ ...editAddress });
      }
    } else {
      setForm({});
    }
    // eslint-disable-next-line
  }, [isShown]);

  return (
    <GenericModal
      isShown={isShown}
      returnTrigger={(bool) => handleSubmit(bool)}
      disableMinSize
    >
      <Content>
        <strong>{idAddress ? 'Editar' : 'Criar'} endereço</strong>

        <span>
          {idAddress
            ? 'Altere as informações do endereço selecionado'
            : 'Insira as informações do novo endereço'}
        </span>

        <Form>
          <div>
            <ContainerInput title="Nome do endereço" flex="1" maxWidth="230px">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, name: target.value });
                }}
                value={form.name}
              />
            </ContainerInput>
            <ContainerInput title="Nome do destinatário" flex="1">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, receiver: target.value });
                }}
                value={form.receiver}
              />
            </ContainerInput>
          </div>

          <div>
            <ContainerInput title="CEP" flex="1" maxWidth="115px" styleInput>
              <CepInput
                onSearchDone={(cepResp) => {
                  const { bairro, cep, localidade, logradouro, uf } = cepResp;
                  setForm({
                    ...form,
                    cep: cep.replace(/[^\d]+/g, ''),
                    state: uf,
                    street: logradouro,
                    city: localidade,
                    district: bairro,
                  });
                }}
                onChangeInput={(value) => {
                  setForm({ ...form, cep: value?.replace(/[^\d]+/g, '') });
                }}
                currentValue={form.cep}
              />
            </ContainerInput>
            <ContainerInput title="Endereço" flex="1">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, street: target.value });
                }}
                value={form.street}
              />
            </ContainerInput>
          </div>

          <div>
            <ContainerInput title="Número" flex="1" maxWidth="115px">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, number: target.value });
                }}
                value={form.number}
              />
            </ContainerInput>

            <ContainerInput title="Bairro" flex="1">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, district: target.value });
                }}
                value={form.district}
              />
            </ContainerInput>

            <ContainerInput title="Complemento" flex="1">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, info: target.value });
                }}
                value={form.info}
              />
            </ContainerInput>
          </div>

          <div>
            <ContainerInput title="Celular" flex="1" styleInput>
              <WppInput
                onChangeInput={(value) => {
                  setForm({ ...form, contact: value });
                }}
                currentValue={form.contact}
              />
            </ContainerInput>

            <ContainerInput title="Cidade" flex="1">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, city: target.value });
                }}
                value={form.city}
              />
            </ContainerInput>

            <ContainerInput title="Estado" flex="1" maxWidth="115px">
              <GenericInput
                onChangeCustom={({ target }) => {
                  setForm({ ...form, state: target.value });
                }}
                value={form.state}
                maxLength={2}
              />
            </ContainerInput>
          </div>
        </Form>

        <GreenButton onClick={() => handleSubmit(true)} text="Salvar" />
      </Content>
    </GenericModal>
  );
};

export default ModalAddress;
