import styled, { createGlobalStyle, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const fadeOutNone = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  99% {
    display: none;
    opacity: 0;
    transform: translateY(-20px);
    margin: 0;
    padding: 0;
  }
  100% {
    display: none;
    opacity: 0;
    transform: translateY(-20px);
    padding: 0;
    margin: 0;
  }
`;

export const fadeInLeft = keyframes`
  0% {
      opacity: 0;
      transform: translateX(-40px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;

export const fadeInRight = keyframes`
  0% {
      opacity: 0;
      transform: translateX(40px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
`;

export const RemoveMobile = styled.article`
  position: absolute;
  top: -200vh;

  transition: all 0.25s ease;

  @media (max-width: 1000px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > strong:nth-of-type(1) {
      color: #f9f9f9;
      font-size: 2rem;
      text-align: center;
    }

    > strong:nth-of-type(2) {
      color: #00c763;
      font-size: 1.75rem;
      text-align: center;
    }
  }
`;

export default createGlobalStyle`
  .txt-fff {
    color: #fff;
  }
  .txt-000 {
    color: #000;
  }

  .confirm-btn, .cancel-btn {
    transition: all .25s ease!important;
  }

  .cancel-btn {
    border: 2px solid #00c763!important;
    background-color: transparent!important;
    font-weight: bold!important;

    color: #00c763!important;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;

    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      width: 8px;
      border: 1px solid #00c763;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #00c763;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #00c76370;
    }

    @media(max-width: 1080px) {
      ::-webkit-scrollbar
      /* ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb,
      ::-webkit-scrollbar-thumb:hover  */
      {
        display: none;
        /* pointer-events: none;
        user-select: none;
        width: 0;
        border: 0;
        background: 0; */
      }
    }
  }

  html, body {
    outline: none;
    /* background-color: linear-gradient(180deg, rgba(95,50,116,1) 0%, rgba(45,20,57,1) 100%); */
    font-family: 'Nuninto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #F5F5F5;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow: hidden!important;

    width: 100%;
    height: 100%;

    @media(min-width: 1401px) {
      font-size: 16px;
    }

    @media(min-width: 1601px) {
      font-size: 16px;
    }


  }

  body {
    position: relative;
    overflow-y: hidden!important;
    overflow-x: hidden!important;
    padding: 0!important;
    margin: 0;
    width: 100vw;
    height: 100vh!important;

    background: transparent;

    > #canvas {
      background: linear-gradient(180deg, rgba(95,50,116,.85) 0%, rgba(45,20,57,95) 100%);
      /* IE10 Consumer Preview */
      background-image: -ms-linear-gradient(top, rgba(95,50,116,.95) 0%, rgba(45,20,57,1) 100%);

      /* Mozilla Firefox */
      background-image: -moz-linear-gradient(top, rgba(95,50,116,.95) 0%, rgba(45,20,57,1) 100%);

      /* Opera */
      background-image: -o-linear-gradient(top, rgba(95,50,116,.95) 0%, rgba(45,20,57,1) 100%);

      /* Webkit (Safari/Chrome 10) */
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(95,50,116,.95)), color-stop(1, rgba(45,20,57,1)));

      /* Webkit (Chrome 11+) */
      background-image: -webkit-linear-gradient(top, rgba(95,50,116,.95) 0%, rgba(45,20,57,1) 100%);

      /* W3C Markup, IE10 Release Preview */
      background-image: linear-gradient(to bottom, rgba(95,50,116,.95) 0%, rgba(45,20,57,1) 100%);


      width: 100%;
      height: 100%;

    }

    > #root {
      position: absolute;
      width: 100%;
      height: 100%;

      /* @media (max-width: 1000px) {
        > *:not(article) {
          display: none;
        }
      } */
    }
  }



  span.react-loading-skeleton {
    background-color: #281334;
    background-image: linear-gradient(90deg,#261131,#431e57,#261131);
    /* background-color: #d3d3d3;
    background-image: linear-gradient(90deg, #d3d3d3, rgb(245, 245, 245), #d3d3d3); */
  }

  input, button, textarea, select {
    font-family: 'Nuninto', sans-serif;
    font-size: 1.175rem;
    border: 0;
    &:focus {
      outline: none;
    }
  }


  button {
    cursor: pointer;
  }

  a, li, ul {
    color:inherit;
    text-decoration: none;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: bold;
    user-select: none;
  }

`;
