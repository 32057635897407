import styled from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

import { ContainerDefault } from 'styles/default';

export const Container = styled(ContainerDefault)`
  margin: 50px 100px;

  > div:nth-of-type(1) {
    margin: 0 auto 0 0;
  }

  @media (max-width: 700px) {
    margin: 24px;
  }
`;

export const Content = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;

  > span {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    > span:nth-of-type(1) {
      max-width: 200px;
      max-height: 2rem;
      width: 100%;
      height: 100%;

      margin-bottom: 24px;

      > span {
        width: 100%;
        height: 100%;
      }
    }

    > span:nth-of-type(2) {
      display: grid;
      grid-template-columns: repeat(auto-fill, 350px);
      grid-gap: 24px;
      justify-content: flex-start;
      grid-auto-flow: row;

      width: 100%;

      > span {
        flex: 1;
        min-height: 150px;
      }
    }
  }
`;

export const LocationContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  + article {
    margin-top: 50px;
  }

  > h1 {
    color: #58c78f;
    font-size: 1.5rem;
    margin-bottom: 24px;
    text-transform: capitalize;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    grid-gap: 24px;
    justify-content: flex-start;
    grid-auto-flow: row;
    width: 100%;
  }

  @media (max-width: 700px) {
    > div {
      justify-content: center;
      grid-template-columns: repeat(auto-fill, 300px);
    }
    > h1 {
      text-align: center;
      width: 100%;
    }
  }
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 50;

  width: 350px;
  height: 225px;

  animation: ${fadeIn} 0.25s ease forwards;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 2px solid #4f1e66;
    border-radius: 25px 25px 0px 0px;

    padding: 16px 24px;
    width: 100%;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > strong {
      color: #58c78f;
    }

    > button {
      background-color: transparent;
      border: 0;

      color: #58c78f;
      text-decoration: underline;
      transition: filter 0.25s ease;

      &:hover {
        filter: brightness(75%);
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;

    background-color: #68577180;
    backdrop-filter: blur(16px);
    border-radius: 0 0 25px 25px;
    padding: 16px 24px;

    > strong,
    > span {
      width: 100%;
      color: #f5f5f5;
    }

    > strong {
      font-size: 1.1rem;
      font-weight: bold;
      margin-bottom: 8px;
    }

    > span {
      font-size: 1rem;
      margin-top: 8px;
    }
  }

  @media (max-width: 700px) {
    width: 300px;
  }
`;

export const Button = styled.span`
  cursor: pointer;

  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(75%);
  }
`;
