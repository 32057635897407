import styled, { css } from 'styled-components';

interface ContainerProps {
  len: number;
  status: number;
}

export const Container = styled.div<ContainerProps>`
  margin: 24px 0;

  display: flex;
  justify-content: center;
  width: 100%;

  ${({ status }) => status >= 6 && `filter: brightness(50%);`}

  > div {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    max-width: 150px;

    > article {
      position: absolute;
      top: 4px;
      right: 4px;
      left: 4px;

      width: 100%;
      height: 16px;
      background-color: #4f1e66;
      /* background-color: #00c763; */
      z-index: 40;
      transform: translateX(50%);
    }

    &:last-of-type {
      > article {
        /* right: calc(50% - 4px); */
        transform: translateX(-50%);
      }
    }

    > strong {
      color: #ffffff;
      font-weight: bold !important;
      margin-top: 8px;
      max-width: 100px;
      min-height: calc(1rem * 2 + 0.5rem);
      text-align: center;
    }

    > div {
      position: relative;
      /* margin-left: 37px; */
      border-radius: 50%;
      width: 24px;
      height: 24px;
      /* background-color: #9c31ab; */
      background-color: #136c49;
      z-index: 50;

      /* ~ div {
        background-color: #136c49;
        background-color: #9c31ab;
      } */
    }

    ${({ status, len }) => css`
      ${() => {
        if (status >= 6)
          return css`
            > div {
              background-color: #9c31ab;
            }
          `;
        const styleArr = [];
        for (let idx = 1; idx < len + 1; idx += 1) {
          if (idx < status) {
            styleArr.push(css`
              &:nth-of-type(${idx}) {
                > article {
                  background-color: #00c763;
                  background: linear-gradient(
                    to right,
                    #00c763 0%,
                    #00c763 100%
                  );
                }
              }
            `);
          }
          if (idx === status && status !== 5) {
            styleArr.push(css`
              &:nth-of-type(${idx}) {
                > article {
                  background: linear-gradient(
                    to right,
                    #4f1e66 0%,
                    #4f1e66 100%
                  );
                }
              }
            `);
          }

          if (idx === 5 && status === 5) {
            styleArr.push(css`
              &:nth-of-type(${idx}) {
                > article {
                  background-color: #00c763;
                  background: linear-gradient(
                    to right,
                    #00c763 0%,
                    #00c763 100%
                  );
                }
              }
            `);
          }

          // if (idx === len) {
          //   styleArr.push(css`
          //     &:nth-of-type(${idx}) {
          //       > article {
          //         background-color: #00c763;
          //       }
          //     }
          //   `);
          // }
        }
        return styleArr.map((item) => item);
      }}

      /* background: linear-gradient(
            to right,
            #00c763 0%,
            #00c763 50%,
            #4f1e66 50%,
            #4f1e66 100%
          ); */


      &:nth-of-type(${status + 1}),
      &:nth-of-type(${status + 1}) ~ div {
        > div {
          background-color: #9c31ab;
        }
      }
    `}
  }

  @media (max-width: 875px) {
    > div {
      max-width: 70px;

      > strong {
        font-size: 0.75rem;
        max-height: calc(0.75rem * 2 + 0.375rem);
      }
    }
  }
`;
