import styled from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  > strong {
    margin-top: 40px;
    color: #00c763;
    text-align: left;
  }
`;

export const CardContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  > strong {
    font-size: 2rem;
    color: #00c763;
    width: 100%;
    text-align: center;
    animation: ${fadeIn} 0.25s ease forwards;
  }

  > span {
    width: 100%;
    height: 200px;
    animation: ${fadeIn} 0.25s ease forwards;

    > span {
      width: 350px;
      height: 100%;

      + span {
        margin-left: 24px;
      }
    }
  }

  @media (max-width: 1080px) {
    align-items: center;
    justify-content: center;
  }
`;

export const CardCC = styled.article`
  display: flex;
  flex-direction: column;
  z-index: 50;
  width: 100%;

  animation: ${fadeIn} 0.25s ease forwards;

  width: calc(350px + 1rem);
  height: calc(200px + 1rem);
  margin: 1rem;

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 2px solid #4f1e66;
    border-radius: 25px 25px 0px 0px;

    padding: 16px 24px;
    width: 100%;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > strong {
      color: #58c78f;
      font-size: 1rem;

      &:nth-of-type(2) {
        margin-left: auto;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    width: 100%;

    background-color: #68577180;
    backdrop-filter: blur(16px);
    border-radius: 0 0 25px 25px;
    padding: 8px 0;

    > div {
      display: flex;
      flex-direction: column;
      padding: 8px 24px;
      width: 100%;

      &:nth-of-type(1) {
        border-bottom: 1px solid #685771;

        > strong {
          color: #f5f5f5;
          margin-bottom: 16px;
        }
        > span {
          color: #f5f5f5;
          + span {
            margin-top: 8px;
          }
        }
      }

      &:nth-of-type(2) {
        flex-direction: row;
      }
    }
  }
`;

export const DeleteCard = styled.button`
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #58c78f;
  margin-left: auto;

  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(75%);
  }
`;

export const CardBrand = styled.div<{ link: string }>`
  width: 50px;
  height: 25px;
  margin-right: 16px;

  background-image: url(${({ link }) => link});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
