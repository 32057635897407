import styled, { css } from 'styled-components';

// Price Color: #00C763;
// #461C58
// #707070

export const Container = styled.div`
  position: relative;
  height: 350px;
  width: 250px;

  > svg {
    position: absolute;
    right: 12px;
    top: 12px;

    cursor: pointer;

    width: 28px;
    height: 28px;
    z-index: 40;

    .heart {
      transition: fill 0.25s ease, filter 0.25s ease;
    }

    &:hover {
      .heart {
        fill: #2b1037 !important;
      }

      .active {
        filter: brightness(75%);
      }
    }
  }
`;

export const ThumbContainer = styled.div<{ outOfStock?: boolean }>`
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 1px solid #461c58;

  /* overflow: hidden; */
  width: 250px;
  height: 315px;

  ${({ outOfStock }) =>
    outOfStock &&
    css`
      filter: brightness(75%);
    `}

  transition: all 0.25s ease;
  z-index: 30;

  &:hover {
    height: 350px;
    box-shadow: 0px 3px 6px #9aff00fc;
  }
`;

interface ImageContainerProps {
  img?: string;
  contain?: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  border-bottom: 1px solid #461c58;

  height: 220px;
  width: 100%;

  > figure {
    overflow: hidden;
    max-height: 220px;
    max-width: 100%;

    > img {
      position: relative;
      z-index: -1;

      height: 220px;
      width: 100%;

      object-fit: cover;
      object-position: center;
      display: block; /*to make it behave as div and avoir whitespace issue*/
    }
  }

  /* background-image: url('${({ img }) => img}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${({ contain }) => contain && `background-size: contain;`} */

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    position: absolute;
    top: 8px;
    left: 8px;

    > div + div {
      margin-top: 6px;
    }
  }

  > article {
    position: relative;
    border-radius: 8px;
    bottom: 8px;
    left: calc(100% - 8px);
    width: 100px;
    text-align: center;

    transform: translate(-100%, -100%);

    font-weight: bold !important;
    color: #fff;
    background-color: #f04630;
    padding: 4px;
    font-size: 0.8rem;

    &.warn {
      background-color: #f47b38;
    }
  }
`;

export const Title = styled.strong`
  color: #461c58;
  text-align: left;
  font-size: 1rem;
`;

export const ProductCost = styled.strong`
  color: #00c763;
  font-size: 1.1rem;
  transition: font-size 0.25s ease;

  ${ThumbContainer}:hover & {
    font-size: 1.5rem;
  }
`;

export const DescContainer = styled.div`
  display: flex;

  padding: 8px 12px;

  /* max-height: 125px; */
  /* min-height: 125px; */
  transition: max-height 0.25s ease;

  /* ${ThumbContainer}:hover & {
    max-height: 180px;
  } */
`;

export const JuiceDescContainer = styled(DescContainer)`
  align-items: flex-start;
  flex-direction: column;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-top: 8px;

      > div {
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;

        ${ThumbContainer}:hover & {
          visibility: visible;
          opacity: 1;
        }

        + div {
          margin-left: 8px;
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > * {
        text-align: right;
      }

      > span:nth-of-type(1) {
        color: #707070;
        font-size: 0.8rem;
      }

      > span:nth-of-type(2) {
        color: #707070;
        font-size: 0.7rem;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;
      }

      > strong:nth-of-type(2) {
        color: #461c58;
        font-size: 0.9rem;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;
      }

      ${ThumbContainer}:hover & {
        > strong:nth-of-type(2),
        > span:nth-of-type(2) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
`;

export const VapeDescContainer = styled(DescContainer)`
  flex-direction: column;

  > div:nth-of-type(1) {
    width: 100%;
    margin-top: 8px;
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    > span {
      color: #707070;
      text-decoration: line-through;
      font-size: 1rem;
    }

    > strong:nth-of-type(2) {
      color: #461c58;
      font-size: 0.8rem;

      visibility: hidden;
      opacity: 0;

      transition: all 0.25s ease;

      > span {
        color: #707070;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  ${ThumbContainer}:hover & {
    > div:nth-of-type(2) {
      > strong:nth-of-type(2) {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;
