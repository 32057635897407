import React, { useState, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';

import { api, handleErrors, handleRespReturn } from 'services/api';

import Carousel from 'components/Carousel';
import RatingStars from 'components/RatingStars';

import {
  Container,
  ProductBanner,
  InstaPost,
  FeedImg,
  ProductName,
} from './styles';

interface FeedSection {
  htmlId?: string;
  products?: any;
}

interface FeedProducts {
  id: number;
  idProduct: number;
  productName: string;
  img: string;
  text: string;
  name: string;
  total: number;
  rate: number;
}

interface InstagramPosts {
  id: number;
  urlImage: string;
  urlPost: string;
}

const FeedSection: React.FC<FeedSection> = ({ htmlId }) => {
  const history = useHistory();

  const [instaPosts, setInstaPosts] = useState<InstagramPosts[] | undefined>();

  const [products, setProducts] = useState<FeedProducts[] | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getProd = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const resp = await api().request({
          url: '/products/feed',
          method: 'GET',
        });

        setIsLoading(false);
        const isErrored = handleRespReturn(resp);
        if (isErrored || !resp.data.content || resp.data.content.length === 0) {
          return;
        }

        setProducts(resp.data.content);
      } catch (err) {
        setIsLoading(false);
        handleErrors(err, true);
      }
    };

    if (!products && !isLoading) {
      getProd();
    }
  }, []);

  useEffect(() => {
    const getInsta = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const resp = await api().request({
          url: '/generic/instagram',
          method: 'GET',
        });

        setIsLoading(false);
        const isErrored = handleRespReturn(resp);
        if (isErrored || !resp.data.content || resp.data.content.length === 0) {
          return;
        }

        setInstaPosts(resp.data.content);
      } catch (err) {
        setIsLoading(false);
        handleErrors(err, true);
      }
    };

    if (!instaPosts && !isLoading) {
      getInsta();
    }
  }, []);

  return (
    <Container id={htmlId}>
      <div>
        <div>
          {instaPosts &&
            instaPosts.length > 0 &&
            instaPosts.map((item) => (
              <InstaPost
                key={item.id}
                imgUrl={item.urlImage}
                onClick={() => {
                  window.open(item.urlPost, '_blank');
                }}
              />
            ))}
        </div>
      </div>

      <div>
        {!products && <Skeleton />}
        {products && (
          <Carousel totalSlides={products.length} slidesPerPage={1} fullWidth>
            {products.map((item) => (
              <ProductBanner key={item.id}>
                <div>
                  <div />

                  <div>
                    <div>
                      <RatingStars
                        rate={item.rate}
                        total={item.total}
                        calc
                        direction="row"
                        showTotal
                      />
                      <ProductName
                        onClick={() => {
                          history.push(`/product/${item.idProduct}`);
                        }}
                      >
                        {item.productName}
                      </ProductName>
                    </div>

                    <p>{item.text}</p>
                    <span>{item.name}</span>
                  </div>
                </div>

                <FeedImg
                  img={item.img}
                  onClick={() => {
                    history.push(`/product/${item.idProduct}`);
                  }}
                />
              </ProductBanner>
            ))}
          </Carousel>
        )}
      </div>
    </Container>
  );
};

export default FeedSection;
