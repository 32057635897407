import React from 'react';

import { Button, ButtonColors, ButtonSizes } from './styles';

interface Props {
  onClick(): void;
  text: string;
  customColor?: keyof ButtonColors;
  icon?: JSX.Element;
  outline?: boolean;
  size?: keyof ButtonSizes;
  bold?: boolean;
  fullRound?: boolean;
  sideRound?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
  type?: 'button' | 'submit';
}

const GreenButton: React.FC<Props> = ({
  text,
  onClick,
  icon,
  customColor = undefined,
  outline = false,
  size = 'medium',
  bold = true,
  fullRound = false,
  sideRound = false,
  isLoading = false,
  loadingLabel = 'Carregando',
  type = 'button',
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      hasIcon={!!icon}
      size={size}
      isOutline={outline}
      isBold={bold}
      isRound={fullRound}
      customColor={customColor}
      isSideRound={sideRound}
      isLoading={isLoading}
    >
      <span>{text}</span>
      {isLoading && <span>{loadingLabel}...</span>}
      {icon}
    </Button>
  );
};

export default GreenButton;
