import styled, { css } from 'styled-components';
import { SizeProps } from './index';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

interface SliderContainerProps {
  totalItems: number;
  itemsPerPage: number;
  currentSlide: number;
  // Size Props Bellow
  sizeProps: SizeProps;
  itemSize: number;
  minHeight: number;
  marginSize: number;
  screenSize: number;
}

export const SliderContainer = styled.section<SliderContainerProps>`
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow: hidden;

  width: 100%;
  height: 100%;
  /* min-height: ${({ minHeight }) => minHeight}px; */
  min-height: ${({ sizeProps }) => sizeProps.minHeight}px;

  > * {
    position: absolute !important;

    top: 0;
    left: -100vw;

    margin: 0 1rem;
    user-select: none;

    transition: all 0.25s ease;

    &:nth-child(${({ currentSlide, itemsPerPage }) =>
          currentSlide * itemsPerPage})
      ~ * {
      left: 100vw;
    }

    ${({
      currentSlide,
      itemsPerPage,
      screenSize,
      // itemSize,
      // marginSize,
      totalItems,
      sizeProps,
    }) => {
      let { itemSize, marginSize } = sizeProps;
      if (screenSize <= 600) {
        itemSize = sizeProps.above600.itemSize;
        marginSize = sizeProps.above600.marginSize;
      }

      let min = (currentSlide - 1) * itemsPerPage + 1;

      let max = min + itemsPerPage - 1;

      if (currentSlide === 1) {
        min = 1;
        max = itemsPerPage;
      }
      if (max > totalItems) {
        max = totalItems;
      }
      const calcItemSize = (itemSize + marginSize) * itemsPerPage;
      const minMargin = (screenSize - calcItemSize) / 2;

      let secondIdx = 0;
      const loopSliders = [];
      for (let index = min; index <= max; index += 1) {
        // if (index === currentSlide * itemsPerPage) calc = '100%';
        loopSliders.push(`&:nth-child(${index}) {
          user-select: unset;
          left: calc(${minMargin}px + (${itemSize}px * ${secondIdx}) + (${marginSize}px * ${secondIdx}));
        }`);
        secondIdx += 1;
      }
      return loopSliders.map((item) => item);
    }}
  }
`;

// loopSliders.push(`&:nth-child(${index}) {
//   position: relative !important;
//   user-select: unset;
//   left: unset;
// }`);

interface Props {
  inside?: boolean;
  fullWidth?: boolean;
}

const Button = styled.button<Props>`
  cursor: pointer;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 0;
  z-index: 100;

  > svg {
    height: 32px;
    width: 32px;
  }
`;

export const ButtonBack = styled(Button)`
  left: -16px;
  ${({ inside, fullWidth }) =>
    inside &&
    css`
      left: ${fullWidth ? '0' : '16px'};
    `}
`;

export const ButtonNext = styled(Button)`
  right: -16px;
  ${({ inside, fullWidth }) =>
    inside &&
    css`
      right: ${fullWidth ? '0' : '16px'};
    `}
`;

interface SliderImgProps {
  img: string;
}

export const SliderBackgroundImg = styled.div<SliderImgProps>`
  width: 100%;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
