import React, { useState } from 'react';

import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import SectionTitle from 'components/SectionTitle';
import { ReactComponent as RocketSVG } from 'assets/draws/espaconave.svg';

import UserData from './UserData';
import Order from './Order';
import CreditCard from './CreditCard';
import Address from './Address';

import Favorites from '../Favorites';
import OrderInfoPage, { Order as OrderInfoObj } from './Order/OrderInfo';

import { Container, Nav, NavButton, Content } from './styles';

const Profile: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [current, setCurrent] = useState(1);

  const [orderInfoData, setOrderInfoData] = useState<
    OrderInfoObj | undefined
  >();

  const handleNavClick = (page: number): void => {
    setCurrent(page);
  };

  const handleOrderInfo = (order: OrderInfoObj): void => {
    setOrderInfoData({ ...order });
    history.push('/profile/order');
  };

  return (
    <Switch>
      <Route exact path={path}>
        <Container>
          <SectionTitle
            icon={<RocketSVG />}
            title="Área do cliente"
            margin="40px 0 0 0"
          />

          <section>
            <Nav current={current}>
              <NavButton onClick={() => handleNavClick(1)}>
                Meus dados
              </NavButton>
              <NavButton onClick={() => handleNavClick(2)}>
                Meus pedidos
              </NavButton>
              <NavButton onClick={() => handleNavClick(3)}>
                Meus cartões de crédito
              </NavButton>
              <NavButton onClick={() => handleNavClick(4)}>
                Meus endereços
              </NavButton>
            </Nav>

            <Content current={current}>
              <UserData enableReq={current === 1} />

              <Order
                handleOrderInfo={handleOrderInfo}
                enableReq={current === 2}
              />

              <CreditCard enableReq={current === 3} />

              <Address enableReq={current === 4} />
            </Content>
          </section>
        </Container>
      </Route>

      <Route path={`${path}/order`}>
        <OrderInfoPage order={orderInfoData} />
      </Route>

      <Route path={`${path}/favorites`}>
        <Favorites />
      </Route>
    </Switch>
  );
};

export default Profile;
