import styled, { css, keyframes } from 'styled-components';
import { ContainerBlock } from 'styles/default';
import { fadeIn, fadeOutNone } from 'styles/globalStyle';
import {
  ProductDescription,
  Container as ProductCont,
} from 'components/Cart/CartItem/styles';

export const Container = styled(ContainerBlock)`
  align-items: unset;
  width: 100%;
  margin: 40px;

  > h1 {
    font-size: 2rem;
    color: #fff;
    max-width: 300px;
  }

  > a {
    max-width: 300px;
    color: #58c78f;
    font-size: 0.9rem;
    margin-top: 16px;

    text-decoration: underline;
  }
`;

export const Card = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  z-index: 50;

  > *:first-child {
    border-radius: 25px 25px 0px 0px;
  }

  > *:last-child {
    border-radius: 0 0 25px 25px;
  }

  > header {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #4f1e66;
    padding: 12px 24px;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > strong {
      color: #58c78f;
      font-size: 1rem;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 22px 16px;

    background-color: #68577180;
    backdrop-filter: blur(16px);
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;

  > svg:nth-of-type(1) {
    position: absolute;
    left: 0;
    top: 0;
  }

  > svg:nth-of-type(2) {
    position: absolute;
    right: 0;
    top: 25%;
  }

  > div:nth-of-type(2) {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-left: 24px;
    z-index: 50;

    > div:nth-of-type(3) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 16px;

      > button,
      > a {
        width: 100%;
        text-align: center;
      }

      > button {
        height: 50px;
      }

      > a {
        color: #58c78f;
        text-decoration: underline;
        margin-top: 8px;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;

    > svg:nth-of-type(1),
    > svg:nth-of-type(2) {
      display: none;
    }

    > div {
      width: 100%;

      &:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        max-width: unset;
        margin-left: 0;

        > div:nth-of-type(1) {
          flex: 1;
        }

        > div:nth-of-type(2) {
          flex: 1;
          margin-top: 0;
          margin-left: 24px;
        }

        > div:nth-of-type(3) {
          margin-top: 40px;
          > button {
            max-width: 500px;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;

    > div {
      width: 100%;

      &:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: row;
        max-width: unset;
        margin-left: 0;

        > div:nth-of-type(1) {
          flex: 1;
        }

        > div:nth-of-type(2) {
          flex: 1;
          margin-top: 24px;
          margin-left: 0;
          min-width: 100%;
        }

        > div:nth-of-type(3) {
          min-width: 100%;
          margin-top: 40px;
          > button {
            max-width: 500px;
          }
        }
      }
    }
  }
`;

export const CardTable = styled(Card)`
  flex: 1;

  > header > strong {
    &:nth-of-type(1) {
      flex: 1;
    }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 100%;
      max-width: 175px;
      text-align: center;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > strong {
      width: 100%;
      text-align: center;
      padding: 24px;
      font-size: 1.5rem;
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 24px;

      + div {
        border-top: 2px solid #685771;
      }

      > div {
        &:nth-of-type(1) {
          flex: 1;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;

          /* > div {
            width: 85px;
            height: 85px;
            background-color: #fff;
            border-radius: 50%;
          } */
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 175px;

          > strong {
            font-size: 1.25rem;
            margin-bottom: 6px;
          }

          > span {
            font-size: 0.75rem;

            &.crossedLine {
              font-size: 0.9rem;
              margin-bottom: 4px;
              text-decoration: line-through;
            }
          }
        }

        &:nth-of-type(3) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 175px;

          > div {
            width: 65px;
            height: 50px;
          }

          > button {
            background-color: transparent;
            border: 0;
            margin-top: 8px;
            text-decoration: underline;
            color: #c7e22b;
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    header > strong {
      display: none;
    }

    > main {
      > div {
        flex-direction: column;
        > div + div {
          margin-bottom: 16px;
        }

        ${ProductCont} {
          flex-direction: column !important;
          min-width: unset !important;
        }

        ${ProductDescription} {
          margin-bottom: 24px;
          margin-top: 8px;
          margin-left: 0;

          > div {
            flex-direction: column !important;
          }
        }
      }
    }
  }
`;

export const CardDelivery = styled(Card)`
  > main {
    > span {
      font-size: 0.9rem;

      animation: ${fadeIn} 0.25s ease 1;
      &.hidden {
        animation: ${fadeOutNone} 0.25s ease 1 forwards;
      }
    }

    > div {
      width: 100%;

      &:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > span {
          width: 100%;
          font-size: 0.7rem;
          margin-bottom: 8px;
          text-align: right;
          opacity: 1;
          transition: opacity 0.25s ease;

          animation: ${fadeIn} 0.25s ease 1;
          &.hidden {
            animation: ${fadeOutNone} 0.25s ease 1 forwards;
          }
        }
      }

      &:last-of-type {
        margin-top: 16px;
      }
    }
  }
`;

export const CardTotal = styled(Card)`
  margin-top: 12px;

  > main {
    > div:nth-of-type(1) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      width: 100%;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > span:nth-of-type(1) {
          font-size: 1.2rem;
        }

        > span:nth-of-type(2) {
          font-size: 0.7rem;
        }
      }
    }

    > div:nth-of-type(2) {
      margin-top: 16px;
    }
  }

  > footer {
    display: flex;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);
    padding: 16px;

    align-items: flex-start;
    justify-content: space-between;

    > strong,
    > div > strong {
      color: #00c763;
      font-weight: bold !important;
      animation: ${fadeIn} 0.25s ease forwards;
    }

    > strong {
      font-size: 1.1rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > strong {
        &:nth-of-type(1) {
          font-size: 1.2rem;
        }
        &:nth-of-type(2) {
          margin-top: 4px;
          font-size: 0.8rem;
        }
        &:nth-of-type(3) {
          color: #f9f9f9;
          margin-top: 4px;
          font-size: 0.9rem;
        }
      }
    }
  }
`;

export const DiscountDiv = styled.div<{ isShown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  opacity: 0;
  visibility: hidden;
  user-select: none;

  transition: opacity 0.25s ease;

  ${({ isShown }) =>
    isShown &&
    css`
      animation: ${fadeIn} forwards 0.25s ease 1;
      opacity: 1;
      visibility: visible;
    `}

  > span:nth-of-type(1) {
    font-size: 1rem;
  }

  > span:nth-of-type(2) {
    text-decoration: line-through;
    font-size: 1.2rem;
  }

  + div {
    margin-top: 8px;
  }
`;
