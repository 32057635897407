import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 40px;

  > h1 {
    font-size: 1.25rem;
    font-weight: bold;
    color: #f5f5f5;
    width: 100%;
    text-align: center;

    > b {
      color: #00c763;
    }
  }

  > strong {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    color: #f5f5f5;
    margin-top: 16px;

    > b {
      color: #00c763;
    }
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 50px 100px;

  @media (max-width: 1300px) {
    margin: 50px 40px;
  }

  @media (max-width: 1150px) {
    margin: 50px 32px;
  }

  @media (max-width: 1150px) {
    > div:nth-of-type(2) {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const ConfigContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  > input {
    min-width: 300px;
    max-width: 300px;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

interface ButtonContainer {
  mlAuto?: boolean;
}

export const ButtonContainer = styled.div<ButtonContainer>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ mlAuto }) => mlAuto && `margin-left: auto;`}

  > button {
    min-width: unset !important;

    + button {
      margin-left: 16px;
    }
  }

  @media (max-width: 1150px) {
    margin-top: 24px;
    justify-content: center;
    flex-wrap: wrap;

    margin-left: 0;

    > button {
      margin: 1rem;
    }
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  > button {
    margin: 8px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;

  margin-top: 24px;

  width: 100%;
`;

export const ColumnFilter = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;

  width: 100%;

  animation: ${fadeIn} 0.25s ease;

  > strong {
    color: #15cb6f;
    font-size: 1.2rem;
  }
`;

export const FilterSection = styled.section`
  flex: 1;
  max-width: 300px;

  @media (max-width: 1150px) {
    max-width: unset;
    width: 100%;
  }
`;

export const ProductSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;

  width: 100%;

  padding-left: 32px;

  > article > span {
    min-height: 315px;
    height: unset;
  }

  > div {
    display: grid;

    grid-template-columns: repeat(auto-fill, 250px);
    row-gap: 16px;
    column-gap: 16px;

    grid-auto-flow: row;
    justify-content: center;
    z-index: 50;

    animation: ${fadeIn} 0.25s ease forwards;
  }

  @media (max-width: 1150px) {
    margin-top: 50px;
    width: 100%;
    padding-left: 0;
  }
`;
