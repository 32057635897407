import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';
import { ProductDescription } from 'components/Cart/CartItem/styles';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  > strong {
    margin: 16px auto;
    font-size: 1.2rem;
  }

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;

    > button + button {
      margin-left: 16px;
    }
  }

  > button {
    margin: 24px auto 0 auto;
  }
`;

export const CardOrder = styled.article<{ orderStatus?: number }>`
  display: flex;
  flex-direction: column;
  z-index: 40;
  width: 100%;

  animation: ${fadeIn} 0.25s ease forwards;

  + article {
    margin-top: 16px;
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 2px solid #4f1e66;
    border-radius: 25px 25px 0px 0px;

    padding: 16px 24px;
    width: 100%;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > svg {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }

    > strong {
      color: #58c78f;
      font-size: 1rem;

      &:nth-of-type(2) {
        ${({ orderStatus }) =>
          orderStatus && orderStatus === 6 && 'color: #ec2626;'}
        margin-left: auto;
      }
    }

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 0;
      }

      > strong {
        margin-top: 8px;
        &:nth-of-type(2) {
          margin-left: 0;
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 24px 0;

    background-color: #68577180;
    backdrop-filter: blur(16px);
    border-radius: 0 0 25px 25px;
  }
`;

export const ProductTable = styled.section<{ showRateColumn?: boolean }>`
  border-bottom: 2px solid #685771;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  > header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #685771;
    padding: 4px 24px;

    > strong {
      color: #58c78f;

      &:nth-of-type(1) {
        text-align: left;
        flex: 1;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        text-align: center;
        min-width: 150px;
        max-width: 150px;
      }

      &:nth-of-type(5) {
        display: none;
      }

      ${({ showRateColumn }) =>
        showRateColumn &&
        css`
          &:nth-of-type(5) {
            display: unset;
            text-align: center;
            min-width: 150px;
            max-width: 150px;
          }
        `}
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin: 12px 0;
    padding: 0 24px;

    > div {
      display: flex;

      &:nth-of-type(1) {
        justify-content: flex-start;
        flex: 1;
      }

      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        justify-content: center;
        min-width: 150px;
        max-width: 150px;
      }

      &:nth-of-type(5) {
        display: none;

        > button {
          color: #f5f5f5;
          text-align: center;
          padding: 12px;
          width: 100%;
          font-size: 0.9rem;
          font-weight: bold;

          border-radius: 8px;
          margin-left: auto;
          background-color: #4f1e66;

          transition: filter 0.25s ease, background-color 0.25s ease;

          &.green {
            background-color: #00c763;
          }
          &:hover {
            filter: brightness(75%);
          }

          + button {
            margin-left: 16px;
          }
        }
      }

      ${({ showRateColumn }) =>
        showRateColumn &&
        css`
          &:nth-of-type(5) {
            display: flex;
            justify-content: center;
            min-width: 150px;
            max-width: 150px;
          }
        `}
    }
  }

  @media (max-width: 900px) {
    > header {
      > strong {
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          min-width: 100px;
          max-width: 100px;
        }

        ${({ showRateColumn }) =>
          showRateColumn &&
          css`
            &:nth-of-type(5) {
              min-width: 100px;
              max-width: 100px;
            }
          `}
      }
    }

    > div {
      padding: 0 8px;
      > div {
        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4) {
          min-width: 100px;
          max-width: 100px;
        }

        ${({ showRateColumn }) =>
          showRateColumn &&
          css`
            &:nth-of-type(5) {
              min-width: 100px;
              max-width: 100px;
            }
          `}

        ${ProductDescription} {
          margin-top: 8px;
          margin-left: 0;

          > div {
            flex-direction: column !important;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    > header {
      display: none;
    }

    > div {
      flex-direction: column;

      > div {
        ${ProductDescription} {
          margin-top: 8px;
          margin-left: 0;

          > div {
            flex-direction: column !important;
          }
        }

        ${({ showRateColumn }) =>
          showRateColumn &&
          css`
            &:nth-of-type(5) {
              min-width: 150px;
              max-width: 150px;
            }
          `}

        + div {
          margin-top: 32px;
        }
      }

      + div {
        margin-top: 32px;
        padding-top: 16px;
        border-top: 2px solid #685771;
      }
    }
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 24px 16px;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;

    > strong {
      color: #58c78f;
      margin-bottom: 8px;
    }

    > span {
      font-size: 1.1rem;
      color: #f5f5f5;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;

    > button {
      color: #f5f5f5;
      text-align: center;
      padding: 16px;
      min-width: 250px;
      font-weight: bold;
      background: #4f1e66;
      border-radius: 8px;
      margin-left: auto;

      transition: filter 0.25s ease;
      &:hover {
        filter: brightness(75%);
      }

      + button {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div:nth-of-type(1) {
      margin-bottom: 40px;
    }
    > div:nth-of-type(2) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0;

      > button {
        + button {
          margin-left: 0;
          margin-top: 24px;
        }
      }
    }
  }
`;

export const TrackContainer = styled.article`
  cursor: pointer;

  display: flex;
  margin-top: auto;
  margin-left: 24px;

  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(125%);
  }

  > svg {
    margin-right: 8px;

    width: 24px;
    height: 24px;

    color: #00c763;
  }
`;

export const TrackLink = styled.a`
  color: #00c763;
  text-decoration: underline;
`;
