import styled from 'styled-components';

export const Content = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 50px;
  min-width: 900px;
  min-height: 575px;

  > header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 24px 0;
    width: 100%;

    > strong {
      color: #f5f5f5;
      font-size: 2.25rem;
      text-align: center;
    }

    > span {
      color: #58c78f;
      text-align: center;
      font-weight: bold;
      margin-top: 16px;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    > div:nth-of-type(1) {
      display: flex;
      align-items: flex-start;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;

        > strong {
          color: #ffffff;
          font-size: 1.1rem;
          margin-bottom: 16px;
        }

        &:nth-of-type(1) {
          > div {
            justify-content: flex-start;
          }
        }

        &:nth-of-type(2) {
          margin-left: 40px;
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      > input {
        height: 40px;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px 8px;
        color: #261231;
        font-weight: bold !important;
        font-size: 0.9rem;
        width: 100%;

        &::placeholder {
          color: #261231;
          font-weight: bold;
        }
      }

      > strong {
        color: #ffffff;
        font-size: 1.1rem;
        margin-bottom: 16px;
        &:nth-of-type(2) {
          margin-top: 24px;
        }
      }

      > div {
        position: relative;
        > textarea {
          padding: 24px;
          width: 100%;
          height: 175px;
          font-weight: bold !important;

          resize: none;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }

        > span {
          position: absolute;
          font-size: 0.8rem;
          right: 16px;
          bottom: 12px;
          color: #fff;
        }
      }
    }
  }

  > button {
    min-width: 250px;
    margin: 16px auto 16px auto;
    min-height: 40px;
  }
`;
