import React, { useMemo } from 'react';
import { QRious } from 'react-qrious';
import { MdOutlineContentCopy } from 'react-icons/md';

import { ReactComponent as PixSVG } from 'assets/pix.svg';
import { ReactComponent as BBSVG } from 'assets/icons/bancos/BancoDoBrasil.svg';
import { ReactComponent as BradescoSVG } from 'assets/icons/bancos/Bradesco.svg';
import { ReactComponent as ItauSVG } from 'assets/icons/bancos/Itau.svg';

import { currencyBRL } from 'utils/format';
import { copyToClipboard } from 'utils/copyToClipboard';

import {
  Container,
  PaymentContainer,
  ContainerTED,
  ContainerPIX,
} from './styles';

export interface CreditCard {
  type: 'CC' | 'Cartão' | 'cartão' | 'CARTÃO';
  cardNumber?: string;
  count?: number;
  subTotal?: number;
  total?: number;
}

export interface Deposit {
  type: 'PIX' | 'pix' | 'TED/DOC' | 'TED' | 'DOC';
  qrcode?: string;
  total?: number;
}

interface PaymentProps {
  payment?: CreditCard | Deposit;
  total: number;
  showPaymentInfo?: boolean;
}

export const paymentTypes = {
  CC: ['CC', 'Cartão', 'cartão', 'CARTÃO'],
  PIX: ['PIX', 'pix'],
  TED: ['TED/DOC', 'TED', 'DOC'],
};
// <BBSVG />
const CNPJ = '47.617.448/0001-12';

const PaymentTED: React.FC = () => (
  <ContainerTED>
    <strong>Dados para depósito</strong>
    <div>
      <div>
        <img
          src="/static/qrcodepix.jpeg"
          width="320px"
          alt="qr code para pagamento pix"
        />
        <span>Banco Nubank - 260</span>
        <span>Passos Consultoria ADM</span>
        <span>CNPJ {CNPJ}</span>
        <span>Agência 0001</span>
        <span>Conta 84396796-1</span>
      </div>
    </div>
  </ContainerTED>
);

const PaymentPix: React.FC<{ qrCode?: string }> = ({ qrCode }) => (
  <ContainerPIX>
    <button
      type="button"
      onClick={() => {
        copyToClipboard(CNPJ);
      }}
    >
      <PixSVG />

      <span>
        <strong>CNPJ</strong>
        <span>{CNPJ}</span>
      </span>
    </button>

    {qrCode && (
      <div>
        <QRious value={qrCode} size={200} />

        <button type="button" onClick={() => copyToClipboard(qrCode)}>
          Copiar código PIX
          <MdOutlineContentCopy />
        </button>

        <p>
          Para pagar, selecione a opção&nbsp;
          <b>
            PIX {'>'} Pagar {'>'} QR Code
          </b>
          &nbsp;na página do seu banco.
        </p>
      </div>
    )}
  </ContainerPIX>
);

const OrderPayment: React.FC<PaymentProps> = ({
  payment,
  total,
  showPaymentInfo,
}) => {
  const renderPaymentJSX = useMemo((): JSX.Element => {
    let paymentJSX: JSX.Element = <></>;
    if (!payment) return paymentJSX;
    if (paymentTypes.CC.includes(payment.type)) {
      const thisPayment = payment as CreditCard;

      paymentJSX = (
        <PaymentContainer>
          <div>
            <span>Cartão de crédito</span>
            <span>
              {thisPayment.count &&
                thisPayment.subTotal &&
                `em ${thisPayment.count}x de ${currencyBRL(
                  thisPayment.subTotal,
                )}`}
            </span>
          </div>
          <div>
            <span>{thisPayment?.cardNumber && thisPayment.cardNumber}</span>
          </div>
        </PaymentContainer>
      );
    } else {
      const thisPayment = payment as Deposit;
      let infoEl = <></>;

      if (showPaymentInfo) {
        if (paymentTypes.PIX.includes(thisPayment.type)) {
          infoEl = <PaymentPix qrCode={thisPayment.qrcode} />;
        } else if (paymentTypes.TED.includes(thisPayment.type)) {
          infoEl = <PaymentTED />;
        }
      }

      paymentJSX = (
        <>
          <PaymentContainer>
            <div>
              <span>{thisPayment?.type}</span>
              <span>em 1x de {currencyBRL(total)}</span>
            </div>
          </PaymentContainer>

          {infoEl}
        </>
      );
    }
    return paymentJSX;
  }, [payment, total]);

  return (
    <Container>
      <div>
        <strong>Total</strong>
        <strong>{currencyBRL(total, true, true)}</strong>
      </div>

      {renderPaymentJSX}
    </Container>
  );
};

export default OrderPayment;
