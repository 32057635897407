import styled, { css, keyframes } from 'styled-components';

export const FilterTitle = styled.strong`
  color: #15cb6f;
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 16px;
`;

export const FakeCheckBox = styled.div<{ selected?: boolean }>`
  position: relative;
  width: 15px;
  height: 15px;

  margin-right: 8px;

  border: 1px solid #15cb6f;
  border-radius: 3px;

  background-color: transparent;

  transition: background-color 0.2s ease;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #15cb6f;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        font-size: 10px;
        content: '🗸';

        color: #fff;
      }
    `}
`;

interface isShownProp {
  isShown?: boolean;
}

export const MenuButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #00c763;
    padding: 4px 0;

    > button:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-items: flex-start;
      background: transparent;
      border: 0;

      color: #fff;
      font-size: 1.1rem;
      font-weight: bold !important;

      transition: filter 0.25s ease;

      > svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 4px;
        transition: transform 0.25s ease;
        .cls-1 {
          fill: #fff;
        }
      }

      &:hover {
        filter: brightness(75%);
      }
    }

    > button:nth-of-type(2) {
      background-color: transparent;
      border: 1px solid #00c763;
      border-radius: 6px;

      color: #00c763;
      padding: 4px 8px;
      font-size: 0.75rem;
      margin-left: auto;

      font-weight: bold !important;

      transition: all 0.25s ease;

      &:hover {
        background-color: #ffffff02;
        filter: brightness(75%);
      }
    }
  }
`;

interface ListProps {
  noBorder?: boolean;
}

export const List = styled.div<ListProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-height: 0;

  ${({ noBorder }) => noBorder && 'border: none;'}

  overflow-y: auto;
  overflow-x: hidden;

  transition: all 0.25s ease;
  // ----------- SCROLL STYLE -----------
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    width: 8px;
    border: 1px solid #00c763;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #00c763;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #00c76370;
  }
  // ----------- SCROLL STYLE -----------

  > button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;

    font-size: 1rem;
    font-weight: bold !important;
    text-transform: capitalize;
    color: #fff;

    transition: all 0.25s ease;
    &:hover {
      filter: brightness(75%);
    }

    + button {
      margin-top: 8px;
    }
  }
`;

export const ListIcon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;

  overflow: hidden;
  max-height: 0;
  opacity: 0;

  transition: all 0.25s ease;

  > div {
    cursor: pointer;
    margin: 8px;
  }
`;

export const DefListContainer = styled.div<isShownProp>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isShown }) =>
    isShown &&
    css`
      ${MenuButton} > div:nth-of-type(1) > button:nth-of-type(1) > svg {
        transform: rotate(90deg);
      }

      ${List} {
        max-height: 200px;
        border: 1px solid #00c763;
        border-top: 0;
        padding: 8px 6px;
      }

      ${ListIcon} {
        max-height: unset;
        overflow: unset;
        opacity: 1;

        margin-top: 8px;
      }
    `}
`;

const progressCountDown = keyframes`
  0% {
    max-width: 100%;
  }
  100% {
    max-width: 0;
  }
`;

export const ProgressBar = styled.article<{ isActive?: boolean }>`
  position: relative;
  width: 100%;
  height: 8px;

  margin: 8px 0;
  background-color: #00c763;
  opacity: 0;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      animation: ${progressCountDown} 2.5s linear 1 forwards;
    `}
`;
