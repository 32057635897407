import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { api, handleErrors, handleRespReturn } from 'services/api';

import GreenButton from '../Button/GreenButton';

import { Container, Error } from './styles';

export interface CupomState {
  code?: string;
  discount?: number;
  discountType?: 'percentage' | 'fixed';
  discountVal?: number;
}

interface ResponseValidate {
  discount?: number;
  discountType?: 'percentage' | 'fixed';
  error?: string;
}

export const validateCupom = async (
  cupom?: string,
): Promise<ResponseValidate> => {
  if (!cupom) return { error: 'Preencha o campo de cupom.' };
  const defErr = { error: 'Ocorreu um erro, tente novamente mais tarde.' };

  try {
    const resp = await api().request({
      url: `/cart/cupom?code=${cupom}`,
      method: 'GET',
    });
    const isErrored = handleRespReturn(resp);
    if (isErrored) {
      return resp.data.exceptionText
        ? { error: resp.data.exceptionText }
        : defErr;
    }

    return {
      discount: resp.data.content.discount,
      discountType: resp.data.content.type,
    };
  } catch (err) {
    handleErrors(err);
  }

  return defErr;
};

interface OnValidateReturn {
  discount: number;
  discountType: 'fixed' | 'percentage';
}

interface Props {
  onValidateCupom(result?: OnValidateReturn): void;
  onCupomChange?: (cupom?: string) => void;
  currentCupom?: string;
  // currentValue?: string | number;
}

const DiscountCupom: React.FC<Props> = ({
  onValidateCupom,
  currentCupom,
  onCupomChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [cupom, setCupom] = useState<string | undefined>(currentCupom);
  const [err, setErr] = useState<string | undefined>();

  // useEffect(() => {
  //   if (currentCupom) {
  //     setCupom(currentCupom);
  //   }
  // }, [currentCupom]);

  return (
    <Container>
      <input
        onChange={({ target }) => {
          if (target.value === currentCupom) return;
          // setCupom(target.value);
          setErr(undefined);
          if (onCupomChange) onCupomChange(target.value);
        }}
        type="text"
        value={currentCupom || ''}
        // value={cupom || ''}
      />
      <GreenButton
        isLoading={isLoading}
        text="Adicionar"
        onClick={async () => {
          setIsLoading(true);
          const result = await validateCupom(currentCupom);
          if (result?.error) {
            setIsLoading(false);
            setErr('Cupom inválido ou expirado.');
            onValidateCupom(undefined);
            return;
          }
          if (result?.discount && result?.discountType) {
            setIsLoading(false);
            setErr(undefined);
            onValidateCupom({
              discount: result.discount,
              discountType: result.discountType,
            });
          }
        }}
        sideRound
      />
      <Error isShown={!!err}>{err || ''}</Error>
    </Container>
  );
};

export default DiscountCupom;
