import styled, { css } from 'styled-components';

interface ContainerProps {
  styleType: 'small' | 'big';
}
export const Container = styled.div<ContainerProps>`
  position: relative;

  border-radius: 5px;
  background-color: #fff;

  width: 90px;
  height: 75px;

  &:focus {
    border-color: #261231;
  }

  > svg {
    cursor: pointer;
    position: absolute;
    color: #261231;
    width: 16px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);

    transition: filter 0.25s ease;
    &:hover {
      filter: brightness(85%);
    }
  }

  > svg:nth-of-type(1) {
    left: 4px;
  }

  > svg:nth-of-type(2) {
    right: 4px;
  }

  input {
    color: #261231;
    background-color: transparent;
    border: 0;

    padding: 8px 22px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }

  ${({ styleType }) =>
    styleType === 'small' &&
    css`
      width: 65px;
      height: 50px;

      > svg {
        width: 12px;
        height: 12px;
      }

      > input {
        font-size: 1.5rem;
        padding: 8px 16px;
      }
    `}
`;
