import React, { useState, useEffect } from 'react';

import { api, handleErrors } from 'services/api';

import { DefaultData as DefaultProduct } from 'components/Product/ProductDTO';

import FeedSection from 'components/FeedSection';
import ProductThumbSmall from 'components/Product/ThumbSmall';
import ProductThumb from 'components/Product/Thumb';
import ProductCarousel from 'components/Carousel/ProductCarousel';
import BannerCarousel, {
  SliderBanner,
} from 'components/Carousel/BannerCarousel';

import SkeletonContainer from 'components/Skeleton';
import SectionTitle from 'components/SectionTitle';

import { ReactComponent as RocketSVG } from 'assets/draws/espaconave.svg';
import { ReactComponent as AlienSVG } from 'assets/draws/alienOhm.svg';
import { ReactComponent as AlienShipSVG } from 'assets/draws/discovoador.svg';
import { ReactComponent as MeteoroSVG } from 'assets/backgrounds/meteoro.svg';

import {
  BannersSection,
  SectionBanner,
  SectionBestSeller,
  SectionMain,
  SectionNewProducts,
  BannerImg,
  Container,
} from './styles';

type ProductsArr = DefaultProduct[] | undefined;

interface Banners {
  id: number;
  link: string;
}

interface Props {
  loggedIn?: boolean;
}

const HomePage: React.FC<Props> = ({ loggedIn }) => {
  const [newProducts, setNewProducts] = useState<ProductsArr>();
  const [selectedForYou, setSelectedForYou] = useState<ProductsArr>();
  const [bestSellers, setBestSellers] = useState<ProductsArr>();

  const [slider, setSlider] = useState<Banners[] | undefined>();
  const [banner, setBanner] = useState<Banners[] | undefined>();

  useEffect(() => {
    let mounted = true;
    const getBanners = async (): Promise<void> => {
      try {
        const resp = await api().get('/generic/banner');
        if (mounted) {
          setBanner(resp.data.content);
        }
      } catch (err) {
        if (mounted) {
          handleErrors(err);
        }
      }
    };

    if (!banner && mounted) {
      getBanners();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    const getSlider = async (): Promise<void> => {
      try {
        const resp = await api().get('/generic/slider');
        if (mounted) {
          setSlider(resp.data.content);
        }
      } catch (err) {
        if (mounted) {
          handleErrors(err);
        }
      }
    };

    if (!slider && mounted) {
      getSlider();
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    const mainContent = document.querySelector('#main-content');

    const options = {
      root: mainContent,
      threshold: [0, 0.3],
    };

    const newProd = document.querySelector('#newTitle') as Element;
    const forYou = document.querySelector('#forYouTitle') as Element;
    const bestSeller = document.querySelector('#bestSellerTitle') as Element;

    const reqLoad = {
      newProducts: false,
      selectedForYou: !loggedIn,
      bestSellers: false,
    };

    const observer = new IntersectionObserver(
      async (entries, thisObserver): Promise<void> => {
        if (entries[0].isIntersecting) {
          const element = entries[0].target;

          if (element === newProd) {
            if (newProducts) return;
            if (!mounted || reqLoad.newProducts) return;
            reqLoad.newProducts = true;

            try {
              const resp = await api().get('/products/newProducts');
              if (mounted) {
                thisObserver.unobserve(newProd);
                setNewProducts(resp.data.content);
              }
            } catch (err) {
              if (mounted) {
                thisObserver.unobserve(newProd);
                reqLoad.newProducts = false;
                handleErrors(err);
              }
            }
          }

          if (element === forYou) {
            if (selectedForYou) return;
            if (!mounted || reqLoad.selectedForYou) return;
            reqLoad.selectedForYou = true;

            try {
              const resp = await api().get('/products/selectedForYou');
              if (mounted) {
                thisObserver.unobserve(forYou);
                setSelectedForYou(resp.data.content);
              }
            } catch (err) {
              if (mounted) {
                thisObserver.unobserve(forYou);
                reqLoad.selectedForYou = false;
                handleErrors(err);
              }
            }
          }

          if (element === bestSeller) {
            if (bestSellers) return;
            if (!mounted || reqLoad.bestSellers) return;
            reqLoad.bestSellers = true;

            try {
              const resp = await api().get('/products/bestSellers');
              if (mounted) {
                thisObserver.unobserve(bestSeller);
                setBestSellers(resp.data.content);
              }
            } catch (err) {
              if (mounted) {
                thisObserver.unobserve(bestSeller);
                reqLoad.bestSellers = false;
                handleErrors(err);
              }
            }
          }
        }
      },
      options,
    );

    observer.observe(newProd);
    observer.observe(forYou);
    observer.observe(bestSeller);

    if (newProducts && bestSellers && selectedForYou) {
      observer.unobserve(newProd);
      observer.unobserve(forYou);
      observer.unobserve(bestSeller);
    }

    return () => {
      mounted = false;
    };
  }, []);

  // Sanity Check
  // useEffect(() => {
  //   console.log('newProducts', newProducts);
  //   console.log('selectedForYou', selectedForYou);
  //   console.log('bestSellers', bestSellers);
  // }, [newProducts, selectedForYou, bestSellers]);

  return (
    <Container>
      {slider && slider.length > 0 ? (
        <BannerCarousel totalSlides={slider.length} maxHeight="633px">
          {slider.map((item) => (
            <SliderBanner key={item.id} img={item.link} />
          ))}
        </BannerCarousel>
      ) : (
        <SkeletonContainer maxHeight="70vh" total={1} />
      )}

      <SectionNewProducts>
        <SectionTitle
          id="newTitle"
          icon={<RocketSVG />}
          title="Novos produtos"
        />

        {newProducts && newProducts.length > 0 ? (
          <ProductCarousel totalItems={newProducts.length}>
            {newProducts.map((item) => (
              <ProductThumb
                key={item.id}
                {...item}
                // id={item.id}
                // name={item.name}
                // type={item.type}
                // data={item.data}
                // img={item.img}
              />
            ))}
          </ProductCarousel>
        ) : (
          <SkeletonContainer
            total={3}
            maxHeight="520px"
            totalAbove600={1}
            totalAbove800={2}
          />
        )}
      </SectionNewProducts>

      <SectionMain hidden={!loggedIn}>
        <SectionTitle
          icon={<AlienSVG />}
          title="Selecionados pra você"
          id="forYouTitle"
        />

        {selectedForYou && selectedForYou.length > 0 ? (
          <ProductCarousel
            totalItems={selectedForYou.length}
            productSizeType={2}
          >
            {selectedForYou.map((item) => (
              <ProductThumbSmall
                key={item.id}
                {...item}
                // id={item.id}
                // name={item.name}
                // type={item.type}
                // data={item.data}
                // img={item.img}
              />
              // <ProductThumbSmall
              //   key={item.id}
              //   id={item.id}
              //   name={item.name}
              //   type={item.type}
              //   data={item.data}
              // />
            ))}
          </ProductCarousel>
        ) : (
          <SkeletonContainer total={4} maxHeight="350px" totalAbove600={1} />
        )}
      </SectionMain>

      <FeedSection htmlId="Feed" />

      <SectionBestSeller>
        <MeteoroSVG />
        <SectionTitle
          icon={<AlienShipSVG />}
          title="Os mais vendidos"
          id="bestSellerTitle"
        />
        {bestSellers && bestSellers.length > 0 ? (
          <div>
            {bestSellers.map((item) => (
              <ProductThumbSmall
                key={item.id}
                {...item}
                // id={item.id}
                // name={item.name}
                // type={item.type}
                // data={item.data}
                // img={item.img}
              />
            ))}
          </div>
        ) : (
          <>
            <SkeletonContainer total={5} maxHeight="350px" />
            <SkeletonContainer total={5} maxHeight="350px" />
          </>
        )}
      </SectionBestSeller>

      {banner && banner.length > 0 && (
        <BannersSection>
          <BannerImg src={banner[0].link} />
          <div>
            <BannerImg src={banner[1].link} />
            <BannerImg src={banner[2].link} />
          </div>
        </BannersSection>
      )}
    </Container>
  );
};

export default HomePage;
