import styled, { keyframes } from 'styled-components';

export const shakeButton = keyframes`
  0% {   transform: rotate(0); }
  10% {   transform: rotate(10deg); }
  20% {  transform: rotate(-10deg); }
  30% {  transform: rotate(10deg); }
  40% {  transform: rotate(-10deg); }
  50% {  transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

export const Container = styled.div`
  pointer-events: none;
  position: fixed;
  /* right: 50%; */
  right: 42px;
  bottom: 24px;
  z-index: 100;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 225px;
  height: 90px;

  > div {
    pointer-events: none;
    position: absolute;
    pointer-events: none;
    user-select: none;

    left: 0;
    bottom: calc(50% - 35px);

    height: 100%;
    max-height: 70px;
    max-width: 200px;
    width: 100%;
    border-radius: 12px 0px 0px 12px;
    background-color: rgba(0, 0, 0, 0.5);
    filter: blur(2px);
    opacity: 0;

    transition: all 0.25s ease;
    z-index: -1;
  }

  > span {
    pointer-events: none;
    position: relative;
    pointer-events: none;
    user-select: none;

    max-width: 175px;
    width: 100%;

    font-weight: bold;
    font-size: 1.3rem;
    padding: 8px;

    color: #1bd741;

    opacity: 0;
    visibility: hidden;

    transition: visible 0.25s ease, opacity 0.25s ease;
    z-index: 55;
  }

  > svg {
    pointer-events: all;
    cursor: pointer;
    order: 2;
    width: 50px;
    height: 50px;

    transition: width 0.25s ease, height 0.25s ease;
    animation: ${shakeButton} 3s ease infinite;
    z-index: 60;

    &:hover {
      ~ div {
        opacity: 1;
      }

      /* > svg { */
      width: 120px;
      height: 90px;
      /* } */

      ~ span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;
