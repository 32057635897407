import styled from 'styled-components';

interface ContainerProps {
  margin?: string;
}
export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 200;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 50px 0 32px 0;
  padding: 50px 0;
  width: 100%;
  ${({ margin }) => margin && `margin: ${margin};`}

  > h1 {
    font-size: 2.25rem;
    color: #f5f5f5;
    font-weight: bold;
    z-index: 20;
  }

  > svg {
    position: absolute;
    fill: #461c58;
    z-index: 10;

    .highlight {
      transition: fill 0.25s ease, stroke 0.25s ease;
    }
  }

  &:hover {
    > svg {
      .highlight {
        fill: #c7e22b !important;
      }
    }
  }
`;
