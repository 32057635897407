import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

// Price Color: #00C763;
// #461C58
// #707070

export const CopyParent = styled.div`
  background-color: transparent;
  width: 400px;
  height: 535px;
  z-index: 0;
`;

export const Container = styled.div`
  cursor: pointer;
  position: relative;
  height: 535px;
  width: 400px;

  > svg {
    position: absolute;
    right: 12px;
    top: 12px;

    cursor: pointer;

    width: 32px;
    height: 32px;
    z-index: 40;

    .heart {
      transition: fill 0.25s ease;
    }

    &:hover {
      .heart {
        fill: #461c58 !important;
      }
    }
  }

  @media (max-width: 600px) {
    height: 425px;
    width: 275px;
  }
`;

export const ThumbContainer = styled.div<{ outOfStock?: boolean }>`
  cursor: pointer;

  position: relative;
  background-color: #fff;
  border: 1px solid #461c58;

  ${({ outOfStock }) =>
    outOfStock &&
    css`
      filter: brightness(75%);
    `}
  /* overflow: hidden; */
  width: 400px;
  height: 480px;

  transition: all 0.25s ease;
  z-index: 30;

  &:hover {
    height: 535px;
    box-shadow: 0px 3px 6px #9aff00fc;
  }

  @media (max-width: 600px) {
    width: 275px;
    height: 425px;
    box-shadow: 0px 3px 6px #9aff00fc;
    &:hover {
      width: 275px;
      height: 425px;
      box-shadow: 0px 3px 6px #9aff00fc;
    }
  }
`;

interface ImageContainerProps {
  img?: string;
  contain?: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  border-bottom: 1px solid #461c58;

  height: 350px;
  width: 100%;

  /* width: 100%;
  background-image: url('${({ img }) => img}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${({ contain }) => contain && `background-size: contain;`}
  transition: background-size 1s ease; */

  > figure {
    overflow: hidden;
    max-height: 350px;
    max-width: 100%;

    > img {
      position: relative;
      z-index: -1;
      width: 100%;
      height: 350px;

      object-fit: contain;
      object-position: center;
      display: block; /*to make it behave as div and avoir whitespace issue*/
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    position: absolute;
    top: 8px;
    left: 8px;

    > div + div {
      margin-top: 12px;
    }
  }

  > article {
    position: relative;
    border-radius: 8px;
    bottom: 8px;
    left: calc(100% - 8px);
    width: 125px;
    text-align: center;

    transform: translate(-100%, -100%);

    font-weight: bold !important;
    color: #fff;
    background-color: #f04630;
    padding: 4px;
    font-size: 1.1rem;

    &.warn {
      background-color: #f47b38;
    }
  }

  @media (max-width: 600px) {
    height: 275px;

    > figure {
      max-height: 275px;

      > img {
        height: 275px;
      }
    }
  }
`;

export const Title = styled.strong`
  color: #461c58;
  text-align: left;
  font-size: 1.25rem;
`;

export const ProductCost = styled.strong`
  color: #00c763;
  font-size: 1.9rem;
  transition: font-size 0.25s ease;

  ${ThumbContainer}:hover & {
    font-size: 2.1rem;
  }
`;

export const DescContainer = styled.div`
  display: flex;

  padding: 12px 16px;

  /* max-height: 125px; */
  /* min-height: 125px; */
  transition: max-height 0.25s ease;

  /* ${Container}:hover & {
    max-height: 180px;
  } */
`;

export const JuiceDescContainer = styled(DescContainer)`
  align-items: flex-start;
  flex-direction: column;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      margin-top: 8px;

      > div {
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;
        ${ThumbContainer}:hover & {
          visibility: visible;
          opacity: 1;
        }

        + div {
          margin-left: 8px;
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      > * {
        text-align: right;
      }

      > span:nth-of-type(1) {
        color: #707070;
        font-size: 1.1rem;
      }

      > span:nth-of-type(2) {
        color: #707070;
        font-size: 1rem;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;
      }

      > strong:nth-of-type(2) {
        color: #461c58;
        font-size: 1.4rem;
        margin-top: 10px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;
      }

      ${ThumbContainer}:hover & {
        > strong:nth-of-type(2),
        > span:nth-of-type(2) {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
`;

export const VapeDescContainer = styled(DescContainer)`
  flex-direction: column;

  > div:nth-of-type(1) {
    width: 100%;
    margin-top: 8px;

    > strong {
      font-size: 1.25rem;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;

    /* margin-top: 8px; */

    > span {
      color: #707070;
      text-decoration: line-through;
      font-size: 1.35rem;
    }

    > strong:nth-of-type(2) {
      color: #461c58;
      font-size: 1.5rem;

      visibility: hidden;
      opacity: 0;

      transition: all 0.25s ease;

      > span {
        color: #707070;
        font-size: 1.25rem;
        font-weight: normal;
      }
    }
  }

  ${ThumbContainer}:hover & {
    > div:nth-of-type(2) {
      > strong:nth-of-type(2) {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`;
