import React from 'react';

import { NavLink } from 'react-router-dom';

import { Container } from './styles';

type subItem = {
  id: number;
  name: string;
  path: string;
};

interface DropdownItemProps {
  bgImage: string;
  title: string;
  titlePath?: string;
  subItems?: subItem[];
  closeMenu: () => void;
  maxHeight?: string;
  extraMargin?: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  bgImage,
  title,
  titlePath,
  subItems,
  closeMenu,
  maxHeight,
  extraMargin,
}) => {
  return (
    <Container maxHeight={maxHeight} extraMargin={extraMargin}>
      <img src={bgImage} alt={title} />
      <div>
        <NavLink
          to={titlePath || '/filter'}
          onClick={() => {
            closeMenu();
          }}
        >
          {title}
        </NavLink>
        <div>
          {subItems &&
            subItems.length > 0 &&
            subItems.map((item) => (
              <NavLink
                key={item.id || item.name}
                to={titlePath ? `${titlePath}&subType=[${item.id}]` : '/filter'}
                onClick={() => {
                  closeMenu();
                }}
              >
                {item.name}
              </NavLink>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default DropdownItem;
