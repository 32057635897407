import React, { useState, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { ReactComponent as LoaderSVG } from 'assets/loaders/puff.svg';
import { ReactComponent as SearchSVG } from 'assets/icons/busca.svg';
import { api, handleErrors, handleRespReturn } from 'services/api';
import { currencyBRL } from 'utils/format';

import { Container, ListContainer, SearchItem } from './styles';

interface SearchItemInfo {
  id: number;
  price: number;
  priceDiscount?: number;
  type: 'juice' | 'vape';
}

interface SearchItems {
  id: number;
  type: 'product';
  name: string;
  image: string;
  info: SearchItemInfo;
}

const SearchInput: React.FC = () => {
  const history = useHistory();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string | undefined>();

  const [products, setProducts] = useState<SearchItems[] | undefined>();

  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [lastGet, setLastGet] = useState<string | undefined>();

  const handleSearch = async (s: string): Promise<void> => {
    if (isLoading) return;
    setIsLoading(true);
    setLastGet(s);

    // console.log('Search', search);
    try {
      const resp = await api().request({
        url: `/products/search?s=${s}`,
        method: 'GET',
      });
      const isErrored = handleRespReturn(resp);
      setIsLoading(false);
      if (isErrored) return;
      setIsShown(true);
      setProducts(resp.data.content);
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
    }
  };

  const handleProductClick = (product: SearchItems) => {
    history.push(`/product/${product.info.id}`);
    setSearch(undefined);
    setIsShown(false);
  };

  const listProduct = React.useMemo(() => {
    if (isLoading) {
      return <LoaderSVG />;
    }

    if (!products || products.length === 0) {
      return <strong>Nenhum produto encontrado</strong>;
    }
    const filteredProducts = products.filter((item) => {
      // console.log(lastGet, search);
      if (lastGet && search) {
        // console.log(item.name, search);
        return item.name.toLowerCase().includes(search.toLowerCase());
      }
      return true;
    });

    if (filteredProducts.length === 0) {
      return <strong>Nenhum produto encontrado</strong>;
    }

    return (
      <>
        {filteredProducts.map((item) => (
          <SearchItem
            type="button"
            key={item.id}
            onClick={() => handleProductClick(item)}
          >
            <figure>
              <img src={item.image} alt="Produto" />
              <figcaption>Produto</figcaption>
            </figure>
            <div>
              <strong>{item.name}</strong>
              <strong>
                {item.info.type === 'juice' && 'a partir de '}
                {currencyBRL(
                  item.info?.priceDiscount || item.info.price,
                  true,
                  true,
                )}
              </strong>
            </div>
          </SearchItem>
        ))}
      </>
    );
  }, [search, lastGet, products]);

  useEffect(() => {
    const externalEventHandler = (e: any): void => {
      if (!isShown) return;

      const node = dropdownRef.current;

      if (node && node.contains(e.target)) {
        return;
      }

      setIsShown(false);
      // setSearchProps({ ...searchProps, isShown: false });
    };

    if (isShown) {
      document.addEventListener('click', externalEventHandler);
    } else {
      document.removeEventListener('click', externalEventHandler);
    }

    return () => {
      document.removeEventListener('click', externalEventHandler);
    };
  }, [isShown]);

  return (
    <Container
      ref={dropdownRef}
      onFocus={() => {
        // if (
        //   searchProps?.items &&
        //   searchProps.items.length > 0 &&
        //   searchProps.isShown === false
        // ) {
        setIsShown(true);
        // setSearchProps({ ...searchProps, isShown: true });
        // }
      }}
    >
      <div>
        <input
          type="text"
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              // handleSearch();
            }
          }}
          onChange={({ target }) => {
            setSearch(target.value);
            if (target.value && target.value.length === 3) {
              if (isLoading) return;
              const valSubStr = target.value.substr(0, 3).toLowerCase();
              if (valSubStr !== lastGet) {
                handleSearch(valSubStr);
              }
            }
            // if (
            //   target.value.length === 3 &&
            //   searchProps.lastGet !== target.value.substr(0, 3)
            // ) {
            //   setSearchProps({
            //     ...searchProps,
            //     lastGet: target.value.substr(0, 3),
            //     isLoading: true,
            //   });
            // }
          }}
          value={search || ''}
          placeholder="Busque pelo nome do produto..."
        />
        <SearchSVG
          onClick={() => {
            // if (search && search.length >= 3) {
            //   handleSearch(search.substr(0,3));
            // }
          }}
        />
      </div>

      <ListContainer isShown={isShown}>{listProduct}</ListContainer>
    </Container>
  );
};

export default SearchInput;
