import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 250px;

    > article {
      align-items: center;
    }

    > strong {
      font-size: 1.75rem;
      color: #f5f5f5;
    }

    svg {
      margin-top: 12px;
      width: 100% !important;
      height: 40px;
    }

    > span {
      color: #f5f5f5;
      font-size: 1.25rem;
      margin-top: 12px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;

    > div {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;

export const RateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > svg {
      width: 100px;
      height: 30px;
    }

    > span {
      color: #f5f5f5;
      font-size: 0.8rem;
    }
  }

  > strong {
    font-size: 1.25rem;
    color: #f5f5f5;
  }

  > p {
    /* flex: 1; */
    font-size: 1rem;
    line-height: 1.25;
  }

  > strong:nth-of-type(1) {
    margin-bottom: 16px;
    font-size: 1.5rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 16px;

    > span {
      font-size: 1rem;
      margin-bottom: 4px;
    }

    > strong {
      font-size: 1.45rem;
      text-align: right;
      color: #f5f5f5;
    }
  }
`;
