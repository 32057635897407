import React, { useState } from 'react';

import { NavLink, useHistory } from 'react-router-dom';
import { api, handleErrors, handleRespReturn } from 'services/api';

import { swalFire } from 'services/swal';
import RadioButton from 'components/Button/RadioButton';
import GoogleButton from 'components/Button/GoogleButton';
import InputContainer from 'components/Input/Container';
import InputGeneric from 'components/Input/Generic';
import CpfCnpj from 'components/Input/MaskedInputs/CpfCnpj';
import Cep from 'components/Input/MaskedInputs/Cep';
import Wpp from 'components/Input/MaskedInputs/Wpp';
import SectionTitle from 'components/SectionTitle';
import GreenButton from 'components/Button/GreenButton';

import { parseInputMask } from 'utils/format';

import { ReactComponent as RocketSVG } from 'assets/draws/espaconave.svg';

import { Container, Row } from './styles';

interface Props {
  email?: string;
}

interface Address {
  cep?: string;
  street?: string;
  number?: string;
  info?: string;
  district?: string;
  city?: string;
  state?: string;
}

interface Form {
  email?: string;
  type: 1 | 2;
  udoc?: string;
  cnpj?: string;
  razao?: string;
  name?: string;
  birthDate?: string;
  password?: string;
  passwordConfirm?: string;
  address?: Address;
  uphone?: string;
}

const Register: React.FC<Props> = ({ email }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState<Form>({
    email,
    type: 1,
  });

  const handleRegister = async (): Promise<void> => {
    if (isLoading) return;
    setIsLoading(true);

    if (
      !form.address ||
      !form.address.district ||
      !form.address.cep ||
      !form.address.city ||
      !form.address.street ||
      !form.address.number ||
      !form.address.state
    ) {
      swalFire({
        text: 'Campos de endereço de cobrança são obrigatórios.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.password || !form.passwordConfirm) {
      swalFire({
        text: 'Preencha os campos de senha.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (form.password !== form.passwordConfirm) {
      swalFire({
        text: 'As senhas não coincidem.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.birthDate) {
      swalFire({
        text: 'Preencha a data de nascimento.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.email) {
      swalFire({
        text: 'Preencha o email.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.name) {
      swalFire({
        text: 'Preencha o seu nome completo.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.udoc) {
      swalFire({
        text: 'Preencha o seu CPF/CNPJ.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (!form.uphone) {
      swalFire({
        text: 'Preencha o seu telefone de contato.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    if (form.type === 2 && form.udoc.length !== 14) {
      if (form.udoc.length !== 14) {
        swalFire({
          text: 'Preencha o CNPJ corretamente.',
          icon: 'warning',
        });
        setIsLoading(false);
        return;
      }
      if (!form.razao) {
        swalFire({
          text: 'Preencha o campo de razão social.',
          icon: 'warning',
        });
        setIsLoading(false);
        return;
      }
    } else if (form.udoc.length !== 11) {
      swalFire({
        text: 'Preencha o CPF corretamente.',
        icon: 'warning',
      });
      setIsLoading(false);
      return;
    }

    try {
      const resp = await api().request({
        url: '/user/register',
        method: 'POST',
        data: form,
      });
      const isErrored = handleRespReturn(resp);
      setIsLoading(false);
      if (isErrored) return;
      swalFire({
        text: 'Cadastro criado.',
        // text: 'Confirme seu e-mail e faça login.',
        icon: 'success',
      });

      history.push('/user');
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
    }
  };

  return (
    <Container>
      <SectionTitle icon={<RocketSVG />} title="Criar uma conta" margin="0" />

      <div>
        <strong>Dados pessoais</strong>

        <InputContainer title="E-mail" direction="row">
          <InputGeneric
            type="email"
            value={form.email}
            onChangeCustom={({ target }) => {
              setForm({ ...form, email: target.value });
            }}
          />
        </InputContainer>

        <Row>
          <RadioButton
            checked={form.type === 1}
            onCheckChange={() => {
              setForm({ ...form, type: 1 });
            }}
          >
            Pessoa Física
          </RadioButton>
          <RadioButton
            checked={form.type === 2}
            onCheckChange={() => {
              setForm({ ...form, type: 2 });
            }}
          >
            Pessoa Jurídica
          </RadioButton>
        </Row>

        <InputContainer title="CPF" direction="row" styleInput>
          <CpfCnpj
            onChangeInput={(value) => {
              setForm({ ...form, udoc: value });
            }}
            currentValue={form.udoc}
            onlyType={1}
          />
        </InputContainer>

        <InputContainer title="Nome Completo" direction="row">
          <InputGeneric
            type="text"
            value={form.name}
            onChangeCustom={({ target }) => {
              setForm({ ...form, name: target.value });
            }}
          />
        </InputContainer>

        <InputContainer
          title="CNPJ"
          direction="row"
          styleInput
          hidden={form.type === 1}
        >
          <CpfCnpj
            onChangeInput={(value) => {
              setForm({ ...form, cnpj: value });
            }}
            currentValue={form.cnpj}
            onlyType={2}
          />
        </InputContainer>

        <InputContainer
          title="Razão Social"
          direction="row"
          hidden={form.type === 1}
        >
          <InputGeneric
            type="text"
            value={form.razao}
            onChangeCustom={({ target }) => {
              setForm({ ...form, razao: target.value });
            }}
          />
        </InputContainer>

        <InputContainer title="Data de nascimento" direction="row">
          <InputGeneric
            type="text"
            value={form.birthDate}
            onChangeCustom={({ target }) => {
              setForm({ ...form, birthDate: parseInputMask(target.value) });
            }}
          />
        </InputContainer>

        <InputContainer title="Senha" direction="row">
          <InputGeneric
            type="password"
            value={form.password}
            onChangeCustom={({ target }) => {
              setForm({ ...form, password: target.value });
            }}
          />
        </InputContainer>

        <InputContainer title="Confirme a senha" direction="row">
          <InputGeneric
            type="password"
            value={form.passwordConfirm}
            onChangeCustom={({ target }) => {
              setForm({ ...form, passwordConfirm: target.value });
            }}
          />
        </InputContainer>

        <strong>Endereço de cobrança</strong>

        <InputContainer title="CEP" direction="row" styleInput>
          <Cep
            onSearchDone={(cepResponse) => {
              setForm({
                ...form,
                address: {
                  cep: cepResponse.cep,
                  street: cepResponse.logradouro,
                  district: cepResponse.bairro,
                  city: cepResponse.localidade,
                  state: cepResponse.uf,
                },
              });
            }}
            onChangeInput={(value) => {
              setForm({ ...form, address: { ...form?.address, cep: value } });
            }}
            currentValue={form?.address?.cep}
          />
        </InputContainer>

        <InputContainer title="Endereço" direction="row">
          <InputGeneric
            type="text"
            value={form?.address?.street}
            onChangeCustom={({ target }) => {
              setForm({
                ...form,
                address: { ...form?.address, street: target.value },
              });
            }}
          />
        </InputContainer>

        <InputContainer title="Número" direction="row">
          <InputGeneric
            type="text"
            value={form?.address?.number}
            onChangeCustom={({ target }) => {
              setForm({
                ...form,
                address: {
                  ...form?.address,
                  number: `${target.value}`.replace(/[^\d]+/g, ''),
                },
              });
            }}
          />
        </InputContainer>

        <InputContainer title="Complemento" direction="row">
          <InputGeneric
            type="text"
            value={form?.address?.info}
            onChangeCustom={({ target }) => {
              setForm({
                ...form,
                address: { ...form?.address, info: target.value },
              });
            }}
          />
        </InputContainer>

        <InputContainer title="Bairro" direction="row">
          <InputGeneric
            type="text"
            value={form?.address?.district}
            onChangeCustom={({ target }) => {
              setForm({
                ...form,
                address: { ...form?.address, district: target.value },
              });
            }}
          />
        </InputContainer>

        <InputContainer title="Cidade" direction="row">
          <InputGeneric
            type="text"
            value={form?.address?.city}
            onChangeCustom={({ target }) => {
              // setForm({
              //   ...form,
              //   address: { ...form?.address, city: target.value },
              // });
            }}
          />
        </InputContainer>

        <InputContainer title="Estado" direction="row">
          <InputGeneric
            type="text"
            maxLength={2}
            value={form?.address?.state}
            onChangeCustom={({ target }) => {
              // setForm({
              //   ...form,
              //   address: { ...form?.address, state: target.value },
              // });
            }}
          />
        </InputContainer>

        <InputContainer title="Celular" direction="row" styleInput>
          <Wpp
            onChangeInput={(value) => {
              setForm({ ...form, uphone: value });
            }}
            currentValue={form.uphone}
          />
        </InputContainer>

        <span>
          Ao continuar, declaro que estou ciente e aceito os&nbsp;
          <NavLink to="/faq/termos-e-condicoes">termos de uso</NavLink>&nbsp;e
          a&nbsp;
          <NavLink to="/faq/politica-de-privacidade">
            Política de Privacidade
          </NavLink>
          .
        </span>

        <GreenButton
          isLoading={isLoading}
          text="Continuar"
          onClick={handleRegister}
        />
      </div>
    </Container>
  );
};

export default Register;
