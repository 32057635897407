import styled, { css } from 'styled-components';
import { ContainerBlock } from 'styles/default';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Strong = styled.strong`
  cursor: pointer;
`;

export const Container = styled(ContainerBlock)`
  align-items: unset;
  width: 100%;
  margin: 40px;

  > h1 {
    font-size: 2rem;
    color: #fff;
    max-width: 300px;
  }

  > a {
    max-width: 300px;
    color: #58c78f;
    font-size: 0.9rem;
    margin-top: 16px;

    text-decoration: underline;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 40px;

  > svg:nth-of-type(1) {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-20%) translateY(-20%);
    z-index: -1;
  }

  > svg:nth-of-type(2) {
    position: absolute;
    right: 0;
    top: 5%;
    z-index: -1;
  }

  > div {
    width: 100%;
    &:nth-of-type(1),
    &:nth-of-type(3) {
      flex: 1;
    }

    &:nth-of-type(2) {
      flex: 1;
    }

    + div {
      margin-left: 16px;
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;
    > div {
      max-width: unset;
      &:nth-of-type(1) {
        > main {
          max-height: 300px;
          overflow-y: auto;
        }
      }

      + div {
        margin-left: 0;
        margin-top: 32px;
      }
    }
  }

  @media (max-width: 800px) {
    > svg:nth-of-type(1),
    > svg:nth-of-type(2) {
      display: none;
    }
  }
`;

export const CardWrap = styled.div`
  max-width: 450px;
  position: relative;
  z-index: 50;

  display: flex;
  flex-direction: column;

  > button {
    margin-top: 24px;
  }

  > p {
    margin-top: 24px;
    text-align: center;
    color: #73448a;
    font-size: 0.9rem;
    line-height: 1.25;

    > a {
      text-decoration: underline;
      transition: filter 0.25s ease;
      &:hover {
        filter: brightness(125%);
      }
    }
  }

  > div {
    flex: 1;
  }
`;

const Card = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  z-index: 50;

  > * > * {
    width: 100%;
  }

  > *:first-child {
    border-radius: 25px 25px 0px 0px;
  }

  > *:nth-child(2) {
    border-radius: 0;
  }

  > *:last-child {
    border-radius: 0 0 25px 25px;
  }

  > header {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #4f1e66;
    padding: 16px 24px;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > svg {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }

    > strong {
      color: #58c78f;
      font-size: 1rem;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px 24px;

    background-color: #68577180;
    backdrop-filter: blur(16px);
  }

  > footer {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);
  }
`;

export const CardDelivery = styled(Card)`
  max-width: 450px;

  > main {
    > article {
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > strong {
        margin-right: 8px;
        color: #f5f5f5;
        font-weight: bold !important;
      }
    }
  }
`;

export const Address = styled.div`
  border-top: 1px solid #685771;
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  animation: ${fadeIn} forwards 0.25s ease;
  + div {
    margin-top: 16px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    > strong {
      color: #f5f5f5;
      margin-left: 0 24px;
      text-align: left;
    }

    > button {
      margin-left: auto;
      background-color: transparent;
      border: none;

      font-size: 0.9rem;
      text-decoration: underline;
      color: #58c78f;

      transition: filter 0.25s ease;
      &:hover {
        filter: brightness(75%);
      }
    }
  }

  > span {
    color: #f5f5f5;
    text-align: left;
    + span {
      margin-top: 8px;
    }
  }
`;

export const CardResume = styled(Card)`
  > main {
    display: flex;
    flex-direction: column;

    > article {
      position: relative;
      width: 100%;

      > strong {
        margin-bottom: 8px;
        font-size: 1rem;
        font-weight: bold !important;
      }

      > span {
        position: absolute;
        font-size: 0.8rem;
        right: 16px;
        bottom: 12px;
        color: #fff;
        font-weight: bold !important;
      }

      > textarea {
        color: #f5f5f5;
        background-color: #4f1e66;
        border: 1px solid #4f1e66;
        border-radius: 12px;
        font-weight: bold !important;

        padding: 16px 22px;
        margin-top: 16px;

        resize: none;

        height: 110px;
        width: 100%;
      }
    }

    > div {
      margin-top: 16px;
    }

    > div:nth-of-type(1),
    > div:nth-of-type(2),
    > div:nth-of-type(4) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      > strong {
        font-size: 1rem;
        margin-right: 16px;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > strong {
          font-size: 1.1rem;
        }

        > span {
          margin-top: 2px;
          font-size: 0.75rem;
        }
      }
    }
  }
`;

export const CardPayment = styled(Card)`
  flex: 1;

  > main {
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;

        > div:nth-of-type(1) {
          padding: 12px 0;
          border-bottom: 1px solid #685771;

          > label {
            width: 18px;
            height: 18px;
          }

          > span {
            display: flex;
            align-items: center;
            font-weight: bold;

            > svg {
              width: 32px;
              height: 32px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
`;

const PaymentContainer = styled.div<{ isShown: boolean }>`
  position: absolute;
  opacity: 0;
  visibility: hidden;

  overflow: hidden;
  max-width: 0;

  pointer-events: none;
  user-select: none;

  animation: ${fadeOut} forwards 0.25s ease 1;

  ${({ isShown }) =>
    isShown &&
    css`
      max-width: unset;
      position: unset;
      opacity: 1;
      visibility: visible;

      pointer-events: unset;
      user-select: unset;
      animation: ${fadeIn} forwards 0.25s ease 1;
    `}
`;

export const CreditCard = styled.article<{ backShow?: boolean }>`
  margin: 16px 0;
  position: relative;

  min-width: 330px;
  min-height: 190px;
  max-width: 330px;
  max-height: 190px;
  perspective: 600px;

  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    > div:nth-of-type(1) {
      transform: rotateY(180deg);
    }
    > div:nth-of-type(2) {
      transform: rotateY(0deg);
    }
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 330px;
    height: 190px;
    box-shadow: 0px 2px 20px rgb(0, 0, 0, 0.9);

    display: inline-block;
    z-index: 200;
    background-image: linear-gradient(
      to right,
      #b721ff,
      #c321ff,
      #d121ff,
      #de21ff,
      #e621ff
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    position: absolute;
    left: 0;
    top: 0;

    padding: 18px;

    transform: rotateY(0);

    > span {
      color: #ffffff;
      &:nth-of-type(1) {
        font-size: 0.9rem;
      }

      &:nth-of-type(2) {
        font-size: 1.5rem;
        font-weight: bold;
        width: 100%;
        margin-top: 24px;
      }

      &:nth-of-type(3) {
        margin-top: 12px;
        font-size: 1rem;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
      width: 100%;

      > span {
        color: #ffffff;
        font-size: 1.2rem;
      }
    }
  }

  > div:nth-of-type(2) {
    position: absolute;
    left: 0;
    top: 0;
    padding: 18px 0;
    transform: rotateY(-180deg);

    > div:nth-of-type(1) {
      margin-top: 5px;
      height: 40px;
      background-color: #303030;
    }

    > div:nth-of-type(2) {
      padding: 12px 16px;

      > div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        margin-top: 16px;
        position: relative;
        padding: 5px 12px;
        background-color: #fff;

        &:before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          height: calc(100% + 6px);
          width: calc(100% - 42px);
          border-radius: 4px;
          background: repeating-linear-gradient(
            45deg,
            #ededed,
            #ededed 5px,
            #f9f9f9 5px,
            #f9f9f9 10px
          );
        }

        > span {
          color: #303030;
        }
      }
    }
  }

  ${({ backShow }) =>
    backShow === true &&
    css`
      > div:nth-of-type(1) {
        transform: rotateY(180deg);
      }
      > div:nth-of-type(2) {
        transform: rotateY(0deg);
      }

      &:hover {
        > div:nth-of-type(1) {
          transform: rotateY(0deg);
        }
        > div:nth-of-type(2) {
          transform: rotateY(-180deg);
        }
      }
    `}

  @media (max-width: 600px) {
    min-width: 250px;
    max-width: 250px;
    perspective: 500px;

    > div {
      width: 250px;
    }
  }
`;

export const CreditCardContainer = styled(PaymentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 330px;

    > div {
      flex: 1;
      width: 100%;

      + div {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      max-width: unset;
      > div {
        max-width: unset;
        > div {
          max-width: calc(50% - 8px);
        }
      }
    }
  }
`;

export const RowForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  width: 100%;

  > div {
    width: 100%;
    max-width: calc((330px - 16px) / 2);
    + div {
      margin-left: 16px;
    }
  }

  @media (max-width: 600px) {
    > div {
      max-width: unset;
    }
  }
`;

export const ColumnInput = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  > span {
    margin-bottom: 8px;
    color: #58c78f;
  }

  > input {
    background-color: #ffffff;
    border: 0;
    border-radius: 5px;
    padding: 10px 16px;

    transition: filter 0.25s ease;
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        filter: brightness(75%);
        cursor: not-allowed;
      `}
  }

  > select {
    background-color: #ffffff;
    border: 0;
    border-radius: 5px;
    padding: 10px 16px;
  }
`;

export const QrContainer = styled.div<{ qrCode?: string }>`
  background-color: #fff9;
  ${({ qrCode }) => qrCode && `background-image: url('${qrCode}')`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 150px;
  height: 150px;
  padding: 4px;
  border: 1px solid #58c78f;

  /* transition: max-height 0.5s ease; */
`;

export const PixContainer = styled(PaymentContainer)`
  display: flex;
  flex-direction: column;
  margin: 16px;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 1.15rem;
      font-weight: bold !important;
    }

    > strong {
      font-size: 1.75rem;
      color: #58c78f;
      margin-top: 8px;
    }
  }
`;

export const DepositContainer = styled(PaymentContainer)`
  display: flex;
  flex-direction: column;
  margin: 16px;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 1.15rem;
      font-weight: bold !important;
    }

    > strong {
      font-size: 1.75rem;
      color: #58c78f;
      margin-top: 8px;
    }
  }

  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 50px;

    > span {
      color: #f5f5f5;
      text-align: left;
      min-width: 500px;

      &::before {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: #fafafa;
        margin-right: 8px;
      }

      + span {
        margin-top: 8px;
      }
    }
  }
`;

export const DiscountDiv = styled.div<{ isShown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  opacity: 0;
  visibility: hidden;
  user-select: none;

  transition: opacity 0.25s ease;

  + div {
    margin-top: 8px;
  }

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  > span:nth-of-type(1) {
    font-size: 1rem;
  }

  > span:nth-of-type(2) {
    text-decoration: line-through;
    font-size: 1.2rem;
  }
`;
