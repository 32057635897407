import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  > strong {
    color: #f5f5f5;
    font-size: 2.25rem;
    margin-bottom: 24px;
    text-align: center;
  }

  > span {
    color: #58c78f;
    text-align: center;
    font-weight: bold;
    margin-bottom: 32px;
  }

  > div {
    min-width: 250px;
    max-width: 250px;
    margin-bottom: 16px;

    > input,
    > div {
      width: 100%;
    }
  }

  > button {
    margin-top: 16px;
    min-width: 250px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 50px;
  width: 100%;
  margin-bottom: 24px;

  > div {
    display: flex;
    align-items: center;

    > div + div {
      margin-left: 16px;
    }

    + div {
      margin-top: 16px;
    }
  }
`;
