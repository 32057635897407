/* eslint-disable react/destructuring-assignment */

import React, { useState, useEffect } from 'react';

import { useFilterProps, PodProp } from 'hooks/filterProps';
import { CircleButtonColor } from 'components/Product/CircleSelect/styles';

import { ReactComponent as ArrowSVG } from 'assets/icons/arrowRight.svg';
import InputCurrency from 'components/Input/MaskedInputs/Currency';

import {
  MenuButton,
  ListIcon,
  List,
  FakeCheckBox,
  DefListContainer,
  ProgressBar,
} from '../styles';

import { Container, PriceFilter } from './styles';

interface PodFilter {
  priceMin?: number;
  priceMax?: number;
  color: number[];
  brand: number[];
  subType: number[];
}

interface ListShown {
  color?: boolean;
  brand?: boolean;
  subType?: boolean;
}

const defEmptyArr: number[] = [];

interface Props {
  subType?: number[];
  handleOnChange: (filters: PodFilter) => void;
}

const FilterPod: React.FC<Props> = ({ handleOnChange, subType }) => {
  const { props: propsHook } = useFilterProps();
  const [props, setProps] = useState<PodProp | undefined>(propsHook?.pod);

  const [listShown, setListShown] = useState<ListShown>({});

  const [filter, setFilter] = useState<PodFilter>({
    color: [],
    brand: [],
    subType: subType || [],
  });

  const handleClickList = (key: keyof PodFilter, idx: number) => {
    if (key === 'priceMax' || key === 'priceMin') return;
    let selectedArr = filter[key];

    if (selectedArr.includes(idx)) {
      selectedArr = selectedArr.filter((item) => item !== idx);
    } else {
      selectedArr = [...selectedArr, idx];
    }

    setFilter({ ...filter, [key]: selectedArr });
  };

  const handleCleanFilter = (key: keyof PodFilter) => {
    if (key === 'priceMax' || key === 'priceMin') return;

    setFilter({ ...filter, [key]: defEmptyArr });
  };

  const handleToggleShowFilter = (key: keyof ListShown) => {
    setListShown({ ...listShown, [key]: !listShown[key] });
  };

  const [timerFilter, setTimerFilter] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (timerFilter) {
      clearTimeout(timerFilter);
      setTimerFilter(undefined);
    }
    // dispatch({ type: 'HANDLE_LOADING_CHANGE', payload: true });

    const newTimer = setTimeout(() => {
      setTimerFilter(undefined);
      handleOnChange(filter);
    }, 2500);

    setTimeout(() => {
      setTimerFilter(undefined);
      setTimerFilter(newTimer);
    }, 10);
    // eslint-disable-next-line
    return () => {
      setTimerFilter(undefined);
      if (timerFilter) clearTimeout(timerFilter);
      if (newTimer) clearTimeout(newTimer);
    };
  }, [filter]);

  useEffect(() => {
    // console.log('JuiceFilter', subType);
    if (subType) {
      setFilter({ ...filter, subType });
      setListShown({ ...listShown, subType: true });
    }
  }, [subType]);

  useEffect(() => {
    if (!props && propsHook?.pod) {
      setProps(propsHook.pod);
    }
  }, [props]);

  return (
    <Container>
      <ProgressBar isActive={!!timerFilter} />

      <PriceFilter>
        <strong>Preço</strong>

        <div>
          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMin: value });
            }}
            placeholder="Mínimo"
            value={filter?.priceMin}
          />

          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMax: value });
            }}
            placeholder="Máximo"
            value={filter?.priceMax}
          />
        </div>
      </PriceFilter>

      {props?.color && (
        <DefListContainer isShown={listShown.color}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('color')}
              >
                <ArrowSVG />
                Cores
              </button>

              <button type="button" onClick={() => handleCleanFilter('color')}>
                Limpar
              </button>
            </div>
          </MenuButton>

          <ListIcon>
            {props.color.map((item: any) => (
              <CircleButtonColor
                key={item.id}
                onClick={() => handleClickList('color', item.id)}
                isActive={filter.color.includes(item.id)}
                hex={item.code}
              >
                <span>{item.name}</span>
              </CircleButtonColor>
            ))}
          </ListIcon>
        </DefListContainer>
      )}

      {props?.brand && (
        <DefListContainer isShown={listShown.brand}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('brand')}
              >
                <ArrowSVG />
                Marcas
              </button>

              <button type="button" onClick={() => handleCleanFilter('brand')}>
                Limpar
              </button>
            </div>
          </MenuButton>

          <List>
            {props.brand.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleClickList('brand', item.id)}
              >
                <FakeCheckBox selected={filter.brand.includes(item.id)} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}

      {props?.subType && (
        <DefListContainer isShown={listShown.subType}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('subType')}
              >
                <ArrowSVG />
                Subtipos
              </button>

              <button
                type="button"
                onClick={() => handleCleanFilter('subType')}
              >
                Limpar
              </button>
            </div>
          </MenuButton>

          <List>
            {props.subType.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleClickList('subType', item.id)}
              >
                <FakeCheckBox selected={filter.subType.includes(item.id)} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}
    </Container>
  );
};

export default FilterPod;
