import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Index from '../pages/IndexStructure';
import ProductPage from '../pages/ProductPage';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/">
        <Index />
      </Route>
    </Switch>
  );
};

export default Routes;
