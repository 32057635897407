import React, { useEffect, useState } from 'react';
import {
  NavLink,
  useRouteMatch,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import { BsBoxArrowInRight } from 'react-icons/bs';

import { useAgeCheck } from 'hooks/AgeCheck';
import { useAuth } from 'hooks/auth';
import { useCart } from 'hooks/Cart';
import { useNotification } from 'hooks/notification';
import { useFavorites } from 'hooks/Favorites';

import { swalPromise } from 'services/swal';

import Notifications from 'components/Notifications';

import { ReactComponent as EmailSVG } from 'assets/icons/Email.svg';
import { ReactComponent as FoneSVG } from 'assets/icons/Fone.svg';
import { ReactComponent as PinSVG } from 'assets/icons/Pin.svg';

import { ReactComponent as FbSVG } from 'assets/socialMedia/facebook.svg';
import { ReactComponent as WppSVG } from 'assets/socialMedia/whatsapp.svg';
import { ReactComponent as InstaSVG } from 'assets/socialMedia/instagram.svg';

import { ReactComponent as LogoBrancaSVG } from 'assets/logo/LogoBranca.svg';

import { ReactComponent as PlanetsLeft } from 'assets/backgrounds/footer/planetasLeft.svg';
import { ReactComponent as PlanetsRight } from 'assets/backgrounds/footer/planetasRigth.svg';
import { ReactComponent as LogoSVG } from 'assets/logo/logo.svg';
import { ReactComponent as SearchSVG } from 'assets/icons/busca.svg';
import { ReactComponent as FavSVG } from 'assets/icons/favoritos.svg';
import { ReactComponent as CartSVG } from 'assets/icons/cesta.svg';

import { ReactGAFn } from '../../App';

import SearchInput from './SearchInput';
import Menu from './Menu';

import Home from '../Home';
import Product from '../ProductPage';
import Cart from '../Cart';
import Checkout from '../Checkout';
import CheckoutDone from '../CheckoutDone';
import User from '../User';
import Profile from '../Profile';
import Filter from '../Filter';
import Favorites from '../Favorites';
import Faq from '../Faq';
import AboutUs from '../AboutUs';
import Dealer from '../Dealer';

import { Container, Header, Main, Footer, HeaderContainer } from './styles';

const IndexStructure: React.FC = () => {
  const { favorites } = useFavorites();
  const { addNotification } = useNotification();
  const { token, user, signOut } = useAuth();
  const { cartProducts } = useCart();
  const { isLoading } = useAgeCheck();

  const { path, url } = useRouteMatch();
  const history = useHistory();

  // useEffect(() => {
  //   console.log(path, url);
  //   document
  //     .querySelector('#main-content')
  //     ?.scrollTo({ top: 0, behavior: 'smooth' });
  // }, [path, url]);

  useEffect(() => {
    history.listen((prop) => {
      ReactGAFn.pageview(prop.pathname, undefined, prop.pathname);
    });
  }, []);

  return (
    <Container id="main-content">
      <Header>
        <NavLink to="/">
          <LogoSVG />
        </NavLink>

        <HeaderContainer>
          <div>
            <div>
              {/* <button
                type="button"
                onClick={() => {
                  addNotification({
                    id: 1,
                    name: 'Aludra',
                    type: 'product',
                    quantity: 1,
                    bottle: 2,
                    nic: 2,
                    proportion: 1,
                  });
                }}
              >
                addNot
              </button> */}
              {/* <NavLink to="/testPage">TestPage</NavLink>
              <NavLink to="/product">Product</NavLink> */}
              <NavLink to="/sobre-nos">Sobre nós</NavLink>
              <NavLink to="/revendedores">Revendedores</NavLink>
            </div>

            <SearchInput />
          </div>

          <div>
            <span>
              {token && user
                ? `Olá, ${user.name.split(' ')[0]}`
                : 'Bem-vindo :)'}
            </span>

            <div>
              {token && user ? (
                <>
                  <NavLink to="/profile">Entrar no perfil</NavLink>
                  <BsBoxArrowInRight
                    onClick={() => {
                      swalPromise({
                        title: 'Deseja mesmo sair?',
                        icon: 'question',
                        cancelButtonText: 'Não',
                        confirmButtonText: 'Sim',
                      }).then((result) => {
                        if (result.isConfirmed) {
                          signOut();
                          history.push('/');
                        }
                      });
                    }}
                  />
                </>
              ) : (
                // <span>Bem-vindo&nbsp;{user.name}</span>
                // <NavLink to="/profile">Entre ou cadastre-se</NavLink>
                <NavLink to="/user">Entre ou cadastre-se</NavLink>
              )}
            </div>
          </div>

          <div>
            <NavLink to="/favorites">
              <FavSVG />
              <strong>{favorites.length || 0}</strong>
              {/* {user ? (
                <strong>{user.fav || 0}</strong>
              ) : (
                <strong>{favorites.length || 0}</strong>
              )} */}
            </NavLink>

            <NavLink to="/cart">
              <CartSVG />
              <strong>{cartProducts.length}</strong>
            </NavLink>
          </div>
        </HeaderContainer>
      </Header>

      <Menu />

      <Main>
        {isLoading ? (
          <div />
        ) : (
          <Switch>
            <Route exact path="/">
              <Home loggedIn={!!(token && user)} />
            </Route>

            <Route path="/product/:id?">
              <Product />
            </Route>

            <Route path="/cart">
              <Cart />
            </Route>

            <Route path="/checkout/:hashCart?">
              <Checkout />
            </Route>

            <Route path="/checkout-done/:hashOrder?">
              <CheckoutDone />
            </Route>

            {/* <Route path="/user/:hash?"> */}
            <Route path="/user">
              <User />
            </Route>

            <Route path="/filter">
              <Filter />
            </Route>

            <Route path="/profile">
              <Profile />
            </Route>

            <Route path="/favorites">
              <Favorites />
            </Route>

            <Route path="/faq">
              <Faq />
            </Route>

            <Route path="/revendedores">
              <Dealer />
            </Route>

            <Route path="/sobre-nos">
              <AboutUs />
            </Route>

            <Route path="*">
              <Home loggedIn={!!(token && user)} />
            </Route>
          </Switch>
        )}
      </Main>

      <Footer>
        <PlanetsLeft />
        <PlanetsRight />

        <div>
          <LogoBrancaSVG />

          <div>
            <WppSVG
              onClick={() => {
                window.open(
                  'https://api.whatsapp.com/send?phone=5548991501656',
                  '_blank',
                );
              }}
            />
            <FbSVG
              onClick={() => {
                window.open(
                  'https://www.facebook.com/constellationsteam/',
                  '_blank',
                );
              }}
            />
            <InstaSVG
              onClick={() => {
                window.open(
                  'https://www.instagram.com/constellationsteam/',
                  '_blank',
                );
              }}
            />
          </div>
        </div>

        <div>
          <div>
            <strong>Pra você</strong>

            <NavLink to="/faq">Perguntas frequentes</NavLink>
            <NavLink to="/faq/pagamento">Formas de pagamento</NavLink>
            <NavLink to="/faq/entrega">Formas de entrega</NavLink>
            <NavLink to="/faq/trocas-e-devolucoes">
              Garantia e devolução
            </NavLink>
          </div>

          <div>
            <strong>Constellation</strong>

            <NavLink to="/sobre-nos">Sobre nós</NavLink>
            <NavLink to="/revendedores">Revendedores</NavLink>
            <NavLink to="/faq/termos-e-condicoes">Termos & Condições</NavLink>
            <NavLink to="/faq/politica-de-privacidade">
              Política de privacidade
            </NavLink>
          </div>

          <div>
            <strong>Constellation</strong>

            <NavLink to="/">Guias em breve...</NavLink>
            {/* <NavLink to="/">Entendendo PG e VG</NavLink>
            <NavLink to="/">Dicas para iniciantes</NavLink>
            <NavLink to="/">Segurança das baterias</NavLink>
            <NavLink to="/">Todos os guias</NavLink> */}
          </div>
        </div>

        <div>
          <div>
            <FoneSVG />
            <span>+55 48 99150-1656</span>
          </div>

          <div>
            <EmailSVG />
            {/* <span>contato@constellation.com.br</span> */}
            <span>steamconstellation@gmail.com</span>
          </div>
        </div>

        <strong>
          Constellation Steam. - © 2020. Todos os direitos reservados.
        </strong>
      </Footer>
    </Container>
  );
};

export default IndexStructure;
