import React from 'react';

import { Mint } from 'hooks/productProps';

import { ReactComponent as MentaSVG } from '../../../assets/flavours/folha_menta.svg';
import { Container } from './styles';

interface MintSelectorProps {
  onChange?: (val: any) => void;
  options?: Mint[];
}

const MintSelector: React.FC<MintSelectorProps> = ({ onChange, options }) => {
  const [mint, setMint] = React.useState(0);

  const handleMintValue = React.useMemo((): string => {
    if (!options || mint === 0) return '';
    const filtered = options.filter((item) => item.id === mint);
    if (filtered.length > 0) {
      return filtered[0].name;
    }

    // OR

    // if (options[mint]) return options[mint].name;

    // if (mint === 1) return 'SUAVE';
    // if (mint === 2) return 'MODERADO';
    // if (mint === 3) return 'INTENSO';

    return '';
  }, [mint]);

  // const handleChange = React.useCallback(
  //   (event: any) => {
  //     if (event?.target) {
  //       const { target } = event;
  //       setMint(parseInt(target.value, 10));
  //       if (onChange && options) {
  //         const filtered = options.filter(
  //           (item) => item.id === parseInt(target.value, 10),
  //         );
  //         if (filtered.length > 0) {
  //           onChange(filtered[0]);
  //         } else {
  //           onChange(undefined);
  //         }
  //       }
  //     }
  //   },
  //   [options, onChange],
  // );

  return (
    <Container mintVal={mint}>
      <input
        draggable="false"
        // DISABLE DRAG AND DROP
        // onMouseMove={(e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        // }}
        // DISABLE DRAG AND DROP
        type="range"
        list="mintList"
        min="0"
        max={options?.length || 3}
        // max={options?.length ? options.length + 1 : 3}
        // onChange={handleChange}
        onChange={({ target }) => {
          setMint(parseInt(target.value, 10));
          if (onChange && options) {
            const filtered = options.filter(
              (item) => item.id === parseInt(target.value, 10),
            );
            if (filtered.length > 0) {
              onChange(filtered[0]);
            } else {
              onChange(undefined);
            }
          }
        }}
        value={mint}
      />
      <span>{handleMintValue}</span>
      {/* <MentaSVG /> */}
    </Container>
  );
};

export default MintSelector;
