import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 1.25rem;

  > input {
    color: #261231;
    font-size: 1.5rem;

    padding: 6px 12px;
    max-width: 150px;
    width: 100%;
    height: 35px;
    text-align: center;
    font-weight: bold;

    border: 1px solid #fff;
  }

  > span {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% + 4px));

    font-size: 0.7rem;
    width: 100%;
    text-align: right;
  }
`;
