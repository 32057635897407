import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';
import { mediaQueries } from 'styles/default';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;

  /* height: 100vh;
  width: 100vw; */
  height: 100%;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Header = styled.header`
  background-color: rgba(24, 11, 31, 0.7);
  /* max-height: 200px; */

  /* flex: 1; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 16px 50px;

  > a {
    > svg {
      min-width: 325px;
      height: auto;
    }
  }

  @media (max-width: 1300px) {
    padding: 16px 0;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    padding: 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  /* margin-left: auto; */

  > * + * {
    margin-left: 32px;
  }

  > div:nth-of-type(1) {
    position: relative;
    z-index: 340;
    flex-direction: column;
    // SobreNós - Revendedores
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 12px;

      > a {
        font-size: 1.1rem;
        font-weight: bold !important;
      }

      a + a {
        margin-left: 12px;
      }
    }

    // Container de input de busca
    > div:last-child {
      align-items: center;
      display: flex;
      height: 35px;
      border-radius: 12px;
      border: 1px solid #4f1e66;
      background-color: #4f1e66;
      min-width: 400px;

      > input {
        background-color: transparent;
        color: #e4e4e4;
        flex: 1;
        border: 0;
        padding: 8px 12px;

        &::placeholder {
          color: #e4e4e4;
        }
      }

      > svg {
        cursor: pointer;
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
    }
  }

  // Bem-Vindo Container
  > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    min-width: 150px;

    > div {
      display: flex;
      align-items: center;

      > svg {
        cursor: pointer;
        margin-left: 6px;

        transition: filter 0.25s ease;
        &:hover {
          filter: brightness(75%);
        }
      }
    }
  }

  // Favorite and Cart Icons Container
  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;

    > a + a {
      margin-left: 16px;
    }

    > a {
      > svg {
        width: 38px;
        height: 38px;

        .stroke {
          stroke: #f5f5f5;
          fill: #f5f5f5 !important;
        }

        transition: filter 0.25s ease;
        &:hover {
          filter: brightness(75%);
        }
      }

      > strong {
        position: relative;
        left: -13px;
        bottom: 5px;

        color: #f5f5f5;
        border: 1px solid #2b1037;
        display: inline-block;
        text-align: center;

        line-height: 1.5;
        vertical-align: middle;
        font-size: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    }

    > a:first-child > strong {
      background-color: #4f1e66;
    }

    > a:last-child > strong {
      background-color: #17824d;
    }
  }

  @media (max-width: 1200px) {
    > div:nth-of-type(1) {
      > div:last-child {
        min-width: 250px;
        > div {
          min-width: 250px;
        }
      }
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    width: 100%;
    max-width: 600px;

    > * + * {
      margin-left: 0;
      margin-top: 24px;
    }

    > div:nth-of-type(1) {
      width: 100%;
      margin-top: 24px;

      > div:first-child {
        justify-content: center;
      }

      > div:last-child {
        width: 100%;

        > div {
          width: 100%;

          > input {
            width: 100%;
          }
        }
      }
    }

    > div:nth-of-type(2) {
      width: 100%;
      align-items: center;

      > span,
      > div > a {
        font-size: 1.1rem;
      }
    }

    > div:last-child {
      width: 100%;
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  width: 100%;
  /*
  NÃO USAR NUNCA JAMAIS EM HIPOTESE ALGUMA!!!!!!!!!!!!!!!!!!
  height: 100%;
  NÃO USAR NUNCA JAMAIS EM HIPOTESE ALGUMA!!!!!!!!!!!!!!!!!!
  */

  /* > section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 70vh;
  } */
`;

/* ${({ isGrid }) =>
    mediaQueries.map(
      (item) => css`
        @media (min-width: ${item.screenSize}px) {
          ${isGrid === true
            ? css`
                > div.container {
                  max-width: ${item.maxWidth}px;
                  margin: auto;
                  display: flex;
                  flex-flow: row wrap;
                  justify-content: center;

                  > div {
                    flex: 1 1 calc(${item.maxWidth}px / 5 - 2rem);
                    margin: 1rem;
                  }
                }
              `
            : css`
                max-width: ${item.maxWidth}px;
              `}
        }
      `,
    )}; */

export const Menu = styled.section<{ current?: number }>`
  position: relative;
  /* z-index: 100; */

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* height: 60px; */

  > div {
    /* position: relative;
    z-index: 120; */

    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin: 0 auto;
    width: 100%;
    background-color: rgba(24, 11, 31, 0.9);

    > button {
      background-color: transparent;
      border: 0;
      font-size: 1.2rem;
      color: #f5f5f5;

      height: 60px;
      width: 350px;
      text-align: center;

      transition: filter 0.2s ease;
      &:hover {
        filter: brightness(75%);
      }
    }
  }

  > section {
    position: absolute;
    top: 85px;
    left: 0;
    max-height: 0;

    user-select: none;
    pointer-events: none;

    overflow: hidden;
    opacity: 0;

    width: 100%;
    /* height: 100%; */

    transition: all 0.25s ease;

    > article {
      display: flex;
      width: 100%;
      height: 100%;

      max-height: 0;

      user-select: none;
      pointer-events: none;
      opacity: 0;

      transition: all 0.25s ease;
    }

    ${({ current }) =>
      current !== undefined &&
      css`
        max-height: unset;
        user-select: unset;
        pointer-events: unset;
        opacity: 1;

        > article {
          max-height: unset;
          user-select: unset;
          pointer-events: unset;
          opacity: 1;
        }
      `}
  }
`;

export const Footer = styled.footer`
  position: relative;
  min-height: 380px;

  margin-top: 50px;
  padding: 50px 16px 0 16px;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;

  > svg:nth-of-type(1) {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  > svg:nth-of-type(2) {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  > strong {
    position: absolute;
    color: #bebebe;
    font-size: 1rem;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
  }

  > div {
    display: flex;
  }

  > div:nth-of-type(1) {
    flex: 1;
    flex-direction: column;

    > svg {
      width: 100%;
      height: auto;
    }

    > div {
      position: relative;
      z-index: 50;

      display: flex;
      align-items: center;
      justify-content: space-evenly;
      /* margin-top: 16px; */
      margin: 16px 10% 0 10%;

      > svg {
        cursor: pointer;

        width: 32px;
        height: 32px;

        transition: filter 0.25s ease;
        &:hover {
          filter: brightness(75%);
        }

        + svg {
          margin-left: 32px;
        }
      }
    }
  }

  > div:nth-of-type(2) {
    flex: 3;
    align-items: flex-start;
    justify-content: space-evenly;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > strong {
        color: #ce86ec;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 16px;
        text-align: left;
        width: 100%;
      }

      > a {
        width: 100%;
        text-align: left;
        color: #cac9cb;
        font-size: 1.15rem;

        transition: filter 0.25s ease;
        &:hover {
          filter: brightness(75%);
        }

        + a {
          margin-top: 8px;
        }
      }
    }
  }

  > div:nth-of-type(3) {
    flex: 1;
    flex-direction: column;

    > div {
      display: flex;

      + div {
        margin-top: 8px;
      }
      > svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 1080px) {
    flex-direction: column;
    padding-bottom: 75px;
    min-height: unset;

    > strong {
      width: 100%;
      text-align: center;
      padding: 0 16px;
    }

    > svg:nth-of-type(1),
    > svg:nth-of-type(2) {
      width: 50%;
      height: auto;
    }

    > div:nth-of-type(1) {
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      > svg {
        width: 325px;
      }
    }

    > div:nth-of-type(2) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 24px 0;

      > div {
        > strong,
        > a {
          text-align: center;
        }

        + div {
          margin-top: 24px;
        }
      }
    }

    > div:nth-of-type(3) {
      align-items: center;
      margin-top: 24px;
    }
  }
`;
