import React, { useEffect, useState } from 'react';

import { swalFire } from 'services/swal';
import Skeleton from 'react-loading-skeleton';

import RadioButton from 'components/Button/RadioButton';
import { api, handleErrors, handleRespReturn } from 'services/api';

import {
  Container,
  CardCC,
  CardContainer,
  DeleteCard,
  CardBrand,
} from './styles';

interface CreditCard {
  id: number;
  brandLink: string;
  brand: string;
  name: string;
  number: string;
  validation: string;
  current?: boolean;
}

interface Props {
  enableReq?: boolean;
}
const CreditCard: React.FC<Props> = ({ enableReq }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [creditCards, setCreditCards] = useState<CreditCard[] | undefined>();

  const handleCurrentCardChange = async (card: CreditCard): Promise<void> => {
    if (!creditCards) return;
    if (card.current) {
      swalFire({
        text: 'Este já é seu cartão de crédito principal.',
        icon: 'info',
      });

      return;
    }
    try {
      const resp = await api().request({
        url: `/user/profile/card?idCard=${card.id}`,
        method: 'POST',
      });
      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      setCreditCards([
        ...creditCards.map((item) => {
          if (item.id === card.id) {
            return { ...item, current: true };
          }
          return { ...item, current: false };
        }),
      ]);
    } catch (err) {
      handleErrors(err, true);
    }
  };

  const handleDeleteCard = async (card: CreditCard): Promise<void> => {
    if (isLoading) return;
    if (!creditCards) return;
    setIsLoading(true);

    try {
      const resp = await api().request({
        url: `/user/profile/card?idCard=${card.id}`,
        method: 'DELETE',
      });
      setIsLoading(false);
      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      setCreditCards([...creditCards.filter((item) => item.id !== card.id)]);
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
    }
  };

  useEffect(() => {
    const getCards = async (): Promise<void> => {
      try {
        const resp = await api().request({
          url: `/user/profile/cards`,
          method: 'GET',
        });
        const isErrored = handleRespReturn(resp);
        if (isErrored) return;

        setCreditCards([...resp.data.content]);
      } catch (err) {
        handleErrors(err, true);
      }
    };

    if (!creditCards && enableReq) {
      getCards();
    }
  }, [enableReq]);

  return (
    <Container>
      <CardContainer>
        {creditCards &&
          creditCards.length > 0 &&
          creditCards.map((item) => (
            <CardCC key={item.id}>
              <header>
                {item.brandLink && <CardBrand link={item.brandLink} />}
                {item.brand}
                <DeleteCard onClick={() => handleDeleteCard(item)}>
                  Excluir
                </DeleteCard>
              </header>
              <main>
                <div>
                  <strong>{item.name}</strong>

                  <span>{item.number}</span>

                  <span>Validade: {item.validation}</span>
                </div>

                <RadioButton
                  onCheckChange={() => {
                    handleCurrentCardChange(item);
                  }}
                  checked={item.current}
                >
                  Selecionar como cartão principal
                </RadioButton>
              </main>
            </CardCC>
          ))}

        {creditCards && creditCards.length === 0 && (
          <strong>Nenhum cartão de crédito cadastrado.</strong>
        )}

        {creditCards === undefined && <Skeleton count={2} />}
      </CardContainer>

      <strong>
        Por questões de segurança, você só poderá editar ou incluir novos
        cartões durante o processo de compra.
      </strong>
    </Container>
  );
};

export default CreditCard;
