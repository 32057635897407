import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.div`
  position: relative;
  z-index: 160;

  > div:nth-of-type(1) {
    position: relative;
    z-index: 30;

    display: flex;
    align-items: center;

    border-radius: 12px;
    border: 1px solid #4f1e66;
    background-color: #4f1e66;

    height: 35px;
    min-width: 400px;

    > input {
      background-color: transparent;
      color: #e4e4e4;
      flex: 1;
      font-size: 0.9rem;
      border: 0;
      padding: 8px 12px;

      &::placeholder {
        color: #e4e4e480;
      }
    }

    > svg {
      cursor: pointer;
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
`;

export const ListContainer = styled.article<{ isShown: boolean }>`
  position: absolute;
  top: 10px;
  left: 0;
  padding-top: 25px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #00c763;
  background-color: #2d1439;
  border-radius: 12px;

  width: 100%;
  max-height: 0;

  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;

  user-select: none;
  pointer-events: none;

  opacity: 0;

  transition: max-height 0.25s ease, opacity 0.25s ease;

  ${({ isShown }) =>
    isShown &&
    css`
      /* min-height: 200px; */
      max-height: 300px;
      user-select: unset;
      pointer-events: unset;
      opacity: 1;
    `}

  > strong {
    color: #00c763;
    font-size: 1.25rem;
    margin: 16px auto;

    animation: ${fadeIn} 0.25s ease;
  }

  > svg {
    margin: 16px auto;
    width: 32px;
    height: 32px;
    animation: ${fadeIn} 0.25s ease;
  }
`;

export const SearchItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 0;
  background-color: transparent;

  width: 100%;
  color: #fff;
  padding: 8px;

  transition: filter 0.25s ease;

  animation: ${fadeIn} 0.25s ease;

  > figure {
    overflow: hidden;
    max-height: 75px;
    max-width: 75px;
    border-radius: 4px;

    > img {
      width: 75px;
      height: 75px;

      object-fit: cover;
      object-position: center;
      display: block; /*to make it behave as div and avoir whitespace issue*/
    }
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 16px;

    > strong:nth-of-type(1) {
      font-size: 1.125rem;
    }

    > strong:nth-of-type(2) {
      margin-top: 8px;
      color: #00c763;
      font-size: 1rem;
    }
  }

  &:hover {
    filter: brightness(75%);
  }

  + button {
    border-top: 1px solid #4f1e66;
  }
`;
