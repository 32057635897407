import React from 'react';

import { Button } from './styles';

interface ButtonProps {
  text?: string;
  color?: number;
  onClick: () => void;
  maxWidth?: string;
  minWidth?: string;
}
const FormButton: React.FC<ButtonProps> = ({
  onClick,
  color = 1,
  text,
  children,
  maxWidth,
  minWidth,
}) => {
  return (
    <Button
      customColor={color}
      onClick={onClick}
      maxWidth={maxWidth}
      minWidth={minWidth}
    >
      {text ? <strong>{text}</strong> : ''}
      {children || ''}
    </Button>
  );
};

export default FormButton;
