import React, { useEffect, useState } from 'react';

import CurrentPath from 'components/CurrentPath';
import SectionTitle from 'components/SectionTitle';
import { ReactComponent as ArrowSVG } from 'assets/icons/arrowRight.svg';
import { ReactComponent as TelescopeSVG } from 'assets/draws/telescopio.svg';

import { PropsGenericFaq, GenericFaqQuestions } from '../index';
import {
  Container,
  Content,
  Question,
  QuestionBtn,
  QuestionContainer,
} from './styles';

const Pedidos: React.FC<PropsGenericFaq> = ({
  title,
  handleGetQuestions,
  thisPath,
  getParam,
}) => {
  const [questions, setQuestions] = useState<GenericFaqQuestions[] | undefined>(
    undefined,
  );

  const [questionShown, setQuestionShown] = useState<number | undefined>(
    undefined,
  );

  const pathList = [
    {
      title: 'Início',
      to: '/',
    },
    {
      title: 'Perguntas Frequentes',
      to: '/faq',
    },
    {
      title,
      to: `/faq${thisPath}`,
    },
  ];

  const handleToggleQuestionShown = (id: number): void => {
    setQuestionShown(questionShown === id ? undefined : id);
  };

  useEffect(() => {
    let mounted = true;
    const getQuestions = async (): Promise<void> => {
      const questionsResp = await handleGetQuestions(getParam);
      if (questionsResp && mounted) {
        setQuestions([...questionsResp]);
      }
    };

    if (!questions && mounted) {
      getQuestions();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <CurrentPath pathList={pathList} />

      <SectionTitle icon={<TelescopeSVG />} title={title} margin="0" />

      <Content>
        {questions &&
          questions.map((item) => (
            <Question key={item.id} isActive={item.id === questionShown}>
              <QuestionBtn
                isActive={item.id === questionShown}
                onClick={() => handleToggleQuestionShown(item.id)}
              >
                <ArrowSVG />
                <strong>{item.question}</strong>
              </QuestionBtn>

              <QuestionContainer isShown={item.id === questionShown}>
                <p>{item.answer}</p>
              </QuestionContainer>
            </Question>
          ))}
      </Content>
    </Container>
  );
};

export default Pedidos;
