import React, { createContext, useState, useContext, useEffect } from 'react';
import { api, handleErrors, handleRespReturn } from '../services/api';

interface GenericName {
  id: number;
  name: string;
}

export interface Color {
  id: number;
  name: string;
  code: string;
}

export interface PodProp {
  subType?: GenericName[];
  color?: Color[];
  brand?: GenericName[];
}

export interface KitProp {
  color?: Color[];
  brand?: GenericName[];
  subType?: GenericName[];
}

export interface ModProp {
  color?: Color[];
  brand?: GenericName[];
  subType?: GenericName[];
}

export interface AttyProp {
  color?: Color[];
  brand?: GenericName[];
  airflow?: GenericName[];
  subType?: GenericName[];
}

export interface JuiceProp {
  profile?: Omit<GenericName, 'name'>[];
  brand?: GenericName[];
  flavour?: GenericName[];
  nicType?: GenericName[];
}

export interface AccessoryProp {
  subType?: GenericName[];
}

export interface PropsData {
  accessory?: AccessoryProp;
  mod?: ModProp;
  pod?: PodProp;
  atty?: AttyProp;
  kit?: KitProp;
  juice?: JuiceProp;
}

interface FilterPropsData {
  props?: PropsData;
  getProps: () => PropsData | undefined;
  updateData: () => Promise<void>;
}

const FilterProps = createContext<FilterPropsData>({} as FilterPropsData);

const FilterPropsProvider: React.FC = ({ children }) => {
  const [props, setProps] = useState<PropsData | undefined>(():
    | PropsData
    | undefined => {
    let parsedStorage: PropsData | undefined;
    const storage = sessionStorage.getItem('@Constellation:PropsFilter');
    if (storage) {
      parsedStorage = JSON.parse(storage);
      if (parsedStorage) {
        return parsedStorage;
      }
    }
    api()
      .get('/products/filterProps')
      .then((resp) => {
        // console.log('fixme', resp);
        if (!resp.data.content) return undefined;
        sessionStorage.setItem(
          '@Constellation:PropsFilter',
          JSON.stringify({ ...resp.data.content }),
        );
        return { ...resp.data.content };
      })
      .catch((resp) => {
        // console.log('Cant get props', resp);
        if (parsedStorage) return { ...parsedStorage };
        return undefined;
      });

    if (parsedStorage) return { ...parsedStorage };
    return undefined;
  });

  const getProps = (): PropsData | undefined => props;

  const updateData = async (): Promise<void> => {
    api()
      .get('/products/filterProps')
      .then((resp) => {
        // console.log('fixme', resp);
        if (!resp.data.content) return;
        sessionStorage.setItem(
          '@Constellation:PropsFilter',
          JSON.stringify({ ...resp.data.content }),
        );
        setProps({ ...resp.data.content });
      })
      .catch((resp) => {
        // console.log('Cant get propsFilter', resp);
      });
  };

  useEffect(() => {
    // console.log('HookProps', props);
    if (!props) {
      updateData();
    }
  }, [props]);

  return (
    <FilterProps.Provider
      value={{
        props,
        getProps,
        updateData,
      }}
    >
      {children}
    </FilterProps.Provider>
  );
};

function useFilterProps(): FilterPropsData {
  const context = useContext(FilterProps);

  if (!context) {
    throw new Error(
      'useFilterProps must be used within an FilterPropsProvider',
    );
  }

  return context;
}

export { FilterPropsProvider, useFilterProps };
