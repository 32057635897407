import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  padding: 0 !important;

  > svg {
    position: absolute;
    top: -140px;
    left: 0;
    width: 500px;
    height: 500px;
    z-index: 30;
    transform: rotateZ(-90deg) translateY(-25%);
  }

  @media (max-width: 1000px) {
    > svg {
      display: none;
    }
  }

  /* > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(250px));
    row-gap: 24px;
    column-gap: 24px;
    justify-content: center;
    grid-auto-flow: row;
  } */
  /*

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  z-index: 50;
  max-width: calc(350px * 6);
  margin: 0 auto; */

  /* > div {
    margin: 1rem;
  } */
`;
