import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-items: flex-start;

  > svg {
    cursor: pointer;
    width: 50px;
    height: 50px;

    &.current ~ svg {
      .cls-1,
      .cls-2 {
        fill-opacity: 0 !important;
      }
    }

    &:hover {
      .cls-1,
      .cls-2 {
        fill-opacity: 1 !important;
      }

      & ~ svg {
        .cls-1,
        .cls-2 {
          fill-opacity: 0 !important;
        }
      }
    }
  }
`;
