import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useNotification } from 'hooks/notification';

import ProductNotification from './Product';

import { Container, Wrap } from './styles';

const Notifications: React.FC = () => {
  const { notifications } = useNotification();

  useEffect(() => {
    if (notifications.length > 0) {
      document.body.style.overflow = 'hidden';
    }
    document.body.style.overflow = 'unset';
  }, [notifications]);

  const notifyContainer = (
    <Wrap>
      <Container isShowing={notifications.length > 0}>
        {notifications.length > 0 && (
          <>
            {notifications[0].type === 'product' && (
              <ProductNotification
                product={notifications[0]}
                idNotify={notifications[0].id}
              />
            )}
          </>
        )}
      </Container>
    </Wrap>
  );

  return ReactDOM.createPortal(notifyContainer, document.body);
};

export default Notifications;
