import styled, { css } from 'styled-components';
import { fadeIn, fadeOut } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  animation: ${fadeIn} 0.25s ease forwards 1;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 50px 100px;

  > button {
    margin: 50px auto 50px 0;
  }

  > strong,
  > p {
    white-space: pre-wrap;
    color: #f5f5f5;
    font-size: 1.1rem;
    text-align: left;
    animation: ${fadeIn} 0.25s ease forwards 1;
  }

  > p {
    line-height: 1.75;
    animation: ${fadeIn} 0.25s ease forwards 1;
  }

  > * + * {
    margin-top: 40px;
  }

  @media (max-width: 900px) {
    margin: 48px 24px;
  }
`;
