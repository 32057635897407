/* eslint-disable react/destructuring-assignment */

import React, { useState, useEffect } from 'react';

import { ReactComponent as ArrowSVG } from 'assets/icons/arrowRight.svg';

import { useFilterProps, JuiceProp } from 'hooks/filterProps';
import InputCurrency from 'components/Input/MaskedInputs/Currency';

import IconFlavour from 'components/Product/Flavours/Sabores';

import {
  MenuButton,
  ListIcon,
  List,
  FakeCheckBox,
  DefListContainer,
  ProgressBar,
} from '../styles';

import { Container, PriceFilter } from './styles';

interface JuiceFilter {
  priceMin?: number;
  priceMax?: number;
  brand: number[];
  profile: number[];
  flavour: number[];
  nicType?: 1 | 2;
}

interface ListShown {
  brand?: boolean;
  flavour?: boolean;
  nicType?: boolean;
  profile?: boolean;
}

const defEmptyArr: number[] = [];

interface Props {
  brand?: number[];
  handleOnChange: (filters: JuiceFilter) => void;
}

const FilterJuice: React.FC<Props> = ({ handleOnChange, brand }) => {
  const { props: propsHook } = useFilterProps();
  const [props, setProps] = useState<JuiceProp | undefined>(propsHook?.juice);

  const [listShown, setListShown] = useState<ListShown>({});

  const [filter, setFilter] = useState<JuiceFilter>({
    brand: brand || [],
    profile: [],
    flavour: [],
  });

  const handleClickList = (key: keyof JuiceFilter, idx: number) => {
    if (key === 'nicType' || key === 'priceMax' || key === 'priceMin') return;
    let selectedArr = filter[key];

    if (selectedArr.includes(idx)) {
      selectedArr = selectedArr.filter((item) => item !== idx);
    } else {
      selectedArr = [...selectedArr, idx];
    }

    setFilter({ ...filter, [key]: selectedArr });
  };

  const handleCleanFilter = (key: keyof JuiceFilter) => {
    if (key === 'priceMax' || key === 'priceMin') return;
    if (key === 'nicType') {
      setFilter({ ...filter, [key]: undefined });
      return;
    }

    setFilter({ ...filter, [key]: defEmptyArr });
  };

  const handleToggleShowFilter = (key: keyof ListShown) => {
    setListShown({ ...listShown, [key]: !listShown[key] });
  };

  const [timerFilter, setTimerFilter] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (timerFilter) {
      clearTimeout(timerFilter);
      setTimerFilter(undefined);
    }
    // dispatch({ type: 'HANDLE_LOADING_CHANGE', payload: true });

    const newTimer = setTimeout(() => {
      setTimerFilter(undefined);
      handleOnChange(filter);
    }, 2500);

    setTimeout(() => {
      setTimerFilter(undefined);
      setTimerFilter(newTimer);
    }, 10);
    // eslint-disable-next-line
    return () => {
      setTimerFilter(undefined);
      if (timerFilter) clearTimeout(timerFilter);
      if (newTimer) clearTimeout(newTimer);
    };
  }, [filter]);

  useEffect(() => {
    if (brand) {
      setFilter({ ...filter, brand });
      setListShown({ ...listShown, brand: true });
    }
  }, [brand]);

  useEffect(() => {
    if (!props && propsHook?.juice) {
      setProps(propsHook.juice);
    }
  }, [props]);

  return (
    <Container>
      <ProgressBar isActive={!!timerFilter} />

      <PriceFilter>
        <strong>Preço</strong>

        <div>
          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMin: value });
            }}
            placeholder="Mínimo"
            value={filter?.priceMin}
          />

          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMax: value });
            }}
            placeholder="Máximo"
            value={filter?.priceMax}
          />
        </div>
      </PriceFilter>

      {props?.profile && (
        <DefListContainer isShown={listShown.profile}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('profile')}
              >
                <ArrowSVG />
                Perfil
              </button>

              <button
                type="button"
                onClick={() => handleCleanFilter('profile')}
              >
                Limpar
              </button>
            </div>
          </MenuButton>

          <ListIcon>
            {props.profile.map((item: any) => (
              <IconFlavour
                key={item.id}
                idx={item.id}
                outline={!filter?.profile?.includes(item.id)}
                onClick={() => handleClickList('profile', item.id)}
              />
            ))}
          </ListIcon>
        </DefListContainer>
      )}

      {props?.brand && (
        <DefListContainer isShown={listShown.brand}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('brand')}
              >
                <ArrowSVG />
                Linhas
              </button>

              <button type="button" onClick={() => handleCleanFilter('brand')}>
                Limpar
              </button>
            </div>
          </MenuButton>

          <List>
            {props.brand.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleClickList('brand', item.id)}
              >
                <FakeCheckBox selected={filter.brand.includes(item.id)} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}

      {props?.flavour && (
        <DefListContainer isShown={listShown.flavour}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('flavour')}
              >
                <ArrowSVG />
                Sabores
              </button>

              <button
                type="button"
                onClick={() => handleCleanFilter('flavour')}
              >
                Limpar
              </button>
            </div>
          </MenuButton>

          <List>
            {props.flavour.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleClickList('flavour', item.id)}
              >
                <FakeCheckBox selected={filter.flavour.includes(item.id)} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}

      {props?.nicType && (
        <DefListContainer isShown={listShown.nicType}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('nicType')}
              >
                <ArrowSVG />
                Tipo de nicotina
              </button>

              <button
                type="button"
                onClick={() => handleCleanFilter('nicType')}
              >
                Limpar
              </button>
            </div>
          </MenuButton>

          <List noBorder>
            {props.nicType.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => setFilter({ ...filter, nicType: item.id })}
              >
                <FakeCheckBox selected={filter.nicType === item.id} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}
    </Container>
  );
};

export default FilterJuice;
