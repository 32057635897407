import styled from 'styled-components';
import { ContainerDefault } from '../../../styles/default';

export const SkeletonContainer = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;

  margin: 50px 0;

  span.react-loading-skeleton {
    display: flex;
    > span {
      flex: 1;
    }
  }

  > div {
    margin: 0 40px;
    display: flex;
    flex-direction: column;

    > section {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;

      > div:nth-of-type(1) {
        display: flex;

        width: 100%;
        height: 100%;
        max-width: 400px;
        max-height: 500px;

        > span {
          > span {
            width: 400px;
            height: 500px;
          }
        }
      }

      > div:nth-of-type(2) {
        flex: 1;
        margin-left: 32px;

        > span:nth-of-type(1) {
          display: flex;
          justify-content: space-between;

          > span:nth-of-type(1) {
            width: 100%;
            max-width: 250px;
            height: 2rem;
          }

          > span:nth-of-type(2) {
            width: 100%;
            max-width: 150px;
            height: 2rem;
          }
        }

        > span:nth-of-type(2) {
          > span {
            width: 100%;
            max-width: 200px;
            height: 1.5rem;
            margin-top: 12px;
          }
        }

        > span:nth-of-type(3) {
          > span {
            width: 100%;
            height: 150px;
            margin-top: 16px;
          }
        }

        > div {
          display: flex;
          justify-content: space-between;

          > div {
            flex: 1;
          }

          > div:nth-of-type(1) {
            > span {
              > span {
                height: 75px;
                width: 70%;
                margin-top: 16px;
              }
            }
          }

          > div:nth-of-type(2) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            > span:nth-of-type(1) {
              > span {
                width: 250px;
                height: 75px;
                margin-top: 16px;
              }
            }

            > span:nth-of-type(2) {
              > span {
                width: 200px;
                height: 1.5rem;
                margin-top: 16px;
              }
            }

            > span:nth-of-type(3) {
              width: 70%;
              > span {
                width: 100%;
                height: 50px;
                margin-top: 40px;
              }
            }

            > span:nth-of-type(4) {
              width: 50%;

              > span {
                width: 100%;
                height: 40px;
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    > div {
      > section {
        flex-direction: column;
        > div:nth-of-type(1) {
          max-width: unset;

          > span {
            width: 100%;
            > span {
              width: 100%;
            }
          }
        }
        > div:nth-of-type(2) {
          margin-left: 0;
          margin-top: 24px;
          width: 100%;
        }
      }
    }
  }
`;

export const Container = styled(ContainerDefault)`
  > section,
  > div {
    width: 100%;
    display: flex;
  }

  > * + * {
    margin-top: 32px;
  }

  > section {
    padding: 0 6rem;
    margin-top: 50px;

    + section {
      margin-top: 80px;
    }
  }

  > div:nth-of-type(1) {
    margin: 0;
    padding: 0 50px;
  }

  .carousel .thumbs-wrapper {
    margin: 20px 0;
  }

  @media (max-width: 1400px) {
    > section:nth-of-type(1) {
      flex-direction: column;
      align-items: center;

      > section:nth-of-type(1) {
        margin-bottom: 150px;
      }

      > section:nth-of-type(2) {
        margin-left: 40px;
        margin-right: 40px;
      }
    }
  }

  @media (max-width: 1000px) {
    > section:nth-of-type(1) {
      flex-direction: column;
      align-items: center;

      > section:nth-of-type(1) {
        margin-bottom: 48px;
      }

      > section:nth-of-type(2) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;

        > section:nth-of-type(2) {
          align-items: center;
          align-self: center;
          margin-top: 96px;
          min-width: unset;

          /*

          > article {
            > div:nth-of-type(2) {
              flex-direction: column;

              > button {
                margin-top: 16px;
              }
            }
          }
          */
        }
      }
    }
  }

  > section {
    padding: 0 2rem;
    margin-top: 50px;

    + section {
      margin-top: 80px;
    }
  }
`;

interface JuiceImgProps {
  img?: string;
}

export const JuiceImageContainer = styled.section<JuiceImgProps>`
  position: relative;

  /* width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 500px; */
  /* width: 400px;
  height: 550px; */
  width: 100%;
  height: 100%;
  max-height: 550px;
  max-width: 400px;

  background-color: #d0b9d290;

  > figure {
    overflow: hidden;
    max-height: 550px;
    max-width: 400px;

    > img {
      width: 400px;
      height: 550px;

      object-fit: cover;
      object-position: center;
      display: block; /*to make it behave as div and avoir whitespace issue*/
    }
  }

  /* > div:nth-of-type(1) {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${({ img }) => img && `${img}`});
  } */

  > div:nth-of-type(1) {
    position: absolute;
    left: 16px;
    top: 16px;

    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: 12px;
    }
  }

  @media (max-width: 600px) {
    > figure {
      > img {
        width: 100%;
      }
    }
  }
`;

export const JuiceInfoContainer = styled.section`
  position: relative;
  display: flex;
  align-items: flex-start;

  width: 100%;
  margin-left: 40px;

  > section:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    > h1 {
      font-size: 2.5rem;
      color: #f5f5f5;
      font-weight: bold;
    }

    > strong {
      margin-top: 8px;
      font-size: 1.5rem;
      color: #00c763;
      font-weight: bold;
    }

    > p {
      font-size: 1.2rem;
      color: #ffffff;
      text-align: left;
      line-height: 1.5;
      width: 100%;
      margin-top: 12px;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      > span {
        margin-bottom: 8px;
        color: #ffffff;
        font-size: 1.25rem;
      }

      + div {
        margin-top: 24px;
      }
    }

    > div:last-of-type {
      > div {
        margin-top: 16px;
      }
    }
  }

  > section:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    min-width: 450px;

    > article {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 100px;

      > div {
        display: flex;
      }

      > div:nth-of-type(1) {
        align-items: flex-end;
        justify-content: flex-end;

        > svg {
          position: absolute;
          top: 0;
          opacity: 0.5;
          transform: translateY(-45%);
          z-index: -2;
        }

        > strong {
          color: #00c763;
          font-weight: bold;
        }

        > strong:nth-of-type(1) {
          font-size: 2rem;
          margin-bottom: 0.3rem;
        }

        > strong:nth-of-type(2) {
          margin-left: 8px;
          font-size: 3.3rem;
        }
      }

      > article {
        border-radius: 8px;
        width: 125px;
        text-align: center;

        font-weight: bold !important;
        color: #fff;
        background-color: #f04630;
        padding: 4px;
        font-size: 1rem;

        margin-top: 8px;

        &.warn {
          background-color: #f47b38;
        }
      }

      > strong {
        color: #ffffff;
        font-size: 1.1rem;
        text-align: right;
        margin-top: 8px;
        transition: opacity 0.25s ease;
        opacity: 1;

        &.hidden {
          opacity: 0;
        }
      }

      > div:nth-of-type(2) {
        align-items: center;
        margin-top: 60px;
        > div {
          height: 60px;
          margin-right: 12px;
        }
      }

      > div:nth-of-type(3) {
        justify-content: flex-end;
        margin-top: 24px;
      }

      > div:nth-of-type(4) {
        flex-direction: column;
        margin-top: 12px;

        > span {
          font-size: 1.25rem;
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    > section:nth-of-type(2) {
      > article {
        > div:nth-of-type(2) {
          flex-direction: column;

          > button {
            margin-top: 16px;
          }
        }
      }
    }
  }
`;

export const Flavours = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`;

export const VapeImageContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 550px;

  .carousel {
    max-width: 400px;
    max-height: 550px;

    .slider,
    .slider-wrapper {
      max-width: 400px;
      max-height: 550px;
    }
    .slide {
      background-color: #d0b9d290;

      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }

    .thumbs {
      /* display: flex;
      align-items: center;
      justify-content: center; */

      .thumb {
        border: 1px solid #fff;

        > img {
          width: 80px !important;
          height: 80px !important;
        }
        + .thumb {
          margin-left: 2px;
        }
      }
    }
  }
`;

export const VapeInfoContainer = styled.section`
  position: relative;
  display: flex;
  align-items: flex-start;

  width: 100%;
  margin-left: 40px;

  > section:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex: 1;

    > h1 {
      font-size: 2.5rem;
      color: #f5f5f5;
      font-weight: bold;
    }

    > strong {
      margin-top: 8px;
      font-size: 1.5rem;
      color: #00c763;
      font-weight: bold;
    }

    > p {
      font-size: 1.2rem;
      color: #ffffff;
      text-align: left;
      line-height: 1.5;
      width: 100%;
      margin-top: 12px;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-top: 24px;

      > span {
        margin-bottom: 8px;
        color: #ffffff;
        font-size: 1.25rem;
      }

      + div {
        margin-top: 24px;
      }
    }
  }

  > section:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 450px;

    > div {
      display: flex;
    }

    > div:nth-of-type(1) {
      position: relative;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      > svg {
        position: absolute;
        top: 0;
        opacity: 0.5;
        z-index: -2;
        transform: translateY(-45%);
      }

      > span {
        color: #f5f5f5;
        font-size: 1.1rem;
        font-weight: bold;
        text-decoration: line-through;
      }

      > div {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        > strong {
          color: #00c763;
          font-weight: bold;
        }

        > strong:nth-of-type(1) {
          font-size: 2rem;
          margin-bottom: 0.3rem;
        }

        > strong:nth-of-type(2) {
          margin-left: 8px;
          font-size: 3.3rem;
        }
      }
    }

    > article {
      border-radius: 8px;
      width: 125px;
      text-align: center;

      font-weight: bold !important;
      color: #fff;
      background-color: #f04630;
      padding: 4px;
      font-size: 1rem;

      margin-top: 8px;

      &.warn {
        background-color: #f47b38;
      }
    }

    > strong {
      color: #ffffff;
      font-size: 1.1rem;
      text-align: right;
      margin-top: 8px;
      transition: opacity 0.25s ease;
      opacity: 1;

      &.hidden {
        opacity: 0;
      }
    }

    > div:nth-of-type(2) {
      align-items: center;
      margin-top: 60px;
      > div {
        height: 60px;
        margin-right: 12px;
      }
    }

    > div:nth-of-type(3) {
      justify-content: flex-end;
      margin-top: 24px;
    }

    > span {
      margin-top: 6px;
      text-align: right;
      font-size: 1rem;
    }

    > div:nth-of-type(4) {
      flex-direction: column;
      margin-top: 12px;

      > span {
        font-size: 1.25rem;
        text-align: left;
      }
    }
  }

  @media (max-width: 1000px) {
    > section:nth-of-type(2) {
      > div:nth-of-type(2) {
        flex-direction: column;

        > button {
          margin-top: 16px;
        }
      }
    }
  }
`;
