import React from 'react';

import { Container } from './styles';

interface Props {
  status: number;
}

export const statusList = [
  'Pedido recebido',
  'Pagamento Aprovado',
  'Separação em estoque',
  'Em transporte',
  'Pedido entregue!',
  'Pedido Recusado!',
  'Pedido em andamento',
];

const OrderStatus: React.FC<Props> = ({ status }) => {
  return (
    <Container status={status} len={statusList.length}>
      {statusList.slice(0, 5).map((item) => (
        <div key={item}>
          <article />
          <div />
          <strong>{item}</strong>
        </div>
      ))}
    </Container>
  );
};

export default OrderStatus;
