import React, { useState, useCallback, useEffect } from 'react';

import { ReactComponent as ArrowLeftSVG } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/icons/arrowRight.svg';

import {
  Container,
  ButtonBack,
  ButtonNext,
  BannerImg,
  SliderContainer,
} from './styles';

interface SliderImgProps {
  img: string;
}

export const SliderBanner: React.FC<SliderImgProps> = ({ img }) => {
  return <BannerImg src={img} />;
};
// export const SliderBanner: React.FC<SliderImgProps> = ({ img }) => {
//   return <BannerImg src={img} />;
// };

interface BannerCarouselProps {
  slidesPerPage?: number;
  totalSlides: number;
  timer?: number;
  maxHeight?: string;
}

const BannerCarousel: React.FC<BannerCarouselProps> = ({
  totalSlides,
  children,
  slidesPerPage = 1,
  timer,
  maxHeight = '633px',
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const [timerEffect, setTimerEffect] = useState<NodeJS.Timeout | undefined>();

  const handleNextSlide = useCallback(() => {
    if (slidesPerPage === totalSlides) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }

    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    const lastSlide = Math.ceil(totalSlides / slidesPerPage);
    if (currentSlide === lastSlide) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }, [currentSlide, timerEffect]);

  const handlePreviousSlide = useCallback(() => {
    if (slidesPerPage === totalSlides) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }

    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    if (currentSlide === 1) {
      setCurrentSlide(Math.ceil(totalSlides / slidesPerPage));
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }, [currentSlide, timerEffect]);

  useEffect(() => {
    if (timer) {
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }
  }, []);

  return (
    <Container maxHeight={maxHeight}>
      <SliderContainer currentSlide={currentSlide}>{children}</SliderContainer>

      <ButtonBack onClick={() => handlePreviousSlide()}>
        <ArrowLeftSVG />
      </ButtonBack>

      <ButtonNext onClick={() => handleNextSlide()}>
        <ArrowRightSVG />
      </ButtonNext>
    </Container>
  );
};

export default BannerCarousel;
