import React, { useEffect, useState } from 'react';

import { swalFire } from 'services/swal';

import { CartItem } from 'hooks/Cart';
import CartProduct from 'components/Cart/CartItem';

import GreenButton from 'components/Button/GreenButton';

import GenericModal from 'components/Modal/Generic';

import StarsRate from './StarsRate';
import { Content } from './styles';

interface Rate {
  rate: number;
  title?: string;
  content?: string;
}

export type RespRateModal = (
  isConfirmed?: boolean,
  rate?: Rate,
) => void | Promise<void>;

export interface RateModalProps {
  isShown: boolean;
  product?: CartItem;
  handleResponse?: RespRateModal;
}

const ModalRateProduct: React.FC<RateModalProps> = ({
  isShown,
  handleResponse,
  product,
}) => {
  const [form, setForm] = useState<Rate>({
    rate: 5,
  });

  const handleSubmit = async (bool?: boolean): Promise<void> => {
    if (!handleResponse) return;
    if (!bool) {
      handleResponse(false);
      return;
    }

    if (!form.title) {
      swalFire({
        title: 'Aviso!',
        text: 'Preencha o campo do título da avaliação.',
        icon: 'warning',
      });
      return;
    }
    if (!form.content) {
      swalFire({
        title: 'Aviso!',
        text: 'Preencha o campo de depoimento.',
        icon: 'warning',
      });
      return;
    }

    if (!form.rate) {
      swalFire({
        title: 'Aviso!',
        text: 'Selecione uma estrela com sua avaliação.',
        icon: 'warning',
      });
      return;
    }

    handleResponse(true, form);
  };

  useEffect(() => {
    if (!isShown) {
      setForm({
        rate: 5,
      });
    }
    // eslint-disable-next-line
  }, [isShown]);

  return (
    <GenericModal
      isShown={isShown}
      returnTrigger={(bool) => handleSubmit(bool)}
      disableClickEvent
      disableMinSize
    >
      <Content>
        <header>
          <strong>Avaliar produto</strong>

          <span>Queremos saber o que você achou da sua compra!</span>
        </header>

        <main>
          <div>
            <div>
              <strong>Produto avaliado</strong>
              {product && <CartProduct product={product} small />}
            </div>

            <div>
              <strong>Sua nota</strong>
              <StarsRate
                rate={form.rate}
                onChange={(newRate) => {
                  setForm({ ...form, rate: newRate });
                }}
              />
            </div>
          </div>

          <div>
            <strong>Título do depoimento</strong>
            <input
              onChange={({ target }) => {
                setForm({ ...form, title: target.value });
              }}
              value={form?.title || ''}
            />

            <strong>Deixe o seu depoimento</strong>
            <div>
              <textarea
                onChange={({ target }) => {
                  if (target.value && target.value.length > 175) {
                    setForm(form);
                    return;
                  }

                  setForm({ ...form, content: target.value });
                }}
                value={form?.content || ''}
              />

              <span>{form.content?.length || 0}/175 caracteres</span>
            </div>
          </div>
        </main>

        <GreenButton onClick={() => handleSubmit(true)} text="Avaliar" />
      </Content>
    </GenericModal>
  );
};

export default ModalRateProduct;
