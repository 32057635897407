import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { CupomState } from 'components/DiscountCupom';
import { ReactComponent as ResumoSVG } from 'assets/icons/Resumo.svg';
import { ReactComponent as PagamentoSVG } from 'assets/icons/Pagamento.svg';
import { ReactComponent as EntregaSVG } from 'assets/icons/Entrega.svg';
import {
  currencyBRL,
  formatCEP,
  formatContact,
  currencySpread,
} from 'utils/format';

import { checkPromo, processProducts } from 'utils/promo';

import WppHelpButton from 'components/WppHelpButton';
import CartProduct from 'components/Cart/CartItem';
import { CartItem } from 'hooks/Cart';
import Payment, { paymentTypes } from './Payment';

import {
  Container,
  PushLink,
  OrderContainer,
  Card,
  MainDelivery,
  ProductContainer,
  MainPayment,
  OrderObs,
} from './styles';

interface Shipment {
  type: string;
  deliveryTime: number;
  price: number;
  isFree?: boolean;
}

interface Address {
  name: string;
  street: string;
  number: string;
  info: string;
  district: string;
  cep: string;
  state: string;
  city: string;
  contact: string;
}

interface Delivery {
  address: Address;
  shipment: Shipment;
}

interface PaymentProps {
  promoNicFree?: { amount: number; price: number };
  promoNicSalt?: { amount: number; price: number };
  cupom?: CupomState;
  delivery: number;
  isFree?: boolean;
  method: any;
  discount10?: number;
  subTotal: number;
  total: number;
}

export interface Order {
  id: number;
  ticket: string;
  products: CartItem[];
  delivery: Delivery;
  payment: PaymentProps;
  obs?: string;
}

interface Props {
  order?: Order;
}

const OrderInfo: React.FC<Props> = ({ order }) => {
  const [freeProducts, setFreeProducts] = useState<any | undefined>();

  const history = useHistory();

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    if (!order) {
      history.push('/profile');
    } else if (order.products && order.products.length > 0) {
      const productsNicFree: any[] = [];
      const productsNicSalt: any[] = [];

      order.products.forEach((item) => {
        if (item.type !== 'juice' || !item.isOfficial || !item.nicType) return;
        if (item.nicType === 1) {
          productsNicFree.push(item);
        } else if (item.nicType === 2) {
          productsNicSalt.push(item);
        }
      });

      const productNicFree: any = processProducts(productsNicFree);
      const productNicSalt: any = processProducts(productsNicSalt, 2);

      const niceFreePromo = checkPromo(productNicFree);
      const niceSaltPromo = checkPromo(productNicSalt);

      // setCartPromo(result);
      setFreeProducts({
        nicFree: niceFreePromo.products,
        nicSalt: niceSaltPromo.products,
      });
    }
  }, []);

  return (
    <Container>
      <h1>Pedido {order ? order.ticket : ''}</h1>
      <PushLink onClick={() => history.push('/profile')}>
        Voltar aos meus pedidos
      </PushLink>

      {order && (
        <OrderContainer>
          <Card padding="0">
            <header>
              <ResumoSVG />
              <strong>Produtos</strong>
            </header>
            <main>
              {order.products.map((product) => {
                let productPromoQtt = 0;

                if (
                  product.type === 'juice' &&
                  product.isOfficial &&
                  freeProducts
                ) {
                  if (product?.nicType === 1) {
                    productPromoQtt = freeProducts.nicFree[product.id] || 0;
                  } else if (product?.nicType === 2) {
                    productPromoQtt = freeProducts.nicSalt[product.id] || 0;
                  }
                }

                return (
                  <ProductContainer key={product.id}>
                    <CartProduct
                      product={product}
                      small
                      showQtt
                      productPromoQtt={productPromoQtt}
                    />

                    <div>
                      <strong>
                        {currencyBRL(
                          product.price * product.quantity,
                          true,
                          true,
                        )}
                      </strong>
                    </div>
                  </ProductContainer>
                );
              })}
            </main>
          </Card>

          <Card>
            <header>
              <EntregaSVG />
              <strong>Entrega</strong>
            </header>

            <MainDelivery>
              {order.obs ? (
                <OrderObs>
                  <strong>Observação do pedido:</strong>
                  <p>{order.obs}</p>
                </OrderObs>
              ) : (
                ''
              )}

              <div>
                <strong>{order.delivery.address.name}</strong>

                <span>
                  {order.delivery.address.street},&nbsp;
                  {order.delivery.address.number}
                </span>

                <span>{order.delivery.address.info || 'Sem complemento'}</span>

                <span>{order.delivery.address.district}</span>

                <span>
                  {formatCEP(order.delivery.address.cep)}&nbsp;|&nbsp;
                  {order.delivery.address.city}&nbsp;-&nbsp;
                  {order.delivery.address.state}
                </span>

                <span>
                  {order.delivery.address.contact
                    ? formatContact(order.delivery.address.contact)
                    : 'Sem contato'}
                </span>
              </div>

              <div>
                <span>Prazo para postagem: 2 dias úteis</span>

                <div>
                  {/* <span>{order.delivery.shipment.type}</span> */}
                  <span>{order.delivery.shipment.deliveryTime}&nbsp;</span>
                  <span>
                    {order.delivery.shipment.isFree
                      ? 'Frete grátis'
                      : currencyBRL(order.delivery.shipment.price)}
                  </span>
                </div>
              </div>
            </MainDelivery>
          </Card>

          <Card>
            <header>
              <PagamentoSVG />
              <strong>Valores e pagamento</strong>
            </header>

            <MainPayment>
              <div>
                <div>
                  <span>Produtos</span>
                  <span>{currencyBRL(order.payment.subTotal)}</span>
                </div>

                <div>
                  <span>Frete</span>
                  <span>
                    {order.delivery.shipment.isFree
                      ? 'Frete grátis'
                      : currencyBRL(order.payment.delivery)}
                  </span>
                </div>

                {order.payment.promoNicFree &&
                  order.payment.promoNicFree.price > 0 && (
                    <div>
                      <span>Desconto Freebase grátis</span>
                      <span className="crossLine">
                        {currencyBRL(
                          order.payment.promoNicFree.price,
                          true,
                          true,
                        )}
                      </span>
                    </div>
                  )}

                {order.payment.promoNicSalt &&
                  order.payment.promoNicSalt.price > 0 && (
                    <div>
                      <span>Desconto Nic Salt grátis</span>
                      <span className="crossLine">
                        {currencyBRL(
                          order.payment.promoNicSalt.price,
                          true,
                          true,
                        )}
                      </span>
                    </div>
                  )}

                {order &&
                order?.payment?.cupom?.code &&
                order?.payment?.cupom?.discountVal ? (
                  <div>
                    <span>Desconto {order.payment.cupom.code}</span>
                    <span className="crossLine">
                      {currencyBRL(order.payment.cupom.discountVal)}
                    </span>
                  </div>
                ) : (
                  ''
                )}

                {!paymentTypes.CC.includes(order.payment.method.type) ? (
                  <div>
                    <span>Desconto {order.payment.method.type}</span>
                    <span className="crossLine">
                      {currencyBRL(order.payment?.discount10 || 0)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <Payment
                showPaymentInfo
                total={order.payment.total}
                payment={order.payment.method}
              />
            </MainPayment>
          </Card>
        </OrderContainer>
      )}

      <WppHelpButton />
    </Container>
  );
};

export default OrderInfo;
