import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  border: 1px solid #707070;

  /* padding: 32px; */
  width: 100%;
  background-color: #c7e22b;

  > div {
    width: 100%;
    display: flex;
    flex: 1;

    &:nth-of-type(1) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin: 32px 0 32px 32px;

      > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-end;
        max-width: 799px;
        width: 100%;

        > div {
          background-color: #ffffff90;
          border: 1px solid #ffffff80;
          height: 200px;
          width: 200px;
        }
      }
    }

    &:nth-of-type(2) {
      align-items: center;
      justify-content: center;
      > span {
        width: 90%;
        height: 90%;
        > span {
          width: 100%;
          height: 100%;
        }
      }
    }

    /* &:nth-of-type(2) {
      display: flex;
      flex-direction: column;
    } */
  }

  @media (max-width: 1300px) {
    flex-direction: column;

    > div {
      &:nth-of-type(1) {
        justify-content: center;
        margin: 32px auto;
        > div {
          justify-content: center;
        }
      }

      &:nth-of-type(2) {
        min-height: 400px;
      }
    }
  }

  @media (max-width: 850px) {
    flex-direction: column;

    > div {
      &:nth-of-type(1) {
        justify-content: center;
        margin: 32px auto;

        > div {
          justify-content: center;

          max-width: 400px;
        }
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;

    > div {
      &:nth-of-type(1) {
        justify-content: center;
        margin: 32px auto;

        > div {
          justify-content: center;

          max-width: 200px;
        }
      }
    }
  }
`;

export const ProductBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    > div:nth-of-type(1) {
      flex: 1;
      max-height: calc(45% - 25px);
      /* max-height: calc(50% - 25px); */
    }

    > div:nth-of-type(2) {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;

      > * {
        padding-left: 32px;
      }

      > div {
        display: flex;
        flex-direction: column;
        width: 100%;

        background: #271233;

        padding: 8px 8px 8px 32px;

        > article {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 0.8rem;

          > svg {
            width: 110px;
            height: 25px;
          }

          > span {
            width: unset;
            margin: 0 0 0 4px !important;
          }
        }
      }

      > p {
        color: #261131;
        font-size: 1.45rem;
        text-align: left;
        line-height: 1.45;

        margin-top: 8px;
        max-width: 60%;
        width: 100%;
      }

      > span {
        font-size: 1.5rem;
        text-align: right;

        max-width: 60%;
        width: 100%;
        color: #271233;
        font-weight: bold !important;
        font-size: 1.5rem;

        margin-top: 6px;
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    > div:nth-of-type(1) {
      > div:nth-of-type(1) {
        max-height: 0;
      }

      > div:nth-of-type(2) {
        > p,
        > span {
          max-width: unset;
          padding: 0 16px;
        }
      }
    }
  }
`;

export const ProductName = styled.strong`
  max-height: 50px;
  color: #f5f5f5;
  font-size: 1.5rem;

  cursor: pointer;
  transition: filter 0.25s ease;
  &:hover {
    filter: brightness(75%);
  }
`;

interface ProductBannerProps {
  img: string;
}

export const FeedImg = styled.div<ProductBannerProps>`
  position: absolute;
  width: 400px;
  height: 400px;

  top: calc(50% - 200px);
  right: 0;

  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  cursor: pointer;
  transition: filter 0.25s ease;

  &:hover {
    filter: brightness(75%);
  }

  @media (max-width: 900px) {
    order: -1;
    position: unset;
    width: 300px;
    height: 300px;
  }
`;

interface InstaPostProps {
  baseImgUrl?: string;
  imgUrl: string;
}

export const InstaPost = styled.div<InstaPostProps>`
  cursor: pointer;
  ${({ imgUrl, baseImgUrl }) =>
    `background-image: url(${baseImgUrl || ''}${imgUrl});`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  transition: filter 0.25s ease;

  &:hover {
    filter: brightness(75%);
  }
`;
