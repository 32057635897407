import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiX } from 'react-icons/fi';

import GreenButton from 'components/Button/GreenButton';
import CardItems from 'components/Cart/CartItem';

import { ReactComponent as CartSVG } from 'assets/icons/cesta.svg';

import { useNotification } from 'hooks/notification';

import { ProductNotify } from '../DTO';
import { Container } from './styles';

interface CartNotificationProps {
  product: ProductNotify;
  idNotify: string;
}

const CartNotification: React.FC<CartNotificationProps> = ({
  product,
  idNotify,
}) => {
  const history = useHistory();
  const { removeNotification } = useNotification();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeNotification(product.id);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [removeNotification]);

  return (
    <Container>
      <div />

      <div>
        <h3>Adicionado!</h3>

        <CardItems product={product} showQtt />
      </div>

      <div>
        <GreenButton
          text="Continuar comprando"
          onClick={() => {
            removeNotification(idNotify);
          }}
          outline
          customColor={1}
        />
        <GreenButton
          text="Finalizar compra"
          onClick={() => {
            removeNotification(idNotify);
            history.push('/cart');
          }}
          icon={<CartSVG />}
        />
      </div>

      <FiX onClick={() => removeNotification(product.id)} />
    </Container>
  );
};

export default CartNotification;
