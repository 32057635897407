import React from 'react';

// eslint-disable-next-line
import { default as NumberFormat } from 'react-number-format';

interface InputProps {
  onChangeInput(value: string): void;
  currentValue?: string | number;
}

const WhatsApp: React.FC<InputProps> = ({
  onChangeInput,
  currentValue,
}: InputProps) => {
  return (
    <NumberFormat
      inputMode="numeric"
      displayType="input"
      format="(##) #.####-####"
      mask=" "
      value={currentValue || ''}
      onValueChange={(values) => {
        onChangeInput(values.value);
      }}
    />
  );
};

export default WhatsApp;
