import React, { useMemo } from 'react';

import { ReactComponent as Star1SVG } from 'assets/icons/StarsRate/1.svg';
import { ReactComponent as Star2SVG } from 'assets/icons/StarsRate/2.svg';
import { ReactComponent as Star3SVG } from 'assets/icons/StarsRate/3.svg';
import { ReactComponent as Star4SVG } from 'assets/icons/StarsRate/4.svg';
import { ReactComponent as Star5SVG } from 'assets/icons/StarsRate/5.svg';
import { Container } from './styles';

interface Props {
  rate: number;
  onChange: (rate: number) => void;
}

const StarsRate: React.FC<Props> = ({ rate, onChange }) => {
  const class1 = useMemo(() => (rate === 1 ? 'current' : ''), [rate]);
  const class2 = useMemo(() => (rate === 2 ? 'current' : ''), [rate]);
  const class3 = useMemo(() => (rate === 3 ? 'current' : ''), [rate]);
  const class4 = useMemo(() => (rate === 4 ? 'current' : ''), [rate]);
  const class5 = useMemo(() => (rate === 5 ? 'current' : ''), [rate]);

  return (
    <Container>
      <Star1SVG className={class1} onClick={() => onChange(1)} />
      <Star2SVG className={class2} onClick={() => onChange(2)} />
      <Star3SVG className={class3} onClick={() => onChange(3)} />
      <Star4SVG className={class4} onClick={() => onChange(4)} />
      <Star5SVG className={class5} onClick={() => onChange(5)} />
    </Container>
  );
};

export default StarsRate;
