import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > span {
    color: #f5f5f5;
  }
`;

export const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 17px;
  max-width: 17px;
  min-height: 17px;
  max-height: 17px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #f5f5f5;

  margin-right: 8px;

  > span.circle {
    cursor: pointer;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: transparent;
    border-radius: 15px;
    transition: background-color 0.25s ease;
  }

  > input {
    opacity: 0;
    width: 0;
    height: 0;
    margin-right: 8px;

    &:checked + .circle {
      background-color: #00c763;
    }
  }
`;

export const Text = styled.span`
  cursor: pointer;
  color: #f5f5f5;
  font-size: 1rem;
`;
