import React from 'react';

import { useHistory } from 'react-router-dom';

import { ReactComponent as GoldSvg } from 'assets/linhas/Gold.svg';
import { ReactComponent as SilverSvg } from 'assets/linhas/Silver.svg';
import { ReactComponent as BronzeSvg } from 'assets/linhas/Bronze.svg';
import { ReactComponent as CombosSvg } from 'assets/linhas/Combos.svg';
import { ReactComponent as SaltSvg } from 'assets/linhas/Salt.svg';
import { ReactComponent as ImportadosSvg } from 'assets/linhas/Importados.svg';
import { ReactComponent as AtabacadosSvg } from 'assets/linhas/Atabacados.svg';

import { Container } from './styles';

const arrIcons = [
  { icon: <GoldSvg />, text: 'Linha Gold' },
  { icon: <SilverSvg />, text: 'Linha Silver' },
  { icon: <BronzeSvg />, text: 'Linha Bronze' },
  { icon: <SaltSvg />, text: 'Linha Salt' },
  { icon: <ImportadosSvg />, text: 'Importados' },
  { icon: <AtabacadosSvg />, text: 'Linha Atabacados' },
  { icon: <CombosSvg />, text: 'Combos' },
];

interface DropdownItemProps {
  idx: number;
  path?: string;
  closeMenu: () => void;
  comingSoon?: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = ({
  idx,
  path,
  closeMenu,
  comingSoon,
}) => {
  const history = useHistory();
  return (
    <Container
      onClick={() => {
        history.push(path || '');
        closeMenu();
      }}
      comingSoon={comingSoon}
    >
      {arrIcons[idx - 1].icon}
    </Container>
  );
};

export default DropdownItem;
