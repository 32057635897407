import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import ModalAgeCheck, { AgeCheckProps } from 'components/Modal/AgeCheckModal';

interface PropsContext {
  isLoading: boolean;
}

const ProductPropsContext = createContext<PropsContext>({} as PropsContext);

interface Check {
  remember?: boolean;
  lastCheck?: string;
}

const AgeCheckProvider: React.FC = ({ children }) => {
  const checkDate = (date?: Date): boolean => {
    if (!date) return false;
    const now = new Date();
    const nowYY = now.getFullYear();
    const nowMM = now.getMonth() + 1;
    const nowDD = now.getDate();

    const nowBegin = new Date(`${nowYY}-${nowMM}-${nowDD} 00:01`);
    const nowEnd = new Date(`${nowYY}-${nowMM}-${nowDD} 23:59`);

    const dateCheckTime = date.getTime();
    const nowBeginTime = nowBegin.getTime();
    const nowEndTime = nowEnd.getTime();
    // SANITY CHECK
    // console.log('LastIF', nowBeginTime, dateCheckTime, nowEndTime);
    // console.log(`LastIF2, ${nowBegin} \n ${date} \n ${nowEnd}`);
    if (dateCheckTime > nowBeginTime && dateCheckTime < nowEndTime) {
      return true;
    }
    return false;
  };
  const [isLoading, setIsLoading] = useState(true);

  const [check, setCheck] = useState<Check>(() => {
    const storage = localStorage.getItem('@Constellation:AgeCheck');
    if (!storage) return {};

    const parsedStorage = JSON.parse(storage);
    if (!parsedStorage) return {};

    // console.log('parsedStorage.lastCheck', parsedStorage.lastCheck);
    if (parsedStorage.lastCheck) {
      const dateCheck = new Date(parsedStorage.lastCheck);

      if (dateCheck && checkDate(dateCheck)) {
        setIsLoading(false);
        return {
          lastCheck: dateCheck.toJSON(),
        };
      }
    }
    return {};
  });

  const [modalProps, setModalProps] = useState<AgeCheckProps>({
    isShown: false,
    handleSubmit: () => {
      const newAgeCheck = {
        lastCheck: new Date().toJSON(),
      };
      localStorage.setItem(
        '@Constellation:AgeCheck',
        JSON.stringify(newAgeCheck),
      );
      setCheck(newAgeCheck);
      setModalProps({ ...modalProps, isShown: false });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (check.lastCheck && checkDate(new Date(check.lastCheck))) {
      setIsLoading(false);
      return;
    }
    if (!check.lastCheck) {
      setIsLoading(true);
      setModalProps({ ...modalProps, isShown: true });
    }
  }, [check]);

  return (
    <ProductPropsContext.Provider value={{ isLoading }}>
      <ModalAgeCheck {...modalProps} />
      {/* {window.innerWidth > 1000 && <ModalAgeCheck {...modalProps} />} */}
      {children}
    </ProductPropsContext.Provider>
  );
};

function useAgeCheck(): PropsContext {
  const context = useContext(ProductPropsContext);

  if (!context) {
    throw new Error('useAgeCheck must be used within an AgeCheckProvider');
  }

  return context;
}

export { AgeCheckProvider, useAgeCheck };
