import React, { useEffect, useState } from 'react';

import { useProductProps } from 'hooks/productProps';

import { ProductNotify } from '../DTO';
import { Container, ProductDescription, ProductImage } from './styles';

interface CartImageProps {
  img: string;
  small?: boolean;
}

const CartImageMemo = React.memo(
  ({ img, small }: CartImageProps) => {
    return (
      <ProductImage small={small}>
        <img src={img} alt="Produto Juice" />
        <figcaption>Produto Juice</figcaption>
      </ProductImage>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.img !== nextProps.img) return false;
    return true;
  },
);

interface CardItemsProps {
  product: ProductNotify;
  showQtt?: boolean;
  small?: boolean;
  productPromoQtt?: number;
}

// const CartItem: React.FC<CardItemsProps> = ({ product, showQtt, small }) => {
//   const { getProps } = useProductProps();

//   // const thisProduct = React.useMemo(() => product, [product]);
//   // const thisSmall = React.useMemo(() => small, [small]);

//   return (
//     <Container small={small}>
//       <CartImageMemo img={product?.img} small={small} />

//       <ProductDescription>
//         <strong>
//           {product.name}&nbsp;
//           {showQtt && `(${product.quantity})`}
//         </strong>

//         {product.bottle && (
//           <span>
//             {!small && 'Tamanho '}
//             {(getProps('bottles', [product.bottle])[0] as BottleSize).amount}
//             {(getProps('bottles', [product.bottle])[0] as BottleSize).name}
//           </span>
//         )}

//         {product.nic && (
//           <span>
//             {!small && 'Nicotina '}
//             {(getProps('nic', [product.nic])[0] as Nic).amount}mg/ml
//           </span>
//         )}

//         {product.proportion && (
//           <span>
//             {!small && 'Proporção '}
//             {
//               (getProps('proportion', [product.proportion])[0] as Proportion)
//                 .name
//             }
//           </span>
//         )}

//         {product.mint ? (
//           <span>
//             {!small && 'Menta Adicional '}
//             {product.mint}
//           </span>
//         ) : (
//           ''
//         )}
//       </ProductDescription>
//     </Container>
//   );
// };

const CartItem = React.memo<CardItemsProps>(
  ({ small, product, showQtt, productPromoQtt }) => {
    const { props } = useProductProps();

    const [options, setOptions] = useState<any>();

    useEffect(() => {
      if (
        !options &&
        props?.bottles &&
        props?.colors &&
        props?.nic &&
        props?.proportion
      ) {
        setOptions({
          bottle: props.bottles.filter((item) => item.id === product.bottle)[0],
          nic: props.nic.filter((item) => item.id === product.nic)[0],
          proportion: props.proportion.filter(
            (item) => item.id === product.proportion,
          )[0],
          color: props.colors.filter((item) => item.id === product.color)[0],
        });
      }
    }, [options, props, product]);

    // console.log(product);
    return (
      <Container small={small}>
        <CartImageMemo img={product.img} small={small} />

        <ProductDescription>
          <strong>
            {product.name}&nbsp;
            {showQtt && `(${product.quantity})`}
            {productPromoQtt && productPromoQtt > 0 ? (
              <span>{productPromoQtt} grátis</span>
            ) : (
              ''
            )}
          </strong>

          <div>
            {product.bottle && options?.bottle ? (
              <span>
                {!small && 'Tamanho '}
                {options.bottle.amount}
                {options.bottle.name}
              </span>
            ) : (
              ''
            )}

            {product.nic && options?.nic ? (
              <span>
                {!small && 'Nicotina '}
                {`${options.nic.amount}mg/ml`}
              </span>
            ) : (
              ''
            )}

            {product.proportion && options?.proportion ? (
              <span>
                {!small && 'Proporção '}
                {options.proportion.name}
              </span>
            ) : (
              ''
            )}

            {product.mint ? (
              <span>
                {/* {!small && 'Menta Adicional '} */}
                {product.mint === 1 && 'Menta suave'}
                {product.mint === 2 && 'Menta moderada'}
                {product.mint === 3 && 'Menta intensa'}
              </span>
            ) : (
              ''
            )}

            {product.color && options?.color ? (
              <span>
                {!small && 'Cor '}
                {options.color && options.color.name}
              </span>
            ) : (
              ''
            )}
          </div>
        </ProductDescription>
      </Container>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.showQtt !== nextProps.showQtt) return false;
    if (prevProps.productPromoQtt !== nextProps.productPromoQtt) return false;
    if (prevProps.small !== nextProps.small) return false;

    let equal = true;
    Object.keys(prevProps.product).forEach((item): void => {
      const key = item as keyof ProductNotify;
      if (prevProps.product[key] !== nextProps.product[key]) equal = false;
    });

    return equal;
  },
);

export default CartItem;
