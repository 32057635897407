import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MdContentCopy } from 'react-icons/md';

import { api, handleErrors, handleRespReturn } from 'services/api';
import { swalFire } from 'services/swal';

import { currencyBRL } from 'utils/format';
import { copyToClipboard } from 'utils/copyToClipboard';

import { CartItem, useCart } from 'hooks/Cart';
import { ReactComponent as ResumoSVG } from 'assets/icons/Resumo.svg';

import CartProduct from 'components/Cart/CartItem';
import GreenButton from 'components/Button/GreenButton';

import { Order as OrderInfoObj } from './OrderInfo';
import OrderStatus, { statusList } from './OrderStatus';
import RateModal, { RateModalProps, RespRateModal } from './RateProduct';

import {
  Container,
  CardOrder,
  OrderInfo,
  ProductTable,
  TrackContainer,
  TrackLink,
} from './styles';

interface Orders {
  id: number;
  ticket: string;
  status: number;
  products: (CartItem & { needRate?: boolean })[];
  trackCode?: string;
  hash?: string;
}

interface OrderObj {
  // open?: Orders[];
  // close?: Orders[];
  done?: Orders[];
  canceled?: Orders[];
  inProgress?: Orders[];
}

interface OrderProps {
  enableReq?: boolean;
  handleOrderInfo: (order: OrderInfoObj) => void;
}

type OrderStatus = keyof OrderObj;

const Order: React.FC<OrderProps> = ({ handleOrderInfo, enableReq }) => {
  const history = useHistory();
  const { importOrder } = useCart();

  const [orderStatus, setOrderStatus] = useState<OrderStatus>('inProgress');
  const [orders, setOrders] = useState<OrderObj>({});

  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingRate, setIsLoadingRate] = useState(false);

  const [rateProps, setRateProps] = useState<RateModalProps>({
    isShown: false,
  });

  const handleRateProduct = (
    order: Orders,
    product: CartItem & { needRate?: boolean },
    orderKey: keyof OrderObj,
  ): void => {
    if (order.status !== 5) {
      swalFire({
        icon: 'info',
        text: 'Não é possível avaliar produtos que não estejam finalizados.',
      });
      return;
    }

    if (!product.needRate) {
      swalFire({ icon: 'info', text: 'Esse produto já foi avaliado.' });
      return;
    }

    const response: RespRateModal = async (isConfirmed, rate) => {
      if (isConfirmed && rate?.content && rate.title) {
        setIsLoadingRate(true);
        if (isLoadingRate) return;

        try {
          const resp = await api().request({
            url: `/products/rate`,
            method: 'POST',
            data: {
              idOrder: order.id,
              idProduct: product.idProduct,
              title: rate.title,
              content: rate.content,
              rate: rate.rate,
            },
          });
          const isErrored = handleRespReturn(resp);
          setIsLoadingRate(false);
          if (isErrored) return;

          let newThisOrderKey = orders[orderKey];
          if (newThisOrderKey) {
            newThisOrderKey = newThisOrderKey.map((thisOrder) => {
              if (thisOrder.id === order.id) {
                const newProducts = thisOrder.products.map((thisProduct) => {
                  if (thisProduct.id === product.id)
                    return { ...thisProduct, needRate: false };
                  return { ...thisProduct };
                });
                return { ...thisOrder, products: newProducts };
              }

              return { ...thisOrder };
            });
          }

          setOrders({
            ...orders,
            [orderKey]: newThisOrderKey,
          });
        } catch (err) {
          setIsLoadingRate(false);
          handleErrors(err, true);
          return;
        }
      }

      setRateProps({ isShown: false });
    };

    setRateProps({ isShown: true, product, handleResponse: response });
  };

  const handleFilter = async (newOrderStatus: OrderStatus): Promise<void> => {
    if (isLoadingFilter) return;
    setIsLoadingFilter(true);
    const key = newOrderStatus;
    // FixMeLater: ADD some exclude orders
    try {
      const resp = await api().request({
        url: `/user/profile/order?type=${key}`,
        method: 'GET',
      });
      // console.log('RespOrders', resp.data);
      const isErrored = handleRespReturn(resp);
      setIsLoadingFilter(false);
      if (isErrored) return;
      if (orderStatus !== newOrderStatus) setOrderStatus(newOrderStatus);

      let oldOrders: Orders[] = [];
      if (key in orders && orders[key] !== undefined)
        oldOrders = orders[key] as Orders[];

      let newOrderArr = [...oldOrders, ...resp.data.content];

      const newOrderIdx: number[] = [];
      newOrderArr = newOrderArr.filter((item) => {
        if (newOrderIdx.includes(item.id)) {
          return false;
        }
        newOrderIdx.push(item.id);
        return true;
      });
      setOrders({
        ...orders,
        [key]: newOrderArr,
      });
    } catch (err) {
      setIsLoadingFilter(false);
      handleErrors(err, true);
    }
  };

  const handleGetMore = async (): Promise<void> => {
    if (isLoadingMore) return;
    const key = orderStatus;
    let excludeIds = '';
    if (
      orderStatus in orders &&
      Array.isArray(orders[key]) &&
      orders[key]?.length
    ) {
      const query = '&notIncluded=';
      const filteredIds = (orders[key] as Orders[]).map((item) => {
        return item.id;
      });
      excludeIds = `${query}${JSON.stringify(filteredIds)}`;
    }

    setIsLoadingMore(true);
    try {
      const resp = await api().request({
        url: `/user/profile/order?type=${key}${excludeIds}`,
        method: 'GET',
      });
      // console.log('??', resp);
      const isErrored = handleRespReturn(resp);
      setIsLoadingMore(false);
      if (isErrored) return;
      const oldKeyOrder = orders[key] !== undefined ? orders[key] : [];
      let newOrderArr = [...(oldKeyOrder as Orders[]), ...resp.data.content];
      const newOrderIdx: number[] = [];
      newOrderArr = newOrderArr.filter((item) => {
        if (newOrderIdx.includes(item.id)) {
          return false;
        }
        newOrderIdx.push(item.id);
        return true;
      });

      setOrders({
        ...orders,
        [key]: newOrderArr,
      });
    } catch (err) {
      setIsLoadingMore(false);
      handleErrors(err, true);
    }
  };

  const handleOrderDetails = async (order: Orders): Promise<void> => {
    if (isLoadingInfo) return;
    setIsLoadingInfo(true);
    try {
      const resp = await api().get(
        `/user/profile/order/info?ticket=${order.ticket}`,
      );
      setIsLoadingInfo(false);
      const { content } = resp.data;
      const thisOrder = { ...content };

      let thisCupom = thisOrder?.payment?.cupom;

      if (
        thisCupom &&
        thisCupom?.code &&
        thisCupom?.discount &&
        thisCupom?.type
      ) {
        const { discount, type: discountType } = thisCupom;
        let discountVal = 0;

        if (discountType === 'fixed') {
          discountVal = discount;
        } else {
          let subTotal = thisOrder.payment.subTotal;

          if (content.payment?.promoNicFree?.price)
            subTotal -= thisOrder.payment.promoNicFree.price;

          if (content.payment?.promoNicSalt?.price)
            subTotal -= thisOrder.payment.promoNicSalt.price;

          discountVal = subTotal * discount;
        }

        thisCupom = { ...thisCupom, discountVal };
      }

      const paymentType = content.payment.type;
      const payment = { type: paymentType };

      // let total = content.payment.subTotal;
      // const total10 = total * 0.1;
      // if (payment?.type !== 'CC') {
      //   total -= total10;
      // }

      // if (thisCupom && thisCupom?.discountVal) {
      //   total -= thisCupom.discountVal;
      // }

      // total += content.payment.delivery;

      // thisOrder.payment.total = total;
      thisOrder.payment.cupom = thisCupom;
      thisOrder.payment.type = payment;

      handleOrderInfo(thisOrder);
    } catch (err) {
      setIsLoadingInfo(false);
      handleErrors(err);
    }
  };

  const handleImportCart = (order: Orders) => {
    importOrder(order.products);
    history.push('/cart');
  };

  useEffect(() => {
    if (enableReq) handleFilter('inProgress');
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line
  }, [enableReq]);

  const orderList = React.useMemo(() => {
    if (
      !orderStatus ||
      !(orderStatus in orders) ||
      orders[orderStatus] === undefined
    ) {
      return <strong>Buscando pedidos...</strong>;
    }
    if (orders[orderStatus]?.length === 0) {
      return <strong>Nenhum pedido encontrado</strong>;
    }

    return (orders[orderStatus] as Orders[]).map((item) => (
      <CardOrder key={item.id} orderStatus={item.status}>
        <header>
          <ResumoSVG />
          <strong>Pedido {item.ticket}</strong>
          <strong>{statusList[item.status - 1]}</strong>
        </header>

        <main>
          <OrderStatus status={item.status} />

          <ProductTable showRateColumn={item.status === 5}>
            <header>
              <strong>Produto</strong>
              <strong>Preço</strong>
              <strong>Quantidade</strong>
              <strong>Subtotal</strong>
              <strong />
            </header>

            {item.products.map((product) => (
              <div key={product.id}>
                <CartProduct product={product} small />

                <div>
                  <strong>{currencyBRL(product.price, true, true)}</strong>
                </div>

                <div>
                  <strong>{product.quantity}</strong>
                </div>

                <div>
                  <strong>
                    {currencyBRL(product.price * product.quantity, true, true)}
                  </strong>
                </div>

                <div>
                  <button
                    type="button"
                    onClick={() => {
                      handleRateProduct(item, product, orderStatus);
                    }}
                    className={product.needRate ? '' : 'green'}
                  >
                    {product.needRate ? 'Avaliar produto' : 'Produto avaliado'}
                  </button>
                </div>
              </div>
            ))}
          </ProductTable>

          <OrderInfo>
            <div>
              <strong>Código de rastreio</strong>
              <span>
                {item.trackCode ? item.trackCode : 'Sem código de rastreio'}
              </span>
            </div>

            {item.trackCode && (
              <TrackContainer onClick={() => copyToClipboard(item.trackCode)}>
                <MdContentCopy />
                <TrackLink>Copiar código</TrackLink>
              </TrackContainer>
            )}

            <div>
              <button
                type="button"
                onClick={() => {
                  handleImportCart(item);
                }}
              >
                Importar produtos
              </button>

              {orderStatus === 'inProgress' &&
              item.status === 7 &&
              item?.hash ? (
                <button
                  type="button"
                  onClick={() => {
                    history.push(`/checkout/${item.hash}`);
                  }}
                >
                  Finalizar pedido
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    if (isLoadingInfo) return;
                    handleOrderDetails(item);
                  }}
                >
                  {isLoadingInfo ? 'Carregando...' : 'Detalhes do pedido'}
                </button>
              )}
            </div>
          </OrderInfo>
        </main>
      </CardOrder>
    ));
  }, [
    orders,
    orderStatus,
    handleRateProduct,
    handleOrderDetails,
    history,
    handleImportCart,
  ]);

  return (
    <Container>
      <RateModal {...rateProps} />

      <div>
        <GreenButton
          isLoading={isLoadingFilter}
          onClick={() => handleFilter('inProgress')}
          text="Em andamento"
          outline={orderStatus !== 'inProgress'}
        />
        <GreenButton
          isLoading={isLoadingFilter}
          onClick={() => handleFilter('done')}
          text="Finalizados"
          outline={orderStatus !== 'done'}
        />
        <GreenButton
          isLoading={isLoadingFilter}
          onClick={() => handleFilter('canceled')}
          text="Cancelados"
          customColor={2}
          outline={orderStatus !== 'canceled'}
        />
      </div>

      {orderList}

      <GreenButton
        isLoading={isLoadingMore}
        onClick={handleGetMore}
        text="Ver mais pedidos"
      />
    </Container>
  );
};

export default Order;
