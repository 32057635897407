import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 750px;
  min-height: 575px;

  > strong {
    color: #f5f5f5;
    font-size: 2.25rem;
    margin: 24px 0;
    text-align: center;
  }

  > span {
    color: #58c78f;
    text-align: center;
    font-weight: bold;
    margin-bottom: 32px;
  }

  > input {
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 8px;
    font-size: 0.9rem;
    min-width: 250px;
    margin-bottom: 16px;

    &::placeholder {
      color: #261231;
      font-weight: bold;
    }
  }

  > button {
    min-width: 250px;
    margin: auto 0 16px;
    min-height: 40px;
  }

  @media (max-width: 1000px) {
    min-width: unset;
    min-height: unset;
    padding: 32px 0;
    margin: 0;
    width: 100%;

    > strong {
      margin: 16px 0;
      font-size: 2rem;
    }

    > span {
      margin-bottom: 16px;
    }

    > div {
      width: 100%;

      > div {
        /* flex-direction: column; */
        flex-wrap: wrap;

        > div {
          max-width: unset !important;
          width: 100%;

          input {
            height: 30px;
          }

          + div {
            /* margin-left: 0; */
            width: 100%;
            /* margin-top: 8px; */
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    min-width: unset;
    min-height: unset;
    padding: 32px 16px;
    margin: 0;
    width: 100%;

    > strong {
      margin: 16px 0;
      font-size: 1.75rem;
    }

    > span {
      margin-bottom: 16px;
    }

    > div {
      width: 100%;

      > div {
        flex-direction: column;
        flex-wrap: wrap;

        > div {
          max-width: unset !important;
          width: 100%;

          input {
            height: 30px;
          }

          + div {
            margin-left: 0;
            width: 100%;
            margin-top: 8px;
          }
        }
      }
    }
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 24px;
  width: 100%;
  margin-bottom: 24px;

  > div {
    display: flex;
    align-items: center;

    > div + div {
      margin-left: 16px;
    }

    + div {
      margin-top: 16px;
    }
  }

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;
