import styled, { css } from 'styled-components';

interface ContainerProps {
  maxHeight?: string;
  extraMargin?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: flex-start;
  margin: 0 5%;
  flex: 1;

  > img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /* height: 120px;
    width: 120px;
    margin-top: 32px; */

    width: auto;
    height: 100%;
    max-height: 120px;
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}

    margin-right: 32px;
    margin-top: 32px;
  }

  > div {
    display: flex;
    flex-direction: column;
    /* width: 100%; */

    > a {
      font-size: 1.35rem;
      font-weight: lighter !important;
      letter-spacing: 1.5px;

      text-transform: lowercase;
      margin-bottom: 8px;

      opacity: 0.8;
      transition: filter 0.2s ease;

      &:hover {
        filter: brightness(125%);
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      /* width: 100%; */

      /* max-height: 175px; */
      max-height: calc(285px - 64px - 1.5rem - 8px);

      > a {
        font-size: 1rem;
        font-weight: bold;
        text-transform: lowercase;

        margin: 6px 0;
        ${({ extraMargin }) => extraMargin && 'margin: 6px 58px 6px 0;'}

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  @media (max-width: 1300px) {
    margin: 0;
    min-width: 50%;
    padding: 16px;
    align-items: stretch;
    justify-content: center;

    > img {
      margin-top: 0;
    }

    > div {
      > div {
        flex-wrap: nowrap;
        max-height: unset;
      }
    }
  }

  @media (max-width: 650px) {
    margin: 0;
    min-width: 100%;
    padding: 16px;
    align-items: stretch;
    justify-content: center;

    > img {
      margin-top: 0;
      min-width: 80px;
    }

    > div {
      min-width: 150px;

      > div {
        flex-wrap: nowrap;
        max-height: unset;
      }
    }
  }
`;

export const BackgroundJuices = styled.article`
  height: auto;
  width: 100%;
  max-width: calc(100% / 4 - 1rem);
  margin: 1rem;
`;
