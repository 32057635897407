import React, { useCallback, useEffect, useMemo, useState } from 'react';

// export const Slider: React.FC = () => {};

import { ReactComponent as ArrowLeftSVG } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/icons/arrowRight.svg';

import {
  Container,
  ButtonBack,
  ButtonNext,
  SliderContainer,
  SliderBackgroundImg,
} from './styles';

interface SliderImgProps {
  img: string;
}

export const SliderImg: React.FC<SliderImgProps> = ({ img }) => {
  return <SliderBackgroundImg img={img} />;
};

type ButtonProps = JSX.Element | string;

interface CarouselProps {
  minHeight?: string;
  btnInside?: boolean;
  btnNext?: ButtonProps;
  btnBack?: ButtonProps;
  slidesPerPage?: number;
  totalSlides: number;
  timer?: number;
  fullWidth?: boolean;
  top?: string;
  banner?: boolean;
  onWidth1366?: number;
  onWidth1000?: number;
  useMediaQuery?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  minHeight = '200px',
  btnBack = <ArrowLeftSVG />,
  btnNext = <ArrowRightSVG />,
  btnInside = true,
  slidesPerPage = 1,
  totalSlides,
  timer = undefined,
  fullWidth = false,
  useMediaQuery = false,
  onWidth1366 = 1,
  onWidth1000 = 1,
  top,
  children,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const [timerEffect, setTimerEffect] = useState<NodeJS.Timeout | undefined>();

  const currentSlidersPerPage = useMemo(() => {
    // console.log('Memo?', window.screen.width);
    if (useMediaQuery) {
      if (onWidth1000 && window.screen.width < 1000) {
        return onWidth1000;
      }
      if (onWidth1366 && window.screen.width < 1366) {
        return onWidth1366;
      }
    }
    return slidesPerPage;
  }, [window.screen.width, useMediaQuery, onWidth1000, onWidth1366]);

  const handleNextSlide = useCallback(() => {
    if (currentSlidersPerPage === totalSlides) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }

    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    const lastSlide = Math.ceil(totalSlides / currentSlidersPerPage);
    if (currentSlide === lastSlide) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  }, [currentSlide, timerEffect]);

  const handlePreviousSlide = useCallback(() => {
    if (currentSlidersPerPage === totalSlides) {
      if (timerEffect) clearTimeout(timerEffect);
      return;
    }

    if (timerEffect && timer) {
      clearTimeout(timerEffect);
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }

    if (currentSlide === 1) {
      setCurrentSlide(Math.ceil(totalSlides / currentSlidersPerPage));
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  }, [currentSlide, timerEffect]);

  useEffect(() => {
    if (timer) {
      const newTimer = setInterval(() => handleNextSlide(), timer);
      setTimerEffect(newTimer);
    }
  }, []);

  return (
    <Container minHeight={minHeight}>
      <SliderContainer
        totalSlides={totalSlides}
        slidesPerPage={currentSlidersPerPage}
        currentSlide={currentSlide}
        fullWidth={fullWidth}
        top={top}
      >
        {children}
      </SliderContainer>

      <ButtonBack
        fullWidth={fullWidth}
        inside={btnInside}
        onClick={() => handlePreviousSlide()}
      >
        {btnBack}
      </ButtonBack>

      <ButtonNext
        fullWidth={fullWidth}
        inside={btnInside}
        onClick={() => handleNextSlide()}
      >
        {btnNext}
      </ButtonNext>
    </Container>
  );
};

export default Carousel;
