import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { swalFire } from './swal';

const baseUrl = 'https://constellationsteam.com/api';

const baseLocalUrl = 'http://localhost:60142';

export const cancelToken = axios.CancelToken.source();

export const api = (token?: string): AxiosInstance => {
  const url = window.location.host.includes('localhost')
    ? baseLocalUrl
    : baseUrl;

  let tokenBearer: string | undefined;
  if (token) {
    tokenBearer = `Bearer ${token}`;
  } else {
    const tokenStorage = localStorage.getItem('@Constellation:Token');

    if (!tokenStorage || tokenStorage.length < 1) {
      localStorage.removeItem('@Constellation:Token');
      tokenBearer = undefined;
    } else {
      tokenBearer = `Bearer ${tokenStorage}`;
    }
  }

  return axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: tokenBearer,
    },
  });
};

type Resp =
  | {
      success: true;
      content: any;
      [key: string]: any;
    }
  | {
      success: true;
      exception: true;
      exceptionText: string;
      content?: any;
      [key: string]: any;
    }
  | {
      success: false;
      content?: any;
      errorText: string;
      [key: string]: any;
    };

export const handleRespReturn = (
  resp: AxiosResponse<Resp>,
): boolean | string => {
  // console.log(
  //   'RespReturn',
  //   resp?.config?.url,
  //   resp?.config?.method,
  //   resp?.data,
  // );
  const { success, exceptionText, exception, content } = resp.data;
  if (!success) {
    // console.log('Success False', resp);
    return true;
  }

  if (success && exception) {
    // console.log('Success && Exception', resp);
    return exceptionText;
  }

  return false;
};

export const handleErrors = (err: any, swal?: boolean): void => {
  // console.log('DefRespError');
  // console.log(err);
  // console.log(err.response);

  if (err?.response?.status && err.response.status === 406) {
    // if (err.response.status === 502 || err.response.status === 503) {
    swalFire({
      text: err.response.data.message,
      icon: 'warning',
    });
    return;
  }

  swalFire({
    text: 'Ocorreu um erro, recarregue e tente novamente.',
    icon: 'error',
  });
};
