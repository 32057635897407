import React from 'react';

import { ReactComponent as WppSVG } from '../../assets/socialMedia/wppButton.svg';
import { Container } from './styles';

const WppButton: React.FC = () => {
  return (
    <Container>
      <WppSVG
        onClick={() => {
          window.open(
            'https://api.whatsapp.com/send?phone=5548991501656',
            '_blank',
          );
        }}
      />
      <div />
      <span>Atendimento Whatsapp!</span>
    </Container>
  );
};

export default WppButton;
