import React, { useEffect, useState } from 'react';

import { api, handleErrors, handleRespReturn } from 'services/api';
import { swalFire } from 'services/swal';

import { formatCpfCnpj, formatDate } from 'utils/format';
import Checkbox from 'components/Button/Checkbox';
import FormButton from 'components/Button/FormButton';

import InputWpp from 'components/Input/MaskedInputs/Wpp';
import InputContainer from 'components/Input/Container';
import InputGeneric from 'components/Input/Generic';

import { Container } from './styles';

interface Form {
  name?: string;
  birthDate?: string;
  email?: string;
  udoc?: string;
  uphone?: string;
  news?: boolean;
}

interface Props {
  enableReq?: boolean;
}

const UserData: React.FC<Props> = ({ enableReq }) => {
  const [form, setForm] = useState<Form>({});

  const handleSubmitForm = async (): Promise<void> => {
    if (!form.email || !form.uphone || !form.name) {
      swalFire({
        text: 'Campos e-mail, nome e celular são obrigatórios!',
        icon: 'warning',
      });
      return;
    }

    try {
      const resp = await api().request({
        url: '/user/profile/info',
        method: 'POST',
        data: { ...form },
      });
      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      swalFire({
        text: 'Dados salvos.',
        icon: 'success',
      });
    } catch (err) {
      handleErrors(err, true);
    }
  };

  useEffect(() => {
    let mounted = true;

    const getInfo = async (): Promise<void> => {
      if (!mounted) return;
      try {
        const resp = await api().request({
          url: '/user/profile/info',
          method: 'GET',
        });
        const isErrored = handleRespReturn(resp);
        if (isErrored || !mounted) return;
        setForm({ ...resp.data.content });
      } catch (err) {
        if (!mounted) return;
        handleErrors(err, true);
      }
    };
    // console.log(mounted, enableReq, !!form);

    if (mounted && enableReq) {
      // if (!form && mounted && !disableReq) {
      getInfo();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <div>
        <InputContainer title="E-mail" direction="row">
          <InputGeneric
            type="email"
            onChangeCustom={({ target }) => {
              setForm({ ...form, email: target.value });
            }}
            value={form.email}
          />
        </InputContainer>

        <InputContainer title="CPF/CNPJ" direction="row">
          <InputGeneric
            type="text"
            onChangeCustom={() => {
              setForm({ ...form });
            }}
            readOnly
            disabled
            value={form.udoc ? formatCpfCnpj(form.udoc) : ''}
          />
        </InputContainer>

        <InputContainer title="Celular" direction="row" styleInput>
          <InputWpp
            onChangeInput={(value) => {
              setForm({ ...form, uphone: value });
            }}
            currentValue={form.uphone}
          />
        </InputContainer>

        {/* <FormButton
          maxWidth="250px"
          text="Redefinir minha senha"
          onClick={() => {
            console.log('Redefinir senha');
          }}
        /> */}

        <Checkbox
          checked={form.news}
          onCheckChange={(state) => {
            setForm({ ...form, news: state });
          }}
        >
          Receber novidades por e-mail
        </Checkbox>
      </div>

      <div>
        <InputContainer title="Nome completo" direction="row">
          <InputGeneric
            type="text"
            onChangeCustom={({ target }) => {
              setForm({ ...form, name: target.value });
            }}
            value={form.name}
          />
        </InputContainer>

        <InputContainer title="Data de nascimento" direction="row">
          <InputGeneric
            type="text"
            onChangeCustom={() => {
              setForm({ ...form });
            }}
            value={formatDate(form.birthDate)}
          />
        </InputContainer>

        <FormButton
          maxWidth="250px"
          text="Salvar alterações"
          color={2}
          onClick={handleSubmitForm}
        />
      </div>
    </Container>
  );
};

export default UserData;
