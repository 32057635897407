import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { useFavorites } from 'hooks/Favorites';
import { useAuth } from 'hooks/auth';

import TuboPNG from 'assets/ALUDRA.png';
import VapePNG from 'assets/vape.png';
import { ReactComponent as FavSVG } from 'assets/icons/favoritos.svg';
import { currencyBRL } from 'utils/format';
import IconFlavour from '../Flavours/Sabores';
import Icon from '../Flavours/Essencias';

import {
  DefThumbTypeProps,
  DefThumbProps,
  ExtendDefThumb,
  JuiceData,
  VapeData,
} from '../ProductDTO';

import {
  Container,
  JuiceDescContainer,
  VapeDescContainer,
  ImageContainer,
  ProductCost,
  Title,
  ThumbContainer,
} from './styles';

const JuiceThumb: React.FC<ExtendDefThumb<JuiceData>> = ({
  name,
  price,
  essences,
  img,
  flavours,
  id,
  isOfficial,
  juiceOutOfStock,
  stock,
}) => {
  const history = useHistory();

  return (
    <ThumbContainer
      // onClick={() => history.push(`/product/${id}`)}
      outOfStock={(!isOfficial && !stock) || (isOfficial && juiceOutOfStock)}
    >
      <NavLink to={`/product/${id}`}>
        <ImageContainer>
          <figure>
            <img src={img} alt="Produto Juice" />
            <figcaption>Produto Juice</figcaption>
          </figure>

          <div>
            {essences &&
              essences
                .filter((_, index) => index <= 4)
                .map((item) => (
                  <Icon key={item} idx={item} position="right" size="40px" />
                ))}
          </div>

          {!isOfficial && (
            <>
              {!stock && <article>Sem estoque</article>}
              {stock && stock > 0 && stock <= 10 && (
                <article className="warn">Estoque acabando!</article>
              )}
            </>
          )}
          {isOfficial && juiceOutOfStock && <article>Sem estoque</article>}
        </ImageContainer>

        <JuiceDescContainer>
          <Title>{name}</Title>

          <div>
            <div>
              {flavours &&
                flavours.map((item) => (
                  <IconFlavour
                    key={item}
                    idx={item}
                    position="bottom"
                    size="22px"
                  />
                ))}
            </div>

            <div>
              <span>a partir de</span>
              <ProductCost>{currencyBRL(price)}</ProductCost>
              <strong>10% OFF</strong>
              <span>no depósito</span>
            </div>
          </div>
        </JuiceDescContainer>
      </NavLink>
    </ThumbContainer>
  );
};

const VapeThumb: React.FC<ExtendDefThumb<VapeData>> = ({
  price,
  name,
  priceDiscount,
  img,
  id,
  stock,
}) => {
  return (
    <ThumbContainer
      // onClick={() => history.push(`/product/${id}`)}
      outOfStock={!stock || stock === 0}
    >
      <NavLink to={`/product/${id}`}>
        <ImageContainer>
          <figure>
            <img src={img} alt="Produto Vape" />
            <figcaption>Produto Vape</figcaption>
          </figure>
          {!stock && <article>Sem estoque</article>}
          {stock && stock > 0 && stock <= 10 && (
            <article className="warn">Estoque acabando!</article>
          )}
        </ImageContainer>
        <VapeDescContainer>
          <div>
            <Title>{name}</Title>
          </div>

          <div>
            {priceDiscount ? (
              <>
                <span>{currencyBRL(price)}</span>
                <ProductCost>{currencyBRL(priceDiscount)}</ProductCost>
              </>
            ) : (
              <ProductCost>{currencyBRL(price)}</ProductCost>
            )}
            <strong>
              {currencyBRL(price * 0.9)} <span>no depósito</span>
            </strong>
          </div>
        </VapeDescContainer>
      </NavLink>
    </ThumbContainer>
  );
};

const AccessoryThumb: React.FC = () => {
  return <div />;
};

const ProductThumbSmall: React.FC<DefThumbProps> = ({
  id,
  type,
  data,
  ...rest
}) => {
  const { updateFav, favorites } = useFavorites();
  const { token } = useAuth();
  const thumbComponent = React.useMemo(() => {
    if (type === 'juice') {
      return <JuiceThumb id={id} {...rest} {...data} />;
    }

    if (type === 'vape' || type === 'accessory') {
      return <VapeThumb id={id} {...rest} {...data} />;
    }

    return <></>;
  }, [id, data, type]);

  const isFavorite = React.useMemo((): string => {
    if (favorites.length === 0) return '';
    if (favorites.includes(id)) return 'active';

    return '';
  }, [favorites, id]);

  return (
    <Container>
      <FavSVG onClick={() => updateFav(id, token)} className={isFavorite} />
      {thumbComponent}
    </Container>
  );
};

export default ProductThumbSmall;
