import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export interface TooltipPos {
  top: FlattenSimpleInterpolation;
  bottom: FlattenSimpleInterpolation;
  left: FlattenSimpleInterpolation;
  right: FlattenSimpleInterpolation;
}

const TooltipPosition: TooltipPos = {
  top: css`
    span {
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-125%);

      /* Triangle for the tooltip box */
      &::after {
        left: calc(50% - 5px);
        bottom: -10px;

        border-color: #000 transparent transparent transparent;
      }
    }
  `,
  bottom: css`
    span {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(125%);

      /* Triangle for the tooltip box */
      &::after {
        left: calc(50% - 5px);
        top: -10px;

        border-color: transparent transparent #000 transparent;
      }
    }
  `,
  right: css`
    span {
      right: -10px;
      top: 50%;
      transform: translateX(125%) translateY(-50%);

      /* Triangle for the tooltip box */
      &::after {
        top: calc(50% - 5px);
        left: -10px;

        border-color: transparent #000 transparent transparent;
      }
    }
  `,
  left: css`
    span {
      left: -10px;
      top: 50%;
      transform: translateX(-125%) translateY(-50%);

      /* Triangle for the tooltip box */
      &::after {
        top: calc(50% - 5px);
        right: -10px;

        border-color: transparent transparent transparent #000;
      }
    }
  `,
};

interface IconProps {
  size: string;
  outline: boolean;
  position: keyof TooltipPos;
}

export const IconContainer = styled.div<IconProps>`
  position: relative;

  border-radius: 50%;
  max-width: ${(props) => props.size};
  max-height: ${(props) => props.size};

  > svg {
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 10;

    [class*='cls-'] {
      transition: fill 0.25s ease, stroke 0.25s ease;
    }
  }

  span {
    user-select: none;
    position: absolute;
    /* bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%); */
    /* transform: translateX(-50%) translateY(calc(100% - 16px)); */

    color: #fff;
    background-color: #000; // #4d4d4d
    border-radius: 8px;
    text-align: center;
    padding: 6px 8px;

    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s;
    z-index: 15;
    min-width: 50px;

    /* Triangle for the tooltip box */
    &::after {
      content: '';
      position: absolute;
      /* left: calc(50% - 5px);
      top: -10px; */

      border-width: 5px;
      border-style: solid;
      /* border-color: transparent transparent #000 transparent; */
    }
  }

  ${({ position }) => TooltipPosition[position]}

  ${({ outline }) =>
    outline &&
    css`
      &:not(:hover) {
        [class*='cls-'] {
          fill: transparent !important;
          stroke: #d1cac5 !important;
        }
      }
    `}

  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`;
