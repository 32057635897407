import styled from 'styled-components';
import { Container as InputContainer } from 'components/Input/Container/styles';

export const Container = styled.section`
  flex: 1;
  display: flex;
  align-items: flex-start;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 450px;

    > * + * {
      margin-top: 16px;
    }

    > button {
      margin-left: auto;
    }

    input[type='text'],
    input[type='email'] {
      max-width: 250px;
      min-width: 250px;
    }

    &:nth-of-type(2) {
      margin-left: 32px;
    }
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      width: 100%;

      > div {
        width: 100%;
      }

      &:nth-of-type(2) {
        margin-left: 0;
        margin-top: 50px;
      }

      ${InputContainer} {
        flex-direction: column;
        width: 100%;
        > span {
          width: 100%;
          margin-right: 0;
          text-align: left;
          margin-bottom: 8px;
        }

        input {
          min-width: unset !important;
          max-width: unset !important;
        }
      }
    }
  }
`;
