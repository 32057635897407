// eslint-disable prefer-destructuring
/*
              <div>
                <RadioButton
                  onCheckChange={() => {
                    if (payment?.current === 2) {
                      // setPayment({});
                      return;
                    }
                    setPayment({
                      current: 2,
                      data: { type: 'PIX' },
                    });
                  }}
                  checked={payment?.current === 2}
                >
                  <DepositoSVG />
                  PIX
                </RadioButton>
                <PixContainer isShown={payment?.current === 2}>
                  <div>
                    <span>Valor a pagar</span>
                    <strong>
                      {currencyBRL(totalWithDiscount, true, true)}
                    </strong>
                  </div>
                </PixContainer>
              </div>
 */

import React, { useEffect, useState } from 'react';

import { QRious } from 'react-qrious';
import Skeleton from 'react-loading-skeleton';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { RiArticleFill, RiMapPinAddLine } from 'react-icons/ri';

import { useCart } from 'hooks/Cart';
import { useAuth } from 'hooks/auth';
import { api, handleErrors, handleRespReturn } from 'services/api';
import { swalFire, swalToast } from 'services/swal';

import {
  currencyBRL,
  formatCEP,
  formatContact,
  currencySpread,
} from 'utils/format';

import GreenButton from 'components/Button/GreenButton';
import RadioButton from 'components/Button/RadioButton';
import InputCreditCard from 'components/Input/CreditCard';
import InputCVV from 'components/Input/CVVCreditCard';
import InputExpirationCC from 'components/Input/ExpirationCC';
import DiscountCupom, { CupomState } from 'components/DiscountCupom';

import DeliveryOptions, {
  DeliveryOptions as DeliveryOpType,
} from 'components/DeliveryOptions';

import Payment, {
  CreditCard as CCObj,
  Deposit,
} from 'pages/Profile/Order/OrderInfo/Payment';
import { Addresses } from 'pages/Profile/Address';
import ModalAddress, {
  AddressProps,
  RespAddressModal,
} from 'components/Modal/Address';

import { ReactComponent as PixSVG } from 'assets/pix.svg';

import { ReactComponent as BBSVG } from 'assets/icons/bancos/BancoDoBrasil.svg';
import { ReactComponent as BradescoSVG } from 'assets/icons/bancos/Bradesco.svg';
import { ReactComponent as ItauSVG } from 'assets/icons/bancos/Itau.svg';

import { ReactComponent as PagamentoSVG } from 'assets/icons/Pagamento.svg';
import { ReactComponent as EntregaSVG } from 'assets/icons/Entrega.svg';
import { ReactComponent as ResumoSVG } from 'assets/icons/Resumo.svg';
import { ReactComponent as CartaoSVG } from 'assets/icons/Cartao.svg';
import { ReactComponent as DepositoSVG } from 'assets/icons/Deposito.svg';

import { ReactComponent as GalaxiaSVG } from 'assets/backgrounds/galaxia.svg';
import { ReactComponent as PlanetaSVG } from 'assets/backgrounds/footer/planeta_fundo.svg';

import {
  Container,
  CardDelivery,
  CardPayment,
  CardResume,
  Content,
  CreditCardContainer,
  DepositContainer,
  PixContainer,
  ColumnInput,
  RowForm,
  CreditCard,
  Address,
  CardWrap,
  DiscountDiv,
  Strong,
} from './styles';

interface PaymentOption {
  id: number;
  price: number;
  count: number;
  total: number;
  interest?: boolean;
}

interface CCObject {
  hash: string;
  brand: string;
  name: string;
  number: string;
  current: boolean;
  validation: string;
}

interface FormCC {
  hash?: string;
  bank?: string;
  number?: string;
  holder?: string;
  expiration?: string;
  cvv?: string;
  payment?: string;
}

// interface DeliveryForm {
//   option: DeliveryOpType;
// }

interface SelectedPayment {
  current?: 1 | 2 | 3;
  data?: CCObj | Deposit;
}

interface CheckoutInfo {
  promoNicFree?: { amount: number; price: number };
  promoNicSalt?: { amount: number; price: number };
  product: { amount: number; price: number };
  delivery: { price: number; label: string; isFree?: boolean };
  subTotal: number;
  total: number;
}

interface Params {
  hashCart?: string;
}

interface QrObj {
  isLoading?: boolean;
  code?: string;
}

const Checkout: React.FC = () => {
  const { hashCart } = useParams<Params>();
  const history = useHistory();

  const { token } = useAuth();
  const { clearCart } = useCart();

  const redirectOut = () => history.push('/');

  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [info, setInfo] = useState<CheckoutInfo | undefined>();
  const [cupom, setCupom] = useState<CupomState>({});

  const [defCards, setDefCards] = useState<CCObject[]>([]);
  const [fees, setFees] = useState<PaymentOption[] | undefined>();

  const [addresses, setAddresses] = useState<Addresses[] | undefined>(
    undefined,
  );

  const [observacao, setObservacao] = useState<string | undefined>();

  const [shipment, setShipment] = useState<number | undefined>();

  const [payment, setPayment] = useState<SelectedPayment>({});

  // const [qr, setQr] = useState<QrObj>({
  //   isLoading: false,
  //   code: undefined,
  // });

  const [formCC, setFormCC] = useState<FormCC>({});

  useEffect(() => {
    if (!cupom.code || !cupom?.discount || !cupom?.discountType) return;
    if (info && info?.subTotal && !cupom.discountVal) {
      // setQr({ isLoading: false });

      if (cupom?.discount) {
        let discountVal = 0;

        if (cupom?.discountType === 'fixed') {
          discountVal = cupom.discount;
        } else {
          discountVal = info.subTotal;
          if (info?.promoNicFree?.price) {
            discountVal -= info?.promoNicFree?.price;
          }

          if (info?.promoNicSalt?.price) {
            discountVal -= info?.promoNicSalt?.price;
          }

          discountVal *= cupom.discount;
        }

        setCupom({ ...cupom, discountVal });
      }
    }
  }, [cupom, info?.subTotal]);

  const subTotalClean = React.useMemo(() => {
    if (!info || !info.subTotal) return 0;
    let result = info.subTotal;

    if (info.promoNicFree) result -= info.promoNicFree.price;
    if (info.promoNicSalt) result -= info.promoNicSalt.price;

    return result;
  }, [info]);

  const deliveryCost = React.useMemo(() => {
    if (!info || !info.delivery || info.delivery.isFree) return 0;

    return info.delivery.price;
  }, [info]);

  const subTotalPix = React.useMemo(() => {
    let result = subTotalClean;
    // result -= subTotalClean * 0.07;
    result -= subTotalClean * 0.1;

    if (cupom?.code && cupom?.discountVal) {
      result -= cupom.discountVal;
    }

    result += deliveryCost;

    return result;
  }, [subTotalClean, cupom]);

  const subTotalCart = React.useMemo(() => {
    let result = subTotalClean;

    if (cupom?.code && cupom?.discountVal) {
      result -= cupom.discountVal;
    }

    if (payment.current === 2 || payment.current === 3) {
      // result -= subTotalClean * 0.07;
      result -= subTotalClean * 0.1;
    }

    result += deliveryCost;

    return result;
  }, [info, cupom, subTotalClean, deliveryCost, payment]);

  const totalWithDiscount = React.useMemo(() => {
    let result = subTotalClean;
    const result10Percent = result * 0.1;
    // const result10Percent = result * 0.07;

    if (cupom?.code && cupom?.discountVal) {
      result -= cupom.discountVal;
    }

    if (payment?.current === 1 && fees) {
      const selected = fees.filter((item) => `${item.id}` === formCC.payment);

      if (selected && selected[0]) {
        result = selected[0].total;
      }
    }

    if (payment?.current === 2 || payment?.current === 3) {
      result -= result10Percent;
    }

    result += deliveryCost;

    return result;
  }, [payment, subTotalCart, deliveryCost, cupom, subTotalClean]);

  // useEffect(() => {
  //   if (paymentType === 2 || paymentType === 3) {
  //     // if (info?.fees?.length) {
  //     //   setInfo({ ...info, total: info.fees[0].total });
  //     // }
  //     setPayment({
  //       type: paymentType === 2 ? 'PIX' : 'TED/DOC',
  //     } as Deposit);
  //   } else if (paymentType === undefined) {
  //     if (info?.fees?.length) {
  //       setInfo({ ...info, total: info.fees[0].total });
  //     }
  //     setPayment(undefined);
  //   }
  // }, [paymentType, formCC]);

  // useEffect(() => {
  //   if (paymentType === 1 && formCC.payment && info && info?.fees) {
  //     const selected = info.fees.filter(
  //       (item) => `${item.id}` === formCC.payment,
  //     );
  //     if (selected && selected[0]) {
  //       if (selected[0].interest) {
  //         setInfo({ ...info, total: selected[0].total });
  //       } else {
  //         setInfo({ ...info, total: info.fees[0].total });
  //       }
  //       setPayment({
  //         type: 'CC',
  //         cardNumber: formCC?.number,
  //         subTotal: selected[0].price,
  //         count: selected[0].count,
  //       });
  //     }
  //   }
  // }, [paymentType, formCC.payment]);

  const [modalProps, setModalProps] = useState<AddressProps>({
    isShown: false,
  });

  const handleNewOrEditAddress = (thisAddress?: Addresses): void => {
    const response: RespAddressModal = async (
      isConfirmed,
      newAddress,
      idAddress,
    ) => {
      if (isConfirmed && newAddress) {
        if (thisAddress && !idAddress) {
          swalFire({
            text: 'Ocorreu um erro, recarregue e tente novamente',
            icon: 'error',
          });

          return;
        }
        try {
          const resp = await api().request({
            url: '/user/profile/address',
            method: 'POST',
            data: { idAddress, address: newAddress },
          });

          const isErrored = handleRespReturn(resp);
          if (isErrored) return;
          let newAddresses = addresses ? [...addresses] : [];

          if (!idAddress) {
            newAddresses.push({ id: resp.data.content, ...newAddress });
          } else {
            newAddresses = newAddresses.map((adI) => {
              if (adI.id === idAddress) {
                return { id: idAddress, ...newAddress };
              }
              return { ...adI };
            });
          }
          setAddresses(newAddresses);
        } catch (err) {
          handleErrors(err, true);
        }
      }
      setModalProps({ isShown: false });
    };

    setModalProps({
      isShown: true,
      handleResponse: response,
      editAddress: thisAddress,
      idAddress: thisAddress?.id,
    });
  };

  // const handleQRCode = async (): Promise<void> => {
  //   if (qr.isLoading) return;
  //   if (qr.code) {
  //     swalFire({ icon: 'info', title: 'QrCode já foi gerado.' });
  //     return;
  //   }

  //   if (!shipment) {
  //     swalFire({ icon: 'info', text: 'Informe um endereço de entrega.' });
  //     return;
  //   }

  //   setQr({ isLoading: true });
  //   try {
  //     const resp = await api().request({
  //       url: '/checkout/pix',
  //       method: 'POST',
  //       data: {
  //         hash: hashCart,
  //         shipment,
  //         discountCode: cupom?.code,
  //       },
  //     });

  //     const isErrored = handleRespReturn(resp);
  //     if (isErrored) {
  //       setQr({ isLoading: false });
  //       return;
  //     }
  //     // console.log(resp.data);
  //     setQr({ isLoading: false, code: resp.data.content.qrCode });
  //   } catch (err) {
  //     setQr({ isLoading: false });
  //     handleErrors(err, true);
  //   }
  // };

  const handleFinish = async (): Promise<void> => {
    if (!hashCart) {
      swalFire({
        icon: 'error',
        text: 'Ocorreu um erro, recarregue e tente novamente.',
      });
      return;
    }

    if (!payment?.current) {
      swalToast({
        icon: 'warning',
        text: 'Selecione um método de pagamento.',
      });
      return;
    }

    if (!shipment) {
      swalToast({
        icon: 'warning',
        text: 'Selecione um endereço de entrega/cobrança.',
      });
      return;
    }

    let card;
    if (payment?.current === 1) {
      if (!fees) {
        swalFire({
          icon: 'error',
          text: 'Ocorreu um erro, recarregue e tente novamente.',
        });
        return;
      }

      if (
        !formCC.hash &&
        formCC.cvv &&
        formCC.holder &&
        formCC.expiration &&
        formCC.number
      ) {
        card = {
          number: formCC?.number,
          holder: formCC?.holder,
          expiration: formCC?.expiration,
          cvv: formCC?.cvv,
        };
      }
    }

    if (payment?.current === 1 && !card && !formCC.hash) {
      swalFire({
        icon: 'warning',
        text: 'Preencha os dados do cartão corretamente.',
      });
      return;
    }

    if (
      payment?.current === 1 &&
      formCC.hash &&
      (!formCC?.cvv || formCC.cvv.length !== 3)
    ) {
      swalToast({
        icon: 'warning',
        text: 'Preencha o CVV do cartão.',
      });
      return;
    }

    if (payment?.current === 1 && !formCC.payment) {
      // console.log(payment?.current, formCC.payment);
      swalToast({
        icon: 'warning',
        text: 'Selecione um parcelamento.',
      });
      return;
    }

    let paymentCC;
    if (fees && formCC.payment) {
      paymentCC = formCC.payment;
      // const filteredFees = info.fees.filter(
      //   (item) => formCC.payment && `${item.id}` === `${formCC.payment}`,
      // );
      // if (filteredFees.length > 0) paymentCC = filteredFees[0];
    }
    setIsCheckoutLoading(true);

    try {
      const resp = await api().request({
        url: '/checkout',
        method: 'POST',
        data: {
          orderHash: hashCart,
          paymentType: payment.current,
          paymentCC,
          cardHash: formCC.hash,
          cardCvv: formCC?.cvv ? `#!@AC${formCC.cvv}$$@#!a` : undefined,
          card,
          shipment,
          discountCode: cupom?.code,
          observacao,
        },
      });

      const isErrored = handleRespReturn(resp);
      setIsCheckoutLoading(false);
      if (isErrored) return;
      // console.log('Being fixed', resp.data.content);

      clearCart();
      history.push(`/checkout-done/${hashCart}`);
    } catch (err) {
      setIsCheckoutLoading(false);
      handleErrors(err, true);
    }
  };

  const handleUpdate = async (thisShipment?: number): Promise<void> => {
    if (isLoadingUpdate) return;
    if (!hashCart) return;
    if (!info) return;

    setIsLoadingUpdate(true);
    try {
      const resp = await api().request({
        url: '/checkout/update',
        method: 'POST',
        data: {
          hash: hashCart,
          discountCode: cupom.code,
          shipment: thisShipment || shipment,
        },
      });

      const isErrored = handleRespReturn(resp);

      setIsLoadingUpdate(false);
      if (isErrored) {
        setShipment(undefined);
        setFees(undefined);
      }

      const { content } = resp.data;
      setFees(content.fees);
      setInfo({
        ...info,
        delivery: {
          ...content.delivery,
          label: `${content.delivery.label} - ${content.delivery.deliveryTime}`,
        },
      });
    } catch (err) {
      setIsLoadingUpdate(false);
      setFees(undefined);
      setShipment(undefined);
      handleErrors(err, true);
    }
  };

  const handleSetAddress = (id: number) => {
    // setQr({ isLoading: false });
    setShipment(id);
    handleUpdate(id);
  };

  useEffect(() => {
    const getCart = async (hash: string): Promise<void> => {
      try {
        const resp = await api().request({
          url: `/checkout?hash=${hash}`,
          method: 'GET',
        });
        const isErrored = handleRespReturn(resp);
        if (isErrored) {
          // redirectOut();
          return;
        }
        // let favoriteAdd: undefined | number;

        // resp.data.content.address.map((item: any) => {
        //   if (item.favorite) favoriteAdd = item.id;
        // });
        const checkoutData = resp.data.content.checkout;
        if (checkoutData.cupom) {
          const { type, discount, code } = checkoutData.cupom;
          setCupom({
            code,
            discount,
            discountType: type,
          });
        }
        // let cupom = checkoutData.cupom;
        // let discountVal = checkoutData.subTotal;

        // if (cupom?.discount) {
        //   if (cupom?.type === 'fixed') {
        //     discountVal -= cupom.discount;
        //   } else {
        //     discountVal *= cupom.discount;
        //   }
        //   cupom = { ...cupom, discountVal, discountType: cupom.type };
        // }
        let formCCPayment;
        console.log(checkoutData.fees);
        if (checkoutData.fees && checkoutData.fees.length > 0) {
          formCCPayment = checkoutData.fees[0].id;
          setFees(checkoutData.fees);
        }
        setAddresses(resp.data.content.address);
        setInfo({ ...checkoutData, cupom });
        // setShipment(favoriteAdd);
        if (resp.data.content.cards && resp.data.content.cards.length > 0)
          setDefCards(resp.data.content.cards);
        setFormCC({ ...formCC, payment: formCCPayment });
      } catch (err) {
        // redirectOut();
        handleErrors(err, true);
      }
    };

    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    if (!token) {
      swalFire({ text: 'Acesso recusado.', icon: 'error' });
      // redirectOut();
    }

    if (hashCart && token && !info) {
      getCart(hashCart);
    } else {
      // redirectOut();
    }
  }, [hashCart, token]);

  return (
    <Container>
      <ModalAddress {...modalProps} />
      <h1>Finalizar Pedido</h1>

      <NavLink to="/cart">Voltar para o carrinho</NavLink>

      <Content>
        <GalaxiaSVG />
        <PlanetaSVG />

        <CardDelivery>
          <header>
            <EntregaSVG />
            <strong>Entrega</strong>
          </header>

          <main>
            <article>
              <strong>Endereço de envio</strong>

              <GreenButton
                bold
                size="medium"
                icon={<RiMapPinAddLine />}
                text="Novo endereço"
                onClick={() => handleNewOrEditAddress()}
              />
            </article>

            {addresses &&
              addresses.map((addI) => (
                <Address key={addI.id}>
                  <div>
                    <RadioButton
                      checked={shipment === addI.id}
                      onCheckChange={() => handleSetAddress(addI.id)}
                    />

                    <Strong
                      onClick={() => {
                        handleSetAddress(addI.id);
                      }}
                    >
                      {addI.name}
                    </Strong>

                    <button
                      type="button"
                      onClick={() => handleNewOrEditAddress(addI)}
                    >
                      Editar
                    </button>
                  </div>

                  <span>
                    {addI.street}, &nbsp;
                    {addI.number}
                  </span>

                  <span>{addI.info}</span>

                  <span>{addI.district}</span>

                  <span>
                    {formatCEP(addI.cep)}&nbsp;|&nbsp;{addI.city}&nbsp;-&nbsp;
                    {addI.state}
                  </span>

                  <span>{addI.contact && formatContact(addI.contact)}</span>
                </Address>
              ))}
          </main>
        </CardDelivery>

        <CardPayment>
          <header>
            <PagamentoSVG />
            <strong>Pagamento</strong>
          </header>
          <main>
            <strong>Forma de pagamento</strong>

            <div>
              <div>
                <RadioButton
                  onCheckChange={() => {
                    if (payment?.current === 1) {
                      // setPayment({});
                      return;
                    }
                    let count;
                    let subTotal;
                    let total;
                    if (fees) {
                      const selected = fees.filter(
                        (item) => `${item.id}` === `${formCC.payment}`,
                      );
                      if (selected && selected[0]) {
                        // if (selected[0].interest) {
                        //   setInfo({ ...info, total: selected[0].total });
                        // } else {
                        //   setInfo({ ...info, total: info.fees[0].total });
                        // }
                        subTotal = selected[0].price;
                        count = selected[0].count;
                        total = selected[0].total;
                      }
                    }
                    setPayment({
                      current: 1,
                      data: {
                        type: 'CC',
                        cardNumber: formCC?.number,
                        subTotal,
                        count,
                        total,
                      },
                    });
                  }}
                  checked={payment?.current === 1}
                >
                  <CartaoSVG />
                  Cartão de crédito
                </RadioButton>
                <CreditCardContainer isShown={payment?.current === 1}>
                  <CreditCard backShow={!!formCC.cvv}>
                    <div>
                      <span />
                      {/* <span>{formCC.bank || ''}</span> */}

                      <span>{formCC.number || '**** **** **** ****'}</span>

                      <span>{formCC.expiration || '00/00'}</span>

                      <div>
                        <span>{formCC.holder || 'Dono do cartão'}</span>

                        <div />
                      </div>
                    </div>

                    <div>
                      <div />

                      <div>
                        <div>
                          <span>{formCC.cvv || 'CVV'}</span>
                        </div>
                      </div>
                    </div>
                  </CreditCard>

                  <div>
                    {defCards.length > 0 && (
                      <ColumnInput>
                        <span>Selecionar um de seus cartões</span>
                        <select
                          onChange={({ target }) => {
                            if (target.value && target.value !== '0') {
                              const thisCard = defCards.filter(
                                (item) => item.hash === target.value,
                              )[0];
                              // console.log(thisCard);
                              setFormCC({
                                ...formCC,
                                hash: thisCard.hash,
                                // cvv: '***',
                                expiration: `${thisCard.validation.substr(
                                  0,
                                  2,
                                )}/${thisCard.validation.substr(2, 2)}`,
                                holder: thisCard.name,
                                number: thisCard.number,
                              });
                              return;
                            }
                            setFormCC({ hash: undefined });
                          }}
                          value={formCC?.hash || 0}
                        >
                          <option value={0}>Nenhum</option>
                          {defCards.map((item) => (
                            <option key={item.hash} value={item.hash}>
                              {item.current && 'Principal - '}
                              {/* {item.brand} -  */}
                              {item.name.split(' ')[0]}
                              &nbsp;-&nbsp;
                              {item.number.replace(/[^\d]+/g, '')}
                            </option>
                          ))}
                        </select>
                      </ColumnInput>
                    )}

                    <ColumnInput isDisabled={!!formCC.hash}>
                      <span>Número do cartão</span>
                      <InputCreditCard
                        onChangeInput={(value) => {
                          setFormCC({
                            ...formCC,
                            number: value,
                            hash: undefined,
                          });
                        }}
                        currentValue={formCC?.number || ''}
                        readOnly={!!formCC.hash}
                      />
                    </ColumnInput>

                    <ColumnInput isDisabled={!!formCC.hash}>
                      <span>Seu nome (como no cartão)</span>
                      <input
                        type="text"
                        onChange={({ target }) => {
                          setFormCC({
                            ...formCC,
                            holder: target.value,
                            hash: undefined,
                          });
                        }}
                        value={formCC?.holder || ''}
                        readOnly={!!formCC.hash}
                        disabled={!!formCC.hash}
                      />
                    </ColumnInput>

                    <RowForm>
                      <ColumnInput isDisabled={!!formCC.hash}>
                        <span>Validade</span>
                        <InputExpirationCC
                          onChangeInput={(value) => {
                            setFormCC({
                              ...formCC,
                              expiration: value,
                              hash: undefined,
                            });
                          }}
                          currentValue={formCC?.expiration || ''}
                          readOnly={!!formCC.hash}
                        />
                      </ColumnInput>

                      <ColumnInput>
                        <span>CVV</span>
                        <InputCVV
                          onChangeInput={(value) => {
                            setFormCC({
                              ...formCC,
                              cvv: value,
                              // hash: undefined,
                            });
                          }}
                          currentValue={formCC?.cvv || ''}
                          // readOnly={!!formCC.hash}
                        />
                      </ColumnInput>
                    </RowForm>

                    <ColumnInput>
                      <span>Parcelar em</span>
                      <select
                        onChange={({ target }) => {
                          setFormCC({
                            ...formCC,
                            payment: target.value,
                          });

                          let count;
                          let subTotal;
                          let total;
                          if (fees && fees.length > 0) {
                            const selected = fees.filter(
                              (item) => `${item.id}` === `${target.value}`,
                            );
                            console.log('selected', selected);
                            if (selected.length > 0 && selected[0]) {
                              // if (selected[0].interest) {
                              //   setInfo({ ...info, total: selected[0].total });
                              // } else {
                              //   setInfo({ ...info, total: info.fees[0].total });
                              // }
                              subTotal = selected[0].price;
                              count = selected[0].count;
                              total = selected[0].total;
                            }
                          }

                          console.log(target.value, {
                            ...payment,
                            data: {
                              type: 'CC',
                              cardNumber: formCC?.number,
                              subTotal,
                              count,
                              total,
                            },
                          });
                          setPayment({
                            ...payment,
                            data: {
                              type: 'CC',
                              cardNumber: formCC?.number,
                              subTotal,
                              count,
                              total,
                            },
                          });
                        }}
                        value={formCC.payment || undefined}
                      >
                        {fees &&
                          fees.length > 0 &&
                          fees.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.count}x&nbsp; de {currencyBRL(item.price)}
                              &nbsp;
                              {item.interest ? 'com juros' : 'sem juros'}
                            </option>
                          ))}
                      </select>
                    </ColumnInput>
                  </div>
                </CreditCardContainer>
              </div>

              <div>
                <RadioButton
                  onCheckChange={() => {
                    if (payment?.current === 3) {
                      // setPayment({});
                      return;
                    }
                    setPayment({
                      current: 3,
                      data: { type: 'TED/DOC' },
                    });
                  }}
                  checked={payment?.current === 3}
                >
                  <DepositoSVG />
                  Depósito bancário (TED, DOC ou PIX)
                </RadioButton>
                <DepositContainer isShown={payment?.current === 3}>
                  <div>
                    <span>Valor a pagar</span>
                    <strong>
                      {currencyBRL(totalWithDiscount, true, true)}
                    </strong>
                  </div>

                  <div>
                    <span>10% de desconto no pedido.</span>
                    <span>Pagamento somente à vista.</span>
                    <span>
                      O pedido será enviado após a confirmação de recebimento.
                    </span>
                    {/* <span>
                      Para agilizar, anexe seu comprovante na área &quot;Meus
                      pedidos&quot;.
                    </span> */}
                  </div>
                </DepositContainer>
              </div>
            </div>
          </main>
        </CardPayment>

        <CardWrap>
          <CardResume>
            <header>
              <ResumoSVG />
              <strong>Resumo</strong>
            </header>

            <main>
              <article>
                <strong>Observações do pedido:</strong>
                <textarea
                  onChange={({ target }) => {
                    if (target.value && target.value.length > 250) {
                      setObservacao(observacao);
                      return;
                    }
                    setObservacao(target.value);
                  }}
                  value={observacao || ''}
                />
                <span>{observacao?.length || 0}/250 caracteres</span>
              </article>

              <div>
                <strong>Produtos</strong>
                {info?.product ? (
                  <div>
                    <strong>{currencyBRL(info.product.price)}</strong>
                    <span>{info.product.amount} produtos</span>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </div>

              <div>
                <strong>Frete</strong>
                {info?.delivery ? (
                  <div>
                    <strong>
                      {info.delivery.isFree
                        ? 'GRÁTIS'
                        : currencyBRL(info.delivery.price)}
                    </strong>
                    <span>{info.delivery.label}</span>
                  </div>
                ) : (
                  <Skeleton />
                )}
              </div>

              <div>
                <span>Cupom de desconto</span>
                <DiscountCupom
                  onCupomChange={(value) => {
                    setCupom({ code: value });
                    // setShipment(undefined);
                  }}
                  onValidateCupom={(result) => {
                    if (!result) {
                      setCupom({ code: cupom?.code });
                      return;
                    }
                    handleUpdate();
                    // setShipment(undefined);
                    // setFees(undefined);
                    setCupom({ ...result, code: cupom?.code });
                  }}
                  currentCupom={cupom?.code}
                />

                {info?.promoNicFree && info?.promoNicFree?.price > 0 && (
                  <DiscountDiv isShown>
                    <span>Desconto Freebase grátis</span>
                    <span>
                      {currencyBRL(info.promoNicFree.price, true, true)}
                    </span>
                  </DiscountDiv>
                )}

                {info?.promoNicSalt && info?.promoNicSalt?.price > 0 && (
                  <DiscountDiv isShown>
                    <span>Desconto Nic Salt grátis</span>
                    <span>
                      {currencyBRL(info.promoNicSalt.price, true, true)}
                    </span>
                  </DiscountDiv>
                )}

                <DiscountDiv isShown={!!cupom?.discountVal}>
                  <span>Desconto {cupom?.code}</span>
                  <span>{currencyBRL(cupom?.discountVal || 0)}</span>
                </DiscountDiv>

                {(payment?.current === 2 || payment?.current === 3) &&
                  payment?.data && (
                    <DiscountDiv isShown>
                      <span>Desconto {payment.data.type}</span>
                      <span>
                        {currencyBRL(subTotalClean * 0.1, true, true)}
                      </span>
                    </DiscountDiv>
                  )}
              </div>

              <div>
                <strong>Subtotal</strong>
                <div>
                  <strong>{currencyBRL(subTotalCart)}</strong>
                  <span>
                    {(payment.current === 1 || !payment.current) &&
                      `ou ${currencyBRL(subTotalPix, true, true)} no depósito`}
                  </span>
                </div>
              </div>
            </main>

            <footer>
              <Payment
                total={
                  payment?.data?.type === 'CC' && payment?.data?.total
                    ? payment.data.total
                    : totalWithDiscount
                }
                payment={payment?.data}
              />
            </footer>
          </CardResume>

          <GreenButton
            isLoading={isCheckoutLoading}
            size="big"
            fullRound
            bold
            text="Finalizar minha compra"
            onClick={handleFinish}
          />

          <p>
            Ao continuar, declaro que estou ciente e aceito os&nbsp;
            <NavLink to="/faq/termos-e-condicoes">termos de uso</NavLink>
            &nbsp;e&nbsp;a&nbsp;
            <NavLink to="/faq/politica-de-privacidade">
              Política de Privacidade
            </NavLink>
            .
          </p>
        </CardWrap>
      </Content>
    </Container>
  );
};

export default Checkout;
