import React from 'react';

import { ReactComponent as AbacaxiSVG } from 'assets/essencias/Abacaxi.svg';
import { ReactComponent as AmendoaSVG } from 'assets/essencias/Amendoa.svg';
import { ReactComponent as AvelaSVG } from 'assets/essencias/Avela.svg';
import { ReactComponent as BalaSVG } from 'assets/essencias/Bala.svg';
import { ReactComponent as BananaSVG } from 'assets/essencias/Banana.svg';
import { ReactComponent as BaunilhaSVG } from 'assets/essencias/Baunilha.svg';
import { ReactComponent as BlueberrySVG } from 'assets/essencias/Blueberry.svg';
import { ReactComponent as BolachaLimaoSVG } from 'assets/essencias/BolachaLimao.svg';
import { ReactComponent as BoloSVG } from 'assets/essencias/Bolo.svg';
import { ReactComponent as CafeSVG } from 'assets/essencias/Cafe.svg';
import { ReactComponent as CanelaSVG } from 'assets/essencias/Canela.svg';
import { ReactComponent as CappuccinoSVG } from 'assets/essencias/Cappuccino.svg';
import { ReactComponent as CarameloSVG } from 'assets/essencias/Caramelo.svg';
import { ReactComponent as CassisSVG } from 'assets/essencias/Cassis.svg';
import { ReactComponent as CheesecakeSVG } from 'assets/essencias/Cheesecake.svg';
import { ReactComponent as ChicleteSVG } from 'assets/essencias/Chiclete.svg';
import { ReactComponent as ChocolateSVG } from 'assets/essencias/Chocolate.svg';
import { ReactComponent as CocoSVG } from 'assets/essencias/Coco.svg';
import { ReactComponent as CremeSVG } from 'assets/essencias/Creme.svg';
import { ReactComponent as DoceDeLeiteSVG } from 'assets/essencias/DoceDeLeite.svg';
import { ReactComponent as DonutSVG } from 'assets/essencias/Donut.svg';
import { ReactComponent as FramboesaSVG } from 'assets/essencias/Framboesa.svg';
import { ReactComponent as FrutasVermelhasSVG } from 'assets/essencias/FrutasVermelhas.svg';
import { ReactComponent as GoiabaSVG } from 'assets/essencias/Goiaba.svg';
import { ReactComponent as HortelaSVG } from 'assets/essencias/Hortela.svg';
import { ReactComponent as IceSVG } from 'assets/essencias/Ice.svg';
import { ReactComponent as KiwiSVG } from 'assets/essencias/Kiwi.svg';
import { ReactComponent as LaranjaSVG } from 'assets/essencias/Laranja.svg';
import { ReactComponent as LaranjaSangueSVG } from 'assets/essencias/LaranjaSangue.svg';
import { ReactComponent as LimaoSVG } from 'assets/essencias/Limao.svg';
import { ReactComponent as MacaSVG } from 'assets/essencias/Maca.svg';
import { ReactComponent as MacaVerdeSVG } from 'assets/essencias/MacaVerde.svg';
import { ReactComponent as MamaoSVG } from 'assets/essencias/Mamao.svg';
import { ReactComponent as MangaSVG } from 'assets/essencias/Manga.svg';
import { ReactComponent as MaracujaSVG } from 'assets/essencias/Maracuja.svg';
import { ReactComponent as MarrolhoSVG } from 'assets/essencias/Marrolho.svg';
import { ReactComponent as MarshmallowSVG } from 'assets/essencias/Marshmallow.svg';
import { ReactComponent as MelSVG } from 'assets/essencias/Mel.svg';
import { ReactComponent as MelanciaSVG } from 'assets/essencias/Melancia.svg';
import { ReactComponent as MelaoSVG } from 'assets/essencias/Melao.svg';
import { ReactComponent as MorangoSVG } from 'assets/essencias/Morango.svg';
import { ReactComponent as PastaAmendoinSVG } from 'assets/essencias/PastaAmendoin.svg';
import { ReactComponent as PessegoSVG } from 'assets/essencias/Pessego.svg';
import { ReactComponent as PinaColadaSVG } from 'assets/essencias/PinaColada.svg';
import { ReactComponent as PitayaSVG } from 'assets/essencias/Pitaya.svg';
import { ReactComponent as RomaSVG } from 'assets/essencias/Roma.svg';
import { ReactComponent as SorveteSVG } from 'assets/essencias/Sorvete.svg';
import { ReactComponent as SucoUvaSVG } from 'assets/essencias/SucoUva.svg';
import { ReactComponent as TabacoSVG } from 'assets/essencias/Tabaco.svg';
import { ReactComponent as TangerinaSVG } from 'assets/essencias/Tangerina.svg';
import { ReactComponent as UvaSVG } from 'assets/essencias/Uva.svg';
import { ReactComponent as WhiskeySVG } from 'assets/essencias/Whiskey.svg';

import { IconContainer, TooltipPos } from './styles';

interface Props {
  idx: number;
  size?: string;
  outline?: boolean;
  position?: keyof TooltipPos;
}

const defaultSizeIcon = '50px';

const arrIcons: { [key: number]: { icon: JSX.Element; text: string } } = {
  1: { icon: <AbacaxiSVG />, text: 'Abacaxi' },
  2: { icon: <AmendoaSVG />, text: 'Amêndoa' },
  3: { icon: <AvelaSVG />, text: 'Avelã' },
  4: { icon: <BalaSVG />, text: 'Bala' },
  5: { icon: <BananaSVG />, text: 'Banana' },
  6: { icon: <BaunilhaSVG />, text: 'Baunilha' },
  7: { icon: <BlueberrySVG />, text: 'Blueberry' },
  8: { icon: <BolachaLimaoSVG />, text: 'Bolacha de limão' },
  9: { icon: <BoloSVG />, text: 'Bolo' },
  10: { icon: <CafeSVG />, text: 'Café' },
  11: { icon: <CanelaSVG />, text: 'Canela' },
  12: { icon: <CappuccinoSVG />, text: 'Cappuccino' },
  13: { icon: <CarameloSVG />, text: 'Caramelo' },
  14: { icon: <CassisSVG />, text: 'Cassis' },
  15: { icon: <CheesecakeSVG />, text: 'Cheesecake' },
  16: { icon: <ChicleteSVG />, text: 'Chiclete' },
  17: { icon: <ChocolateSVG />, text: 'Chocolate' },
  18: { icon: <CocoSVG />, text: 'Coco' },
  19: { icon: <CremeSVG />, text: 'Creme' },
  20: { icon: <DoceDeLeiteSVG />, text: 'Doce de leite' },
  21: { icon: <DonutSVG />, text: 'Donut' },
  22: { icon: <FramboesaSVG />, text: 'Framboesa' },
  23: { icon: <FrutasVermelhasSVG />, text: 'Frutas vermelhas' },
  24: { icon: <GoiabaSVG />, text: 'Goiaba' },
  25: { icon: <HortelaSVG />, text: 'Hortelã' },
  26: { icon: <IceSVG />, text: 'Gelo' },
  27: { icon: <KiwiSVG />, text: 'Kiwi' },
  28: { icon: <LaranjaSVG />, text: 'Laranja' },
  29: { icon: <LaranjaSangueSVG />, text: 'Laranja sangue' },
  30: { icon: <LimaoSVG />, text: 'Limão' },
  31: { icon: <MacaSVG />, text: 'Maçã' },
  32: { icon: <MacaVerdeSVG />, text: 'Maçã verde' },
  33: { icon: <MamaoSVG />, text: 'Mamão' },
  34: { icon: <MangaSVG />, text: 'Manga' },
  35: { icon: <MaracujaSVG />, text: 'Maracujá' },
  36: { icon: <MarrolhoSVG />, text: 'Marrolho' },
  37: { icon: <MarshmallowSVG />, text: 'Marshmallow' },
  38: { icon: <MelSVG />, text: 'Mel' },
  39: { icon: <MelanciaSVG />, text: 'Melancia' },
  40: { icon: <MelaoSVG />, text: 'Melão' },
  41: { icon: <MorangoSVG />, text: 'Morango' },
  42: { icon: <PastaAmendoinSVG />, text: 'Pasta de amendoim' },
  43: { icon: <PessegoSVG />, text: 'Pêssego' },
  44: { icon: <PinaColadaSVG />, text: 'Piña colada' },
  45: { icon: <PitayaSVG />, text: 'Pitaia' },
  46: { icon: <RomaSVG />, text: 'Romã' },
  47: { icon: <SorveteSVG />, text: 'Sorvete' },
  48: { icon: <SucoUvaSVG />, text: 'Suco de uva' },
  49: { icon: <TabacoSVG />, text: 'Tabaco' },
  50: { icon: <TangerinaSVG />, text: 'Tangerina' },
  51: { icon: <UvaSVG />, text: 'Uva' },
  52: { icon: <WhiskeySVG />, text: 'Whiskey' },
};

const Icon: React.FC<Props> = ({
  idx,
  size = defaultSizeIcon,
  position = 'bottom',
  outline = false,
}) => {
  return (
    <IconContainer size={size} outline={outline} position={position}>
      {arrIcons[idx].icon}
      <span>{arrIcons[idx].text}</span>
    </IconContainer>
  );
};

export default Icon;
