import styled, { css } from 'styled-components';

interface ContainerProps {
  maxHeight: string;
}

export const Container = styled.section<ContainerProps>`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: flex; */
  width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
`;

interface SliderContainerProps {
  currentSlide: number;
}

export const SliderContainer = styled.div<SliderContainerProps>`
  width: 100%;
  height: 100%;

  > img,
  > div {
    position: absolute;
    left: -100vw;
    opacity: 0;
    top: 0;
    transition: all 0.25s ease;
    width: 100%;
    height: 100%;
    /* padding: 0 24px; */

    /* @media (max-width: 1800px) {
      height: 600px;
    }

    @media (max-width: 1800px) {
      height: 550px;
    }

    @media (max-width: 1600px) {
      height: 525px;
    }

    @media (max-width: 1500px) {
      height: 500px;
    }

    @media (max-width: 1400px) {
      height: 475px;
    }

    @media (max-width: 1350px) {
      height: 450px;
    }

    @media (max-width: 1300px) {
      height: 425px;
    }

    @media (max-width: 1200px) {
      height: 400px;
    }

    @media (max-width: 1100px) {
      height: 375px;
    }

    @media (max-width: 1050px) {
      height: 350px;
    }

    @media (max-width: 1000px) {
      height: 325px;
    }

    @media (max-width: 900px) {
      height: 300px;
    }

    @media (max-width: 800px) {
      height: 275px;
    }

    @media (max-width: 770px) {
      height: 250px;
    }

    @media (max-width: 690px) {
      height: 225px;
    }

    @media (max-width: 620px) {
      height: 200px;
    }

    @media (max-width: 550px) {
      height: 200px;
    } */
    /*

    @media (max-width: 900px) {
      height: 325px;
    }

    @media (max-width: 800px) {
      height: 300px;
    } */

    ${({ currentSlide }) => css`
      &:nth-of-type(${currentSlide}) ~ div {
        left: 100vw;
      }

      &:nth-of-type(${currentSlide}) {
        position: unset;
        opacity: 1;
      }
    `}
  }
`;

interface SliderImgProps {
  img?: string;
}

export const BannerImg = styled.img<SliderImgProps>`
  width: 100vw;

  /* width: 100%;
height: 100%;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
`;

const Button = styled.button`
  cursor: pointer;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 0;
  z-index: 100;

  > svg {
    height: 32px;
    width: 32px;
  }
`;

export const ButtonBack = styled(Button)`
  left: 24px;
`;

export const ButtonNext = styled(Button)`
  right: 24px;
`;
