import styled, { css } from 'styled-components';

interface ImageProps {
  img?: string;
  small?: boolean;
}

export const ProductImage = styled.figure<ImageProps>`
  overflow: hidden;

  background-color: #d4c0d7;
  border: solid 2px #d4c0d7;
  border-radius: 50%;

  /*
  background-image: url('${({ img }) => img}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */

  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;

  > img {
    margin: -2px 0 0 -2px;
    width: 100px;
    height: 100px;

    object-fit: cover;
    object-position: center;
    display: block; /*to make it behave as div and avoir whitespace issue*/
  }

  ${({ small }) =>
    small &&
    css`
      min-width: 75px;
      max-width: 75px;
      min-height: 75px;
      max-height: 75px;

      > img {
        min-width: 75px;
        max-width: 75px;
        min-height: 75px;
        max-height: 75px;
      }
    `}
`;

export const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 32px;

  > strong {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 1.25rem;

    > span {
      font-size: 1rem;
      margin-left: 4px;
      color: #c7e22b;
    }
  }

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    > span {
      font-size: 1rem;
      margin-top: 2px;
    }
  }
`;

export const Container = styled.div<{ small?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 250px;

  ${({ small }) =>
    small === true &&
    css`
      > div {
        > div {
          margin-top: 4px;
          justify-content: flex-start;
          display: flex;
          flex-direction: row !important;
          flex-wrap: wrap;

          > span {
            font-size: 0.9rem;
            line-height: 1.25;
            &:not(:last-child)::after {
              content: ', ';
              white-space: pre;
            }

            &:last-child::after {
              content: '.';
            }
          }
        }
      }
    `}

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
