import React from 'react';

import { Container } from './styles';

interface Props {
  id?: string;
  title: string;
  icon: JSX.Element;
  margin?: string;
}

const SectionTitle: React.FC<Props> = ({ title, icon, margin, id }) => {
  return (
    <Container margin={margin} id={id}>
      <h1>{title}</h1>
      {icon}
    </Container>
  );
};

export default SectionTitle;
