import styled, { css } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;

  overflow: hidden;

  > section {
    animation: ${fadeIn} 0.25s ease forwards 1;
  }

  > span {
    width: 100vw;
    height: 80vh;

    > span {
      width: 100%;
      height: 100%;
    }
  }

  > * + * {
    margin-top: 32px;
  }

  > div {
    margin-top: calc(50px + 32px);
  }
`;

export const SectionMain = styled.section<{ hidden?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ hidden }) => hidden && 'display: none;'}
`;

export const SectionBanner = styled(SectionMain)`
  /* background-color: #000; */
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SectionNewProducts = styled(SectionMain)`
  > div:nth-of-type(2) {
    animation: ${fadeIn} 0.25s ease forwards 1;
  }
`;

export const SectionBestSeller = styled(SectionMain)`
  position: relative;

  > article + article {
    margin-top: 24px;
  }

  > svg {
    position: absolute;
    top: -190px;
    right: -75px;
    width: 500px;
    height: 500px;
    z-index: 30;
  }

  > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: repeat(auto-fill, calc(250px));
    grid-gap: 24px;
    justify-content: center;
    grid-auto-flow: row;
    margin: 0 50px;
  }

  @media (max-width: 700px) {
    > svg {
      right: 0;
      transform: translateX(60%);
    }
  }
  /* > div:nth-of-type(2),
  > div:nth-of-type(2) ~ div {
    // max-width: 1280px;
    //margin: 1rem auto;

    //display: flex;
    //flex-flow: row wrap;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    z-index: 50;
    max-width: 90%;
    margin: 0 auto;

    > div {
      // flex: 1 1 calc(250px + 2rem);
      margin: 1rem;
    }

     //> div {
      //flex: 1 1 256px;

      //margin: 1rem;
    //}
  } */
`;

export const BannersSection = styled(SectionMain)`
  padding: 0 24px;

  > img {
    width: 100%;
    height: auto;
  }

  > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    flex: 1;

    > img {
      margin-top: 24px;
      height: auto;
      width: calc(50% - 8px);

      + img {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 750px) {
    padding: 0;

    > div {
      > img {
        width: 100%;

        + img {
          margin-left: 0;
        }
      }
    }
  }
`;

// > div:nth-of-type(1) {
//   width: auto;
//   min-height: 305px;
// }
// > div:nth-of-type(2) {
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: center;
//   flex: 1;
//   > div {
//     /* flex: 1; */
//     margin: 2rem 1rem 1rem 1rem;
//     width: 630px;
//     min-height: 339px;
//   }
// }

export const BannerImg = styled.img<{ img?: string }>`
  /* width: 100%;
  height: auto; */
  height: auto;
  width: 100%;
  /* background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
`;
