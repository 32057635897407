import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';

import GreenButton from 'components/Button/GreenButton';
import InputGeneric from 'components/Input/Generic';
import ContainerInput from 'components/Input/Container';
import CpfCnpj from 'components/Input/MaskedInputs/CpfCnpj';

import GenericModal from '../Generic';
import { Content } from './styles';

interface Form {
  hashOne?: string;
  email?: string;
  udoc?: string;
  password?: string;
  passwordConfirm?: string;
}

export interface ForgotMailProps {
  isShown: boolean;
  hash?: string;
  close(): void;
}

const ForgotPasswordConfirm: React.FC<ForgotMailProps> = ({
  isShown,
  close,
  hash,
}) => {
  const history = useHistory();
  const [form, setForm] = useState<Form>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (bool?: boolean): Promise<void> => {
    if (isLoading) return;

    if (!bool) {
      close();
      return;
    }

    if (!form.email) {
      swalFire({
        text: 'Confirme seu e-mail.',
        icon: 'warning',
      });
      return;
    }

    if (!form.udoc) {
      swalFire({
        text: 'Confirme seu CPF/CNPJ.',
        icon: 'warning',
      });
      return;
    }

    if (!form.password || !form.passwordConfirm) {
      swalFire({
        text: 'Preencha os campos de senha.',
        icon: 'warning',
      });
      return;
    }

    if (form.password !== form.passwordConfirm) {
      swalFire({
        text: 'Senhas não conferem.',
        icon: 'warning',
      });
      return;
    }

    if (!hash) {
      return;
    }

    setIsLoading(true);
    try {
      const resp = await api().post('/user/forgotPassword/done', {
        email: form.email,
        udoc: form.udoc,
        password: form.password,
        passwordConfirm: form.passwordConfirm,
        hashOne: form.hashOne,
        hashTwo: hash,
      });
      const isErrored = handleRespReturn(resp);
      setIsLoading(false);
      if (isErrored) return;

      swalFire({
        text: 'Sua senha foi alterada com sucesso.',
        icon: 'success',
      });
      history.push('/user');
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
      return;
    }
    close();
  };

  return (
    <GenericModal
      isShown={isShown}
      returnTrigger={(bool) => handleSubmit(bool)}
      disableMinSize
    >
      <Content>
        <strong>Redefinir minha senha</strong>

        <span>Digite abaixo a sua nova senha de acesso.</span>

        <ContainerInput title="Email">
          <InputGeneric
            onChangeCustom={({ target }) => {
              setForm({ ...form, email: target.value });
            }}
            type="email"
            value={form?.email || ''}
          />
        </ContainerInput>

        <ContainerInput title="CPF/CNPJ" direction="column" styleInput>
          <CpfCnpj
            onChangeInput={(value) => {
              setForm({ ...form, udoc: value });
            }}
            currentValue={form?.udoc}
          />
        </ContainerInput>

        <ContainerInput title="Senha">
          <InputGeneric
            onChangeCustom={({ target }) => {
              setForm({ ...form, password: target.value });
            }}
            type="password"
            value={form?.password || ''}
          />
        </ContainerInput>

        <ContainerInput title="Confirme a senha">
          <InputGeneric
            onChangeCustom={({ target }) => {
              setForm({ ...form, passwordConfirm: target.value });
            }}
            type="password"
            value={form?.passwordConfirm || ''}
          />
        </ContainerInput>

        <ContainerInput title="Código de segurança">
          <InputGeneric
            onChangeCustom={({ target }) => {
              setForm({ ...form, hashOne: target.value });
            }}
            type="text"
            value={form?.hashOne || ''}
          />
        </ContainerInput>

        <GreenButton
          text={isLoading ? 'Enviando...' : 'Enviar'}
          onClick={() => handleSubmit(true)}
        />
      </Content>
    </GenericModal>
  );
};

export default ForgotPasswordConfirm;
