import React, { createContext, useCallback, useContext, useState } from 'react';

import NotificationComponent from 'components/Notifications';
import { Notification } from 'components/Notifications/DTO';
// import CartNotificationContainer from 'components/Cart/CartContainer';

interface NotificationContextData {
  notifications: Notification[];
  addNotification(value: Notification): void;
  removeNotification(idNotify: string): void;
}

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  // const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback((newNotify: Notification) => {
    setNotifications([...notifications, newNotify]);
  }, []);

  const removeNotification = useCallback((idNotify: string) => {
    setNotifications([...notifications.filter((item) => item.id !== idNotify)]);
  }, []);

  // React.useEffect(() => {
  //   // console.log('notifications', notifications);
  // });
  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      <NotificationComponent />
      {/* <CartNotificationContainer product={product} /> */}
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider',
    );
  }

  return context;
}

export { useNotification, NotificationProvider };
