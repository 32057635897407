import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

import { Container } from './styles';

interface Props {
  onChange(value: number): void;
  styleType?: 'small' | 'big';
  currentValue?: string | number;
  min?: number;
  max?: number;
}

const InputInteger: React.FC<Props> = ({
  currentValue = 1,
  styleType = 'big',
  onChange,
  min = 1,
  max,
}) => {
  const handleAdd = (): void => {
    // console.log('Sum');

    const replaceVal = `${currentValue}`.replace(/[^\d]+/g, '');
    if (!Number(replaceVal) && replaceVal !== '0') {
      onChange(0);
      return;
    }

    const parseVal = parseInt(replaceVal, 10);
    if (max && parseVal >= max) {
      onChange(max);
      return;
    }

    onChange(parseVal + 1);
  };

  const handleDecrease = (): void => {
    // console.log('Decrease');
    const replaceVal = `${currentValue}`.replace(/[^\d]+/g, '');
    // console.log(currentValue, replaceVal, Number(replaceVal));
    if (!Number(replaceVal)) {
      onChange(0);
      return;
    }
    const parseVal = parseInt(replaceVal, 10);
    if (parseVal <= min) {
      onChange(min);
      return;
    }
    onChange(parseVal - 1);
  };

  return (
    <Container styleType={styleType}>
      <FiMinus onClick={handleDecrease} />

      <input
        type="text"
        onKeyDown={(event) => {
          if (event.key === '+') {
            handleAdd();
            return;
          }
          if (event.key === '-') {
            handleDecrease();
          }
        }}
        onChange={(event) => {
          const { value } = event.target;
          const replaceVal = `${value}`.replace(/[^\d]+/g, '');
          if (!Number(replaceVal)) {
            onChange(0);
            return;
          }
          const parseVal = parseInt(replaceVal, 10);

          if (parseVal <= min) {
            onChange(min);
            return;
          }

          if (max && parseVal >= max) {
            onChange(max);
            return;
          }

          onChange(parseVal);
        }}
        value={currentValue}
      />

      <FiPlus onClick={handleAdd} />
    </Container>
  );
};

export default InputInteger;
