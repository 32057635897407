/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState } from 'react';

import { api, handleErrors, handleRespReturn } from 'services/api';

import GreenButton from 'components/Button/GreenButton';
import CurrentPath from 'components/CurrentPath';
import SectionTitle from 'components/SectionTitle';
import { ReactComponent as TelescopeSVG } from 'assets/draws/telescopio.svg';

import { Container, Content } from './styles';

const pathList = [
  {
    title: 'Início',
    to: '/',
  },
  {
    title: 'Perguntas Frequentes',
    to: '/faq',
  },
  {
    title: 'Termos & Condições',
    to: '/faq/termos-e-condicoes',
  },
];

const TermosCondicoes: React.FC = () => {
  const [text, setText] = useState<string | undefined>();

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });

    const getTermos = async (): Promise<void> => {
      try {
        const resp = await api().request({
          url: `/generic/termos`,
          method: 'GET',
        });

        const isErrored = handleRespReturn(resp);
        if (isErrored) return;

        setText(resp.data.content.text);
      } catch (err) {
        handleErrors(err, true);
      }
    };

    if (!text) {
      getTermos();
    }
  }, []);

  return (
    <Container>
      <CurrentPath pathList={pathList} />

      <SectionTitle
        icon={<TelescopeSVG />}
        title="Termos & Condições"
        margin="0"
      />

      <Content>
        <GreenButton
          onClick={() => {
            window.open(
              'http://www.planalto.gov.br/ccivil_03/leis/l8078compilado.htm',
              '_blank',
            );
          }}
          text="Código de Defesa do Consumidor"
          outline
        />

        {text && <p>{text}</p>}
      </Content>
    </Container>
  );
};

export default TermosCondicoes;
