import styled from 'styled-components';
import { fadeIn } from 'styles/globalStyle';
import {
  ProductDescription,
  Container as ProductCont,
} from 'components/Cart/CartItem/styles';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 50px 24px;

  > h1 {
    color: #f5f5f5;
    text-align: center;
    font-size: 2.25rem;
    font-weight: bold;
    margin-top: 24px;
    width: 100%;
  }

  > strong {
    margin-top: 16px;
    color: #58c78f;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    > button {
      background-color: transparent;
      border: none;
      color: #58c78f;
      text-decoration: underline;

      margin-bottom: 16px;

      transition: filter 0.25s ease;
      &:hover {
        filter: brightness(75%);
      }
    }
  }

  > section {
    display: flex;
    align-items: flex-start;
    margin-top: 24px;
    width: 100%;

    > span {
      display: flex;
      align-items: flex-start;
      min-height: 400px;
      width: 100%;

      > span {
        width: 100%;
        min-height: 400px;
        flex: 1;
        margin-left: 24px;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    > div {
      align-items: center;
      margin-top: 16px;
    }

    > section {
      > span {
        flex-direction: column;

        > span {
          margin-top: 24px;
          margin-left: 0;
        }
      }
    }
  }
`;

export const OrderContainer = styled.section`
  display: flex;
  align-items: flex-start;
  padding: 0 40px;
  margin-top: 50px;

  animation: ${fadeIn} 0.25s ease forwards;

  @media (max-width: 1500px) {
    flex-wrap: wrap;
    padding: 0;

    > article:nth-of-type(1) {
      flex-basis: 100%;
    }

    > article:nth-of-type(2) {
      margin-left: 0;
      margin-top: 24px;
    }

    > article:nth-of-type(3) {
      margin-top: 24px;
    }
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding: 0;

    > article:nth-of-type(1) {
      flex-basis: 100%;
    }

    > article:nth-of-type(2) {
      margin-left: 0;
      margin-top: 24px;
      min-width: 100%;
    }

    > article:nth-of-type(3) {
      margin-left: 0;
      min-width: 100%;
      margin-top: 24px;
    }
  }
`;

interface CardProps {
  padding?: string;
}

export const Card = styled.article<CardProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 50;
  width: 100%;

  + article {
    margin-left: 16px;
  }

  &:nth-of-type(1) {
    flex: 2;
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    flex: 1;
    /* max-width: 400px; */
  }

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 2px solid #4f1e66;
    border-radius: 25px 25px 0px 0px;

    padding: 16px 24px;
    width: 100%;

    background-color: #361c45a3;
    backdrop-filter: blur(16px);

    > svg {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }

    > strong {
      color: #58c78f;
      font-size: 1rem;

      &:nth-of-type(2) {
        margin-left: auto;
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 24px 0;
    ${({ padding }) => padding && `padding: ${padding};`}

    background-color: #68577180;
    backdrop-filter: blur(16px);
    border-radius: 0 0 25px 25px;
  }
`;

export const ProductContainer = styled.article`
  display: flex;
  align-items: center;

  padding: 8px;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 2px solid #685771;
  }

  > div {
    &:nth-of-type(1) {
      justify-content: flex-start;
      flex: 1;
    }

    &:nth-of-type(2) {
      max-width: 100px;
      min-width: 100px;
    }
  }

  @media (max-width: 450px) {
    ${ProductCont} {
      min-width: unset !important;
    }

    ${ProductDescription} {
      margin-top: 8px;
      margin-left: 0;

      > div {
        flex-direction: column !important;
      }
    }
  }
`;

export const MainDelivery = styled.main`
  display: flex;
  flex-direction: column;

  > div {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &:nth-of-type(1) {
      border-bottom: 1px solid #685771;

      > strong {
        color: #f5f5f5;
        margin-bottom: 16px;
        text-align: left;
      }

      > span {
        color: #f5f5f5;
        text-align: left;

        + span {
          margin-top: 8px;
        }
      }
    }

    &:nth-of-type(2) {
      margin-top: 16px;

      > span {
        margin-left: auto;
        color: #f5f5f5;
        font-size: 0.9rem;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;

        > span {
          display: flex;
          align-items: flex-start;
          color: #f5f5f5;

          + span {
            margin-left: 8px;
          }
        }
      }
    }
  }
`;

export const MainPayment = styled.main`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;

    &:nth-of-type(2) {
      padding: 0 24px;
    }

    &:nth-of-type(1) {
      border-bottom: 1px solid #685771;
      padding: 0 24px 40px;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        + div {
          margin-top: 16px;
        }

        > span {
          color: #f5f5f5;
          font-size: 1.15rem;

          &.crossLine {
            text-decoration: line-through;
          }

          &:nth-of-type(2) {
            font-size: 1.25rem;
            font-weight: bold !important;
            margin-left: 24px;
          }
        }
      }
    }
  }
`;

export const OrderObs = styled.article`
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;

  > strong {
    color: #fff;
  }

  > p {
    margin-top: 8px;
    line-height: 1.25;
    white-space: break-spaces;
    max-width: 450px;
    word-break: break-all;
    line-break: inherit;
  }
`;
