import styled, { css } from 'styled-components';

export const Container = styled.section<{ isShown?: boolean }>`
  position: fixed;
  left: 0;
  top: -100%;
  transform: translateY(-100%);
  width: 100vw;
  height: 100vh;
  display: flex;

  .flex-all {
    display: flex;
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
      transform: none;
      /* transform: translateY(0); */
    `}

  /* transition: top .25s ease; */
  transition: top .25s ease, transform .25s ease;
  z-index: 550;
`;

export const BackDrop = styled.div<{ isShown?: boolean }>`
  position: fixed;
  left: 0;
  top: -200%;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100%);

  ${(props) =>
    props.isShown &&
    css`
      top: 0;
      /* transform: translateY(0); */
      transform: none;
    `}

  /* transition: top 0.25s ease; */
  transition: top 0.25s ease, transform 0.25s ease;
  background-color: #2d143960;
  z-index: 500;
`;

export const Modal = styled.section<{ disableMinSize?: boolean }>`
  position: relative;
  display: flex;

  background: #4f1e66;
  box-shadow: 0px 0px 12px #00c76329;
  border: 1px solid #1b5b49;
  border-radius: 12px;

  min-width: 625px;
  min-height: 300px;

  transition: all 0.25s ease;

  > svg {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;

    top: 22px;
    right: 22px;

    color: #58c78f;
    fill: #58c78f;
    transition: filter 0.25s ease;

    &:hover {
      filter: brightness(85%);
    }
  }

  ${({ disableMinSize }) =>
    disableMinSize &&
    css`
      @media (max-width: 1000px) {
        min-width: unset;
        min-height: unset;
        max-height: 100vh;
        width: 100%;
        overflow-y: auto;
        margin: 24px;
      }
    `}
`;
