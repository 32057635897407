import React from 'react';

import { swalFire } from 'services/swal';
// eslint-disable-next-line
import { default as NumberFormat } from 'react-number-format';

// import Swal from 'sweetalert2';

import { CEPResponse, getAddress } from 'components/CEPDelivery';

interface InputProps {
  onSearchDone?: (cepResponse: CEPResponse) => void;
  onChangeInput(value: string): void;
  ignoreSearch?: boolean;
  currentValue?: string | number;
}

const MaskCep: React.FC<InputProps> = ({
  onSearchDone,
  onChangeInput,
  ignoreSearch = false,
  currentValue = '',
}: InputProps) => {
  return (
    <NumberFormat
      inputMode="numeric"
      displayType="input"
      format="#####-###"
      mask="_"
      value={currentValue}
      onValueChange={async (values) => {
        const rgxValue = `${values.value}`.replace(/[^\d]+/g, '');
        if (rgxValue.length === 8) {
          if (!ignoreSearch && onSearchDone) {
            const cepResponse = await getAddress(rgxValue);
            if (cepResponse !== undefined) {
              onSearchDone(cepResponse);
              return;
            }
          }

          swalFire({
            title: 'CEP não encontrado.',
            icon: 'error',
            toast: true,
            position: 'top-right',
            timer: 3000,
            showCloseButton: false,
          });
          return;
        }
        onChangeInput(values.value);
      }}
    />
  );
};

export default MaskCep;
