import React from 'react';
import SectionTitle from 'components/SectionTitle';
import { ReactComponent as DiscoSVG } from 'assets/draws/discovoador.svg';
import { ReactComponent as PlanetaSVG } from 'assets/backgrounds/footer/planeta_fundo.svg';
import { ReactComponent as GalaxiaSVG } from 'assets/backgrounds/galaxia.svg';
import {
  Container,
  Nav,
  NavContent,
  DescContainer,
  SpecContainer,
} from './styles';

interface Specs {
  title: string;
  rows: string[];
}

export interface AboutProductProps {
  description: any;
  // description: string;
  specs?: Specs[];
}

const AboutProduct: React.FC<AboutProductProps> = ({ specs, description }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const handleOnNavClick = (page: number): void => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <GalaxiaSVG />
      <PlanetaSVG />
      {/* <PlanetaSVG /> */}

      <SectionTitle icon={<DiscoSVG />} title="Sobre o produto" />

      <article>
        <Nav current={currentPage}>
          <button type="button" onClick={() => handleOnNavClick(1)}>
            Descrição
          </button>
          {specs && (
            <button type="button" onClick={() => handleOnNavClick(2)}>
              Especificações
            </button>
          )}
        </Nav>

        <NavContent current={currentPage}>
          {!description && <DescContainer />}
          {description && !Array.isArray(description) ? (
            // {/* eslint-disable-next-line */}
            <DescContainer dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            description &&
            Array.isArray(description) && (
              <DescContainer>
                {description &&
                  Array.isArray(description) &&
                  description.map((item: any) => {
                    if (item.type === 1) {
                      return <p>{item.content}</p>;
                    }

                    if (item.type === 2) {
                      let src = '';
                      if (item?.file?.b64) {
                        src = item.file.b64;
                      } else {
                        src = item.content;
                      }
                      return (
                        <figure>
                          <img src={src} alt=" " />
                        </figure>
                      );
                    }

                    return (
                      <iframe
                        width="560"
                        height="500"
                        src={`https://www.youtube.com/embed/${item.content}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    );
                  })}
              </DescContainer>
            )
          )}
          {/* <DescContainer dangerouslySetInnerHTML={{ __html: description }} /> */}

          <SpecContainer>
            {specs &&
              specs.map((item, index) => (
                // eslint-disable-next-line
                <div key={index}>
                  <strong>{item.title}</strong>
                  <div>
                    {item.rows.map((specI, specIdx) => (
                      // eslint-disable-next-line
                      <span key={specIdx}>{specI}</span>
                    ))}
                  </div>
                </div>
              ))}
          </SpecContainer>
        </NavContent>
      </article>
    </Container>
  );
};

export default AboutProduct;

/* <div>
  <strong>Altura</strong>
  <div>
    <span>24,6mm</span>
  </div>
</div>

<div>
  <strong>Tanque Normal (ml)</strong>
  <div>
    <span>2,3ml</span>
  </div>
</div>

<div>
  <strong>Tanque Bubble (ml)</strong>
  <div>
    <span>3,5ml</span>
  </div>
</div>

<div>
  <strong>Características</strong>
  <div>
    <span>
      Plataforma de Montagem de Terminal Quad Montado no Topo
    </span>
    <span>O-Ring Duplo Protegido</span>
    <span>Isolador PEEK Alemão</span>
    <span>Fluxo de ar ajustável inferior duplo</span>
    <span>Conexão banhada a ouro 510</span>
    <span>Construção 304 de aço inoxidável</span>
  </div>
</div> */
