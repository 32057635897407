import React, { createContext, useState, useContext, useEffect } from 'react';
import { api, handleErrors, handleRespReturn } from '../services/api';

export interface MenuItem {
  id: number;
  name: string;
  path: string;
  comingSoon?: boolean;
}

export interface Menu {
  title: string;
  path: string;
  items: MenuItem[];
}

export interface PropsData {
  mods?: Menu;
  atty?: Menu;
  pods?: Menu;
  kits?: Menu;
  accessory?: Menu;
  linhas?: MenuItem[];
}

interface MenuPropsData {
  props?: PropsData;
  getProps: () => PropsData | undefined;
  updateData: () => Promise<void>;
}

const MenuProps = createContext<MenuPropsData>({} as MenuPropsData);

const MenuPropsProvider: React.FC = ({ children }) => {
  const [props, setProps] = useState<PropsData | undefined>(():
    | PropsData
    | undefined => {
    let parsedStorage: PropsData | undefined;
    const storage = sessionStorage.getItem('@Constellation:PropsMenu');
    if (storage) {
      parsedStorage = JSON.parse(storage);
      if (parsedStorage) {
        return parsedStorage;
      }
    }
    api()
      .get('/products/menuProps')
      .then((resp) => {
        if (!resp.data.content) return undefined;
        sessionStorage.setItem(
          '@Constellation:PropsMenu',
          JSON.stringify({ ...resp.data.content }),
        );
        return { ...resp.data.content };
      })
      .catch((resp) => {
        // console.log('Cant get props', resp);
        if (parsedStorage) return { ...parsedStorage };
        return undefined;
      });

    if (parsedStorage) return { ...parsedStorage };
    return undefined;
  });

  const getProps = (): PropsData | undefined => props;

  const updateData = async (): Promise<void> => {
    api()
      .get('/products/menuProps')
      .then((resp) => {
        if (!resp.data.content) return;
        sessionStorage.setItem(
          '@Constellation:PropsMenu',
          JSON.stringify({ ...resp.data.content }),
        );
        setProps({ ...resp.data.content });
      })
      .catch((resp) => {
        // console.log('Cant get propsMenu', resp);
      });
  };

  useEffect(() => {
    // console.log('HookProps', props);
    if (!props) {
      updateData();
    }
  }, [props]);

  return (
    <MenuProps.Provider
      value={{
        props,
        getProps,
        updateData,
      }}
    >
      {children}
    </MenuProps.Provider>
  );
};

function useMenuProps(): MenuPropsData {
  const context = useContext(MenuProps);

  if (!context) {
    throw new Error('useMenuProps must be used within an MenuPropsProvider');
  }

  return context;
}

export { MenuPropsProvider, useMenuProps };
