import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 199, 99, 0.9);
  padding: 16px;

  > div {
    flex: 1;
  }

  > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;

    > h3 {
      align-self: center;
      margin-right: 30px;
    }
  }

  > div:nth-of-type(3) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 40px;

    > button + button {
      margin-left: 24px;
    }
  }

  > svg {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;

    color: #fff;

    width: 32px;
    height: 32px;

    transform: filter 0.25s ease;

    &:hover {
      filter: brightness(75%);
    }
  }
`;
