import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;

  background-color: transparent;

  color: #f5f5f5;
  font-weight: bold !important;
  font-size: 1rem;

  margin-right: 12px;

  > input {
    width: 14px;
    height: 14px;
    border: 1px solid #9b9b9b;
    border-radius: 2px;

    margin-right: 8px;
  }
`;
