import React, { useState } from 'react';

import { FiCornerDownRight } from 'react-icons/fi';

import { swalFire } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';
import { useAuth } from 'hooks/auth';

import { ReactComponent as TelescopioSVG } from '../../../assets/draws/telescopio.svg';
import { ReactComponent as LoaderSVG } from '../../../assets/loaders/rings.svg';
import SectionTitle from '../../SectionTitle';

import {
  Container,
  Main,
  ShowMore,
  DoAskContainer,
  SendButton,
  AskList,
} from './styles';

interface AskForm {
  text?: string;
  status?: boolean;
}

export interface Question {
  id: number;
  answer: string;
  question: string;
}

export interface QuestionsProps {
  questions: Question[];
  idProduct: number;
}

const Questions: React.FC<QuestionsProps> = ({ idProduct, questions }) => {
  const { token } = useAuth();

  const [isGetLoading, setIsGetLoading] = useState(false);
  const [questionList, setQuestionList] = useState<Question[]>(
    questions ? [...questions] : [],
  );

  const [ask, setAsk] = useState<AskForm>({});

  const handleShowMore = async (): Promise<void> => {
    if (isGetLoading) return;
    const excluded = questionList.map((item) => {
      return item.id;
    });

    setIsGetLoading(true);
    try {
      const resp = await api().request({
        url: `/products/question?idProduct=${idProduct}&excludedIds=${JSON.stringify(
          excluded,
        )}`,
        method: 'GET',
      });

      const isErrored = handleRespReturn(resp);
      setIsGetLoading(false);
      if (isErrored || !resp.data.content) return;
      if (resp.data.content && resp.data.content.length > 0) {
        const newQuestionList: Question[] = [
          ...questionList,
          ...resp.data.content,
        ];
        setQuestionList(newQuestionList);

        swalFire({
          title: 'Busca concluída.',
          icon: 'success',
          position: 'top-right',
          toast: true,
          timer: 2000,
        });
        return;
      }
      swalFire({
        title: 'Nenhuma pergunta encontrada.',
        icon: 'warning',
        position: 'top-right',
        toast: true,
        timer: 2000,
      });
      return;
    } catch (err) {
      setIsGetLoading(false);
      handleErrors(err, true);
    }
  };

  const handleSendAsk = async (): Promise<void> => {
    if (ask.status === false) return;
    if (!ask.text) {
      swalFire({
        icon: 'warning',
        text: 'Preencha a mensagem.',
        toast: true,
        timer: 2000,
      });
      return;
    }

    if (!token) {
      swalFire({
        icon: 'info',
        text: 'Você precisa estar logado para fazer uma pergunta.',
      });
      return;
    }

    setAsk({ ...ask, status: false });

    try {
      const resp = await api().request({
        url: '/products/question',
        method: 'POST',
        data: { idProduct, question: ask.text },
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored) {
        setAsk({ status: undefined });
        return;
      }

      setAsk({ status: true });
      return;
    } catch (err) {
      handleErrors(err, true);
    }
    setAsk({ ...ask, status: undefined });
  };

  // const handleButtonLabel = (state?: boolean): string | JSX.Element => {
  //   if (state === undefined) {
  //     return 'Perguntar';
  //   }

  //   if (state === false) {
  //     return <LoaderSVG />;
  //   }

  //   return 'Enviado!';
  // };

  return (
    <Container>
      <SectionTitle title="Perguntas" icon={<TelescopioSVG />} />

      <Main>
        <DoAskContainer>
          <h3>Faça sua pergunta</h3>

          <textarea
            onChange={({ target }) => {
              const text = target.value;
              if (ask.status === false) {
                setAsk({ ...ask });
                return;
              }
              if (ask.status === true) {
                setAsk({ text, status: undefined });
                return;
              }
              setAsk({ text });
            }}
            value={ask.text || ''}
            placeholder="Escreva a sua pergunta..."
          />

          <SendButton onClick={handleSendAsk} send={ask.status}>
            <span>Perguntar</span>
            <LoaderSVG />
            <span>Enviado!</span>
          </SendButton>
        </DoAskContainer>

        <AskList>
          <h1>
            {questionList && questionList.length === 0
              ? 'Nenhuma pergunta feita.'
              : 'Últimas feitas'}
          </h1>

          {questionList &&
            questionList.length > 0 &&
            questionList.map((item) => (
              <div key={item.id}>
                <strong>{item.question}</strong>
                <span>
                  <FiCornerDownRight />
                  {item.answer}
                </span>
              </div>
            ))}
        </AskList>

        <ShowMore onClick={handleShowMore} hidden={questionList.length === 0}>
          Ver mais
        </ShowMore>
      </Main>
    </Container>
  );
};

export default Questions;
