import React from 'react';

import { FilterPropsProvider } from './filterProps';
import { ProductPropsProvider } from './productProps';
import { MenuPropsProvider } from './menuProps';
import { AgeCheckProvider } from './AgeCheck';
import { CartProvider } from './Cart';
import { NotificationProvider } from './notification';
import { AuthProvider } from './auth';
import { FavoritesProvider } from './Favorites';

const AppProvider: React.FC = ({ children }) => (
  <AgeCheckProvider>
    <ProductPropsProvider>
      <MenuPropsProvider>
        <FilterPropsProvider>
          <FavoritesProvider>
            <NotificationProvider>
              <CartProvider>
                <AuthProvider>{children}</AuthProvider>
              </CartProvider>
            </NotificationProvider>
          </FavoritesProvider>
        </FilterPropsProvider>
      </MenuPropsProvider>
    </ProductPropsProvider>
  </AgeCheckProvider>
);

export default AppProvider;
