import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import GreenButton from 'components/Button/GreenButton';
import CurrentPath from 'components/CurrentPath';
import SectionTitle from 'components/SectionTitle';
import { ReactComponent as TelescopeSVG } from 'assets/draws/telescopio.svg';
import MrStoomPNG from 'assets/MrStoom.png';

import {
  Container,
  Content,
  RoundBoxContainer,
  MrStoomContainer,
  TextContainer,
  Bold,
  GreenBold,
} from './styles';

const pathList = [
  {
    title: 'Início',
    to: '/',
  },
  {
    title: 'Sobre nós',
    to: '/sobre-nos',
  },
];

const AboutUs: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <CurrentPath pathList={pathList} />
      <SectionTitle icon={<TelescopeSVG />} title="Sobre nós" margin="0" />

      <Content>
        <RoundBoxContainer>
          <div>
            <strong>+15mil</strong>
            <span>pedidos</span>
          </div>

          <div>
            <strong>+400</strong>
            <span>vendas mensais</span>
          </div>

          <div>
            <strong>+3mil</strong>
            <span>clientes</span>
          </div>
        </RoundBoxContainer>

        <TextContainer>
          <p>
            A Constellation nasceu em 2016, sendo uma das&nbsp;
            <GreenBold>marcas pioneiras no Brasil</GreenBold> a atuar neste
            ramo, e a <Bold>primeira no estado de Santa Catarina</Bold>. Nossa
            paixão por este universo começou em 2012.
            <br />
            Após quatro anos de muito treinamento, erros e aprendizado, nosso
            juicemaker preparou as suas primeiras receitas e, satisfeito com o
            resultado, quis compartilhar seus sabores com mais pessoas. Desse
            momento em diante, a Constellation surge com uma missão muito
            clara:&nbsp;
            <GreenBold>
              trazer produtos saborosos e com qualidade para todos os vapers do
              Brasil, por um preço justo e com uma experiência única!
            </GreenBold>
          </p>

          <p>
            Com muito esforço e conhecimento adquirido, conseguimos nosso espaço
            no mercado com preços diferenciados, promoções exclusivas e
            benefícios únicos para quem compra com a gente. Pensando em tudo
            isso, encontramos uma nova paixão:&nbsp;
            <GreenBold>os nossos clientes</GreenBold>. Nos primeiros meses de
            empresa já sabíamos que vocês, que confiam na Constellation, seriam
            o nosso ponto forte, e trabalhamos com mais afinco para garantir um
            atendimento diferenciado e humanizado, pensado para que a sua
            experiência seja satisfatória do início ao fim. Nesse contexto,
            criamos&nbsp;
            <GreenBold>
              o maior cardápio do Brasil, com mais de 50 sabores
            </GreenBold>
            &nbsp;e diversas personalizações feitas pelo cliente, possibilitando
            criações únicas para o seu paladar.
          </p>

          <p>
            E mesmo após cinco anos de empresa, mais de&nbsp;
            <GreenBold>15 mil pedidos atendidos</GreenBold>e uma média de&nbsp;
            <GreenBold>300 compras mensais</GreenBold>, as nossas duas
            principais missões seguem as mesmas:&nbsp;
            <GreenBold>
              o foco no produto excepcional e no cliente satisfeito.
            </GreenBold>
            <br />
            Independentemente de qualquer dificuldade, mantivemos um atendimento
            humanizado e individual a cada cliente, garantindo uma experiência
            formidável do pré-compra ao pós-compra. Hoje contamos com seis
            colaboradores diretos e diversos colaboradores indiretos em nossas
            operações, motivados em manter vivas as nossas missões e os nossos
            valores. Agora que você conhece um pouco mais da nossa
            história,&nbsp;
            <GreenBold>
              convidamos a viver essa experiência junto conosco.
            </GreenBold>
          </p>

          <GreenButton
            onClick={() => {
              history.push('/');
            }}
            text="Ir para a página inicial"
            bold
            fullRound
            size="big"
          />
        </TextContainer>

        <MrStoomContainer>
          <img src={MrStoomPNG} alt="MrStoom" />
        </MrStoomContainer>
      </Content>
    </Container>
  );
};

export default AboutUs;
