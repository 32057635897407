import React, { useEffect, useState } from 'react';

import {
  Switch,
  Route,
  useLocation,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';

import { useAuth } from 'hooks/auth';
import { swalFire } from 'services/swal';
import { handleErrors } from 'services/api';

import ForgotEmail from 'components/Modal/ForgotEmail';
import ForgotPass from 'components/Modal/ForgotPass';
import ForgotPassConfirm from 'components/Modal/ForgotPassConfirm';
// import UpdateUser from 'components/Modal/UpdateUser';

// import GoogleButton from 'components/Button/GoogleButton';
import InputContainer from 'components/Input/Container';
import InputGeneric from 'components/Input/Generic';
import SectionTitle from 'components/SectionTitle';
import GreenButton from 'components/Button/GreenButton';

import { ReactComponent as RocketSVG } from 'assets/draws/espaconave.svg';

import Register from './Register';

import {
  ClientContainer,
  LoginContainer,
  RegisterContainer,
  ForgotLink,
} from './styles';

interface Login {
  email?: string;
  password?: string;
  isLoading?: boolean;
}

const User: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { signIn } = useAuth();
  const { path } = useRouteMatch();

  const [register, setRegister] = useState<string>();
  const [login, setLogin] = useState<Login>({
    isLoading: false,
  });

  const [modalFMailProps, setModalFMailProps] = useState<any>({
    isShown: false,
    close: () => setModalFMailProps({ ...modalFMailProps, isShown: false }),
    open: () => setModalFMailProps({ ...modalFMailProps, isShown: true }),
  });

  const [modalFPassProps, setModalFPassProps] = useState<any>({
    isShown: false,
    close: () => setModalFPassProps({ ...modalFPassProps, isShown: false }),
    open: () => setModalFPassProps({ ...modalFPassProps, isShown: true }),
  });

  const [modalFPConfirmProps, setModalFPConfirmProps] = useState<any>({
    isShown: false,
    close: () =>
      setModalFPConfirmProps({ ...modalFPConfirmProps, isShown: false }),
    open: (thisHash: string) =>
      setModalFPConfirmProps({
        ...modalFPConfirmProps,
        isShown: true,
        hash: thisHash,
      }),
  });

  // const [modalUpdateUserProps, setModalUpdateUserProps] = useState<any>({
  //   isShown: false,
  //   close: () =>
  //     setModalUpdateUserProps({ ...modalUpdateUserProps, isShown: false }),
  //   open: (thisHash: string, thisEmail?: string, thisUdoc?: string) =>
  //     setModalUpdateUserProps({
  //       ...modalUpdateUserProps,
  //       isShown: true,
  //       hash: thisHash,
  //       email: thisEmail,
  //       udoc: thisUdoc,
  //     }),
  // });

  useEffect(() => {
    const urlQuery = new URLSearchParams(location.search || '');
    const target = urlQuery.get('target');
    const hash = urlQuery.get('hash');

    if (!hash) return;

    if (target === 'newPass') {
      modalFPConfirmProps.open(hash);
    }
    // else if (target === 'updateUser') {
    //   const email = urlQuery.get('mail');
    //   const udoc = urlQuery.get('udoc');
    //   modalUpdateUserProps.open(hash, email, udoc);
    // }

    // eslint-disable-next-line
  }, []);

  const handleLogin = async (): Promise<void> => {
    if (login.isLoading) return;
    if (!login.email || !login.password) {
      swalFire({
        text: 'Preencha email e senha.',
        icon: 'warning',
      });
      return;
    }
    setLogin({ ...login, isLoading: true });
    try {
      await signIn({
        username: login.email,
        password: login.password,
        lastLogin: new Date().getTime(),
      });
      history.push('/profile');
    } catch (err) {
      setLogin({ ...login, isLoading: false });
      handleErrors(err, true);
    }
  };

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ClientContainer>
          {/* <UpdateUser {...modalUpdateUserProps} /> */}
          <ForgotEmail {...modalFMailProps} />
          <ForgotPass {...modalFPassProps} />
          <ForgotPassConfirm {...modalFPConfirmProps} />

          <SectionTitle
            icon={<RocketSVG />}
            title="Área do cliente"
            margin="0"
          />

          <section>
            <RegisterContainer>
              <div>
                <strong>Quero criar uma conta</strong>

                <InputContainer title="E-mail" direction="row">
                  <InputGeneric
                    type="email"
                    sideButton
                    value={register}
                    onChangeCustom={({ target }) => {
                      setRegister(target.value);
                    }}
                  >
                    <GreenButton
                      text="Continuar"
                      sideRound
                      onClick={() => {
                        history.push('/user/register');
                      }}
                    />
                  </InputGeneric>
                </InputContainer>

                {/* <strong>Ou faça login com o Google</strong> */}

                {/* <GoogleButton
                  onClick={() => {
                    console.log('Google login click');
                  }}
                  title="Faça login com o Google"
                /> */}
              </div>
            </RegisterContainer>

            <LoginContainer>
              <div>
                <strong>Já sou cliente</strong>
                <span>
                  Se você já possuía cadastro conosco, pedimos gentilmente para
                  que se cadastre novamente.
                </span>
                <InputContainer title="E-mail" direction="row">
                  <InputGeneric
                    type="email"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) handleLogin();
                    }}
                    value={login.email}
                    onChangeCustom={({ target }) => {
                      setLogin({ ...login, email: target.value });
                    }}
                  />
                </InputContainer>
                <InputContainer title="Senha" direction="row">
                  <InputGeneric
                    type="password"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) handleLogin();
                    }}
                    value={login.password}
                    onChangeCustom={({ target }) => {
                      setLogin({ ...login, password: target.value });
                    }}
                  />
                </InputContainer>

                <GreenButton
                  isLoading={login.isLoading}
                  text="Continuar"
                  onClick={handleLogin}
                />

                <span>
                  Esqueci meu&nbsp;
                  <ForgotLink onClick={() => modalFMailProps.open()}>
                    e-mail
                  </ForgotLink>
                  &nbsp; ou minha&nbsp;
                  <ForgotLink onClick={() => modalFPassProps.open()}>
                    senha
                  </ForgotLink>
                </span>
              </div>
            </LoginContainer>
          </section>
        </ClientContainer>
      </Route>

      <Route path="/user/register">
        <Register email={register} />
      </Route>
    </Switch>
  );
};

export default User;
