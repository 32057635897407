//  eslint-disable react/destructuring-assignment
import React, { useState, useEffect, useRef } from 'react';

import { useMenuProps, Menu as MenuObj, MenuItem } from 'hooks/menuProps';
import DropdownMenu from 'components/Dashboard/DropdownMenu';
import DropdownItem from 'components/Dashboard/DropdownItem';
import DropDownLogo from 'components/Dashboard/DropDownLogo';
import DropdownJuices from 'components/Dashboard/DropdownJuices';

import PodSvg from 'assets/categorias/POD.svg';
import AtomizadorSvg from 'assets/categorias/Atomizador.svg';
import KitSvg from 'assets/categorias/KIT.svg';
import ModSvg from 'assets/categorias/MOD.svg';
import BateriaSvg from 'assets/categorias/Bateria.svg';

import { Container } from './styles';

interface List {
  mods?: MenuObj;
  atty?: MenuObj;
  pods?: MenuObj;
  kits?: MenuObj;
  accessory?: MenuObj;
  linhas?: MenuItem[];
}

const Menu: React.FC = () => {
  const { props } = useMenuProps();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [list, setList] = useState<List | undefined>(props);

  const [currentMenu, setCurrentMenu] = useState<number | undefined>(undefined);

  const handleMenuClick = (menu: number) => {
    setCurrentMenu(undefined);
    if (currentMenu === menu) return;
    setTimeout(() => setCurrentMenu(menu), 250);
  };

  const closeMenu = (): void => setCurrentMenu(undefined);

  useEffect(() => {
    const externalEventHandler = (e: any): void => {
      if (currentMenu === undefined) return;

      const node = dropdownRef.current;

      if (node && node.contains(e.target)) {
        return;
      }

      setCurrentMenu(undefined);
    };

    if (currentMenu !== undefined) {
      document.addEventListener('click', externalEventHandler);
    } else {
      document.removeEventListener('click', externalEventHandler);
    }

    return () => {
      document.removeEventListener('click', externalEventHandler);
    };
  }, [currentMenu]);

  // useEffect(() => {
  //   console.log('CheckEffect', list);
  //   if (!list) {
  //     console.log('CheckEffectIf', list);
  //     updateData();
  //     setTimeout(() => {
  //       setList(getProps());
  //       console.log('CheckEffectIfTime', list, getProps());
  //     }, 2000);
  //   }
  // }, [list]);

  useEffect(() => {
    if (!list && props) {
      setList(props);
    }
  }, [props]);

  return (
    <Container ref={dropdownRef} current={currentMenu}>
      <div>
        <button type="button" onClick={() => handleMenuClick(1)}>
          Juices
        </button>
        <button type="button" onClick={() => handleMenuClick(2)}>
          Equipamentos
        </button>
        <button type="button" onClick={() => handleMenuClick(3)}>
          Acessórios
        </button>
      </div>

      <section>
        {currentMenu && currentMenu === 1 && (
          <article>
            <DropdownJuices>
              {list &&
                list.linhas &&
                list.linhas.map((item) => (
                  <DropDownLogo
                    key={item.id}
                    idx={item.id}
                    path={item.path}
                    comingSoon={item.comingSoon}
                    closeMenu={closeMenu}
                  />
                ))}
            </DropdownJuices>
          </article>
        )}

        {currentMenu && currentMenu === 2 && (
          <article>
            <DropdownMenu>
              {/* {list?.mods && (
                <DropdownItem
                  bgImage={ModSvg}
                  title={list.mods.title}
                  titlePath="/filter?category=mod"
                  subItems={list.mods.items}
                  closeMenu={closeMenu}
                />
              )}

              {list?.atty && (
                <DropdownItem
                  maxHeight="80px"
                  bgImage={AtomizadorSvg}
                  title={list.atty.title}
                  titlePath="/filter?category=atty"
                  // titlePath={list.atty.path}
                  subItems={list.atty.items}
                  closeMenu={closeMenu}
                />
              )} */}

              {list?.pods && (
                <DropdownItem
                  bgImage={PodSvg}
                  title={list.pods.title}
                  titlePath="/filter?category=pod"
                  // titlePath={list.pods.path}
                  subItems={list.pods.items}
                  closeMenu={closeMenu}
                />
              )}

              {/* {list?.kits && (
                <DropdownItem
                  bgImage={KitSvg}
                  title={list.kits.title}
                  titlePath="/filter?category=kit"
                  // titlePath={list.kits.path}
                  subItems={list.kits.items}
                  closeMenu={closeMenu}
                />
              )} */}
            </DropdownMenu>
          </article>
        )}

        {currentMenu && currentMenu === 3 && (
          <article>
            <DropdownMenu>
              {list?.accessory && (
                <DropdownItem
                  extraMargin
                  bgImage={BateriaSvg}
                  title={list.accessory.title}
                  titlePath="/filter?category=accessory"
                  // titlePath={list.accessory.path}
                  subItems={list.accessory.items}
                  closeMenu={closeMenu}
                />
              )}
            </DropdownMenu>
          </article>
        )}
      </section>
    </Container>
  );
};

export default Menu;

/* <section>
  <article>
    <DropdownJuices>
      <DropDownLogo icon={<SilverSvg />} />
      <DropDownLogo icon={<SilverSvg />} />
      <DropDownLogo icon={<BronzeSvg />} />
      <DropDownLogo icon={<CombosSvg />} />
      <DropDownLogo icon={<SaltSvg />} />
      <DropDownLogo icon={<AtabacadosSvg />} />
      <DropDownLogo icon={<ImportadosSvg />} />
    </DropdownJuices>
  </article>

  <article>
    <DropdownMenu>
      <DropdownItem bgImage={ModSvg} title="mods" subItems={mods} />
      <DropdownItem
        bgImage={AtomizadorSvg}
        title="atomizadores"
        subItems={atomizadores}
      />
      <DropdownItem bgImage={PodSvg} title="pods" subItems={pods} />
      <DropdownItem bgImage={KitSvg} title="kits" subItems={kits} />
    </DropdownMenu>
  </article>

  <article>
    <DropdownMenu>
      <DropdownItem
        bgImage={BateriaSvg}
        title="acessórios"
        subItems={acessorios}
      />
    </DropdownMenu>
  </article> */
