import styled, { css } from 'styled-components';

export const Container = styled.section<{ isShown?: boolean }>`
  position: fixed;
  left: 0;
  top: -100%;
  transform: translateY(-100%);
  width: 100vw;
  height: 100vh;
  display: flex;

  .flex-all {
    display: flex;
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ isShown }) =>
    isShown &&
    css`
      top: 0;
      transform: none;
    `}

  /* transition: top .25s ease; */
  transition: top .25s ease, transform .25s ease;
  z-index: 550;

  > svg {
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;

    opacity: 0.2;
    top: 28px;
    right: 28px;

    /* color: #fff;
  fill: #fff; */
    transition: filter 0.25s ease;

    &:hover {
      filter: brightness(85%);
    }
  }
`;

export const BackDrop = styled.div<{ isShown?: boolean }>`
  position: fixed;
  left: 0;
  top: -200vh;
  width: 100vw;
  height: 100vh;
  transform: translateY(-100%);

  ${({ isShown }) =>
    isShown &&
    css`
      top: 0;
      transform: none;
    `}

  /* transition: top 0.25s ease; */
  transition: top 0.25s ease, transform 0.25s ease;
  background-color: #2d1439;
  z-index: 500;
`;

export const Modal = styled.section<{ img: string; isShown?: boolean }>`
  position: relative;
  display: flex;

  background: transparent;
  box-shadow: 0px 3px 6px #aeaeae29;
  border-radius: 10px;

  width: 100%;
  height: 100%;

  transition: all 0.25s ease;

  > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 50vw;

    > div:nth-of-type(1) {
      position: absolute;
      background-image: url('${({ img }) => img}');

      /* width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-position: calc(100vw - 200%) 5%;
      background-size: 70% 200%; */

      width: 50%;
      height: 100vh;
      right: 0;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      background-size: 140% 140%;
    }

    > svg:nth-of-type(1) {
      position: absolute;
      top: 40px;
      left: 32px;
      min-width: 380px;
      min-height: 125px;
    }

    > svg:nth-of-type(2) {
      position: absolute;
      transform: translateY(-75%) translateX(-25%);
      ${({ isShown }) => (isShown ? 'top: 75%;' : 'top: -100vh;')}
      left: 0;
      z-index: 20;
      opacity: 0.15;
      width: 100%;
      height: 100%;
      /* height: 150%; */
    }

    > div:nth-of-type(2) {
      position: relative;
      z-index: 50;

      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: auto;

      > h1 {
        position: relative;
        z-index: 50;
        color: #c7e22b;
        font-size: 2.4rem;
        margin-bottom: 100px;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          border-radius: 5px;
          font-size: 1.2rem;
          font-weight: bold !important;
          padding: 12px 24px;
          height: 55px;
          flex: 1;

          transition: filter 0.25s ease;
          &:hover {
            filter: brightness(75%);
          }
        }

        > button:nth-of-type(1) {
          border: 1px solid #ff5d5d;
          color: #ff5d5d;
          background-color: transparent;
        }

        > button:nth-of-type(2) {
          border: 1px solid #00c763;
          background-color: #00c763;
          color: #ffffff;
          margin-left: 40px;
        }
      }
    }
  }

  > div:nth-of-type(2) {
    flex: 1;
    background-image: url('${({ img }) => img}');
    background-repeat: no-repeat;
    background-position: 50% 5%;
    background-size: 150% 200%;
  }

  @media (max-width: 1080px) {
    > div:nth-of-type(1) {
      align-items: center;
      max-width: 100vw;

      > svg:nth-of-type(1) {
        left: 50%;
        transform: translateX(-50%);
      }

      > svg:nth-of-type(2) {
        transform: translateY(0%) translateX(-25%);
        top: 0;
        left: 0;
        height: unset;
      }

      > div:nth-of-type(1) {
        display: none;
      }

      > div:nth-of-type(2) {
        margin-left: unset;
        padding: 0 16px;

        > h1 {
          text-align: center;
          font-size: 2rem;
        }

        > div {
          > button {
            padding: 8px;
            height: 45px;
          }

          > button:nth-of-type(2) {
            margin-left: 24px;
          }
        }
      }
    }
  }
`;
