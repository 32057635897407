import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export interface ButtonSizes {
  small: FlattenSimpleInterpolation;
  medium: FlattenSimpleInterpolation;
  big: FlattenSimpleInterpolation;
}

const buttonSizes = {
  small: css`
    > span {
      font-size: 0.9rem;
    }
    height: 25px;
    padding: 6px 4px;
    > svg {
      width: 20px;
      height: 20px;
    }
  `,

  medium: css``,
  big: css`
    > span {
      font-size: 1.5rem;
    }
    height: 60px;
    padding: 12px;

    > svg {
      width: 32px;
      height: 32px;
    }
  `,
};

export interface ButtonColors {
  1: string;
  2: string;
}

const buttonColors = {
  1: '#fff',
  2: '#ec2626',
};

export interface ButtonStyleProps {
  hasIcon: boolean;
  isOutline: boolean;
  size: keyof ButtonSizes;
  customColor?: keyof ButtonColors;
  isBold: boolean;
  isRound: boolean;
  isSideRound: boolean;
  isLoading: boolean;
}

export const Button = styled.button<ButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ customColor }) => css`
    background: #00c763;
    border: 1px solid #00c763;
    ${customColor &&
    css`
      background: ${buttonColors[customColor]};
      border: 1px solid ${buttonColors[customColor]};
    `}
  `}
  text-align: center;
  border-radius: 5px;
  min-width: 130px;

  transition: filter 0.25s ease;

  &:hover {
    filter: brightness(85%);
  }

  > span {
    color: #fff;
    font-size: 1rem;
    transition: color 0.25s ease;
  }

  height: 35px;
  padding: 6px 18px;

  ${({ size, hasIcon, isBold, isRound, isSideRound }) => css`
    ${isRound && 'border-radius: 20px;'}
    ${isSideRound && 'border-radius: 0px 5px 5px 0px;'}
    > span {
      ${isBold && 'font-weight: bold;'}
    }

    ${hasIcon &&
    css`
      > svg {
        width: 24px;
        height: 24px;
        color: #fff;
        fill: #fff;
        margin-left: 6px;
        transition: color 0.25s ease, fill 0.25s ease;
      }
    `}

    ${buttonSizes[size]}
  `}

  ${({ isOutline, customColor }) =>
    isOutline &&
    css`
      background: transparent;
      border-color: #00c763;
      color: #00c763;

      > span {
        color: #00c763;
        transition: color 0.25s ease;
      }

      > svg {
        color: #00c763;
        fill: #00c763;
      }

      transition: color 0.25s ease, background-color 0.25s ease;

      &:hover {
        color: #f5f5f5;
        background: #00c763;
        filter: unset;

        > span {
          color: #f5f5f5;
        }

        > svg {
          color: #f5f5f5;
          fill: #f5f5f5;
        }
      }

      ${customColor &&
      css`
        color: ${buttonColors[customColor]};
        border-color: ${buttonColors[customColor]};

        > span {
          color: ${buttonColors[customColor]};
        }

        > svg {
          color: ${buttonColors[customColor]};
          fill: ${buttonColors[customColor]};
        }

        &:hover {
          background: ${buttonColors[customColor]};
        }
      `}
    `}


    ${({ isLoading }) =>
    isLoading &&
    css`
      filter: brightness(75%);
      cursor: not-allowed;
      pointer-events: none;
      user-select: none;

      > span:nth-of-type(1) {
        display: none;
      }

      > span:nth-of-type(2) {
        animation: ${fadeIn} 0.25s ease forwards;
      }
    `}
`;

// ${(props) =>
//   props.isOutline &&
//   css`
//     background: transparent;
//     border-color: #fff;

//     transition: border-color 0.25s ease, color 0.25s ease,
//       background-color 0.25s ease;

//     &:hover {
//       background: rgba(255, 255, 255, 0.25);
//       border-color: rgba(255, 255, 255, 0.25);
//       > span {
//         color: rgba(255, 255, 255, 0.25);
//       }
//     }
//   `}
