import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import FocusLock from 'react-focus-lock';

import { ReactComponent as LogoSVG } from 'assets/logo/logo.svg';
import { ReactComponent as GalaxiaSVG } from 'assets/backgrounds/galaxia.svg';
import MrStoomPNG from 'assets/MrStoom.png';
import { Container, BackDrop, Modal } from './styles';

export interface AgeCheckProps {
  isShown: boolean;
  handleSubmit(): void;
}

const ModalAgeCheck: React.FC<AgeCheckProps> = ({ isShown, handleSubmit }) => {
  const image = React.useMemo((): string => {
    return MrStoomPNG;
  }, []);
  const modal = (
    <>
      <BackDrop isShown={isShown} />
      <Container isShown={isShown}>
        <FocusLock className="flex-all" disabled={!isShown}>
          <Modal img={image} isShown={isShown}>
            <div>
              <div />
              <LogoSVG />
              <GalaxiaSVG />

              <div>
                <h1>Você tem mais de 18 anos?</h1>

                <div>
                  <button
                    type="button"
                    onClick={() => {
                      window.location.replace('https://www.google.com');
                    }}
                  >
                    Não
                  </button>
                  <button type="button" onClick={() => handleSubmit()}>
                    Sim, ir para a loja
                  </button>
                </div>
              </div>
            </div>

            {/* <div /> */}
          </Modal>
        </FocusLock>
      </Container>
    </>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default ModalAgeCheck;
