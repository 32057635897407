import React, { useState, useEffect } from 'react';

import { NavLink, useHistory } from 'react-router-dom';

import CartProduct from 'components/Cart/CartItem';

import { useCart, CartItem } from 'hooks/Cart';
import { useAuth } from 'hooks/auth';
import { swalPromise, swalFire, swalToast } from 'services/swal';
import { api, handleErrors, handleRespReturn } from 'services/api';

import { ReactComponent as GalaxiaSVG } from 'assets/backgrounds/galaxia.svg';
import { ReactComponent as PlanetaSVG } from 'assets/backgrounds/footer/planeta_fundo.svg';

import GreenButton from 'components/Button/GreenButton';
import IntegerCustom from 'components/Input/IntegerCustom';
import DiscountCupom, { CupomState } from 'components/DiscountCupom';
import DeliveryOptions, {
  DeliveryOptions as DeliveryOpType,
} from 'components/DeliveryOptions';
import CEPDelivery, {
  CEPResponse,
  formatAddress,
} from 'components/CEPDelivery';
import { currencyBRL } from 'utils/format';

import {
  Container,
  Content,
  CardTotal,
  CardDelivery,
  CardTable,
  DiscountDiv,
} from './styles';

const Cart: React.FC = () => {
  const {
    cartProducts,
    total,
    changeQuantity,
    removeItem,
    cartProductsPromo,
  } = useCart();
  const { token, user } = useAuth();
  const history = useHistory();

  const [cep, setCep] = useState<string | undefined>();
  const [location, setLocation] = useState<CEPResponse | undefined>();
  const [cupom, setCupom] = useState<CupomState>({});
  const [delivery, setDelivery] = useState<DeliveryOpType | undefined>();
  const [deliveryOptions, setDeliveryOptions] = useState<
    DeliveryOpType[] | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const handleQuantityChange = (item: CartItem, quantity: number): void => {
    if (quantity === 0) {
      swalPromise({
        html: `Você está prestes a remover o produto <b>${item.name}</b> do carrinho.`,
        icon: 'warning',
        confirmButtonText: 'Sim, quero remover.',
      }).then(async (result) => {
        if (result.isConfirmed) {
          removeItem(item.id);
        }
      });
      setDeliveryOptions(undefined);
      return;
    }
    setDeliveryOptions(undefined);
    changeQuantity(item.id, quantity);
  };

  const handleFinish = async (): Promise<void> => {
    if (!delivery || !delivery.id) {
      swalFire({ text: 'Selecione um método de entrega.', icon: 'info' });
      return;
    }

    if (!cartProducts || cartProducts.length === 0) {
      swalFire({ text: 'Seu carrinho está vazio.', icon: 'info' });
      return;
    }

    if (!token || !user) {
      history.push('/user');
      swalToast({
        text: 'Faça login antes de efetuar a compra.',
        icon: 'info',
      });
      // swalFire({
      //   text: 'Faça login antes de fazer um pedido.',
      //   icon: 'warning',
      // });
      return;
    }

    setIsLoading(true);
    try {
      const resp = await api().request({
        url: `/cart`,
        method: 'POST',
        data: {
          cupom: cupom?.discount && cupom?.code ? cupom?.code : undefined,
          shipment: delivery,
          products: cartProducts.map((item) => {
            return {
              ...item,
              img: undefined,
              // name: undefined,
              // type: undefined,
            };
          }),
        },
      });
      const isErrored = handleRespReturn(resp);
      setIsLoading(false);
      if (isErrored || !resp.data.content) return;
      // console.log('Being fixed', resp.data.content);
      history.push(`/checkout/${resp.data.content}`);
    } catch (err) {
      setIsLoading(false);
      handleErrors(err, true);
    }
  };

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // useEffect(() => {
  //   if (!cupom.code || !cupom?.discount || !cupom?.discountType) return;
  //   if (total) {
  //     if (cupom?.discount) {
  //       let discountVal = total;

  //       if (cupom?.discountType === 'fixed') {
  //         discountVal -= cupom.discount;
  //       } else {
  //         discountVal *= cupom.discount;
  //       }

  //       setCupom({ ...cupom, discountVal });
  //     }
  //   }
  // }, [cupom, total]);

  const deliveryPrice = React.useMemo(() => {
    if (!delivery || !delivery.price || delivery.isFree) return 0;
    return parseInt(`${delivery.price}`, 10);
  }, [delivery]);

  const subTotal = React.useMemo(() => {
    return total || 0;
  }, [total]);

  // const totalDiscount = React.useMemo(() => {
  //   let result = subTotal * 0.9;

  //   if (cupom?.code && cupom?.discountVal) {
  //     result -= cupom.discountVal;
  //   }

  //   result += deliveryPrice;

  //   return result;
  // }, [subTotal, cupom, deliveryPrice]);

  const totalDiscount10 = React.useMemo(() => {
    let result = subTotal;

    if (cartProductsPromo.discountNicFree)
      result -= cartProductsPromo.discountNicFree;

    if (cartProductsPromo.discountNicSalt)
      result -= cartProductsPromo.discountNicSalt;

    // const discount10 = result * 0.07;
    const discount10 = result * 0.1;

    result -= discount10;
    result += deliveryPrice;

    if (cupom?.code && cupom?.discountVal) {
      result -= cupom.discountVal;
    }

    return result;
  }, [
    subTotal,
    cupom,
    cartProductsPromo.discountNicSalt,
    cartProductsPromo.discountNicFree,
    deliveryPrice,
  ]);

  const totalCartPrice = React.useMemo(() => {
    let result = subTotal;

    if (cupom?.code && cupom?.discountVal) {
      result -= cupom.discountVal;
    }

    result += deliveryPrice;

    if (cartProductsPromo.discountNicFree)
      result -= cartProductsPromo.discountNicFree;

    if (cartProductsPromo.discountNicSalt)
      result -= cartProductsPromo.discountNicSalt;
    return result;
  }, [
    subTotal,
    cupom,
    deliveryPrice,
    cartProductsPromo.discountNicFree,
    cartProductsPromo.discountNicSalt,
  ]);

  return (
    <Container>
      <h1>Meu carrinho</h1>
      <NavLink to="/">Escolher mais produtos</NavLink>

      <Content>
        <GalaxiaSVG />
        <PlanetaSVG />

        <CardTable>
          <header>
            <strong>Produto</strong>
            <strong>Preço</strong>
            <strong>Quantidade</strong>
            <strong>Total</strong>
          </header>

          <main>
            {cartProducts.length === 0 && <strong>Carrinho vazio :C</strong>}
            {cartProducts.length > 0 &&
              cartProducts.map((item) => {
                let productPromoQtt = 0;
                if (item.type === 'juice' && item?.isOfficial) {
                  if (item?.nicType === 1) {
                    productPromoQtt = cartProductsPromo.nicFree[item.id];
                  } else if (item?.nicType === 2) {
                    productPromoQtt = cartProductsPromo.nicSalt[item.id];
                  }
                }

                return (
                  <div key={item.id}>
                    <CartProduct
                      product={item}
                      productPromoQtt={productPromoQtt}
                    />
                    <div>
                      {item.priceDiscount ? (
                        <>
                          <span className="crossedLine">
                            {currencyBRL(item.price, true, true)}
                          </span>
                          <strong>
                            {currencyBRL(item.priceDiscount, true, true)}
                          </strong>
                          <span>
                            ou&nbsp;
                            {currencyBRL(item.priceDiscount, true, true, true)}
                            &nbsp;no depósito
                          </span>
                        </>
                      ) : (
                        <>
                          <strong>{currencyBRL(item.price, true, true)}</strong>
                          <span>
                            ou&nbsp;
                            {currencyBRL(item.price, true, true, true)}
                            &nbsp;no depósito
                          </span>
                        </>
                      )}
                    </div>
                    <div>
                      <IntegerCustom
                        min={0}
                        max={99}
                        styleType="small"
                        onChange={(value) => {
                          if (value > 99) return;
                          handleQuantityChange(item, value);
                        }}
                        currentValue={item.quantity}
                      />
                      <button
                        type="button"
                        onClick={() => handleQuantityChange(item, 0)}
                      >
                        excluir
                      </button>
                    </div>
                    <div>
                      {item.priceDiscount ? (
                        <>
                          <strong>
                            {currencyBRL(
                              item.priceDiscount * item.quantity,
                              true,
                              true,
                            )}
                          </strong>
                          <span>
                            ou&nbsp;
                            {currencyBRL(
                              item.priceDiscount * item.quantity,
                              true,
                              true,
                              true,
                            )}
                            &nbsp;no depósito
                          </span>
                        </>
                      ) : (
                        <>
                          <strong>
                            {currencyBRL(
                              item.price * item.quantity,
                              true,
                              true,
                            )}
                          </strong>
                          <span>
                            ou&nbsp;
                            {currencyBRL(
                              item.price * item.quantity,
                              true,
                              true,
                              true,
                            )}
                            &nbsp;no depósito
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
          </main>
        </CardTable>

        <div>
          <CardDelivery>
            <header>
              <strong>Entrega</strong>
            </header>

            <main>
              <div>
                <span className={cep && 'hidden'}>
                  Insira o seu CEP para calcular o frete.
                </span>

                <CEPDelivery
                  cart={cartProducts}
                  cupomCode={cupom?.code}
                  onCalcDelivery={(options) => {
                    if (location?.cep) {
                      setDeliveryOptions(options);
                    }
                  }}
                  onChangeCep={(value: string) => {
                    setCep(value);
                    setDeliveryOptions(undefined);
                  }}
                  onSearchAddress={(address: CEPResponse | undefined) => {
                    address
                      ? setLocation({ ...address })
                      : setLocation(undefined);
                  }}
                  currentValue={cep}
                />
              </div>

              <span className={deliveryOptions ? '' : 'hidden'}>
                {location ? formatAddress(location) : <>&nbsp;</>}
              </span>

              <DeliveryOptions
                onChange={(option) => {
                  setDelivery(option);
                }}
                options={deliveryOptions}
              />
            </main>
          </CardDelivery>

          <CardTotal>
            <main>
              <div>
                <span>Subtotal</span>

                <div>
                  <span>{currencyBRL(subTotal)}</span>
                  <span>
                    ou&nbsp;
                    {currencyBRL(subTotal, true, true, true)}
                    &nbsp;no depósito
                  </span>
                </div>
              </div>

              <div>
                <span>Cupom de desconto</span>
                <DiscountCupom
                  onCupomChange={(value) => {
                    setCupom({ code: value, discount: undefined });
                    if (deliveryOptions) setDeliveryOptions(undefined);
                  }}
                  currentCupom={cupom.code}
                  onValidateCupom={(result) => {
                    if (result?.discount) {
                      let discountVal = 0;

                      if (result?.discountType === 'fixed') {
                        discountVal = result.discount;
                      } else {
                        discountVal = total;
                        if (cartProductsPromo.discountNicFree)
                          discountVal -= cartProductsPromo.discountNicFree;

                        if (cartProductsPromo.discountNicSalt)
                          discountVal -= cartProductsPromo.discountNicSalt;

                        discountVal *= result.discount;
                      }

                      if (total - discountVal <= 0) {
                        swalFire({
                          title: 'Cupom inválido!',
                          text:
                            'O desconto do cupom é maior que o valor total da sua compra, adicione mais itens ao carrinho ou utilize outro cupom.',
                          icon: 'warning',
                        });
                        return;
                      }
                      setCupom({ ...cupom, ...result, discountVal });
                    }

                    // setCupom({ ...cupom, ...result });
                  }}
                />

                <DiscountDiv isShown={!!cupom?.discountVal}>
                  <span>Desconto {cupom.code}</span>
                  <span>{currencyBRL(cupom?.discountVal || 0)}</span>
                </DiscountDiv>

                {cartProductsPromo.discountNicFree > 0 && (
                  <DiscountDiv isShown>
                    <span>Desconto FreeBase grátis</span>
                    <span>
                      {currencyBRL(cartProductsPromo.discountNicFree)}
                    </span>
                  </DiscountDiv>
                )}

                {cartProductsPromo.discountNicSalt > 0 && (
                  <DiscountDiv isShown>
                    <span>Desconto Nic Salt grátis</span>
                    <span>
                      {currencyBRL(cartProductsPromo.discountNicSalt)}
                    </span>
                  </DiscountDiv>
                )}
              </div>
            </main>

            <footer>
              <strong>Total</strong>
              <div>
                <strong>{currencyBRL(totalCartPrice, true, true)}</strong>
                <strong>
                  ou {currencyBRL(totalDiscount10, true, true)} no depósito
                </strong>
                {totalCartPrice - 25000 < 0 && (
                  <strong>
                    +&nbsp;
                    {currencyBRL((totalCartPrice - 25000) * -1, true, true)}
                    &nbsp;para PAC grátis
                  </strong>
                )}
              </div>
            </footer>
          </CardTotal>

          <div>
            <GreenButton
              text="Fechar pedido"
              onClick={handleFinish}
              fullRound
              isLoading={isLoading}
              loadingLabel="Enviando pedido"
            />

            <NavLink to="/">Continuar comprando</NavLink>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default Cart;
