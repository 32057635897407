import React, { useState, useMemo } from 'react';

import { FiEye, FiEyeOff } from 'react-icons/fi';

import {
  Input,
  PasswordContainer,
  SideButtonContainer,
  GenericContainer,
} from './styles';

interface Props {
  onChangeCustom?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sideButton?: boolean;
}

const GenericInput: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  onChangeCustom,
  value,
  type,
  sideButton,
  placeholder,
  children,
  onKeyDown,
}) => {
  const [passType, setPassType] = useState<string | undefined>(type);

  const renderReturn = (): JSX.Element => {
    if (!onChangeCustom) {
      return <GenericContainer>{children}</GenericContainer>;
    }
    const defInput = (
      <Input
        onKeyDown={onKeyDown}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={(event) => onChangeCustom(event)}
      />
    );

    if (type === 'password') {
      return (
        <PasswordContainer type={passType}>
          <Input
            onKeyDown={onKeyDown}
            type={passType}
            placeholder={placeholder}
            value={value || ''}
            onChange={(event) => onChangeCustom(event)}
          />
          <FiEye
            onClick={() => {
              setPassType('text');
            }}
          />
          <FiEyeOff
            onClick={() => {
              setPassType('password');
            }}
          />
        </PasswordContainer>
      );
    }

    if (sideButton) {
      return (
        <SideButtonContainer>
          {defInput}
          {children}
        </SideButtonContainer>
      );
    }

    return defInput;
  };

  return renderReturn();
};

export default GenericInput;
