import styled, { css } from 'styled-components';
import { fadeIn } from './globalStyle';

export const mediaQueries = [
  {
    maxWidth: 1000,
    screenSize: 1024,
  },
  {
    maxWidth: 1200,
    screenSize: 1280,
  },
  {
    maxWidth: 1300,
    screenSize: 1366,
  },
  {
    maxWidth: 1400,
    screenSize: 1440,
  },
  {
    maxWidth: 1500,
    screenSize: 1600,
  },
  {
    maxWidth: 1800,
    screenSize: 1920,
  },
];

export const ContainerDefault = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  padding: 24px 0;

  animation: ${fadeIn} 0.25s ease forwards 1;
`;

export const ContainerBlock = styled(ContainerDefault)`
  > section,
  > div {
    flex: 1;
    display: flex;

    width: 100%;
  }
`;
