import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  NavLink,
} from 'react-router-dom';

import { api, handleErrors, handleRespReturn } from 'services/api';

import SectionTitle from 'components/SectionTitle';
import { ReactComponent as TelescopeSVG } from 'assets/draws/telescopio.svg';

import { ReactComponent as PedidosSVG } from 'assets/icons/Pedidos.svg';
import { ReactComponent as EntregaSVG } from 'assets/icons/Entrega.svg';
import { ReactComponent as PagamentoSVG } from 'assets/icons/Pagamento.svg';
import { ReactComponent as CadastroSVG } from 'assets/icons/Cadastro.svg';
import { ReactComponent as TrocasSVG } from 'assets/icons/Troca.svg';
import { ReactComponent as LinhasSVG } from 'assets/icons/Ohm.svg';
import { ReactComponent as InsumosSVG } from 'assets/icons/Insumos.svg';
import { ReactComponent as PromocoesSVG } from 'assets/icons/Promocoes.svg';
import { ReactComponent as TermosSVG } from 'assets/icons/Termos.svg';

import Generic from './Generic';
import TermosCondicoes from './TermosCondicoes';
import PoliticaPrivacidade from './PoliticaPrivacidade';

import { Container, Nav } from './styles';

type ReqGetQuestions = (
  target: string,
) => Promise<GenericFaqQuestions[] | undefined>;

export interface PropsGenericFaq {
  handleGetQuestions: ReqGetQuestions;
  title: string;
  thisPath: string;
  getParam: string;
}

export interface GenericFaqQuestions {
  id: number;
  question: string;
  answer: string;
}

// type NavButton =
//   | {
//       title: string;
//       icon: JSX.Element;
//       link: string;
//       page: React.FC;
//     }
//   | {
//       title: string;
//       icon: JSX.Element;
//       link: string;
//       generic: true;
//       page: React.FC<PropsGenericFaq>;
//     };

interface NavButton {
  title: string;
  icon: JSX.Element;
  link: string;
  generic?: string;
  page: React.FC<any>;
}

const navItems: NavButton[] = [
  {
    title: 'Meus pedidos',
    icon: <PedidosSVG />,
    link: '/meus-pedidos',
    generic: '1',
    page: Generic,
  },
  {
    title: 'Entrega',
    icon: <EntregaSVG />,
    link: '/entrega',
    generic: '2',
    page: Generic,
  },
  {
    title: 'Pagamento',
    icon: <PagamentoSVG />,
    link: '/pagamento',
    generic: '3',
    page: Generic,
  },
  {
    title: 'Meus cadastro',
    icon: <CadastroSVG />,
    link: '/meu-cadastro',
    generic: '4',
    page: Generic,
  },
  {
    title: 'Trocas & Devoluções',
    icon: <TrocasSVG />,
    link: '/trocas-e-devolucoes',
    generic: '5',
    page: Generic,
  },
  {
    title: 'Linhas Constellation',
    icon: <LinhasSVG />,
    link: '/linhas',
    generic: '6',
    page: Generic,
  },
  {
    title: 'Insumos & Produtos',
    icon: <InsumosSVG />,
    link: '/insumos-e-produtos',
    generic: '7',
    page: Generic,
  },
  {
    title: 'Nossas promoções',
    icon: <PromocoesSVG />,
    link: '/nossas-promocoes',
    generic: '8',
    page: Generic,
  },
  {
    title: 'Termos & Condições',
    icon: <TermosSVG />,
    link: '/termos-e-condicoes',
    page: TermosCondicoes,
  },
  {
    title: 'Política de Privacidade',
    icon: <TermosSVG />,
    link: '/politica-de-privacidade',
    page: PoliticaPrivacidade,
  },
];

const FAQ: React.FC = () => {
  const { path, url } = useRouteMatch();

  const handleGetQuestions: ReqGetQuestions = async (target) => {
    try {
      const resp = await api().request({
        url: `/faq?target=${target}`,
        method: 'GET',
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored) return undefined;

      return resp.data.content;
    } catch (err) {
      handleErrors(err, true);
    }
    return undefined;
  };

  useEffect(() => {
    document
      .querySelector('#main-content')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Switch>
      <Route exact path={path}>
        <Container>
          <SectionTitle
            icon={<TelescopeSVG />}
            title="Perguntas frequentes"
            margin="0"
          />

          <strong>Tire suas dúvidas sobre os temas abaixo</strong>

          <Nav>
            {navItems.map((item) => (
              <NavLink key={item.link} to={`${path}${item.link}`}>
                {item.icon}
                {item.title}
              </NavLink>
            ))}
          </Nav>
        </Container>
      </Route>

      {navItems.map((item) => {
        const PageComponent = item.page;
        if (item.generic) {
          return (
            <Route key={item.link} path={`${path}${item.link}`}>
              <PageComponent
                handleGetQuestions={handleGetQuestions}
                title={item.title}
                thisPath={item.link}
                getParam={item.generic}
              />
            </Route>
          );
        }
        return (
          <Route key={item.link} path={`${path}${item.link}`}>
            <PageComponent />
          </Route>
        );
      })}

      {/* <Route path={`${path}/politica-de-privacidade`}>
        <PoliticaPrivacidade />
      </Route> */}
    </Switch>
  );
};

export default FAQ;
