import styled, { css } from 'styled-components';
import { fadeInLeft } from 'styles/globalStyle';

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 1080px) {
    align-items: center;
    justify-content: center;
  }
`;

const cardSize = css`
  min-width: 360px;
  min-height: 320px;
  max-height: 320px;
  max-width: 360px;
  margin: 1rem;
  animation: ${fadeInLeft} 0.25s ease 1;
`;

export const AddAddress = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #4f1e66;
  border-radius: 25px;
  backdrop-filter: blur(16px);
  ${cardSize}

  transition: filter 0.25s ease;

  > article {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #00c763;
    width: 80px;
    height: 80px;

    > svg {
      width: 80px;
      height: 80px;
      color: #fff;
    }
  }

  > strong {
    margin-top: 16px;
    text-align: center;
    font-size: 1.2rem;
    color: #58c78f;
  }

  &:hover {
    filter: brightness(75%);
  }
`;

export const CardAddress = styled.div`
  display: flex;
  flex-direction: column;
  ${cardSize}

  > header {
    display: flex;
    align-items: center;

    background: #261131;
    border-radius: 25px 25px 0px 0px;

    padding: 16px 24px;

    > svg {
      width: 40px;
      height: 40px;
    }

    > strong {
      margin-left: 16px;
      color: #58c78f;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: auto;

      > button {
        background-color: transparent;
        border: 0;
        text-decoration: underline;
        color: #58c78f;

        transition: filter 0.25s ease;
        &:hover {
          filter: brightness(125%);
        }
        + button {
          margin-left: 8px;
        }
      }
    }
  }

  > main {
    flex: 1;

    background: #4f1e66;
    border-radius: 0 0 25px 25px;
    backdrop-filter: blur(16px);

    > div:nth-of-type(1) {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      border-bottom: 1px solid #685771;

      > strong {
        color: #f5f5f5;
        margin-bottom: 16px;
      }

      > span {
        color: #f5f5f5;
        font-size: 0.9rem;
        + span {
          margin-top: 8px;
        }
      }
    }

    > div:nth-of-type(2) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 24px;
    }
  }
`;
