/* eslint no-continue: 0 */ // --> OFF

import { CartItemJuice } from 'hooks/Cart';

const isBlackFriday = false;

let discountQuantity = [4, 9];
let discountFree = [1, 3];

if (isBlackFriday) {
  discountQuantity = [3, 9];
  discountFree = [1, 4];
}

interface JuiceSameProp {
  [key: number]: CartItemJuice[];
}

export const processProducts = (
  cartI: CartItemJuice[],
  nicType = 1,
): JuiceSameProp => {
  const result: JuiceSameProp = {};

  cartI.forEach((item) => {
    if (!item.isOfficial) return;
    const thisKey = parseInt(`${item.bottle}`, 10);
    // if (nicType === 2) thisKey = parseInt(`${item.bottle}${item.nic}`, 10);
    result[thisKey] =
      result[thisKey] && result[thisKey].length > 0
        ? [...result[thisKey], item]
        : [item];
  });

  return result;
};

// 4 9 17 24
// 12 * 2
// qtd = 4 + 1 || qtd = 9 ~ 12
// discount[0] + 1

export interface ApplyDiscount {
  [key: string]: number;
}

interface CheckPromoResult {
  products: ApplyDiscount;
  discount: number;
}

export const checkPromoNormal = (
  productSameProp: JuiceSameProp,
): CheckPromoResult => {
  const productToApplyDiscount: ApplyDiscount = {};
  // eslint-disable-next-line
  let discount = 0;

  // console.log('---------------------------------------------------');

  Object.keys(productSameProp).forEach((keyString) => {
    const samePropDiscount: ApplyDiscount = {};
    const key = parseInt(keyString, 10);
    const productQuantity = productSameProp[key].reduce(
      (prev, current) => {
        return { quantity: prev.quantity + current.quantity };
      },
      { quantity: 0 },
    ).quantity;

    // console.log(`MyList ${key}`, productSameProp[key], productQuantity);
    let quantity = productQuantity;
    let freeProducts = 0;
    while (quantity >= discountQuantity[0] + 1) {
      if (quantity > discountQuantity[1]) {
        let removeQuantity = discountQuantity[1] + discountFree[1];

        if (quantity === removeQuantity) {
          removeQuantity = quantity;
        }
        if (quantity === removeQuantity - 1) {
          removeQuantity = quantity;
        }

        if (quantity === removeQuantity - 2) {
          quantity -= removeQuantity - 2;
          freeProducts += 2;
          continue;
        }
        /*
        if (quantity === removeQuantity - 3) {
          quantity -= removeQuantity - 3;
          freeProducts += 2;
          continue;
        }
        */
        quantity -= removeQuantity;
        freeProducts += removeQuantity - discountQuantity[1];
        continue;
      }

      if (quantity >= discountQuantity[0] + 1) {
        quantity -= discountQuantity[0] + 1;
        freeProducts += discountFree[0];
      }

      if (quantity <= discountQuantity[0]) break;
    }

    // console.log(`Free: ${freeProducts} ${quantity} ${productQuantity}`);

    productSameProp[key]
      .sort((a, b) => (a.price > b.price ? 1 : -1))
      .forEach((item) => {
        if (freeProducts > 0) {
          if (freeProducts > item.quantity) {
            samePropDiscount[item.id] = item.quantity;
            freeProducts -= item.quantity;
            discount += item.price * item.quantity;
          } else {
            samePropDiscount[item.id] = freeProducts;
            discount += item.price * freeProducts;
            freeProducts = 0;
          }
        }
      });

    Object.keys(samePropDiscount).forEach((item) => {
      productToApplyDiscount[item] = samePropDiscount[item];
    });
  });

  // console.log(`????`, productToApplyDiscount);
  // console.log('---------------------------------------------------');
  return { products: productToApplyDiscount, discount };
};

export const checkPromoBlackFriday = (
  productSameProp: JuiceSameProp,
): CheckPromoResult => {
  const productToApplyDiscount: ApplyDiscount = {};
  // eslint-disable-next-line
  let discount = 0;

  // console.log('---------------------------------------------------');

  Object.keys(productSameProp).forEach((keyString) => {
    const samePropDiscount: ApplyDiscount = {};
    const key = parseInt(keyString, 10);
    const productQuantity = productSameProp[key].reduce(
      (prev, current) => {
        return { quantity: prev.quantity + current.quantity };
      },
      { quantity: 0 },
    ).quantity;

    // console.log(`MyList ${key}`, productSameProp[key], productQuantity);
    let quantity = productQuantity;
    let freeProducts = 0;
    while (quantity >= discountQuantity[0] + 1) {
      console.log('qt', quantity, 'free', freeProducts);

      // if (quantity > discountQuantity[1] + 2) { ANTES OK!
      if (quantity > discountQuantity[1]) {
        const removeQuantityMax = discountQuantity[1] + discountFree[1];
        const realQuantity = quantity - removeQuantityMax;

        let removeQuantity = removeQuantityMax;

        if (quantity === removeQuantity) {
          console.log('QT == REMOVE QT');
          removeQuantity = quantity;
        }
        if (quantity === removeQuantity - 1) {
          console.log('QT == REMOVE QT - 1');
          removeQuantity = quantity;
        }

        if (quantity === removeQuantity - 2) {
          console.log('STOP - 2');
          quantity -= removeQuantity - 2;
          freeProducts += 2;
          continue;
        }

        if (quantity === removeQuantity - 3) {
          console.log('STOP - 3');
          quantity -= removeQuantity - 3;
          freeProducts += 2;
          continue;
        }

        quantity -= removeQuantity;
        freeProducts += removeQuantity - discountQuantity[1];
        continue;

        // let removeQuantity = 13;

        // if (quantity === 12) removeQuantity = 12;
        // freeProducts += 1;
        // freeProducts += removeQuantityMax - quantity + 2;
        // quantity -= removeQuantity;

        /*
        if (quantity === 12) removeQuantity = 12;

        if (quantity === 11) {
          removeQuantity = 11;
          quantity -= removeQuantity;
          freeProducts += discountFree[1];
          continue;
        }
        */
        console.log('REAL QUANTITY', realQuantity);
        if (quantity === 12) {
          freeProducts += discountFree[1] - 1;
          console.log('QT 12');
          quantity -= 12;
          continue;
        }
        if (quantity === 13) {
          console.log('QT 13');
          freeProducts += discountFree[1];
          quantity -= 13;
          continue;
        } else {
          console.log('QT !!', quantity);
          freeProducts += discountFree[1];
          quantity -= 13;
        }
      }

      if (quantity >= discountQuantity[0] + 1) {
        quantity -= discountQuantity[0] + 1;
        freeProducts += discountFree[0];
      }

      if (quantity <= discountQuantity[0]) break;
    }

    // console.log(`Free: ${freeProducts} ${quantity} ${productQuantity}`);

    productSameProp[key]
      .sort((a, b) => (a.price > b.price ? 1 : -1))
      .forEach((item) => {
        if (freeProducts > 0) {
          if (freeProducts > item.quantity) {
            samePropDiscount[item.id] = item.quantity;
            freeProducts -= item.quantity;
            discount += item.price * item.quantity;
          } else {
            samePropDiscount[item.id] = freeProducts;
            discount += item.price * freeProducts;
            freeProducts = 0;
          }
        }
      });

    Object.keys(samePropDiscount).forEach((item) => {
      productToApplyDiscount[item] = samePropDiscount[item];
    });
  });
  return { products: productToApplyDiscount, discount };
};

export const checkPromo = (
  productSameProp: JuiceSameProp,
): CheckPromoResult => {
  if (isBlackFriday) {
    return checkPromoBlackFriday(productSameProp);
  }
  return checkPromoNormal(productSameProp);
};
