import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #00c763;
  border-radius: 20px;
  width: 250px;
  height: 50px;

  transition: filter 0.25s ease;

  > strong {
    font-size: 1.3rem;
    color: #ffffff;
    margin-right: 16px;
  }

  > svg {
    width: 40px;
    height: 40px;
    .background {
      fill: transparent;
    }
  }

  &:hover {
    filter: brightness(75%);
  }
`;
