import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 24px 100px;

  > a {
    text-decoration: underline;
    font-size: 0.8rem;
    color: #00c763;

    transition: filter 0.25s ease;
    &:hover {
      filter: brightness(75%);
    }
  }

  > span {
    font-size: 0.8rem;
    color: #00c763;
    margin: 0 8px;
  }

  @media (max-width: 1000px) {
    margin: 24px auto;
  }
`;
