import React, { useCallback } from 'react';

// eslint-disable-next-line
import { default as NumberFormat } from 'react-number-format';
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onChangeInput(value: string): void;
  currentValue?: string | number;
  customOnBlur?(): void;
  onBlur?(): any;
  onFocus?(): any;
  getReference?(ref: HTMLInputElement): void;
  disabled?: boolean;
  onlyType?: number;
  placeholder?: string;
  // ref?(reference: React.RefObject<HTMLInputElement>): void;
}

const MaskDoc: React.FC<InputProps> = ({
  currentValue = '',
  getReference,
  onChangeInput,
  disabled = false,
  customOnBlur,
  onBlur,
  onFocus,
  onlyType = 0,
  placeholder = '',
}: InputProps) => {
  const formatCPF = useCallback((val: string) => {
    return `${val.substr(0, 3).padEnd(3, '_')}.${val
      .substr(3, 3)
      .padEnd(3, '_')}.${val.substr(6, 3).padEnd(3, '_')}-${val
      .substr(9, 2)
      .padEnd(2, '_')}`;
  }, []);

  const formatCNPJ = useCallback((val: string) => {
    return `${val.substr(0, 2).padEnd(2, '_')}.${val
      .substr(2, 3)
      .padEnd(3, '_')}.${val.substr(5, 3).padEnd(3, '_')}/${val
      .substr(8, 4)
      .padEnd(4, '_')}-${val.substr(12, 2).padEnd(2, '_')}`;
  }, []);

  const handleFormat = (value: string): string => {
    const valRgxp = `${value}`.replace(/[^\d]+/g, '');
    if (!onlyType) {
      if (valRgxp.length > 11) {
        return formatCNPJ(valRgxp);
      }

      return formatCPF(valRgxp);
    }

    if (onlyType === 1) {
      return formatCPF(valRgxp);
    }
    return formatCNPJ(valRgxp);
  };

  return (
    <NumberFormat
      onBlur={onBlur}
      onFocus={onFocus}
      getInputRef={(el: HTMLInputElement) => {
        if (getReference) getReference(el);
      }}
      // onBlur={customOnBlur}
      format={handleFormat}
      mask="_"
      placeholder={placeholder}
      value={currentValue}
      onValueChange={(values) => {
        // console.log(values);
        onChangeInput(values.value);
      }}
      disabled={disabled}
    />
  );
};
export default MaskDoc;
