import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
`;

interface ButtonProps {
  isActive: boolean;
}

export const CircleButton = styled.button<ButtonProps>`
  cursor: pointer;
  background-color: transparent;

  color: #fff;
  font-weight: bold;

  font-size: 0.8rem;
  border: 2px solid #ffffff;
  border-radius: 50%;

  /* padding: 1rem; */

  margin: 4px;
  min-width: 47.5px;
  min-height: 47.5px;

  /* max-width: 42px;
  max-height: 42px; */
  /* width: 100%;
  height: 100%; */

  display: inline;
  vertical-align: middle;
  text-align: center;

  transition: background-color 0.25s ease, opacity 0.25s ease;

  /* &:focus {
    opacity: 0.75;
  } */

  &:hover {
    opacity: 0.5;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #00c763;
    `}
`;

interface ButtonColorProps {
  isActive: boolean;
  hex: string;
  name?: string;
}

export const CircleButtonColor = styled.button<ButtonColorProps>`
  cursor: pointer;

  /* border: 1px solid #ffffff; */
  border-radius: 50%;

  /* padding: 1rem; */

  margin: 4px;
  max-width: 45px;
  max-height: 45px;
  min-width: 45px;
  min-height: 45px;
  opacity: 0.5;
  filter: blur(1px);
  transition: opacity 0.25s ease, filter 0.25s ease, border 0.25s ease;

  > span {
    user-select: none;
    position: absolute;

    bottom: calc(-50% - 20px);
    left: -20px;
    right: -20px;

    color: #fff;
    background-color: #000;
    border-radius: 8px;
    text-align: center;
    padding: 6px 8px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s;

    &::after {
      content: '';
      position: absolute;
      left: calc(50% - 5px);
      top: -10px;

      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #000 transparent;
    }
  }

  /* &:focus {
    opacity: 0.8;
  } */

  &:hover {
    opacity: 0.7;
    filter: blur(0);
    > span {
      visibility: visible;
      opacity: 1;
    }
  }

  ${(props) =>
    props.isActive &&
    css`
      filter: blur(0);
      opacity: 1;
      border: 2px solid #fff;
    `}

  ${({ hex, name }) =>
    !name || !['rainbow', 'Rainbow'].includes(name)
      ? css`
          background: linear-gradient(
            45deg,
            rgba(0, 0, 0, 1) 0%,
            #${hex} 30%,
            #${hex} 50%,
            #${hex} 60%,
            rgba(0, 0, 0, 1) 100%
          );
        `
      : css`
          background: linear-gradient(
            45deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(102, 13, 142, 1) 22%,
            rgba(13, 76, 142, 1) 33%,
            rgba(13, 139, 142, 1) 48%,
            rgba(13, 142, 80, 1) 58%,
            rgba(85, 142, 13, 1) 70%,
            rgba(142, 97, 13, 1) 78%,
            rgba(123, 11, 113, 1) 84%,
            rgba(0, 0, 0, 1) 100%
          );
        `}
`;
