/* eslint-disable react/destructuring-assignment */

import React, { useState, useEffect } from 'react';

import { useFilterProps, AccessoryProp } from 'hooks/filterProps';
import { ReactComponent as ArrowSVG } from 'assets/icons/arrowRight.svg';
import InputCurrency from 'components/Input/MaskedInputs/Currency';

import {
  MenuButton,
  List,
  FakeCheckBox,
  DefListContainer,
  ProgressBar,
} from '../styles';

import { Container, PriceFilter } from './styles';

interface AccessoryFilter {
  priceMin?: number;
  priceMax?: number;
  subType: number[];
}

interface ListShown {
  subType?: boolean;
}

const defEmptyArr: number[] = [];

interface Props {
  subType?: number[];
  handleOnChange: (filters: AccessoryFilter) => void;
}

const FilterAccessory: React.FC<Props> = ({ handleOnChange, subType }) => {
  const { props: propsHook } = useFilterProps();
  const [props, setProps] = useState<AccessoryProp | undefined>(
    propsHook?.accessory,
  );

  const [listShown, setListShown] = useState<ListShown>({});
  const [filter, setFilter] = useState<AccessoryFilter>({
    subType: subType || [],
  });

  const handleClickList = (key: keyof AccessoryFilter, idx: number) => {
    if (key === 'priceMax' || key === 'priceMin') return;
    let selectedArr = filter[key];

    if (selectedArr.includes(idx)) {
      selectedArr = selectedArr.filter((item) => item !== idx);
    } else {
      selectedArr = [...selectedArr, idx];
    }

    setFilter({ ...filter, [key]: selectedArr });
  };

  const handleCleanFilter = (key: keyof AccessoryFilter) => {
    if (key === 'priceMax' || key === 'priceMin') return;

    setFilter({ ...filter, [key]: defEmptyArr });
  };

  const handleToggleShowFilter = (key: keyof ListShown) => {
    setListShown({ ...listShown, [key]: !listShown[key] });
  };

  const [timerFilter, setTimerFilter] = useState<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (timerFilter) {
      clearTimeout(timerFilter);
      setTimerFilter(undefined);
    }
    // dispatch({ type: 'HANDLE_LOADING_CHANGE', payload: true });

    const newTimer = setTimeout(() => {
      setTimerFilter(undefined);
      handleOnChange(filter);
    }, 2500);

    setTimeout(() => {
      setTimerFilter(undefined);
      setTimerFilter(newTimer);
    }, 10);
    // eslint-disable-next-line
    return () => {
      setTimerFilter(undefined);
      if (timerFilter) clearTimeout(timerFilter);
      if (newTimer) clearTimeout(newTimer);
    };
  }, [filter]);

  // useEffect(() => {
  //   if (subCategory) {
  //     setFilter({ ...filter, subType });
  //     setListShown({ ...listShown, subType: true });
  //   }
  // }, [subCategory]);

  useEffect(() => {
    if (subType) {
      setFilter({ ...filter, subType });
      setListShown({ ...listShown, subType: true });
    }
  }, [subType]);

  useEffect(() => {
    if (!props && propsHook?.accessory) {
      setProps(propsHook.accessory);
    }
  }, [props]);

  return (
    <Container>
      <ProgressBar isActive={!!timerFilter} />

      <PriceFilter>
        <strong>Preço</strong>

        <div>
          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMin: value });
            }}
            placeholder="Mínimo"
            value={filter?.priceMin}
          />

          <InputCurrency
            onChange={(value) => {
              setFilter({ ...filter, priceMax: value });
            }}
            placeholder="Máximo"
            value={filter?.priceMax}
          />
        </div>
      </PriceFilter>

      {props?.subType && (
        <DefListContainer isShown={listShown.subType}>
          <MenuButton>
            <div>
              <button
                type="button"
                onClick={() => handleToggleShowFilter('subType')}
              >
                <ArrowSVG />
                Acessórios
              </button>

              <button
                type="button"
                onClick={() => handleCleanFilter('subType')}
              >
                Limpar
              </button>
            </div>
          </MenuButton>

          <List>
            {props.subType.map((item: any) => (
              <button
                key={item.id}
                type="button"
                onClick={() => handleClickList('subType', item.id)}
              >
                <FakeCheckBox selected={filter.subType.includes(item.id)} />
                {item.name}
              </button>
            ))}
          </List>
        </DefListContainer>
      )}
    </Container>
  );
};

export default FilterAccessory;
