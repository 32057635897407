import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';

import FocusLock from 'react-focus-lock';

import { Container, BackDrop, Modal } from './styles';

export interface Props {
  isShown: boolean;
  returnTrigger(event: boolean): void;
  disableClickEvent?: boolean;
  disableMinSize?: boolean;
}

const GenericModal: React.FC<Props> = ({
  children,
  isShown,
  returnTrigger,
  disableClickEvent,
  disableMinSize,
}) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    if (event.keyCode === 27 && isShown) {
      event.preventDefault();
      returnTrigger(false);
    }
    if (disableClickEvent) return;
    if (event.keyCode === 13 && isShown) {
      event.preventDefault();
      returnTrigger(true);
    }
  };

  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = 'hidden';
    }
    document.body.style.overflow = 'unset';
  }, [isShown]);

  const modal = (
    <>
      <BackDrop isShown={isShown} />
      <Container isShown={isShown}>
        <FocusLock className="flex-all" disabled={!isShown}>
          <Modal onKeyDown={onKeyDown} disableMinSize={disableMinSize}>
            <FiX onClick={() => returnTrigger(false)} />
            {children}
          </Modal>
        </FocusLock>
      </Container>
    </>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default GenericModal;
