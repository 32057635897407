import React, { createContext, useCallback, useState, useContext } from 'react';
import { api } from 'services/api';

interface SignInCredentials {
  username: string;
  password: string;
  lastLogin: number;
}

interface User {
  name: string;
  fav: number;
}

type AuthState =
  | { token: string; user: User }
  | { token: undefined; user: undefined };
// interface AuthState {
//   token?: string;
// }

interface AuthContextData {
  token?: string;
  user?: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Constellation:Token');
    const storageUser = localStorage.getItem('@Constellation:User');
    const user = storageUser ? JSON.parse(storageUser) : undefined;

    if (token && user) {
      if (!user.lastLogin) return {} as AuthState;
      const lastLogin = new Date(user.lastLogin);
      lastLogin.setDate(lastLogin.getDate() + 30);
      const now = new Date().getTime();

      if (now <= lastLogin.getTime()) {
        return { token, user };
      }
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ username, password }) => {
    const response = await api().post('/user/login', {
      username,
      password,
    });

    const { content, success, exception, exceptionText } = response.data;

    if (success && exception) {
      // console.log(response);
      throw Error(`Exceção ${exceptionText}.`);
    }

    if (!success) {
      // console.log(response);
      throw Error('Dados inválidos.');
    }

    const { token, user } = content;
    const userObj = { ...user, lastLogin: new Date().getTime() };
    // console.log(content);

    localStorage.setItem('@Constellation:Token', token);
    localStorage.setItem('@Constellation:User', JSON.stringify(userObj));

    setData({ token, user: userObj });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Constellation:Token');
    localStorage.removeItem('@Constellation:User');
    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token: data?.token,
        user: data?.user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
