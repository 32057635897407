import React from 'react';
import { ReactComponent as WppSVG } from 'assets/socialMedia/wppButton.svg';

import { Container } from './styles';

const WppHelpButton: React.FC = () => {
  const handleClick = (): void => {
    window.open('https://api.whatsapp.com/send?phone=5548991501656', '_blank');
  };

  return (
    <Container onClick={handleClick}>
      <strong>Preciso de ajuda</strong>
      <WppSVG />
    </Container>
  );
};

export default WppHelpButton;
