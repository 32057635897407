import styled from 'styled-components';
import { fadeIn } from 'styles/globalStyle';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 8px;

    animation: ${fadeIn} forwards 0.25s ease;

    > * {
      flex: 1;
      font-size: 1rem;
      color: #f5f5f5;

      &:nth-child(1) {
        flex: 3;
      }

      &:nth-child(2) {
        flex: 2;
        text-align: center;
      }

      &:nth-child(3) {
        flex: 2;
        text-align: right;
      }

      + * {
        margin-left: 16px;
      }
    }

    + div {
      border-top: 1px solid #685771;
    }
  }
`;
