import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

import { useAuth } from 'hooks/auth';
import { useFavorites } from 'hooks/Favorites';

import { api, handleRespReturn, handleErrors } from 'services/api';

import ProductThumbSmall from 'components/Product/ThumbSmall';
import SkeletonContainer from 'components/Skeleton';

import { DefaultData as DefaultProduct } from 'components/Product/ProductDTO';

import GreenButton from 'components/Button/GreenButton';
import SectionTitle from 'components/SectionTitle';
import { ReactComponent as RocketSVG } from 'assets/draws/espaconave.svg';

import { Container, Header, SectionList } from './styles';

const Favorites: React.FC = () => {
  const history = useHistory();
  const { token } = useAuth();
  const { favorites } = useFavorites();
  const [current, setCurrent] = useState(1);

  const [products, setProducts] = useState<DefaultProduct[] | undefined>(
    undefined,
  );

  const handleGet = async (): Promise<void> => {
    if (products) return;
    if (favorites.length === 0) {
      setProducts([]);
      // Swal.fire(
      //   'Nenhum favorito encontrado.',
      //   'Para visualizar seus favoritos, clique no coração no card dos produtos',
      //   'info',
      // );
      return;
    }

    let query = '';

    // if (!token) {
    query = `?idProducts=${JSON.stringify(favorites)}`;
    // }

    try {
      const resp = await api().request({
        url: `/products/favorites${query}`,
        method: 'GET',
      });

      const isErrored = handleRespReturn(resp);
      if (isErrored) return;

      setProducts(resp.data.content);
      // console.log(resp.data.content);
    } catch (err) {
      handleErrors(err, true);
    }
  };

  const handleSortChange = (type: number): void => {
    if (!products) return;
    setCurrent(type);
    let newProducts = [...products];

    if (type === 1) {
      newProducts = newProducts.sort((a, b) => {
        return a.data.price > b.data.price ? 1 : -1;
      });
    }

    if (type === 2) {
      newProducts = newProducts.sort((a, b) => {
        return a.data.price > b.data.price ? -1 : 1;
      });
    }

    if (type === 3) {
      newProducts = newProducts.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });
    }

    if (type === 4) {
      newProducts = newProducts.sort((a, b) => {
        const dateA = new Date(a.dtAdd).getTime();
        const dateB = new Date(b.dtAdd).getTime();
        return dateA > dateB ? 1 : -1;
      });
    }

    setProducts([...newProducts]);
  };

  const handleRedirect = () => {
    if (token) {
      history.push('/profile');
      return;
    }

    history.push('/user');
  };

  useEffect(() => {
    if (!products) handleGet();
  }, []);

  return (
    <Container>
      <SectionTitle icon={<RocketSVG />} title="Meus Favoritos" margin="0" />

      <Header>
        <button type="button" onClick={handleRedirect}>
          Ir para área do cliente
        </button>

        <div>
          <GreenButton
            onClick={() => handleSortChange(1)}
            icon={<IoMdArrowDropdown />}
            outline={current !== 1}
            text="R$"
          />
          <GreenButton
            onClick={() => handleSortChange(2)}
            outline={current !== 2}
            icon={<IoMdArrowDropup />}
            text="R$"
          />
          <GreenButton
            onClick={() => handleSortChange(3)}
            outline={current !== 3}
            text="Destaques"
          />
          <GreenButton
            onClick={() => handleSortChange(4)}
            outline={current !== 4}
            text="Recentes"
          />
        </div>
      </Header>

      <SectionList>
        {products === undefined && (
          <>
            <SkeletonContainer total={4} maxHeight="315px" />
            <SkeletonContainer total={4} maxHeight="315px" />
          </>
        )}

        {products && products.length === 0 && (
          <strong>Nenhum favorito encontrado.</strong>
        )}

        {products && products.length > 0 && (
          <div>
            {products.map((item) => (
              <ProductThumbSmall
                key={item.id}
                {...item}
                // id={item.id}
                // name={item.name}
                // type={item.type}
                // data={item.data}
                // img={item.img}
              />
            ))}
          </div>
        )}
      </SectionList>
    </Container>
  );
};

export default Favorites;
