import React, { createContext, useEffect, useState, useContext } from 'react';
import { api, handleErrors, handleRespReturn } from '../services/api';

type Id = { id: number };

export interface Color extends Id {
  code: string;
  name: string;
}

interface DefaultProp extends Id {
  priceAdd: number;
}

export interface Mint extends DefaultProp {
  name: string;
}

export interface Proportion extends DefaultProp {
  name: string;
}

export interface BottleSize extends DefaultProp {
  name: string;
  amount: number;
}

export interface Nic extends DefaultProp {
  amount: number;
}

export type PropsTypes = Color | BottleSize | Nic | Proportion;

interface Prices {
  [key: string]: number;
}

export interface PropsData {
  colors?: Color[];
  bottles?: BottleSize[];
  nic?: Nic[];
  proportion?: Proportion[];
  mint?: Mint[];
  prices?: Prices;
  serieUseCalc?: number[];
}

type GetProps = (
  key: keyof PropsData,
  idList: number[],
) => PropsTypes[] | undefined;

interface ProductPropsContextData {
  props?: PropsData;
  updateProps: () => void;
  getProps: GetProps;
}

const ProductPropsContext = createContext<ProductPropsContextData>(
  {} as ProductPropsContextData,
);

const ProductPropsProvider: React.FC = ({ children }) => {
  const [props, setProps] = useState<PropsData | undefined>(():
    | PropsData
    | undefined => {
    let parsedStorage: PropsData | undefined;
    const storage = sessionStorage.getItem('@Constellation:Props');
    // console.log(storage);
    if (storage) {
      parsedStorage = JSON.parse(storage);
      // console.log('MyProps', parsedStorage);
      if (parsedStorage) {
        return parsedStorage;
      }
    }
    api()
      .get('/products/props')
      .then((resp) => {
        // console.log('fixme', resp);
        if (!resp.data.content) return undefined;
        sessionStorage.setItem(
          '@Constellation:Props',
          JSON.stringify({ ...resp.data.content }),
        );
        return { ...resp.data.content };
      })
      .catch((resp) => {
        // console.log('Cant get props', resp);
        if (parsedStorage) return { ...parsedStorage };
        return undefined;
      });

    if (parsedStorage) return { ...parsedStorage };
    return undefined;
  });

  const updateProps = async (): Promise<void> => {
    try {
      const resp = await api().get('/products/props');
      // console.log('RespApiProps', resp.data);
      const isErrored = handleRespReturn(resp);
      if (isErrored) return;
      sessionStorage.setItem(
        '@Constellation:Props',
        JSON.stringify({ ...resp.data.content }),
      );
      setProps({ ...resp.data.content });
    } catch (err) {
      handleErrors(err);
    }
  };

  const getProps: GetProps = (key, idList) => {
    if (!props) return undefined;
    if (!props[key]) return [];
    return (props[key] as PropsTypes[]).filter((item) =>
      idList.includes(item.id),
    );
  };

  useEffect(() => {
    // console.log('HookPropsProduct', props);
    if (!props) {
      updateProps();
    }
  }, [props]);

  return (
    <ProductPropsContext.Provider
      value={{
        props,
        updateProps,
        getProps,
      }}
    >
      {children}
    </ProductPropsContext.Provider>
  );
};

function useProductProps(): ProductPropsContextData {
  const context = useContext(ProductPropsContext);

  if (!context) {
    throw new Error(
      'useProductProps must be used within an ProductPropsProvider',
    );
  }

  return context;
}

export { ProductPropsProvider, useProductProps };
