import styled, { css } from 'styled-components';

export const SideButtonContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: 40px;
  min-height: 40px;
  > input {
    border-right: 0;
    border-radius: 5px 0 0 5px;
  }
  > * {
    max-height: 40px;
    min-height: 40px;
  }
`;

export const PasswordContainer = styled.div<{ type?: string }>`
  position: relative;

  > input {
    padding-right: 32px;
  }

  > svg {
    position: absolute;
    cursor: pointer;

    user-select: none;
    pointer-events: none;
    opacity: 0;
    top: 50%;
    right: 8px;

    width: 24px;
    height: 24px;

    transform: translateY(-50%);

    transition: filter 0.25s ease, opacity 0.25s ease;

    &:nth-of-type(1) {
      color: #95989a;
    }

    &:nth-of-type(2) {
      color: #4f1e66;
    }

    ${({ type }) =>
      type !== undefined &&
      css`
        &:nth-of-type(${type === 'password' ? 1 : 2}) {
          opacity: 1;
          pointer-events: unset;
        }
      `}
    &:hover {
      filter: brightness(75%);
    }
  }
`;

export const Input = styled.input`
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 8px;
  color: #261231;
  font-weight: bold !important;
  font-size: 0.9rem;
  width: 100%;

  &::placeholder {
    color: #261231;
    font-weight: bold;
  }

  &.invert {
    background-color: #4f1e66;
    color: #fff;

    &::placeholder {
      color: #fff;
      font-weight: bold;
    }
  }
`;

export const GenericContainer = styled.div`
  input {
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 8px;
    font-size: 0.9rem;
    width: 100%;
  }
`;
